<template>
    <div class="wrapper__group__button">
        <p
            v-if="userStore?.userInfo?.affiliationKbn !== 2"
            :class="{ disabled: userStore?.userInfo?.affiliationKbn === 2 }"
            @click="emits('handleUpdate')"
        >
            {{ t('flyers.table.labelButton2') }}
        </p>
        <p @click="emits('handleReference')">{{ t('flyers.table.labelButton1') }}</p>
        <p
            v-if="userStore?.userInfo?.affiliationKbn !== 2"
            :class="{ disabled: userStore?.userInfo?.affiliationKbn === 2 }"
            @click="emits('handleCreate')"
        >
            {{ t('flyers.table.labelButton3') }}
        </p>
        <p @click="emits('handleReferenceProduct')">
            {{ t('flyers.table.labelButton4') }}
        </p>
        <p
            v-if="userStore?.userInfo?.affiliationKbn !== 2"
            :class="{ disabled: userStore?.userInfo?.affiliationKbn === 2 }"
            @click="emits('handleExportCSV')"
        >
            {{ t('flyers.table.labelButton5') }}
        </p>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../../../stores/UserStore';
const emits = defineEmits(['handleReference', 'handleUpdate', 'handleCreate', 'handleReferenceProduct', 'handleExportCSV']);

// i18n
const { t } = useI18n();

// store
const userStore = useUserStore();
</script>

<style lang="css" src="./GroupButton.css" scoped></style>
