<template>
    <div class="wrapper__flyer__registration">
        <Teleport to="body">
            <component :is="DialogCustom" :show="flyerRegistrationStores?.isModalNavigate" :z-index="990">
                <BaseDialogNavigate></BaseDialogNavigate>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="flyerRegistrationStores?.isModalPreview" :z-index="991">
                <BaseDialogPhone :message="flyerRegistrationStores?.message" :show="true"></BaseDialogPhone>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="flyerRegistrationStores?.isModalPreviewImage" :z-index="992">
                <BaseDialogPhone :show="false"></BaseDialogPhone>
            </component>
        </Teleport>
        <div class="container">
            <div class="content__flyer__registration">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!-- TODO - β1,β2は、何も表示しない、もしくは固定メッセージ -->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <ThePaper class="section2">
                    <ContentFlyer></ContentFlyer>
                </ThePaper>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onUnmounted } from 'vue';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import ContentFlyer from './components/content/ContentFlyer.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import DialogCustom from '../../components/dialog/DialogCustom.vue';
import BaseDialogNavigate from './components/dialog/BaseDialogNavigate.vue';
import BaseDialogPhone from './components/dialog/BaseDialogPhone.vue';
import { useFlyerRegistrationStores } from '../../stores/FlyerRegistrationStores.js';
import { useCommonStores } from '../../stores/CommonStores.js';

const flyerRegistrationStores = useFlyerRegistrationStores();
const commonStores = useCommonStores();

// unmouted
onUnmounted(() => {
    flyerRegistrationStores?.reset();
});
</script>

<style lang="css" src="./FlyerRegistrationPage.css" scoped></style>
