<template>
    <div class="wrapper__staff--search">
        <div class="search__staff--title">
            <TheTitle class="header">
                <h2>{{ t('shopStaff.titleSearch') }}</h2>
            </TheTitle>
        </div>
        <div class="search__staff--list">
            <div class="search__staff--item">
                <label class="item__label">{{ t('shopStaff.labelShopName') }}</label>
                <div class="item__selection">
                    <input
                        id="focus--item"
                        v-model.trim="shopName"
                        class="item__input"
                        :placeholder="t('shopStaff.placeholder1')"
                    />
                </div>
            </div>
            <div class="search__staff--item">
                <label class="item__label">{{ t('shopStaff.labelAddress') }}</label>
                <div class="item__selection">
                    <input v-model.trim="address" class="item__input" :placeholder="t('shopStaff.placeholder1')" />
                </div>
            </div>
        </div>
        <div class="actions">
            <TheButton class="search__staff--button" :disabled="false" @click="handleSearch">
                <template #left-icon>
                    <img :src="iconSearch" alt="search-icon" />
                </template>
                <p>{{ t('shopStaff.labelSearchButton') }}</p>
            </TheButton>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import iconSearch from '../../../../assets/icons/search.svg';
import { useShopStaffStores } from '../../../../stores/ShopStaffStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';

// i18n
const { t } = useI18n();

// stores
const userStore = useUserStore();
const shopStaffStores = useShopStaffStores();

// ref
const shopName = ref('');
const address = ref('');

// onMounted
onMounted(() => {
    document?.querySelector('#focus--item')?.focus();
});

// function
const handleSearch = () => {
    shopStaffStores?.setShopParams({
        shopName: shopName.value,
        address1: address.value,
        userId: userStore?.userId,
    });
    shopStaffStores?.setShopCode('');
    shopStaffStores?.setIsSearchShop();
};
</script>

<style lang="css" src="./BaseSearchStaff.css" scoped></style>
