<template>
    <div class="wrapper__teacher--search">
        <div class="search__teacher--title">
            <TheTitle class="header">
                <h2>{{ t('schoolStaff.titleSearch') }}</h2>
            </TheTitle>
        </div>
        <div class="search__teacher--list">
            <div class="search__teacher--item">
                <label class="item__label">
                    {{ t('schoolStaff.labelSchoolCode') }}
                    <strong>{{ t('common.required') }}</strong>
                </label>
                <div class="item__selection">
                    <input
                        v-model.trim="schoolCode"
                        class="item__input"
                        maxlength="13"
                        :placeholder="t('schoolStaff.placeholder1')"
                        :required="validate.schoolCode"
                    />
                    <p v-if="validate.schoolCode" class="error__message">
                        {{ t('schoolStaff.dialog.contentError') }}
                    </p>
                </div>
            </div>
        </div>
        <div class="actions">
            <TheButton class="search__teacher--button" :disabled="false" @click="handleSearch">
                <template #left-icon>
                    <img :src="iconSearch" alt="search-icon" />
                </template>
                <p>{{ t('schoolStaff.labelSearchName') }}</p>
            </TheButton>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useUserStore } from '../../../../stores/UserStore.js';
import { useSchoolStaffStores } from '../../../../stores/SchoolStaffStores.js';
import iconSearch from '../../../../assets/icons/search.svg';

// i18n
const { t } = useI18n();

// stores
const userStore = useUserStore();
const schoolStaffStores = useSchoolStaffStores();

// ref
const schoolCode = ref('');
const validate = reactive({
    schoolCode: false,
});

// onMounted
onMounted(() => {
    document?.querySelector('.item__input')?.focus();
});

// function
const handleSearch = () => {
    if (!schoolCode.value) {
        validate.schoolCode = true;
    }

    if (schoolCode.value) {
        schoolStaffStores?.setParams({
            schoolCode: schoolCode.value,
            userId: userStore?.userId,
        });
        schoolStaffStores?.setSchoolCode(schoolCode.value);
        schoolStaffStores?.setErrorSearchMessage('');
        schoolStaffStores?.setIsSearch();
    }
};
watch(schoolCode, (newValue) => {
    if (newValue) {
        validate.schoolCode = false;
    }
});
</script>

<style lang="css" src="./BaseSearchSchoolStaff.css" scoped></style>
