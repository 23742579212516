<template>
    <div class="order__list--container">
        <div class="orders__header">
            <TheTitle class="orders__header--title">
                <h2>{{ t('order.table.title') }}</h2>
            </TheTitle>
            <div class="orders__header--actions">
                <UpdatePayTypeButton v-if="userStore?.userInfo?.affiliationKbn !== 2" @refresh-orders="handleRefreshData" />
                <!--GI#4_1_13 - β1,2は対象外-->
                <!-- <TheButton v-if="userStore?.userInfo?.affiliationKbn !== 2" disabled>
                    <template #left-icon>
                        <img class="margin__icon--right" src="@/assets/icons/search-gray.svg" alt="search-gray-icon" />
                    </template>
                    {{ t('order.table.searchFlyer') }}
                </TheButton> -->
                <!--GI#4_1_14, GI#4_1_15-->
                <CSVButton />
                <!--GI#4_1_16-->
                <img
                    :src="commonStores.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </div>
        <!--GI#4_1_17-->
        <div class="orders__body">
            <TheTable :columns="columns" :data="formatedOrders" class="orders__table" />
            <div class="search__status">
                <!-- Empty -->
                <div v-if="isError && errorCode === 'E0012'" class="table__item--center">
                    <img src="../../../../assets/icons/empty_folder.svg" alt="empty-folder-icon" />
                    <p class="empty_list">{{ errorMessage }}</p>
                </div>
                <!-- Error -->
                <div v-if="isError && errorCode !== 'E0012'" class="table__item--center">
                    <img src="../../../../assets/icons/error-icon.svg" alt="error-icon" />
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <!-- Loading -->
                <div v-if="isLoading" class="table__item--center">
                    <div class="custom-loader"></div>
                </div>
            </div>
        </div>
        <div v-if="orders && orders?.length !== 0" class="orders__footer">
            <div class="number__of--page">
                <span>{{ t('common.searchResult') }}</span>
                <SelectField :model-value="params?.limit" @update:model-value="handleChangeLimit" />
                <span>
                    {{
                        t('common.showSearchResult', {
                            total: totalRecords,
                            start: (currPage - 1) * params?.limit + 1,
                            end: currPage * params?.limit > totalRecords ? totalRecords : currPage * params?.limit,
                        })
                    }}
                </span>
            </div>
            <div>
                <ThePagination :total="pagesTotal" :curr-page="currPage" @change="handleChangePage" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, h, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import HeaderItem from '../../../../components/table/HeaderItem.vue';
import CSVButton from './ExportCsvButton.vue';
import TheTitle from '../../../../components/title/TheTitle.vue';
// import TheButton from '../../../../components/button/TheButton.vue';
import TheTable from '../../../../components/table/TheTable.vue';
import TheBadge from '../../../../components/badge/TheBadge.vue';
import ThePagination from '../../../../components/pagination/ThePagination.vue';
import SelectField from '../../../../components/select/SelectField.vue';
import UpdatePayTypeButton from './UpdatePayTypeButton.vue';
import { useOrderStores } from '../../../../stores/OrderStores.js';
import { useOrderDetailStores } from '../../../../stores/OrderDetailStores.js';
import { useCommonStores } from '../../../../stores/CommonStores';
import { useUserStore } from '../../../../stores/UserStore';
import OrdersService from '../../../../services/OrdersService.js';
import useFetch from '../../../../utils/useFetch.js';
import { SORT_TYPE, orderStatus, payType } from '../../../../utils/Consts.js';
import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';
import TheToolTip from '../../../../components/tooltip/TheToolTip.vue';
import TheCheckbox from '../../../../components/checkbox/TheCheckbox.vue';
// i18n
const { t } = useI18n();

// stores
const commonStores = useCommonStores();
const userStore = useUserStore();

const renderWidth = (width) => {
    return { 'min-width': `${width}px`, 'max-width': `${width}px` };
};

const columnsData = ref([
    {
        key: 'checked',
        title: '',
        styles: {
            ...renderWidth(40),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        render: (column, item) => checkboxElement(column, item),
        renderHeader: (column, item) =>
            h(TheCheckbox, { checked: checkedAll.value, onChecked: (value) => handleChecked(item, value, true) }),
    },
    {
        key: 'schoolName',
        title: t('order.table.schoolName'),
        styles: {
            ...renderWidth(220),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'schoolYear',
        title: t('order.table.schoolYear'),
        styles: {
            ...renderWidth(100),
        },
        stylesBody: {
            padding: '6px 24px',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'schoolClass',
        title: t('order.table.schoolClass'),
        styles: {
            ...renderWidth(100),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'flyerName',
        title: t('order.table.flyerName'),
        styles: {
            ...renderWidth(400),
            cursor: 'pointer',
        },
        render: (column, item) => ToolTipElement(column, item),
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'studentName',
        title: t('order.table.studentName'),
        styles: {
            ...renderWidth(220),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'payDate',
        title: t('order.table.payDate'),
        styles: {
            ...renderWidth(190),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'orderStatus',
        title: t('order.table.orderStatus'),
        styles: {
            ...renderWidth(120),
        },
        render: (column, item) => badgeElement(column, item),
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'payType',
        title: t('order.table.payType'),
        styles: {
            ...renderWidth(110),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'payAmount',
        title: t('order.table.payAmount'),
        styles: { 'text-align': 'end', ...renderWidth(110) },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },

    {
        key: 'orderUser',
        title: t('order.table.orderUser'),
        styles: {
            ...renderWidth(220),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'orderDate',
        title: t('order.table.orderDate'),
        styles: {
            ...renderWidth(190),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'orderCode',
        title: t('order.table.orderCode'),
        styles: {
            ...renderWidth(200),
        },
        render: (column, item) => aElement(column, item),
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'delvPlace',
        title: t('order.table.delvPlace'),
        styles: {
            ...renderWidth(200),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'payLimitDate',
        title: t('order.table.payLimitDate'),
        styles: {
            ...renderWidth(150),
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },

    // {
    //     key: 'schoolName',
    //     title: t('order.table.schoolName'),
    //     styles: {
    //         ...renderWidth(160),
    //     },
    //     renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    // },
]);

const orderStores = useOrderStores();
const orderDetailStores = useOrderDetailStores();
// Ver1.2 #11
const checkedAll = ref(false);
const columns = ref(columnsData.value);
const orders = ref(null);
const pagesTotal = ref(5);
const currPage = ref(1);
const totalRecords = ref(255);
const params = ref({
    userId: '',
    page: 1,
    flyerCode: '',
    schoolCode: '',
    startYear: '',
    shopCode: '',
    orderStatus: [],
    sortField: '',
    sortType: '',
    limit: 50,
});

const formatedOrders = computed(() => {
    return orders.value?.map((order) => ({
        ...order,
        orderDate: order?.orderDate,
        payDate: order?.payDate,
        payLimitDate: order?.payLimitDate || t('order.table.defaultPayLimitDate'),
        payAmount: `${String(order?.payAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`,
        payType: payType[order?.payType],
        schoolYear: order?.schoolYear == 0 ? t('common.newStudent') : order?.schoolYear,
        schoolClass: order?.schoolClass == 0 ? '-' : order?.schoolClass,
    }));
});

const { isLoading, errorMessage, isError, errorCode, data, fetchData } = useFetch(OrdersService.getOrderList);

watch(
    () => params.value,
    async (newParams) => {
        await fetchData(newParams);
    },
);
watch(
    () => orderDetailStores?.refreshData,
    async (newValue) => {
        await fetchData(params.value);
    },
);

watch(data, (newData) => {
    orders.value = newData?.result?.data?.orderLists?.map((order) => {
        // Ver1.2 #11
        return { ...order, checked: false };
    });
    pagesTotal.value = newData?.result?.data?.totalPages;
    totalRecords.value = newData?.result?.data?.totalRecords;
    // Ver1.2 #11
    checkedAll.value = false;
    orderStores?.setDataOrderStatusChange(orders.value);

    if (newData?.result?.data?.infoStatus) {
        orderStores?.setInfoStatus(newData?.result?.data?.infoStatus);
    } else {
        orderStores?.setInfoStatus({});
    }
});

watch(
    () => orderStores?.searchParams,
    (newSearchParams) => {
        params.value = { ...params.value, ...newSearchParams, page: 1 };
        currPage.value = 1;
        // Ver1.2 #11
        checkedAll.value = false;
    },
);

// Ver1.2 #11
watch(
    () => orders.value,
    (newOrders) => {
        checkedAll.value = newOrders?.every((order) => order?.checked === true);
    },
);

const handleChangePage = (page) => {
    params.value = { ...params.value, page };
    currPage.value = page;
};

const handleChangeLimit = (newValue) => {
    params.value = { ...params.value, limit: newValue, page: 1 };
    currPage.value = 1;
};

const handleChangeSize = () => {
    commonStores.setIsExpand(!commonStores?.isExpand);
};

const handleSortColumn = (key, sortType, isActive) => {
    columns.value = columnsData.value.map((e) => {
        if (e?.key === key) {
            if (!(orders.value && orders.value.length !== 0)) return e;

            let type = sortType || SORT_TYPE.SORT_UP;
            let active = isActive || false;
            if (sortType === SORT_TYPE.SORT_UP) {
                type = active ? SORT_TYPE.SORT_DOWN : SORT_TYPE.SORT_UP;
                active = true;
            } else if (sortType === SORT_TYPE.SORT_DOWN) {
                type = SORT_TYPE.SORT_UP;
                active = false;
            }
            params.value = { ...params.value, sortField: active ? key : '', sortType: active ? type : '', page: 1 };
            currPage.value = 1;
            return {
                ...e,
                renderHeader: (column) => headerElement(column, type, active),
            };
        }
        return e;
    });
};

const handleOpenToggle = (e, value) => {
    e.preventDefault();
    orderStores?.setOrderDetail(true);
    orderStores?.setOrderCode(value);
};

const handleRefreshData = async () => {
    await fetchData(params.value);
};

// Ver1.2 #11
const handleChecked = (item, value, all) => {
    if (all) {
        if (value) {
            orders.value = orders.value?.map((order) => {
                return {
                    ...order,
                    checked: true,
                };
            });
        } else {
            orders.value = orders.value?.map((order) => {
                return {
                    ...order,
                    checked: false,
                };
            });
        }
        orderStores?.setDataOrderStatusChange(orders.value);
        return;
    }
    orders.value = orders.value?.map((order) => {
        if (order.orderCode === item.orderCode) {
            order.checked = value;
        }
        return order;
    });
    orderStores?.setDataOrderStatusChange(orders.value);
};

const aElement = (column, item) =>
    h('a', { href: '/', style: { color: '#1778CA' }, onClick: (e) => handleOpenToggle(e, item[column?.key]) }, item[column?.key]);

const headerElement = (column, sortType, isActive) =>
    h(HeaderItem, {
        title: column?.title,
        keyColumn: column?.key,
        styles: column?.styles,
        sortType,
        isActive,
        onHandleClick: (key, sortType, isActive) => {
            handleSortColumn(key, sortType, isActive);
        },
    });

const badgeElement = (column, item) =>
    h(TheBadge, {
        ...orderStatus[item[column.key]],
        style: { width: 'fit-content' },
    });
const ToolTipElement = (column, item) => h(TheToolTip, { content: item[column?.key] });

// Ver1.2 #11
const checkboxElement = (column, item) =>
    h(TheCheckbox, { checked: item[column?.key], onChecked: (value) => handleChecked(item, value) });

// Ver1.2 #11
onBeforeMount(() => {
    if (userStore?.userInfo?.affiliationKbn === 2) columnsData.value?.shift();
});

watch(
    () => userStore?.userInfo,
    (newValue) => {
        if (newValue?.affiliationKbn === 2) columnsData.value?.shift();
    },
);
</script>

<style lang="css" src="./OrderList.css" scoped></style>
