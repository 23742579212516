/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
 
const awsmobile = {
    aws_project_region: 'ap-northeast-1',
    aws_cognito_region: 'ap-northeast-1',
    aws_user_pools_id: 'ap-northeast-1_B1iFCWQvQ',
    aws_user_pools_web_client_id: '1n43f7111egosevjchvtn15kb4',
    oauth: {
        domain: 'conetprdbo.auth.ap-northeast-1.amazoncognito.com',
    },
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_UPPERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS'],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    allow_custom_auth: true,
    authenticationFlowType: 'CUSTOM_AUTH',
};
 
export default awsmobile;