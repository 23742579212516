<template>
    <BaseDialog
        :title="t('shop.dialog.titleDialog1')"
        :content="t('shop.dialog.contentDialog1') + '\n' + t('shop.dialog.contentDialog2')"
    >
        <template #default>
            <div class="more__action">
                <div class="button__action">
                    <TheButton class="button__action--cancel" @click="handleCloseModal">{{ t('confirm.content2') }}</TheButton>
                    <TheButton class="button__action--confirm" @click="handleDeleteOrder">{{ t('confirm.content3') }}</TheButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import BaseDialog from './BaseDialog.vue';
import TheButton from '../../../../../../components/button/TheButton.vue';
import { useShopStores } from '../../../../../../stores/ShopStores.js';

// i18n
const { t } = useI18n();

// router
const router = useRouter();

// store
const shopStores = useShopStores();

// function
const handleCloseModal = () => {
    shopStores?.setIsModalNavigate(false);
};

const handleDeleteOrder = () => {
    shopStores?.setIsModalNavigate(false);
    shopStores?.setShopList(true);
    shopStores?.setShopCRUDStatus(4);
    router.push('/shops');
};
</script>

<style lang="css" src="./BaseDialogNavigate.css" scoped></style>
