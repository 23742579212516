<template>
    <select
        class="select__custom"
        :value="props?.selection"
        :disabled="hide"
        :style="{ cursor: hide ? 'no-drop' : 'pointer' }"
        :required="props?.required"
        @change="(e) => changeValue(e)"
    >
        <option v-for="(option, index) in options" :key="index" :value="arrayValue ? arrayValue[index] : option">
            {{ option }}
        </option>
        <slot name="icon-right"></slot>
    </select>
</template>

<script setup>
const emits = defineEmits(['input']);
const props = defineProps({
    selection: {
        type: String || Number,
        required: false,
        default: 'string',
    },
    options: {
        type: Array,
        required: false,
    },
    dataType: {
        type: String,
        required: false,
        default: 'string',
    },
    arrayValue: {
        type: Array,
        required: false,
    },
    hide: {
        type: Boolean,
        required: false,
        default: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
});
const changeValue = (e) => {
    if (!props?.disabled) {
        emits('input', e?.target?.value);
    }
};
</script>

<style lang="css" src="./TheSelect.css" scoped></style>
