<template>
    <div class="wrapper__content__flyer__registration">
        <Teleport to="body">
            <BaseLoading
                :loading="fetchRegister?.isLoading.value || fetchBoxList?.isLoading.value || fetchUpdate?.isLoading.value"
            >
            </BaseLoading>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="flyerRegistrationStores?.isModalShopList" :z-index="990">
                <BaseDialogShop
                    @handle-choice="
                        (code, name) => {
                            shopCode = code;
                            fetchGetFlyerRegisterShopName?.fetchData({ shopCode });
                        }
                    "
                >
                </BaseDialogShop>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="isError" :z-index="990">
                <BaseDialog :title="t('notice.title')" :content="errorMessage.value">
                    <template #default>
                        <div class="button__confirm">
                            <TheButton
                                @click="
                                    () => {
                                        isError = false;
                                    }
                                "
                                >OK</TheButton
                            >
                        </div>
                    </template>
                </BaseDialog>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="isValidate" :z-index="990">
                <BaseDialog :title="t('notice.title')" :content="t('flyerRegistration.errorMessage')">
                    <template #default>
                        <div class="button__confirm">
                            <TheButton
                                @click="
                                    () => {
                                        isValidate = false;
                                    }
                                "
                                >OK</TheButton
                            >
                        </div>
                    </template>
                </BaseDialog>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="isSuccess" :z-index="990">
                <BaseDialog :title="flyerRegistrationStores?.titleDialog" :content="flyerRegistrationStores?.contentDialog">
                    <template #default>
                        <div class="button__confirm">
                            <TheButton @click="handleConfirm">OK</TheButton>
                        </div>
                    </template>
                </BaseDialog>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="flyerRegistrationStores?.isModalDelete" :z-index="993">
                <BaseDialogDelete :flyer-name="titleFlyerName"></BaseDialogDelete>
            </component>
        </Teleport>
        <header class="flyer__registration--header">
            <!-- タイトル -->
            <TheTitle class="flyer__registration--title">
                <h2 v-if="flyerRegistrationStores?.registrationStates === 1">{{ t('flyerRegistration.titleRegister') }}</h2>
                <h2 v-else-if="flyerRegistrationStores?.registrationStates === 2">{{ t('flyerRegistration.titleUpdate') }}</h2>
                <h2 v-if="flyerRegistrationStores?.registrationStates === 3">{{ t('flyerRegistration.titleReferences') }}</h2>
            </TheTitle>
            <div class="acction__button--header">
                <img
                    :src="commonStores?.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </header>
        <div class="flyer__registration--data">
            <div class="flyer__registration--list">
                <!-- 販売店 -->
                <div v-if="userStore?.userInfo?.affiliationKbn === 1" class="flyer__registration--input">
                    <label class="input__item--label">
                        {{ t('flyerRegistration.shopName') }}
                        <strong>{{ t('common.required') }}</strong>
                    </label>
                    <div class="item__selection">
                        <div class="group--item">
                            <input
                                id="focus--item"
                                v-model="shopCode"
                                class="selection--item item__input--code"
                                :placeholder="t('common.placeholder2')"
                                :required="validateForm.shopCode"
                                :disabled="flyerRegistrationStores?.activeSelect?.shopCode"
                                @blur="handleFetchShopName"
                            />
                            <button
                                class="input__item--btn"
                                :disabled="flyerRegistrationStores?.activeSelect?.shopCode"
                                @click="
                                    () => {
                                        if (!flyerRegistrationStores?.activeSelect?.shopCode) {
                                            flyerRegistrationStores?.setIsModalShopList(true);
                                        }
                                    }
                                "
                            >
                                {{ t('flyerRegistration.labelButton14') }}
                            </button>
                            <div
                                v-if="!fetchGetFlyerRegisterShopName?.isLoading.value"
                                class="text__item--name"
                                :class="{
                                    error: fetchGetFlyerRegisterShopName?.isError.value,
                                    disabled: flyerRegistrationStores?.activeSelect?.shopCode,
                                }"
                            >
                                <p v-if="errorShopName !== ''">{{ errorShopName }}</p>
                                <p v-if="shopName !== ''">{{ shopName }}</p>
                            </div>
                            <div v-else class="custom-loader"></div>
                        </div>
                        <p v-if="validateForm.shopCode" class="validate__message">
                            {{ t('error.validateMessage8', { name: t('flyerRegistration.shopName') }) }}
                        </p>
                    </div>
                </div>
                <!-- イベント名 -->
                <div class="flyer__registration--item">
                    <label class="item__label">
                        {{ t('flyerRegistration.eventLabel') }}
                        <strong>{{ t('common.required') }}</strong>
                    </label>
                    <div class="item__selection">
                        <TheSelect
                            class="selection--item"
                            :required="validateForm.eventCode"
                            :selection="eventCode"
                            :hide="flyerRegistrationStores?.activeSelect?.eventName || !shopName"
                            :options="eventListOptions?.map((item) => item?.eventName)"
                            data-type="string"
                            :array-value="eventListOptions?.map((item) => item?.eventCode)"
                            @input="
                                (newValue) => {
                                    eventCode = newValue;
                                }
                            "
                        ></TheSelect>
                        <p v-if="validateForm.eventCode" class="validate__message">
                            {{ t('error.validateMessage9', { name: t('flyerRegistration.eventLabel') }) }}
                        </p>
                    </div>
                </div>
                <!-- チラシ名 -->
                <div class="flyer__registration--item">
                    <label class="item__label">
                        {{ t('flyerRegistration.flyerName') }}<strong>{{ t('common.required') }}</strong></label
                    >
                    <div class="item__selection">
                        <input
                            v-model.trim="flyerName"
                            class="selection--item item__input"
                            :required="validateForm.flyerName"
                            :disabled="flyerRegistrationStores?.activeSelect?.flyerName"
                            :placeholder="t('common.placeholder2')"
                            maxLength="128"
                        />
                        <p v-if="validateForm.flyerName" class="validate__message">
                            {{ t('error.validateMessage8', { name: t('flyerRegistration.flyerName') }) }}
                        </p>
                    </div>
                </div>
                <!-- 学校名 -->
                <div class="flyer__registration--item">
                    <label class="item__label">
                        {{ t('flyerRegistration.schoolName') }}<strong>{{ t('common.required') }}</strong>
                    </label>
                    <div class="item__selection">
                        <TheSelect
                            class="selection--item"
                            :required="validateForm.schoolCode"
                            :selection="schoolCode"
                            :hide="flyerRegistrationStores?.activeSelect?.schoolName || !shopName"
                            :options="schoolListOptions?.map((item) => item?.schoolName)"
                            :array-value="schoolListOptions?.map((item) => item?.schoolCode)"
                            data-type="string"
                            @input="
                                (newValue) => {
                                    schoolCode = newValue;
                                }
                            "
                        ></TheSelect>
                        <p v-if="validateForm.schoolCode" class="validate__message">
                            {{ t('error.validateMessage9', { name: t('flyerRegistration.schoolName') }) }}
                        </p>
                    </div>
                </div>
                <div class="flyer__registration--item--group">
                    <!-- 学年 -->
                    <div class="flyer__registration--item">
                        <label class="item__label">
                            {{ t('flyerRegistration.schoolYear') }}<strong>{{ t('common.required') }}</strong>
                        </label>
                        <div class="item__selection">
                            <TheSelect
                                class="selection--item"
                                :required="validateForm.schoolYear"
                                :selection="schoolYear"
                                :hide="
                                    flyerRegistrationStores?.activeSelect?.schoolYear || schoolCode === t('common.placeholder1')
                                "
                                :options="
                                    // Ver1.2 新入学
                                    schoolYearOptions?.map((item) => (item?.schoolYear == 0 ? newStudent : item?.schoolYear))
                                "
                                :array-value="schoolYearOptions?.map((item) => item?.schoolYear)"
                                data-type="string"
                                @input="
                                    (newValue) => {
                                        schoolYear = newValue;
                                    }
                                "
                            ></TheSelect>
                            <p v-if="validateForm.schoolYear" class="validate__message left-text">
                                {{ t('error.validateMessage9', { name: t('flyerRegistration.schoolYear') }) }}
                            </p>
                        </div>
                    </div>
                    <!-- クラス -->
                    <div class="flyer__registration--item">
                        <label class="item__label"
                            >{{ t('flyerRegistration.className') }}<strong>{{ t('common.required') }}</strong></label
                        >
                        <div class="item__selection">
                            <TheSelect
                                class="selection--item"
                                :required="validateForm.schoolClass"
                                :selection="schoolClass"
                                :hide="
                                    flyerRegistrationStores?.activeSelect?.className || schoolYear === t('common.placeholder1')
                                "
                                :options="schoolClassOptions"
                                :array-value="schoolClassOptions"
                                data-type="string"
                                @input="
                                    (newValue) => {
                                        schoolClass = newValue;
                                    }
                                "
                            ></TheSelect>
                            <p v-if="validateForm.schoolClass" class="validate__message left-text">
                                {{ t('error.validateMessage9', { name: t('flyerRegistration.className') }) }}
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 販売開始日 -->
                <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.startDate') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :required="validateForm.flyerStartDate"
                            :date="flyerStartDate"
                            :hide="flyerRegistrationStores?.activeSelect?.startDate"
                            @update-date="
                                (newValue) => {
                                    flyerStartDate = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="validateForm.flyerStartDate" class="validate__message">
                            {{ t('error.validateMessage8', { name: t('flyerRegistration.startDate') }) }}
                        </p>
                    </div>
                </div>
                <!-- 販売終了日 -->
                <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.endDate') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :required="validateForm.flyerEndDate || checkDate"
                            :date="flyerEndDate"
                            :hide="flyerRegistrationStores?.activeSelect?.endDate"
                            @update-date="
                                (newValue) => {
                                    flyerEndDate = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="checkDate" class="validate__message left-text">
                            {{ t('error.validateMessage10') }}
                        </p>
                        <p v-else-if="validateForm.flyerEndDate" class="validate__message left-text">
                            {{ t('error.validateMessage8', { name: t('flyerRegistration.endDate') }) }}
                        </p>
                    </div>
                </div>
                <!-- 表示開始日 -->
                <!-- <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.displayStartDate') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :required="validateForm.displayStartDate"
                            :date="displayStartDate"
                            :hide="flyerRegistrationStores?.activeSelect?.displayStartDate"
                            @update-date="
                                (newValue) => {
                                    displayStartDate = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="validateForm.displayStartDate" class="validate__message">
                            {{ t('error.validateMessage12') }}
                        </p>
                    </div>
                </div> -->
                <!-- 表示終了日 -->
                <!-- <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.displayEndDate') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :required="validateForm.displayEndDate"
                            :date="displayEndDate"
                            :hide="flyerRegistrationStores?.activeSelect?.displayEndDate"
                            @update-date="
                                (newValue) => {
                                    displayEndDate = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="validateForm.displayEndDate" class="validate__message left-text">
                            {{ t('error.validateMessage13') }}
                        </p>
                    </div>
                </div> -->
                <!-- リマインド配信日1 -->
                <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.notificationRemindDate1') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :date="notificationRemindDate1"
                            :required="validateForm.notificationRemindDate1"
                            :hide="flyerRegistrationStores?.activeSelect?.notificationRemindDate1"
                            @update-date="
                                (newValue) => {
                                    notificationRemindDate1 = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="validateForm.notificationRemindDate1" class="validate__message left-text">
                            {{ t('error.validateMessage11', { index: 1 }) }}
                        </p>
                    </div>
                </div>
                <!-- リマインド配信日2 -->
                <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.notificationRemindDate2') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :required="validateForm.notificationRemindDate2"
                            :date="notificationRemindDate2"
                            :hide="flyerRegistrationStores?.activeSelect?.notificationRemindDate2"
                            @update-date="
                                (newValue) => {
                                    notificationRemindDate2 = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="validateForm.notificationRemindDate2" class="validate__message left-text">
                            {{ t('error.validateMessage11', { index: 2 }) }}
                        </p>
                    </div>
                </div>
                <!-- リマインド配信日3 -->
                <div class="flyer__registration--item">
                    <label class="item__label">{{ t('flyerRegistration.notificationRemindDate3') }}</label>
                    <div class="item__selection">
                        <DatePicker
                            class="selection--item date__picker"
                            :required="validateForm.notificationRemindDate3"
                            :date="notificationRemindDate3"
                            :hide="flyerRegistrationStores?.activeSelect?.notificationRemindDate3"
                            @update-date="
                                (newValue) => {
                                    notificationRemindDate3 = newValue;
                                }
                            "
                        ></DatePicker>
                        <p v-if="validateForm.notificationRemindDate3" class="validate__message left-text">
                            {{ t('error.validateMessage11', { index: 3 }) }}
                        </p>
                    </div>
                </div>
                <div class="flyer__registration--item">
                    <label class="item__label-1-3">
                        {{ t('flyerRegistration.flyerSupplement') }}
                        <strong>{{ t('common.required') }}</strong>
                        <!-- <p class="note__message">{{ t('common.note2') }}</p> -->
                    </label>
                    <div class="item__selection">
                        <!-- <textarea
                            v-model="flyerSupplement"
                            :placeholder="t('common.placeholder4')"
                            class="selection--item input__flyer-supplement--item"
                            :required="validateForm.flyerSupplement"
                            :disabled="flyerRegistrationStores?.activeSelect?.flyerSupplement"
                            maxLength="128"
                            rows="3"
                        ></textarea> -->
                        <input
                            v-model.trim="flyerSupplement"
                            class="selection--item item__input"
                            :required="validateForm.flyerSupplement"
                            :disabled="flyerRegistrationStores?.activeSelect?.flyerSupplement"
                            :placeholder="t('common.placeholder4')"
                            maxLength="128"
                        />
                        <p v-if="validateForm.flyerSupplement" class="validate__message">
                            {{ t('error.validateMessage8', { name: t('flyerRegistration.flyerSupplement') }) }}
                        </p>
                    </div>
                </div>
                <!-- チラシ画像メイン選択 -->
                <div class="flyer__registration--input">
                    <label class="input__item--label">
                        {{ t('flyerRegistration.labelImage', { sub: t('flyerRegistration.subImage1') }) }}
                        <strong>{{ t('common.required') }}</strong>
                        <p class="note__message">{{ t('common.note1') }}</p>
                    </label>
                    <!-- 画像ファイル名 -->
                    <button
                        :disabled="flyerRegistrationStores?.activeSelect?.mainImage"
                        class="input__item--btn"
                        @click="clickMainImage"
                    >
                        {{ t('flyerRegistration.labelButton1') }}
                    </button>
                    <input id="main__image" class="input__item--file" type="file" @change="(e) => handleChangeMainImage(e)" />

                    <div class="group__item-image">
                        <p
                            v-if="!validateForm.flyerMainImage"
                            class="input__item--name"
                            :class="{ reference: route.name === 'Flyer Reference Page' }"
                        >
                            {{ mainImage?.imageName }}
                        </p>
                        <p
                            v-else-if="validateForm.flyerMainImage && !mainImage?.imageName"
                            class="input__item--name valid--image"
                        >
                            {{ t('error.validateMessage4') }}
                        </p>
                        <p v-else-if="validateForm.flyerMainImage === 1" class="input__item--name valid--image">
                            {{ t('error.validateMessage5') }}
                        </p>
                        <p v-else-if="validateForm.flyerMainImage === 2" class="input__item--name valid--image">
                            {{ t('error.validateMessage6') }}
                        </p>
                        <p v-else-if="validateForm.flyerMainImage === 3" class="input__item--name valid--image">
                            {{ t('error.validateMessage7') }}
                        </p>
                    </div>
                </div>
                <!-- チラシ画像サブ選択 -->
                <div class="flyer__registration--input">
                    <label class="input__item--label">
                        {{ t('flyerRegistration.labelImage', { sub: t('flyerRegistration.subImage2') }) }}
                        <p class="note__message">{{ t('common.note1') }}</p>
                    </label>
                    <button
                        :disabled="flyerRegistrationStores?.activeSelect?.subImage"
                        class="input__item--btn"
                        @click="clickSubImage"
                    >
                        {{ t('flyerRegistration.labelButton1') }}
                    </button>
                    <input id="sub__image" class="input__item--file" type="file" @change="(e) => handleChangeSubImage(e)" />
                    <!-- 画像ファイル名 -->
                    <div class="group__item-image">
                        <p
                            v-if="!validateForm.flyerSubImage"
                            class="input__item--name"
                            :class="{ reference: route.name === 'Flyer Reference Page' }"
                        >
                            {{ subImage?.imageName }}
                        </p>
                        <!-- <p v-if="validateForm.flyerSubImage && !subImage?.imageName" class="valid--image">
                        {{ t('error.validateMessage4') }}
                        </p> -->
                        <p v-else-if="validateForm.flyerSubImage === 1" class="input__item--name valid--image">
                            {{ t('error.validateMessage5') }}
                        </p>
                        <p v-else-if="validateForm.flyerSubImage === 2" class="input__item--name valid--image">
                            {{ t('error.validateMessage6') }}
                        </p>
                        <p v-else-if="validateForm.flyerSubImage === 3" class="input__item--name valid--image">
                            {{ t('error.validateMessage7') }}
                        </p>
                        <button
                            v-if="subImage?.imageName"
                            class="item__action--delete"
                            :class="{ disabled: flyerRegistrationStores?.activeSelect?.subImage }"
                            @click="RemoveSubImage"
                        >
                            {{ t('flyerRegistration.labelButton15') }}
                        </button>
                    </div>
                </div>
                <!-- 通知メッセージ -->
                <div class="flyer__registration--input">
                    <label for="input__notification" class="item__label--notification">
                        {{ t('flyerRegistration.labelMessage') }}
                        <strong>{{ t('common.required') }}</strong>
                    </label>
                    <div class="input__notification--list">
                        <textarea
                            id="input__notification"
                            v-model="notificationMessage"
                            class="selection--item input__notification--item"
                            :required="validateForm.notificationMessage"
                            :disabled="flyerRegistrationStores?.activeSelect?.messageNotification"
                            maxLength="512"
                            rows="7"
                        ></textarea>
                        <div class="input__notification--action">
                            <!-- 通知プレビュー -->
                            <button class="input__notification--button" @click="handleShowPreview">
                                {{ t('flyerRegistration.labelButton2') }}
                            </button>
                            <!-- 画像プレビュー -->
                            <button class="input__notification--button" @click="handleShowPreviewImage">
                                {{ t('flyerRegistration.labelButton3') }}
                            </button>
                            <p v-if="validateForm.notificationMessage" class="validate__message right-text">
                                {{ t('error.validateMessage8', { name: t('flyerRegistration.labelMessage') }) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flyer__registration--image">
                <PhoneDevice class="smart__device" :message="flyerRegistrationStores?.message" :show="true">
                    <template #image>
                        <!-- <div class="image__item"> -->
                        <img
                            v-if="flyerRegistrationStores?.urlMainImagePreview"
                            class="image__phone"
                            :src="mainImage?.imageURL"
                            alt="image-flyer"
                        />
                        <img v-else class="image__phone" :src="defaultThumbnail" alt="image-flyer" />
                        <!-- </div> -->
                    </template>
                </PhoneDevice>
            </div>
        </div>
        <div class="actions--button">
            <!-- チラシ一覧に戻る -->
            <button @click="handleBackToOrderList">{{ t('flyerRegistration.labelButton4') }}</button>
            <!-- チラシを登録する -->
            <button
                v-if="flyerRegistrationStores?.registrationStates === 1"
                :disabled="userStore?.userInfo?.affiliationKbn === 2"
                @click="handleRegister"
            >
                {{ t('flyerRegistration.labelButton5') }}
            </button>
            <!-- チラシを登録して商品一覧を表示する -->
            <button
                v-if="flyerRegistrationStores?.registrationStates === 1"
                :disabled="userStore?.userInfo?.affiliationKbn === 2"
                @click="handleRegisterAndNavigation"
            >
                {{ t('flyerRegistration.labelButton7') }}
            </button>
            <!-- チラシを更新する -->
            <button
                v-if="flyerRegistrationStores?.registrationStates !== 1"
                :disabled="userStore?.userInfo?.affiliationKbn === 2 || flyerRegistrationStores?.registrationStates === 3"
                @click="handleUpdate"
            >
                {{ t('flyerRegistration.labelButton6') }}
            </button>
            <!-- チラシを更新して商品一覧を表示する -->
            <button
                v-if="flyerRegistrationStores?.registrationStates === 2"
                :disabled="userStore?.userInfo?.affiliationKbn === 2"
                @click="handleUpdateAndNavigation"
            >
                {{ t('flyerRegistration.labelButton8') }}
            </button>
            <!-- 商品一覧を表示する -->
            <button v-if="route.name === 'Flyer Reference Page'" @click="handleProductNavigation">
                {{ t('flyerRegistration.labelButton10') }}
            </button>
            <!-- チラシ情報を削除する -->
            <button
                v-if="flyerRegistrationStores?.registrationStates !== 1"
                :disabled="userStore?.userInfo?.affiliationKbn === 2"
                @click="handleDeleteFlyer"
            >
                {{ t('flyerRegistration.labelButton9') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import TheSelect from '../../../../components/select/TheSelect.vue';
import DatePicker from '../../../../components/datePicker/DatePicker.vue';
import PhoneDevice from '../phoneDevice/PhoneDevice.vue';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import { useFlyerRegistrationStores } from '../../../../stores/FlyerRegistrationStores.js';
import { useCommonStores } from '../../../../stores/CommonStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import FlyerRegistrationService from '../../../../services/FlyerRegistrationService.js';
import useFetch from '../../../../utils/useFetch.js';
import { checkValidate } from '../../../../utils/Helpers.js';

import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';
import defaultThumbnail from '../../../../assets/img/default-thumbnail.jpg';
import BaseDialogDelete from '../dialog/BaseDialogDelete.vue';
import BaseDialogShop from '../dialog/BaseDialogShop.vue';
import BaseLoading from '../../../../components/loading/BaseLoading.vue';

// i18n
const { t } = useI18n();

// router
const router = useRouter();
const route = useRoute();

// store
const flyerRegistrationStores = useFlyerRegistrationStores();
const userStore = useUserStore();
const commonStores = useCommonStores();

// useFetch
const fetchRegister = useFetch(FlyerRegistrationService?.registerFlyerInfor);
const fetchFlyerDetail = useFetch(FlyerRegistrationService?.getFlyerdDetail);
const fetchBoxList = useFetch(FlyerRegistrationService?.getFlyerRegisterList);
const fetchUpdate = useFetch(FlyerRegistrationService?.updateFlyer);
const fetchGetFlyerRegisterShopName = useFetch(FlyerRegistrationService?.getFlyerRegisterShopName);

// ref
const eventCode = ref(t('common.placeholder1'));
const flyerCode = ref('');
const flyerName = ref('');
const shopCode = ref();
const shopName = ref('');
const schoolCode = ref(t('common.placeholder1'));
const schoolYear = ref(t('common.placeholder1'));
const schoolClass = ref(t('common.placeholder1'));
const flyerStartDate = ref('');
const flyerEndDate = ref('');
const displayStartDate = ref('');
const displayEndDate = ref('');
const checkDate = ref(false);
const flyerSupplement = ref('');
const notificationMessage = ref(`【保護者の方へ】
〇〇セット購入のお知らせ
上記の画像をタップして購入ください。
↑↑↑↑↑↑↑
購入期限：〇〇〇〇年〇〇月〇〇日
販売店：〇〇販売店`);
const eMainImage = ref();
const eSubImage = ref();
const mainImageFile = ref(null);
const subImageFile = ref(null);
const mainImage = ref({});
const subImage = ref({});
const notificationRemindDate1 = ref('');
const notificationRemindDate2 = ref('');
const notificationRemindDate3 = ref('');
const titleFlyerName = ref('');

// value selectbox
const eventListOptions = ref([{ eventName: t('common.placeholder1'), eventCode: t('common.placeholder1') }]);
const schoolListOptions = ref([
    {
        schoolName: t('common.placeholder1'),
        schoolCode: t('common.placeholder1'),
        schoolYearClassList: [
            {
                schoolYear: t('common.placeholder1'),
                schoolClass: [t('common.placeholder1'), t('common.all')],
            },
        ],
    },
]);
const schoolYearOptions = ref([
    {
        schoolYear: t('common.placeholder1'),
        schoolClass: [t('common.placeholder1'), t('common.all')],
    },
]);
const schoolClassOptions = ref([t('common.placeholder1'), t('common.all')]);

const isError = ref(false);
const errorMessage = ref('');
const errorShopName = ref('');
const isSuccess = ref(false);
const navigate = ref(false);
const validateForm = reactive({
    eventCode: false,
    flyerName: false,
    shopCode: false,
    schoolCode: false,
    schoolYear: false,
    schoolClass: false,
    notificationMessage: false,
    flyerStartDate: false,
    flyerEndDate: false,
    displayStartDate: false,
    displayEndDate: false,
    flyerMainImage: 0,
    flyerSubImage: 0,
    notificationRemindDate1: false,
    notificationRemindDate2: false,
    notificationRemindDate3: false,
    flyerSupplement: false,
});
const isValidate = ref(false);
const checkChangeValue = ref(false);

// Ver1.2 新入学
const newStudent = ref(t('common.newStudent'));

onMounted(async () => {
    if (userStore?.userInfo?.affiliationKbn === 3 && route.name === 'Flyer New Registration Page') {
        shopCode.value = userStore?.userInfo?.shopCd;
        await fetchGetFlyerRegisterShopName?.fetchData({ shopCode: shopCode.value });
    }
    if (route.name === 'Flyer Update Page') {
        flyerRegistrationStores?.setRegistrationStates(2);
        flyerRegistrationStores?.setActiveSelect({
            eventName: true,
            flyerName: false,
            shopCode: true,
            schoolName: true,
            schoolYear: true,
            className: false,
            startDate: false,
            endDate: false,
            displayStartDate: false,
            displayEndDate: false,
            mainImage: false,
            subImage: false,
            messageNotification: false,
            notificationRemindDate1: false,
            notificationRemindDate2: false,
            notificationRemindDate3: false,
            flyerSupplement: false,
        });
        await fetchGetFlyerRegisterShopName?.fetchData({ shopCode: route.params?.shopCode });
    }

    if (route.name === 'Flyer Reference Page') {
        flyerRegistrationStores?.setRegistrationStates(3);
        flyerRegistrationStores?.setActiveSelect({
            eventName: true,
            flyerName: true,
            shopCode: true,
            schoolName: true,
            schoolYear: true,
            className: true,
            startDate: true,
            endDate: true,
            displayStartDate: true,
            displayEndDate: true,
            mainImage: true,
            subImage: true,
            messageNotification: true,
            notificationRemindDate1: true,
            notificationRemindDate2: true,
            notificationRemindDate3: true,
            flyerSupplement: true,
        });
        await fetchGetFlyerRegisterShopName?.fetchData({ shopCode: route.params?.shopCode });
    }
    if (route.name === 'Flyer Registration Page') {
        flyerRegistrationStores?.setActiveSelect({
            eventName: false,
            flyerName: false,
            shopCode: false,
            schoolName: false,
            schoolYear: false,
            className: false,
            endDate: false,
            displayStartDate: false,
            displayEndDate: false,
            startDate: false,
            mainImage: false,
            subImage: false,
            messageNotification: false,
            notificationRemindDate1: false,
            notificationRemindDate2: false,
            notificationRemindDate3: false,
            flyerSupplement: false,
        });
        await fetchGetFlyerRegisterShopName?.fetchData({ shopCode: route.params?.shopCode });
    }
    flyerRegistrationStores?.setMessage(
        notificationMessage.value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br>'),
    );
    document.querySelector('#focus--item')?.focus();
});

// function
const handleChangeSize = () => {
    commonStores?.setIsExpand(!commonStores?.isExpand);
};
const handleShowPreview = () => {
    flyerRegistrationStores?.setIsModalPreview(true);
};
const handleShowPreviewImage = () => {
    flyerRegistrationStores?.setIsModalPreviewImage(true);
};
const handleBackToOrderList = () => {
    if (route.name === 'Flyer Reference Page') {
        router.push('/flyers');
    } else {
        flyerRegistrationStores?.setIsModalNavigate(true);
    }
};
const validateFunction = () => {
    if (eventCode.value === t('common.placeholder1')) {
        validateForm.eventCode = true;
    }
    if (!flyerName.value) {
        validateForm.flyerName = true;
    }
    if (!flyerSupplement.value) {
        validateForm.flyerSupplement = true;
    }
    if (!shopCode.value && userStore?.userInfo?.affiliationKbn !== 3) {
        validateForm.shopCode = true;
    }
    if (schoolCode.value === t('common.placeholder1')) {
        validateForm.schoolCode = true;
    }
    if (schoolYear.value === t('common.placeholder1')) {
        validateForm.schoolYear = true;
    }
    if (schoolClass.value === t('common.placeholder1')) {
        validateForm.schoolClass = true;
    }
    if (!notificationMessage.value) {
        validateForm.notificationMessage = true;
    }
    if (flyerStartDate.value && !flyerEndDate.value) {
        validateForm.flyerEndDate = true;
    }
    if (!flyerStartDate.value && flyerEndDate.value) {
        validateForm.flyerStartDate = true;
    }
    if (!flyerStartDate.value && !flyerEndDate.value) {
        validateForm.flyerStartDate = false;
        validateForm.flyerEndDate = false;
    }
    // if (
    //     (displayStartDate.value && !flyerStartDate.value) ||
    //     (displayStartDate.value && flyerStartDate.value && new Date(displayStartDate.value) > new Date(flyerStartDate.value))
    // ) {
    //     validateForm.displayStartDate = true;
    // } else {
    //     validateForm.displayStartDate = false;
    // }
    // if (
    //     (displayEndDate.value && !flyerEndDate.value) ||
    //     (displayEndDate.value && flyerEndDate.value && new Date(displayEndDate.value) < new Date(flyerEndDate.value))
    // ) {
    //     validateForm.displayEndDate = true;
    // } else {
    //     validateForm.displayEndDate = false;
    // }
    if (!mainImageFile.value) {
        validateForm.flyerMainImage = true;
        mainImage.value.imageName = '';
    }
    if (flyerEndDate.value && new Date(notificationRemindDate1.value) > new Date(flyerEndDate.value)) {
        validateForm.notificationRemindDate1 = true;
    } else {
        validateForm.notificationRemindDate1 = false;
    }
    if (flyerEndDate.value && new Date(notificationRemindDate2.value) > new Date(flyerEndDate.value)) {
        validateForm.notificationRemindDate2 = true;
    } else {
        validateForm.notificationRemindDate2 = false;
    }
    if (flyerEndDate.value && new Date(notificationRemindDate3.value) > new Date(flyerEndDate.value)) {
        validateForm.notificationRemindDate3 = true;
    } else {
        validateForm.notificationRemindDate3 = false;
    }
    if (flyerStartDate.value && flyerEndDate.value && new Date(flyerStartDate.value) >= new Date(flyerEndDate.value)) {
        checkDate.value = true;
    }
};
const assignFormData = () => {
    const form = new FormData();
    form.append('userId', userStore?.userId);
    form.append('eventCode', eventCode.value);
    form.append('flyerName', flyerName.value);
    form.append('flyerCode', flyerCode.value);
    if (userStore?.userInfo?.affiliationKbn === 3) {
        shopCode.value = userStore?.userInfo?.shopCd;
        form.append('shopCode', shopCode.value);
    } else {
        form.append('shopCode', shopCode?.value);
    }
    form.append('schoolCode', schoolCode.value);
    form.append('schoolYear', schoolYear.value);
    form.append('schoolClass', schoolClass.value === t('common.all') ? '0' : schoolClass.value);
    form.append('notificationMessage', notificationMessage.value);
    form.append('flyerSupplement', flyerSupplement.value);
    form.append('flyerStartDate', flyerStartDate.value ? flyerStartDate.value.replace(/-/g, '/') : '');
    form.append('flyerEndDate', flyerEndDate.value ? flyerEndDate.value.replace(/-/g, '/') : '');
    // before change spec
    // if (displayStartDate.value) {
    //     form.append('displayStartDate', displayStartDate.value.replace(/-/g, '/'));
    // } else {
    //     form.append('displayStartDate', flyerStartDate.value ? flyerStartDate.value.replace(/-/g, '/') : '');
    // }
    // if (displayEndDate.value) {
    //     form.append('displayEndDate', displayEndDate.value.replace(/-/g, '/'));
    // } else {
    //     form.append('displayEndDate', flyerEndDate.value ? flyerEndDate.value.replace(/-/g, '/') : '');
    // }
    // after change spec
    form.append('displayStartDate', flyerStartDate.value ? flyerStartDate.value.replace(/-/g, '/') : '');
    form.append('displayEndDate', flyerEndDate.value ? flyerEndDate.value.replace(/-/g, '/') : '');
    form.append('notificationRemindDate1', notificationRemindDate1.value ? notificationRemindDate1.value.replace(/-/g, '/') : '');
    form.append('notificationRemindDate2', notificationRemindDate2.value ? notificationRemindDate2.value.replace(/-/g, '/') : '');
    form.append('notificationRemindDate3', notificationRemindDate3.value ? notificationRemindDate3.value.replace(/-/g, '/') : '');
    form.append('flyerMainImage', mainImageFile.value);
    form.append('flyerSubImage', subImageFile.value);

    return form;
};
// function register
const handleRegister = () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        validateFunction();
        validateForm.flyerSubImage = false;
        if (checkValidate(validateForm) && !checkDate.value && !fetchGetFlyerRegisterShopName?.isError.value) {
            const formData = assignFormData();
            fetchRegister.fetchData(formData);
        }
    }
};
// function register and navigate
const handleRegisterAndNavigation = () => {
    handleRegister();
    navigate.value = true;
};
const handleUpdate = () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        validateFunction();
        validateForm.flyerSubImage = false;
        if (checkValidate(validateForm) && !checkDate.value && !fetchGetFlyerRegisterShopName?.isError.value) {
            const formData = assignFormData();
            fetchUpdate.fetchData(formData);
        }
    }
};
const handleUpdateAndNavigation = () => {
    handleUpdate();
    navigate.value = true;
};
const handleProductNavigation = () => {
    router.push(
        `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
            route.params?.shopCode,
        )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(route.params?.schoolCode)}/${encodeURIComponent(
            route.params?.schoolYear,
        )}/reference/products/`,
    );
};
const handleDeleteFlyer = () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        flyerRegistrationStores?.setIsModalDelete(true);
    }
};
const handleFetchShopName = () => {
    if (shopCode.value) {
        fetchGetFlyerRegisterShopName?.fetchData({ shopCode: shopCode.value });
    } else {
        shopName.value = '';
    }
};

// proceed when call successfully register API
const handleConfirm = () => {
    if (navigate.value) {
        router.push(
            // eslint-disable-next-line max-len
            `/flyers/${flyerCode.value}/${shopCode.value}/${eventCode.value}/${schoolCode.value}/${schoolYear.value}/update/products`,
        );
        navigate.value = false;
        isSuccess.value = false;
    } else {
        router.push('/flyers');
        isSuccess.value = false;
    }
};
const clickMainImage = () => {
    eMainImage.value = document.querySelector('#main__image');
    if (!flyerRegistrationStores?.activeSelect?.mainImage) {
        eMainImage.value?.click();
    }
};
const clickSubImage = () => {
    eSubImage.value = document.querySelector('#sub__image');
    if (!flyerRegistrationStores?.activeSelect?.subImage) {
        eSubImage.value?.click();
    }
};
const RemoveSubImage = () => {
    if (!flyerRegistrationStores?.activeSelect?.subImage) {
        URL.revokeObjectURL(subImage.value.imageURL);
        subImage.value.imageName = '';
        subImage.value.imageURL = '';
        subImageFile.value = null;
        flyerRegistrationStores?.setUrlSubImagePreview('');
        eSubImage.value = document.querySelector('#sub__image');
        eSubImage.value.value = '';
        validateForm.flyerSubImage = 0;
    }
};
const handleChangeMainImage = (e) => {
    validateForm.flyerMainImage = 0;
    if (
        e?.target?.files[0] &&
        (e?.target?.files[0]?.name?.slice(-4) === '.png' || e?.target?.files[0]?.name?.slice(-4) === '.jpg') &&
        e?.target?.files[0]?.name?.length <= 128 &&
        e?.target?.files[0]?.size <= 1024 * 1024
    ) {
        mainImageFile.value = null;
        URL.revokeObjectURL(mainImage.value.imageURL);
        mainImage.value.imageName = e?.target?.files[0]?.name;
        mainImage.value.imageURL = URL.createObjectURL(e?.target?.files[0]);
        mainImageFile.value = e?.target?.files[0];
        flyerRegistrationStores?.setUrlMainImagePreview(mainImage.value.imageURL);
        return;
    }
    if (
        e?.target?.files[0] &&
        e?.target?.files[0]?.name?.slice(-4) !== '.png' &&
        e?.target?.files[0]?.name?.slice(-4) !== '.jpg'
    ) {
        URL.revokeObjectURL(subImage.value.imageURL);
        mainImage.value.imageName = '1'; // hard code to pass validate
        validateForm.flyerMainImage = 1;
        mainImageFile.value = null;
        flyerRegistrationStores?.setUrlMainImagePreview('');
        return;
    }
    if (e?.target?.files[0] && e?.target?.files[0]?.name?.length > 128) {
        URL.revokeObjectURL(subImage.value.imageURL);
        mainImage.value.imageName = '2'; // hard code to pass validate
        validateForm.flyerMainImage = 2;
        mainImageFile.value = null;
        flyerRegistrationStores?.setUrlMainImagePreview('');
        return;
    }
    if (e?.target?.files[0] && e?.target?.files[0]?.size > 1024 * 1024) {
        URL.revokeObjectURL(subImage.value.imageURL);
        mainImage.value.imageName = '3'; // hard code to pass validate
        validateForm.flyerMainImage = 3;
        mainImageFile.value = null;
        flyerRegistrationStores?.setUrlMainImagePreview('');
        return;
    }
    if (!e?.target?.files[0]) {
        URL.revokeObjectURL(mainImage.value.imageURL);
        validateForm.flyerMainImage = 1;
        mainImage.value.imageName = ''; // hard code to pass validate
        mainImageFile.value = null;
        flyerRegistrationStores?.setUrlMainImagePreview('');
    }
};

const handleChangeSubImage = (e) => {
    validateForm.flyerSubImage = 0;
    if (
        e?.target?.files[0] &&
        (e?.target?.files[0]?.name?.slice(-4) === '.png' || e?.target?.files[0]?.name?.slice(-4) === '.jpg') &&
        e?.target?.files[0]?.name?.length <= 128 &&
        e?.target?.files[0]?.size <= 1024 * 1024
    ) {
        subImageFile.value = null;
        URL.revokeObjectURL(subImage.value.imageURL);
        subImage.value.imageName = e?.target?.files[0]?.name;
        subImage.value.imageURL = URL.createObjectURL(e?.target?.files[0]);
        subImageFile.value = e?.target?.files[0];
        flyerRegistrationStores?.setUrlSubImagePreview(subImage.value.imageURL);
    }
    if (
        e?.target?.files[0] &&
        e?.target?.files[0]?.name?.slice(-4) !== '.png' &&
        e?.target?.files[0]?.name?.slice(-4) !== '.jpg'
    ) {
        URL.revokeObjectURL(subImage.value.imageURL);
        subImage.value.imageName = '1'; // hard code to pass validate
        validateForm.flyerSubImage = 1;
        subImageFile.value = null;
        flyerRegistrationStores?.setUrlSubImagePreview('');
        return;
    }
    if (e?.target?.files[0] && e?.target?.files[0]?.name?.length > 128) {
        URL.revokeObjectURL(subImage.value.imageURL);
        subImage.value.imageName = '2'; // hard code to pass validate
        validateForm.flyerSubImage = 2;
        subImageFile.value = null;
        flyerRegistrationStores?.setUrlSubImagePreview('');
        return;
    }
    if (e?.target?.files[0] && e?.target?.files[0]?.size > 1024 * 1024) {
        URL.revokeObjectURL(subImage.value.imageURL);
        subImage.value.imageName = '3'; // hard code to pass validate
        validateForm.flyerSubImage = 3;
        subImageFile.value = null;
        flyerRegistrationStores?.setUrlSubImagePreview('');
        return;
    }
    if (!e?.target?.files[0]) {
        URL.revokeObjectURL(subImage.value.imageURL);
        subImage.value.imageName = ''; // hard code to pass validate
        subImageFile.value = null;
        flyerRegistrationStores?.setUrlSubImagePreview('');
    }
};

// watch
watch(
    () => userStore?.userInfo?.affiliationKbn,
    async (newValue, oldValue) => {
        if (route.name === 'Flyer New Registration Page' && newValue === 2) {
            router.push('/orders');
        }
        if (newValue === 3) {
            shopCode.value = userStore?.userInfo?.shopCd;
            fetchGetFlyerRegisterShopName.fetchData({ shopCode: shopCode.value });
        }
    },
);

/**
 * listen error when call GetSelectBoxInformation API.
 */
watch(fetchBoxList?.isError, (newError) => {
    if (newError) {
        eventListOptions.value = [{ eventName: t('common.placeholder1'), eventCode: t('common.placeholder1') }];
        schoolListOptions.value = [
            {
                schoolName: t('common.placeholder1'),
                schoolCode: t('common.placeholder1'),
                schoolYearClassList: [
                    {
                        schoolYear: t('common.placeholder1'),
                        schoolClass: [t('common.placeholder1'), t('common.all')],
                    },
                ],
            },
        ];
        schoolYearOptions.value = [
            {
                schoolYear: t('common.placeholder1'),
                schoolClass: [t('common.placeholder1'), t('common.all')],
            },
        ];
    }
});

/**
 * listen and handle Response Data when call GetSelectBoxInformation API.
 */
watch(fetchBoxList?.data, async (newValue) => {
    eventListOptions.value = newValue?.result?.data?.eventList || [];
    eventListOptions.value?.unshift({ eventName: t('common.placeholder1'), eventCode: t('common.placeholder1') });

    schoolListOptions.value = newValue?.result?.data?.schoolList || [];
    schoolListOptions.value?.unshift({
        schoolName: t('common.placeholder1'),
        schoolCode: t('common.placeholder1'),
        schoolYearClassList: [
            {
                schoolYear: t('common.placeholder1'),
                schoolClass: [t('common.placeholder1'), t('common.all')],
            },
        ],
    });
    checkChangeValue.value = true;
    if (route.name === 'Flyer Update Page' || route.name === 'Flyer Reference Page' || route.name === 'Flyer Registration Page') {
        schoolCode.value = route.params.schoolCode;
        schoolYear.value = route.params.schoolYear;
        await fetchFlyerDetail?.fetchData({
            ...route.params,
        });
    }
});

/**
 * handle notificationMessage to display in mobilephone screen.
 */
watch(notificationMessage, (newValue) => {
    flyerRegistrationStores?.setMessage(
        newValue.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br>'),
    );
});

/**
 * listen Response data when call GetFlyerDetail API.
 * */
watch(fetchFlyerDetail?.data, async (newValue) => {
    if (newValue) {
        eventCode.value = newValue?.result?.data?.flyerInfo?.eventCode || t('common.placeholder1');
        flyerCode.value = newValue?.result?.data?.flyerInfo?.flyerCode || '';
        flyerName.value = newValue?.result?.data?.flyerInfo?.flyerName || '';
        titleFlyerName.value = newValue?.result?.data?.flyerInfo?.flyerName || '';
        shopCode.value = newValue?.result?.data?.flyerInfo?.shopCode || t('common.placeholder1');
        // await fetchGetFlyerRegisterShopName?.fetchData({ shopCode: newValue?.result?.data?.flyerInfo?.shopCode });
        schoolCode.value = newValue?.result?.data?.flyerInfo?.schoolCode;
        schoolYear.value = newValue?.result?.data?.flyerInfo?.schoolYear || t('common.placeholder1');
        schoolClass.value = newValue?.result?.data?.flyerInfo?.schoolClass || t('common.all');
        notificationMessage.value = newValue?.result?.data?.flyerInfo?.notificationMessage || '';
        flyerSupplement.value = newValue?.result?.data?.flyerInfo?.flyerSupplement || '';
        flyerStartDate.value = newValue?.result?.data?.flyerInfo?.flyerStartDate || '';
        flyerEndDate.value = newValue?.result?.data?.flyerInfo?.flyerEndDate || '';
        displayStartDate.value = newValue?.result?.data?.flyerInfo?.displayStartDate || '';
        displayEndDate.value = newValue?.result?.data?.flyerInfo?.displayEndDate || '';

        if (route.name === 'Flyer Reference Page') {
            mainImage.value = newValue?.result?.data?.flyerInfo?.flyerImageNameMain;
            flyerRegistrationStores?.setUrlMainImagePreview(newValue?.result?.data?.flyerInfo?.flyerImageNameMain?.imageURL);
            subImage.value = newValue?.result?.data?.flyerInfo?.flyerImageNameSub;
            flyerRegistrationStores?.setUrlSubImagePreview(newValue?.result?.data?.flyerInfo?.flyerImageNameSub?.imageURL);
        } else {
            // get main image
            await fetch(newValue?.result?.data?.flyerInfo?.flyerImageNameMain?.imageURL)
                .then((res) => {
                    if (res.ok) {
                        return res.blob();
                    }
                    throw new Error('Request failed');
                })
                .then((blob) => {
                    mainImage.value = newValue?.result?.data?.flyerInfo?.flyerImageNameMain;
                    flyerRegistrationStores?.setUrlMainImagePreview(
                        newValue?.result?.data?.flyerInfo?.flyerImageNameMain?.imageURL,
                    );
                    mainImageFile.value = new File([blob], mainImage.value?.imageName, { type: blob.type });
                })
                .catch((err) => {
                    console.log(err);
                });

            // get sub-image
            if (newValue?.result?.data?.flyerInfo?.flyerImageNameSub?.imageURL) {
                await fetch(newValue?.result?.data?.flyerInfo?.flyerImageNameSub?.imageURL)
                    .then((res) => {
                        if (res.ok) {
                            return res.blob();
                        }
                        throw new Error('Request failed');
                    })
                    .then((blob) => {
                        subImage.value = newValue?.result?.data?.flyerInfo?.flyerImageNameSub;
                        flyerRegistrationStores?.setUrlSubImagePreview(
                            newValue?.result?.data?.flyerInfo?.flyerImageNameSub?.imageURL,
                        );
                        subImageFile.value = new File([blob], subImage.value?.imageName, { type: blob.type });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }

        notificationRemindDate1.value = newValue?.result?.data?.flyerInfo?.notificationRemindDate1 || '';
        notificationRemindDate2.value = newValue?.result?.data?.flyerInfo?.notificationRemindDate2 || '';
        notificationRemindDate3.value = newValue?.result?.data?.flyerInfo?.notificationRemindDate3 || '';
    }
});

/**
 * listen Response when call FlyerRegistration API.
 */
watch(fetchRegister?.data, (newValue) => {
    if (newValue?.status === 0) {
        flyerCode.value = newValue?.result?.data?.flyerCode;
        isSuccess.value = true;
        flyerRegistrationStores?.setTitleDialog(t('flyerRegistration.dialog.titleRegister'));
        if (navigate.value === false) {
            flyerRegistrationStores?.setContentDialog(t('flyerRegistration.dialog.messageSuccess1'));
        } else {
            flyerRegistrationStores?.setContentDialog(t('flyerRegistration.dialog.messageRegisterProduct1'));
        }
    }
});

/**
 * listen Error when call FlyerRegistration API.
 */
watch(fetchRegister?.isError, (newValue) => {
    if (newValue || isError) {
        isError.value = newValue;
        errorMessage.value = fetchRegister?.errorMessage;
    }
});

/**
 * listen Response when call UpdateFlyer API.
 */
watch(fetchUpdate?.data, (newValue) => {
    if (newValue?.status === 0) {
        isSuccess.value = true;
        flyerRegistrationStores?.setTitleDialog(t('flyerRegistration.dialog.titleUpdate'));
        if (navigate.value === false) {
            flyerRegistrationStores?.setContentDialog(t('flyerRegistration.dialog.messageSuccess2'));
        } else {
            flyerRegistrationStores?.setContentDialog(t('flyerRegistration.dialog.messageRegisterProduct2'));
        }
    }
});

/**
 * listen Error when call UpdateFlyer API.
 */
watch(fetchUpdate?.isError, (newValue) => {
    if (newValue) {
        isError.value = newValue;
        errorMessage.value = fetchUpdate?.errorMessage;
    }
});

/**
 * listen Error when call fetchGetFlyerRegisterShopName API.
 */
watch(fetchGetFlyerRegisterShopName?.isError, (newError) => {
    if (newError) {
        shopName.value = '';
        errorShopName.value = t('error.validateMessage14', { name: shopCode.value });
    }
});

/**
 * listen response when call fetchGetFlyerRegisterShopName API.
 */
watch(fetchGetFlyerRegisterShopName?.data, (newData) => {
    if (newData) {
        errorShopName.value = '';
        shopName.value = newData?.result?.data?.shopName;
    }
});

// listen validate form
watch(eventCode, (newEventCode) => {
    if (newEventCode !== t('common.placeholder1')) {
        validateForm.eventCode = false;
    }
});
watch(flyerName, (newValue) => {
    validateForm.flyerName = false;
});
watch(flyerSupplement, (newValue) => {
    validateForm.flyerSupplement = false;
});
watch(shopName, async (newShopName) => {
    if (newShopName) {
        if (
            route.name === 'Flyer Update Page' ||
            route.name === 'Flyer Reference Page' ||
            route.name === 'Flyer Registration Page'
        ) {
            await fetchBoxList?.fetchData({
                userId: userStore?.userId,
                shopCode: route.params?.shopCode,
            });
        } else {
            await fetchBoxList?.fetchData({
                userId: userStore?.userId,
                shopCode: shopCode.value,
            });
        }
    }
});
watch(shopCode, (newShopCode) => {
    validateForm.shopCode = false;
});
watch(schoolListOptions, (newValue) => {
    schoolYearOptions.value = [
        {
            schoolYear: t('common.placeholder1'),
            schoolClass: [t('common.placeholder1'), t('common.all')],
        },
    ];
    schoolYearOptions.value.push(
        ...(newValue?.filter((item) => item?.schoolCode == schoolCode.value)[0]?.schoolYearClassList || []),
    );
    schoolClassOptions.value = [t('common.placeholder1'), t('common.all')];
    schoolClassOptions.value.push(
        ...(schoolYearOptions.value?.filter((item) => item?.schoolYear === schoolYear.value)[0]?.schoolClass || []),
    );
});
watch(schoolCode, (newSchoolCode) => {
    validateForm.schoolCode = false;
    if (checkChangeValue.value) {
        schoolClass.value = t('common.placeholder1');
        schoolYear.value = t('common.placeholder1');
    }
    if (newSchoolCode !== t('common.placeholder1')) {
        schoolYearOptions.value = [
            {
                schoolYear: t('common.placeholder1'),
                schoolClass: [t('common.placeholder1'), t('common.all')],
            },
        ];
        schoolYearOptions.value.push(
            ...(schoolListOptions.value?.filter((item) => item?.schoolCode == newSchoolCode)[0]?.schoolYearClassList || []),
        );
    }
});
watch(schoolYear, (newSchoolYear) => {
    if (newSchoolYear !== t('common.placeholder1')) {
        validateForm.schoolYear = false;
    }
    if (newSchoolYear === t('common.placeholder1')) {
        schoolClass.value = t('common.placeholder1');
    }

    schoolClassOptions.value = [t('common.placeholder1'), t('common.all')];
    const tempClassOptions = schoolYearOptions.value?.filter((item) => item?.schoolYear === newSchoolYear)[0]?.schoolClass || [];
    if (newSchoolYear === t('common.teacher')) {
        if (!schoolClassOptions.value?.includes(schoolClass.value)) {
            schoolClass.value = t('common.placeholder1');
        }
        return;
    }
    if (newSchoolYear == 0) {
        const filterClassForNewStudent = tempClassOptions.filter((item) => item != 0) || [];
        schoolClassOptions.value.push(...filterClassForNewStudent);
        if (!schoolClassOptions.value?.includes(schoolClass.value)) {
            schoolClass.value = t('common.placeholder1');
        }
        return;
    }

    schoolClassOptions.value.push(
        ...(schoolYearOptions.value?.filter((item) => item?.schoolYear === newSchoolYear)[0]?.schoolClass || []),
    );
    if (!schoolClassOptions.value?.includes(schoolClass.value)) {
        schoolClass.value = t('common.placeholder1');
    }
});
watch(schoolClass, (newSchoolClass) => {
    if (newSchoolClass !== t('common.placeholder1')) {
        validateForm.schoolClass = false;
    }
});
watch(notificationMessage, (newValue) => {
    validateForm.notificationMessage = false;
});
watch(flyerStartDate, (newValue) => {
    validateForm.flyerStartDate = false;
    checkDate.value = false;
});
watch(flyerEndDate, (newValue) => {
    validateForm.flyerEndDate = false;
    checkDate.value = false;
    if (new Date(flyerEndDate.value) >= new Date(notificationRemindDate1.value)) {
        validateForm.notificationRemindDate1 = false;
    }
    if (new Date(flyerEndDate.value) >= new Date(notificationRemindDate2.value)) {
        validateForm.notificationRemindDate2 = false;
    }
    if (new Date(flyerEndDate.value) >= new Date(notificationRemindDate3.value)) {
        validateForm.notificationRemindDate3 = false;
    }
});
watch(displayStartDate, (newValue) => {
    validateForm.displayStartDate = false;
    checkDate.value = false;
});
watch(displayEndDate, (newValue) => {
    validateForm.displayEndDate = false;
    checkDate.value = false;
});
watch(notificationRemindDate1, (newValue) => {
    validateForm.notificationRemindDate1 = false;
});
watch(notificationRemindDate2, (newValue) => {
    validateForm.notificationRemindDate2 = false;
});
watch(notificationRemindDate3, (newValue) => {
    validateForm.notificationRemindDate3 = false;
});
</script>

<style lang="css" src="./ContentFlyer.css" scoped></style>
