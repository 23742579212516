<template>
    <div class="wrapper__staff--list">
        <div class="staff__list--title">
            <TheTitle class="title">
                <h1>{{ t('shopStaff.titleList') }}</h1>
            </TheTitle>
            <div class="acction__button--header">
                <TheButton
                    :disabled="userStore?.userInfo?.affiliationKbn !== 1 || !shopStaffStores?.shopCode"
                    @click="handleCreateStaff"
                >
                    {{ t('shopStaff.labelRegisterUser') }}
                </TheButton>
                <img
                    :src="commonStores?.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </div>
        <StaffList></StaffList>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import StaffList from './StaffList.vue';
import { useCommonStores } from '../../../../stores/CommonStores.js';
import { useShopStaffStores } from '../../../../stores/ShopStaffStores.js';

import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';
import { useUserStore } from '../../../../stores/UserStore';

// i18n
const { t } = useI18n();

// stores
const commonStores = useCommonStores();
const userStore = useUserStore();
const shopStaffStores = useShopStaffStores();

// function
const handleChangeSize = () => {
    commonStores?.setIsExpand(!commonStores?.isExpand);
};
const handleCreateStaff = () => {
    shopStaffStores?.setIsModalCreate(true);
};
</script>

<style lang="css" src="./BaseStaffList.css" scoped></style>
