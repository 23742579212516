<template>
    <div class="wrapper__tag__information">
        <div class="tag__information--label">{{ props.label }}</div>
        <div class="tag__information--value">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    label: {
        type: String,
        required: true,
        default: '',
    },
});
</script>

<style lang="css" src="./TagInfo.css" scoped></style>
