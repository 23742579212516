<template>
    <div class="order__user">
        <TheTitle class="order__user--title">
            <h2>{{ t('orderDetail.userInfo.title') }}</h2>
        </TheTitle>
        <BaseInformationDetailContent class="order__user--content">
            <div class="order__user--content1">
                <div class="order__user--infor">
                    <TagInfo v-for="(item, index) in info1" :key="index" :label="item?.label">
                        <p class="infomation__content--item">{{ orderDetailStores?.orderDetailInfo?.[item?.key] }}</p>
                    </TagInfo>
                </div>
                <div class="order__user--infor">
                    <TagInfo v-for="(item, index) in info2" :key="index" :label="item?.label">
                        <p class="infomation__content--item">{{ orderDetailStores?.orderDetailInfo?.[item?.key] }}</p>
                    </TagInfo>
                </div>
            </div>
            <div class="order__user--content2">
                <TagInfo v-for="(item, index) in address" :key="index" :label="item?.label">
                    <p class="infomation__content--item">{{ orderDetailStores?.orderDetailInfo?.[item?.key] }}</p>
                </TagInfo>
            </div>
        </BaseInformationDetailContent>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TagInfo from '../../../../components/tagInfo/TagInfo.vue';
import BaseInformationDetailContent from '../informationDetailContent/BaseInformationDetailContent.vue';
import { useOrderDetailStores } from '../../../../stores/OrderDetailStores';

// i18n
const { t } = useI18n();

// stores
const orderDetailStores = useOrderDetailStores();

const info1 = [
    {
        label: t('orderDetail.userInfo.orderUser'),
        key: 'orderUser',
    },
    {
        label: t('orderDetail.userInfo.studentName'),
        key: 'studentName',
    },
    {
        label: t('orderDetail.userInfo.telNo'),
        key: 'telNo',
    },
];
const info2 = [
    {
        label: t('orderDetail.userInfo.delvPlace'),
        key: 'delvPlace',
    },
    {
        label: t('orderDetail.userInfo.postalCode'),
        key: 'postalCode',
    },
];
const address = [
    {
        label: t('orderDetail.userInfo.address1'),
        key: 'address1',
    },
    {
        label: t('orderDetail.userInfo.address2'),
        key: 'address2',
    },
    {
        label: t('orderDetail.userInfo.address3'),
        key: 'address3',
    },
];
</script>

<style lang="css" src="./BaseInformationUser.css" scoped></style>
