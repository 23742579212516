<template>
    <div class="student__list--container">
        <div class="student__list--content">
            <TheTitle>
                <h1>{{ t('student.title1') }}</h1>
            </TheTitle>
            <div class="filter__bar">
                <FilterBar @change="hanleChangeFilter" />
            </div>
            <div class="students__table--wrapper">
                <ThePaper class="table__paper">
                    <div v-if="!isNewSearchLoading && !isError && students?.length" class="table__header">
                        <div class="number__of--page">
                            <span>{{ t('common.searchResult') }}</span>
                            <SelectField :model-value="params?.limit" @update:model-value="handleChangeLimit" />
                            <span>
                                {{
                                    t('common.showSearchResult', {
                                        total: totalRecords,
                                        start: (currPage - 1) * params?.limit + 1,
                                        end: currPage * params?.limit > totalRecords ? totalRecords : currPage * params?.limit,
                                    })
                                }}
                            </span>
                        </div>
                        <div>
                            <ThePagination :total="pagesTotal" :curr-page="currPage" @change="handleChangePage" />
                        </div>
                    </div>
                    <TheTable :columns="columns" :data="students" class="students__table" />
                    <!-- Empty -->
                    <div v-if="isError && errorCode === 'E0004'" class="table__item--center">
                        <img src="../../../assets/icons/empty_folder.svg" alt="empty folder icon" />
                        <p class="empty_list">{{ errorMessage }}</p>
                    </div>
                    <!-- Error -->
                    <div v-if="isError && errorCode !== 'E0004'" class="table__item--center">
                        <img src="../../../assets/icons/error-icon.svg" alt="error icon" />
                        <p>
                            {{ errorMessage }}
                        </p>
                    </div>
                    <!-- Loading -->
                    <div v-if="isLoading" class="table__item--center">
                        <div class="custom-loader"></div>
                    </div>
                    <div v-if="!isNewSearchLoading && !isError && students?.length !== 0" class="bottom__pagination">
                        <ThePagination :total="pagesTotal" :curr-page="currPage" @change="handleChangePage" />
                    </div>
                </ThePaper>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import FilterBar from './FilterBar.vue';
import ThePaper from '../../../components/paper/ThePaper.vue';
import SelectField from '../../../components/select/SelectField.vue';
import ThePagination from '../../../components/pagination/ThePagination.vue';
import TheTable from '../../../components/table/TheTable.vue';
import TheTitle from '../../../components/title/TheTitle.vue';
import useFetch from '../../../utils/useFetch';
import StudentsService from '../../../services/StudentsService';
import { useUserStore } from '../../../stores/UserStore.js';

// i18n
const { t } = useI18n();

// const renderWidth = (width) => {
//     return { 'min-width': `${width}px`, 'max-width': `${width}px` };
// };

const columns = [
    {
        key: 'schoolYear',
        title: t('student.schoolYear'),
        styles: {
            minWidth: '150px',
            maxWidth: '150px',
        },
        stylesBody: {
            paddingLeft: '24px',
        },
    },
    {
        key: 'schoolClass',
        title: t('student.schoolClass'),
        styles: {
            minWidth: '150px',
            maxWidth: '150px',
        },
    },
    {
        key: 'studentName',
        title: t('student.studentName'),
        styles: {
            minWidth: '300px',
            maxWidth: '300px',
        },
    },
    {
        key: 'studentNameKana',
        title: t('student.studentNameKata'),
        styles: {
            minWidth: '300px ',
        },
    },
];

// store
const userStore = useUserStore();

const students = ref(null);
const pagesTotal = ref(0);
const currPage = ref(1);
const totalRecords = ref(0);
const isNewSearchLoading = ref(false);
const params = ref({
    page: 1,
    studentName: '',
    schoolYear: '',
    schoolClass: '',
    userId: userStore?.userId || '',
    limit: 50,
    schoolCode: '',
});

const { isLoading, errorMessage, isError, errorCode, data, fetchData } = useFetch(StudentsService.getStudentList);

watch(
    () => params.value,
    async (newParams) => {
        await fetchData(newParams);
        isNewSearchLoading.value = false;
    },
);

watch(data, (newData) => {
    students.value = newData?.result?.data?.studentLists;
    pagesTotal.value = newData?.result?.data?.totalPages;
    totalRecords.value = newData?.result?.data?.totalRecords;
});

const handleChangePage = (newPage) => {
    params.value = { ...params.value, page: newPage };
    currPage.value = newPage;
};

const hanleChangeFilter = (newValue) => {
    params.value = { ...params?.value, ...newValue, page: 1 };
    currPage.value = 1;
    isNewSearchLoading.value = true;
};

const handleChangeLimit = (newValue) => {
    params.value = { ...params.value, limit: newValue, page: 1 };
    currPage.value = 1;
};

onMounted(() => {
    if (userStore?.userInfo?.affiliationKbn !== 1) fetchData(params.value);
});
</script>

<style lang="css" src="./StudentList.css" scoped></style>
