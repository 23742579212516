export const validateForm = (form, errors) => {
    errors.SHOP_NAME = form.SHOP_NAME ? '' : '販売店名は必須です';
    errors.SHOP_NAME_KANA = /^[ァ-ヶー]*$/.test(form.SHOP_NAME_KANA) ? '' : '販売店名カナはカタカナのみ入力可能です';
    errors.SHOP_NAME_ABBREVIATION = form.SHOP_NAME_ABBREVIATION.length <= 20 ? '' : '販売店名略称は20文字以下で入力してください';
    errors.POSTAL_CODE = /^[0-9]+$/.test(form.POSTAL_CODE) ? '' : '郵便番号はハイフンなしの数値を入力してください';
    errors.ADDRESS1 = form.ADDRESS1 ? '' : '住所１は必須入力です';
    errors.TEL_NO = /^[0-9]+$/.test(form.TEL_NO) ? '' : '電話番号はハイフンなしの数値を入力してください';
    errors.FAX_NO = /^[0-9]+$/.test(form.FAX_NO) ? '' : 'FAX番号はハイフンなしの数値を入力してください';
    errors.REPRESENTATIVE_NAME_KANA = /^[ァ-ヶー]*$/.test(form.REPRESENTATIVE_NAME_KANA)
        ? ''
        : '代表者名カナは全角カタカナのみ入力可能です';
    errors.REPRESENTATIVE_MAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.REPRESENTATIVE_MAIL)
        ? ''
        : '代表メールアドレスはメールアドレス形式のみ入力可能です';
    errors.START_DATE = !isNaN(Date.parse(form.START_DATE)) ? '' : '利用開始日は日付のみ入力可能です';
    errors.FDC_SHOP_CODE =
        form.FDC_SHOP_CODE && form.FDC_SHOP_CODE.length <= 10 ? '' : 'FDC販売店コードは必須入力かつ10文字以下です';
    errors.REGISTRATION_NUMBER = /^[0-9]+$/.test(form.REGISTRATION_NUMBER) ? '' : '登録番号は必須入力かつ数値を入力してください';
    return errors;
};

export const validateUpdate = (form, errors) => {
    errors.shopName = errors.shopName == '' && form.shopName == '' ? '販売店名を入力してください' : errors.shopName;
    errors.shopNameKana =
        errors.shopNameKana == '' && form.shopNameKana == '' ? '販売店名カナを入力してください' : errors.shopNameKana;
    errors.phoneNumber = form.phoneNumber == '' ? '電話番号を入力してください' : errors.phoneNumber;
    errors.phoneNumber =
        errors.phoneNumber != '' || /^[0-9]+$/.test(form.phoneNumber) ? errors.phoneNumber : 'ハイフンなしで入力してください';
    errors.representativeName = form.representativeName ? '' : '代表者名を入力してください';
    errors.representativeEmail = form.representativeEmail ? '' : '代表メールアドレスを入力してください';
    errors.representativeEmail =
        errors.representativeEmail != '' || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.representativeEmail)
            ? errors.representativeEmail
            : 'メールアドレスの形式で入力してください';
    return errors;
};

export const validateshop = (form, errors) => {
    errors.shopName = errors.shopName == '' && form.shopName == '' ? '販売店名を入力してください' : errors.shopName;
    errors.shopNameKana = /^[ァ-ヶ0-9A-Za-zー－\s]*$/.test(form.shopNameKana) ? '' : '販売店名カナはカタカナのみ入力可能です';
    errors.postalCode =
        form.postalCode === '' || /^[0-9]+$/.test(form.postalCode) ? errors.postalCode : 'ハイフンなしで入力してください';
    errors.phoneNumber = form.phoneNumber == '' ? '電話番号を入力してください' : errors.phoneNumber;
    errors.phoneNumber =
        errors.phoneNumber != '' || /^[0-9]+$/.test(form.phoneNumber) ? errors.phoneNumber : 'ハイフンなしで入力してください';
    errors.faxNumber =
        form.faxNumber === '' || /^[0-9]+$/.test(form.faxNumber) ? errors.faxNumber : 'ハイフンなしで入力してください';
    errors.representativeName = form.representativeName ? '' : '代表者名を入力してください';
    errors.representativeNameKana = /^[ァ-ヶ0-9A-Za-zー－\s]*$/.test(form.representativeNameKana)
        ? ''
        : '代表者名カナはカタカナのみ入力可能です';
    errors.windowerkana = /^[ァ-ヶ0-9A-Za-zー－\s]*$/.test(form.windowerkana) ? '' : '窓口担当者カナはカタカナのみ入力可能です';
    errors.representativeEmail = form.representativeEmail ? '' : '代表者メールアドレスを入力してください';
    errors.representativeEmail =
        errors.representativeEmail != '' || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.representativeEmail)
            ? errors.representativeEmail
            : 'メールアドレスの形式で入力してください';
    errors.shippingPostalCode =
        form.shippingPostalCode === '' || /^[0-9]+$/.test(form.shippingPostalCode)
            ? errors.shippingPostalCode
            : 'ハイフンなしで入力してください';
    errors.accountHolderNameKana = /^[ァ-ヶ0-9A-Za-zー－\s]*$/.test(form.accountHolderNameKana)
        ? ''
        : '口座名義カナはカタカナのみ入力可能です';
    return errors;
};
