<template>
    <BaseDialog :title="t('notification.title2')" :content="t('notification.content2')">
        <template #default>
            <div class="more__action">
                <label for="reason" class="reason__label">{{ t('orderDetail.modal.reason') }}</label>
                <textarea id="reason" v-model="reason" class="reason__input" resize="none" type="textarea"></textarea>
                <div class="button__action">
                    <TheButton class="button__action--cancel" @click="handleCloseModal">{{ t('confirm.content2') }}</TheButton>
                    <TheButton class="button__action--confirm" @click="handleDeleteOrder">{{ t('confirm.content3') }}</TheButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseDialog from './BaseDialog.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useOrderDetailStores } from '../../../../stores/OrderDetailStores.js';
import { useOrderStores } from '../../../../stores/OrderStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import OrderDetailService from '../../../../services/OrderDetailService.js';
import useFetch from '../../../../utils/useFetch.js';

// i18n
const { t } = useI18n();

// store
const orderDetailStores = useOrderDetailStores();
const orderStores = useOrderStores();
const userStore = useUserStore();

// ref
const reason = ref('');

// fetch
const { isError, errorMessage, data, fetchData } = useFetch(OrderDetailService?.deleteOrder);

// function
const handleCloseModal = () => {
    orderDetailStores?.setModalDeleteOrder(false);
    reason.value = '';
};

const handleDeleteOrder = async () => {
    await fetchData({
        userId: userStore?.userId,
        cancelReason: reason.value,
        orderCode: orderStores?.orderCode,
    });
    orderDetailStores?.setModalDeleteOrder(false);
};

// mounted
onMounted(() => {
    document.querySelector('.reason__input')?.focus();
});

// watch
watch(isError, (newValue) => {
    if (newValue) {
        orderDetailStores?.setErrorContent(errorMessage.value);
        orderDetailStores?.setModalResultDelete(true);
    }
    reason.value = '';
});

watch(data, (newValue) => {
    orderDetailStores?.setModalDeleteOrder(false);
    orderStores?.setOrderDetail(false);
    orderStores?.setOrderCode('');
    reason.value = '';
});
</script>

<style lang="css" src="./DialogDelete.css" scoped></style>
