<template>
    <div class="order__list--container">
        <div class="orders__header">
            <TheTitle class="orders__header--title">
                <h2>{{ t('shop.table.title') }}</h2>
            </TheTitle>
            <div class="orders__header--actions">
                <TheButton @click="goShopRegister">販売店を登録する</TheButton>
                <img
                    :src="commonStores.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </div>
        <!--GI#4_1_17-->
        <div class="orders__body">
            <TheTable :columns="columns" :data="shops" class="orders__table" />
            <div class="search__status">
                <!-- Error -->
                <div v-if="isError && errorCode === 'E0093'" class="table__item--center">
                    <img src="../../../assets/icons/empty_folder.svg" alt="error-icon" />
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <!-- Error -->
                <div v-if="isError && errorCode !== 'E0093'" class="table__item--center">
                    <img src="../../../assets/icons/error-icon.svg" alt="error-icon" />
                    <p class="error">
                        {{ errorMessage }}
                    </p>
                </div>
                <!-- Loading -->
                <div v-if="isLoading" class="table__item--center">
                    <div class="custom-loader"></div>
                </div>
            </div>
        </div>
        <div v-if="shops?.length > 0" class="orders__footer">
            <div class="number__of--page">
                <span>{{ t('common.searchResult') }}</span>
                <SelectField :model-value="params?.limit" @update:model-value="handleChangeLimit" />
                <span>
                    {{
                        t('common.showSearchResult', {
                            total: totalRecords,
                            start: (currPage - 1) * params?.limit + 1,
                            end: currPage * params?.limit > totalRecords ? totalRecords : currPage * params?.limit,
                        })
                    }}
                </span>
            </div>
            <div>
                <ThePagination :total="pagesTotal" :curr-page="currPage" @change="handleChangePage" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, h } from 'vue';
// import FilterBar from './FilterBar.vue';
import { useI18n } from 'vue-i18n';
// import ThePaper from '../../../components/paper/ThePaper.vue';
import SelectField from '../../../components/select/SelectField.vue';
import ThePagination from '../../../components/pagination/ThePagination.vue';
import TheTable from '../../../components/table/TheTable.vue';
import TheTitle from '../../../components/title/TheTitle.vue';
import TheButton from '../../../components/button/TheButton.vue';
import useFetch from '../../../utils/useFetch';
import shopsService from '../../../services/ShopsService';
import { useUserStore } from '../../../stores/UserStore.js';
import { useShopStores } from '../../../stores/ShopStores.js';
import { useCommonStores } from '../../../stores/CommonStores';
import expandIcon from '../../../assets/icons/maximize.svg';
import collapseIcon from '../../../assets/icons/minimize.svg';

const commonStores = useCommonStores();
const shopStores = useShopStores();
// i18n
const { t } = useI18n();

const renderWidth = (width) => {
    return { 'min-width': `${width}px`, 'max-width': `${width}px` };
};

const columns = [
    {
        key: 'shopName',
        title: '販売店名',
        styles: renderWidth(200),
        render: (column, item) => aElement(column, item),
    },
    {
        key: 'representativeName',
        title: '代表者名',
        styles: renderWidth(200),
    },
    {
        key: 'address1',
        title: '住所１',
        styles: renderWidth(200),
    },
    {
        key: 'address2',
        title: '住所２',
        styles: renderWidth(200),
    },
    {
        key: 'phoneNumber',
        title: '電話番号',
        styles: renderWidth(200),
    },
    {
        key: 'startingDate',
        title: '利用開始日',
        styles: renderWidth(200),
    },
    {
        key: 'shopStatus',
        title: '販売店ステータス',
        styles: renderWidth(200),
    },
];

// store
const userStore = useUserStore();

const shops = ref(null);
const pagesTotal = ref(0);
const currPage = ref(1);
const totalRecords = ref(0);
const limit = ref(30);
const isNewSearchLoading = ref(false);
const params = ref({
    page: 1,
    shopName: '',
    shopRepresentativeName: '',
    userId: userStore?.userId || '',
    limit: limit,
    reload: false,
});

// const shopsFormat = computed(() => {
//     return shops.value?.map((shop) => ({
//         ...shop,
//         schoolYear: shop?.schoolYear ? shop?.schoolYear + '年' : '0年',
//         schoolClass: shop?.schoolClass + '組',
//     }));
// });

const { isLoading, errorMessage, isError, errorCode, data, fetchData } = useFetch(shopsService.getShopList);

watch(
    () => params.value,
    async (newParams) => {
        await fetchData(newParams);
        isNewSearchLoading.value = false;
    },
);

watch(
    () => shopStores?.shopListRefreshData,
    async (newValue) => {
        await fetchData(params.value);
    },
);

watch(
    () => shopStores?.isListSearch,
    async (newValue) => {
        params.value = {
            ...shopStores?.searchParams,
            page: 1,
            limit: limit.value,
            userId: userStore?.userId || '',
        };
        await fetchData(params.value);
    },
);

watch(data, (newData) => {
    shops.value = newData?.result?.data?.shopsLists;
    pagesTotal.value = newData?.result?.data?.totalPages;
    totalRecords.value = newData?.result?.data?.totalRecords;
});

const handleChangePage = (newPage) => {
    currPage.value = newPage;
    params.value = { ...params.value, offset: (currPage.value - 1) * limit.value };
};
const handleChangeLimit = (newLimit) => {
    limit.value = newLimit;
    params.value = { ...params.value, limit: limit.value };
};
const handleChangeSize = () => {
    commonStores.setIsExpand(!commonStores?.isExpand);
};

const handleRowClicked = (e, row) => {
    e.preventDefault();
    shopStores?.setShopCRUDStatus(2);
    shopStores?.setShopList(false);
    shopStores?.setShopCode(row.shopCode);
};

const goShopRegister = () => {
    shopStores?.setShopCRUDStatus(1);
    shopStores?.setShopList(false);
};

const aElement = (column, item) =>
    h('a', { href: '/', style: { color: '#1778CA' }, onClick: (e) => handleRowClicked(e, item) }, item[column?.key]);

fetchData(params.value);
</script>

<style lang="css" src="./ShopsList.css" scoped></style>
