<template>
    <ThePaper>
        <div v-if="userStore?.userInfo?.affiliationKbn === 1" class="school__code__col">
            <TextInput
                ref="schoolCodeRef"
                :label="t('student.schoolLabel')"
                class="school__input"
                placeholder="入力してください"
                :style-label="{ 'min-width': '145px' }"
                :model-value="schoolCode"
                :max-length="13"
                :is-required="true"
                :error="schoolCodeError"
                @update:model-value="(newValue) => (schoolCode = newValue)"
                @blur="handleBlur"
            />
            <SearchSchoolModal :init-value="{ schoolCode }" @select-school="handleSelectSchool" @close="handleClose" />
            <p v-if="schoolName" class="school__name--content">{{ schoolName }}</p>
            <p v-if="isError" class="school__name--error">{{ errorMessage }}</p>
            <div v-if="isLoading" class="custom-loader"></div>
        </div>
        <div class="inputs">
            <div class="select--container school__year__col">
                <label class="label">
                    {{ t('student.schoolYear') }}
                    <span v-if="false" class="input__required">(*)</span>
                </label>
                <TheSelect
                    class="select--item"
                    :required="false"
                    :selection="schoolYear"
                    :hide="isLoading"
                    :options="[t('common.all'), ...schoolYearListReplace]"
                    :array-value="[t('common.all'), ...schoolYearList]"
                    data-type="string"
                    @input="
                        (newValue) => {
                            schoolYear = newValue;
                        }
                    "
                ></TheSelect>
            </div>
            <!-- <TextInput
                ref="schoolYearRef"
                :label="t('student.schoolYear')"
                class="school__year__col"
                :model-value="schoolYear"
                :max-length="10"
                @keyup.enter="handleSubmit"
                @update:model-value="(newValue) => (schoolYear = newValue)"
            /> -->

            <div class="select--container class__col">
                <label class="label">
                    {{ t('student.schoolClass') }}
                    <span v-if="false" class="input__required">(*)</span>
                </label>
                <TheSelect
                    class="select--item"
                    :required="false"
                    :selection="schoolClass"
                    :hide="isLoading"
                    :options="[t('common.all'), ...schoolClassList]"
                    data-type="string"
                    @input="
                        (newValue) => {
                            schoolClass = newValue;
                        }
                    "
                ></TheSelect>
            </div>
            <!-- <TextInput
                :label="t('student.schoolClass')"
                class="class__col"
                :model-value="schoolClass"
                :max-length="10"
                @keyup.enter="handleSubmit"
                @update:model-value="(newValue) => (schoolClass = newValue)"
            /> -->
            <div class="student__name__col">
                <TextInput
                    :label="t('student.studentName')"
                    class="student__name"
                    :model-value="studentName"
                    :max-length="20"
                    @keyup.enter="handleSubmit"
                    @update:model-value="(newValue) => (studentName = newValue)"
                />
                <TheButton class="filter__button" @click="handleSubmit">
                    <template #left-icon>
                        <img src="@/assets/icons/search.svg" alt="search-icon" />
                    </template>
                    {{ t('student.searchName') }}
                </TheButton>
            </div>
        </div>
    </ThePaper>
</template>

<script setup>
import { onMounted, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ThePaper from '../../../components/paper/ThePaper.vue';
import TextInput from '../../../components/textInput/TextInput.vue';
import TheButton from '../../../components/button/TheButton.vue';
import TheSelect from '../../../components/select/TheSelect.vue';
import SearchSchoolModal from './SearchSchoolModal.vue';
import useFetch from '../../../utils/useFetch';
import SchoolStaffService from '../../../services/SchoolStaffService';
import { useUserStore } from '../../../stores/UserStore';
import StudentsService from '../../../services/StudentsService';

const emits = defineEmits(['change']);
// i18n
const { t } = useI18n();
const userStore = useUserStore();

const studentName = ref('');
const schoolYear = ref(t('common.all'));
const schoolClass = ref(t('common.all'));
const schoolYearRef = ref(null);
const schoolCodeRef = ref(null);
const schoolCode = ref('');
const schoolName = ref('');
const schoolCodeError = ref('');
const schoolList = ref([]);
const schoolYearList = ref([]);
const schoolYearListReplace = ref([]);
const schoolClassList = ref([]);

const { isLoading, errorMessage, isError, data, fetchData } = useFetch(SchoolStaffService.getStudentListSchoolName);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGradeClassListSearch = useFetch(StudentsService.getGradeClassListSearch);

watch(
    () => userStore?.userInfo,
    () => {
        setTimeout(() => {
            if (userStore?.userInfo?.affiliationKbn === 1) schoolCodeRef.value?.input?.focus();
            else schoolYearRef.value?.input?.focus();
        }, 500);
    },
);

watch(data, (newData) => {
    schoolName.value = newData?.result?.data?.schoolName;
    if (newData) {
        getGradeClassListSearch?.fetchData({ schoolCode: schoolCode.value, userId: userStore?.userId });
    }
});

const handleSubmit = () => {
    if (userStore?.userInfo?.affiliationKbn === 1) {
        if (!schoolCode.value) {
            schoolCodeError.value = t('student.schools.schoolCodeRequiedMessage');
            return;
        }
        if (!schoolName.value) return;
    }

    emits('change', {
        studentName: studentName.value,
        schoolYear: schoolYear.value === t('common.all') ? '' : schoolYear.value,
        schoolClass: schoolClass.value === t('common.all') ? '' : schoolClass.value,
        schoolCode: schoolCode.value,
    });
};

const handleSelectSchool = (school) => {
    schoolCode.value = school.schoolCode;
    schoolName.value = school.schoolName;
    isError.value = false;
    isError.value = '';
    if (schoolCode.value) {
        fetchData({ schoolCode: schoolCode.value, userId: userStore?.userId });
    }
};

const handleClose = () => {
    isError.value = false;
    errorMessage.value = '';
};

const handleBlur = () => {
    schoolName.value = '';
    isError.value = '';
    schoolCodeError.value = '';
    if (schoolCode.value) {
        fetchData({ schoolCode: schoolCode.value, userId: userStore?.userId });
    }
};

const handleHiddenErrorMessage = () => {
    if (!isError.value) {
        schoolName.value = '';
    }
    isError.value = '';
    schoolCodeError.value = '';
};

const replaceYearValue = (yearList) => {
    if (yearList?.length === 0) {
        return yearList;
    }

    const yearListReplace = yearList?.map((year) => {
        if (year == 0) {
            // Ver1.2 #schoolYear = 0
            return t('common.newStudent');
        }
        return year;
    });

    return yearListReplace;
};

watch(isError, (newError) => {
    if (newError) {
        schoolYear.value = t('common.all');
        schoolClass.value = t('common.all');
        schoolYearList.value = [];
        schoolYearListReplace.value = replaceYearValue(schoolYearList.value);
        schoolClassList.value = [];
    }
});

watch(getGradeClassListSearch.data, (newData) => {
    if (newData) {
        schoolList.value = newData.result.data.schoolList;
        schoolYearList.value = newData.result.data.schoolList.map((item) => item.schoolYear);
        schoolYearListReplace.value = replaceYearValue(schoolYearList.value);
        schoolYear.value = t('common.all');
    }
});

watch(getGradeClassListSearch.isError, (newIsError) => {
    if (newIsError) {
        schoolList.value = [];
        schoolYear.value = t('common.all');
        schoolClass.value = t('common.all');
        schoolYearList.value = [];
        schoolYearListReplace.value = replaceYearValue(schoolYearList.value);
        schoolClassList.value = [];
    }
});

watch(schoolYear, (newSchoolYear) => {
    if (newSchoolYear === t('common.all')) {
        schoolClassList.value = [];
        schoolClass.value = t('common.all');
        return;
    }
    if (newSchoolYear === t('common.teacher')) {
        schoolClass.value = t('common.all');
        schoolClassList.value = [];
        return;
    }
    if (newSchoolYear == 0) {
        schoolClass.value = t('common.all');
        const schoolClassTemp = schoolList.value?.filter((item) => item.schoolYear === newSchoolYear)[0]?.schoolClass || [];
        schoolClassList.value = schoolClassTemp?.filter((item) => item != 0) || [];
        return;
    }
    schoolClass.value = t('common.all');
    schoolClassList.value = schoolList.value?.filter((item) => item.schoolYear === newSchoolYear)[0]?.schoolClass || [];
});

watch(
    () => userStore?.userInfo,
    (newUserInfo) => {
        if (newUserInfo?.affiliationKbn === 2) {
            getGradeClassListSearch?.fetchData({ schoolCode: newUserInfo?.schoolCd, userId: userStore?.userId });
        }
    },
);

onMounted(() => {
    if (userStore?.userInfo?.affiliationKbn === 2) {
        getGradeClassListSearch?.fetchData({ schoolCode: userStore?.userInfo?.schoolCd, userId: userStore?.userId });
    }
});

// fix bug: hide error when show dialog choice school from school code
// provides
provide('filterBar', { handleHiddenErrorMessage });
</script>

<style lang="css" src="./FilterBar.css" scoped></style>
