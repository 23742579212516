<template>
    <button
        class="button"
        :class="{ button__outlined: props?.variant === 'outlined', button__disabled: props?.disabled }"
        :disabled="props?.disabled"
    >
        <slot name="left-icon"></slot>
        <slot></slot>
        <slot name="right-icon"></slot>
    </button>
</template>

<script setup>
const props = defineProps({
    variant: String,
    disabled: Boolean,
});
</script>

<style lang="css" src="./TheButton.css" scoped></style>
