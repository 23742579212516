<template>
    <TheButton
        class="searching__school__button"
        @click="
            () => {
                handleHiddenErrorMessage();
                isShowModal = true;
            }
        "
    >
        {{ t('student.searchSchoolButton') }}
    </TheButton>
    <v-dialog v-model="isShowModal" class="searching__school--modal" min-height="70%" min-width="70%" max-width="70%">
        <div class="searching__modal--container">
            <div class="modal__header">
                <TheTitle class="modal__header--title">
                    <h3 class="header__title--content">{{ t('student.schools.title') }}</h3>
                </TheTitle>
                <div class="close__icon" @click="handleClose">
                    <img src="../../../assets/icons/close-icon.svg" alt="" />
                </div>
            </div>
            <div class="searching__modal--content">
                <div class="searching__form">
                    <div class="inputs">
                        <TextInput
                            ref="schoolCodeRef"
                            :label="t('student.schools.schoolCode')"
                            class="school__code__col"
                            :placeholder="t('common.placeholder3')"
                            :model-value="schoolCode"
                            :max-length="13"
                            :style-label="{ 'min-width': '135px' }"
                            :error="errors.schoolCode"
                            @keyup.enter="handleSearchSchool"
                            @update:model-value="(newValue) => (schoolCode = newValue)"
                        />
                        <TextInput
                            :label="t('student.schools.schoolName')"
                            class="school__name__col"
                            :placeholder="t('common.placeholder3')"
                            :model-value="schoolName"
                            :max-length="10"
                            :style-label="{ 'min-width': '135px' }"
                            :error="errors.schoolName"
                            @keyup.enter="handleSearchSchool"
                            @update:model-value="(newValue) => (schoolName = newValue)"
                        />
                        <TextInput
                            :label="t('student.schools.schoolAddress')"
                            class="school__address__col"
                            :model-value="schoolAddress"
                            :max-length="20"
                            :style-label="{ 'min-width': '135px' }"
                            @keyup.enter="handleSearchSchool"
                            @update:model-value="(newValue) => (schoolAddress = newValue)"
                        />
                    </div>
                    <div class="searching__form--actions">
                        <TheButton class="searching__actions--button" @click="handleSearchSchool">{{
                            t('student.schools.searchButton')
                        }}</TheButton>
                        <TheButton class="searching__actions--button" @click="handleClearData">{{
                            t('student.schools.cancelButton')
                        }}</TheButton>
                    </div>
                </div>
                <div class="school__table">
                    <TheTable class="school__table--content" :columns="columnsData" :data="schools" />
                    <!-- Empty -->
                    <div v-if="isError && errorCode === 'E0004'" class="table__item--center">
                        <img src="../../../assets/icons/empty_folder.svg" alt="empty folder icon" />
                        <p class="empty_list">{{ errorMessage }}</p>
                    </div>
                    <!-- Error -->
                    <div v-if="isError && errorCode !== 'E0004'" class="table__item--center">
                        <img src="../../../assets/icons/error-icon.svg" alt="error icon" />
                        <p>
                            {{ errorMessage }}
                        </p>
                    </div>
                    <!-- Loading -->
                    <div v-if="isLoading" class="table__item--center">
                        <div class="custom-loader"></div>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
    <v-dialog v-model="isShowPopupMaxSize" width="400">
        <v-card>
            <div class="card__container">
                <TheTitle class="modal__title">
                    <h3 class="header__title--content">{{ t('student.schools.dialogMaxCountTitle') }}</h3>
                </TheTitle>
                <div class="content__dialog">
                    <p>{{ t('student.schools.maxSchoolLengthMsg1') }}</p>
                    <p>{{ t('student.schools.maxSchoolLengthMsg2') }}</p>
                    <div class="button__action">
                        <TheButton class="button__action--cancel" @click="isShowPopupMaxSize = false">{{
                            t('student.schools.closeDialogBtn')
                        }}</TheButton>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { ref, h, inject, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import TheButton from '../../../components/button/TheButton.vue';
import TextInput from '../../../components/textInput/TextInput.vue';
import TheTable from '../../../components/table/TheTable.vue';
import TheRowActions from './TheRowActions.vue';
import TheTitle from '../../../components/title/TheTitle.vue';

import SchoolStaffService from '../../../services/SchoolStaffService';
import useFetch from '../../../utils/useFetch';
import { useUserStore } from '../../../stores/UserStore.js';

const props = defineProps({
    initValue: {
        type: Object,
    },
});
const emits = defineEmits(['selectSchool', 'close', 'open']);
// i18n
const { t } = useI18n();
const userStore = useUserStore();

// injects
const { handleHiddenErrorMessage } = inject('filterBar');

const schoolCode = ref(props.initValue?.schoolCode || '');
const schoolName = ref('');
const schoolAddress = ref('');
const errors = ref({
    schoolCode: '',
    schoolName: '',
});

const isShowModal = ref(false);
const schoolCodeRef = ref(null);
const schools = ref(null);
const isShowPopupMaxSize = ref(false);

const { isLoading, errorMessage, isError, errorCode, data, fetchData } = useFetch(SchoolStaffService.getStudentListSchoolSearch);

onMounted(() => {
    schoolCodeRef.value?.input?.focus();
});

watch(data, (newData) => {
    schools.value = newData?.result?.data?.schoolList;
    if (newData?.result?.data?.count > 100) {
        isShowPopupMaxSize.value = true;
    }
});

watch(
    () => props.initValue,
    (newInit) => {
        schoolCode.value = newInit?.schoolCode;
    },
);

const handleSearchSchool = () => {
    if (!(schoolCode.value || schoolName.value)) {
        errors.value = {
            schoolCode: t('student.schools.oneInTwoRequiredMessage'),
            schoolName: t('student.schools.oneInTwoRequiredMessage'),
        };
        return;
    }
    errors.value = {
        schoolCode: '',
        schoolName: '',
    };
    fetchData({
        userId: userStore?.userId,
        schoolCode: schoolCode.value,
        schoolName: schoolName.value,
        schoolAddress: schoolAddress.value,
    });
};

const handleSelectRow = (schoolCode) => {
    const school = schools.value?.find((school) => school?.schoolCode === schoolCode);
    emits('selectSchool', school);
    isShowModal.value = false;
};

const handleClearData = () => {
    schoolCode.value = '';
    schoolName.value = '';
    schoolAddress.value = '';
    errors.value = {
        schoolCode: '',
        schoolName: '',
    };
    schools.value = null;
};

const handleClose = () => {
    schoolCode.value = '';
    schoolName.value = '';
    schoolAddress.value = '';
    errors.value = {
        schoolCode: '',
        schoolName: '',
    };
    schools.value = null;
    isShowModal.value = false;
};

const TheRowActionsElement = (column, item) =>
    h(TheRowActions, {
        schoolCode: item['schoolCode'],
        onClick: (schoolCode) => handleSelectRow(schoolCode),
    });

const renderWidth = (width) => {
    return { 'min-width': `${width}px`, 'max-width': `${width}px` };
};

const columnsData = [
    {
        key: 'tableKey0',
        title: '',
        styles: renderWidth(50),
        render: (column, item) => TheRowActionsElement(column, item),
    },
    {
        key: 'schoolCode',
        title: t('student.schools.schoolCode'),
        styles: renderWidth(200),
    },
    {
        key: 'schoolName',
        title: t('student.schools.schoolName'),
        styles: renderWidth(300),
    },
    {
        key: 'schoolAddress',
        title: t('student.schools.schoolAddress'),
        styles: renderWidth(500),
    },
];
</script>
<style lang="css" src="./SearchSchoolModal.css" scoped></style>
