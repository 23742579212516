<template>
    <component :is="DialogCustom" :show="commonStores?.expired" :z-index="998">
        <div class="wrapper__notification__login">
            <div class="notification__title">{{ t('notice.title') }}</div>
            <div class="notification__content">
                <p>{{ t('notice.content1') }}</p>
                <p>{{ t('notice.content2') }}</p>
            </div>
            <button class="notification__button" @click="handleLogout">{{ t('confirm.content1') }}</button>
        </div>
    </component>
    <component :is="DialogCustom" :show="!userStore?.user" :z-index="999">
        <div class="wrapper__notification__login">
            <div class="notification__title">{{ t('error.title') }}</div>
            <div class="notification__content">
                <p>{{ t('error.content1') }}</p>
                <p>{{ t('error.content2') }}</p>
            </div>
            <button class="notification__button" @click="handleLogout">{{ t('confirm.content1') }}</button>
        </div>
    </component>
    <header>
        <div class="logo__icon">
            <img src="@/assets/icons/logo-cloud-app.svg" alt="logo-cloud-app-img" @click="() => router.push('/orders')" />
        </div>

        <div class="content__header">
            <div class="version">
                <p>{{ t('header.version') }}</p>
            </div>
            <div class="user__info">
                <p class="user">
                    {{ t('header.hello') }}<strong> {{ userStore?.userInfo?.userName }}</strong> さん
                </p>
                <p v-if="userStore?.userInfo?.affiliationKbn === 1" class="user"></p>
                <p v-else-if="userStore?.userInfo?.affiliationKbn === 2" class="user">{{ userStore?.userInfo?.schoolName }}</p>
                <p v-else class="user">{{ userStore?.userInfo?.shopName || '' }}</p>
            </div>
            <button class="button__logout" @click="handleLogout">
                <p>{{ t('header.signout') }}</p>
                <img src="@/assets/icons/log-out.svg" alt="log-out-img" />
            </button>
        </div>
    </header>
</template>

<script setup>
import { watch } from 'vue';
import { signOut } from '@aws-amplify/auth';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '../../stores/UserStore.js';
import { useSidebarStore } from '../../stores/SidebarStore.js';
import { useCommonStores } from '../../stores/CommonStores.js';
import { useOrderDetailStores } from '../../stores/OrderDetailStores';
import { useOrderStores } from '../../stores/OrderStores';
import { useFlyerRegistrationStores } from '../../stores/FlyerRegistrationStores';
import { useFlyerStores } from '../../stores/FlyerStores';
import { useRouter } from 'vue-router';
import useFetch from '../../utils/useFetch';
import UserService from '../../services/UserService';
import DialogCustom from '../../components/dialog/DialogCustom.vue';

// i18n
const { t } = useI18n();

// route
const router = useRouter();

const props = defineProps({
    userId: {
        type: String,
        required: true,
    },
});

// store
const userStore = useUserStore();
const sidebarStore = useSidebarStore();
const commonStores = useCommonStores();
const orderDetailStores = useOrderDetailStores();
const orderStores = useOrderStores();
const flyerRegistrationStores = useFlyerRegistrationStores();
const flyerStores = useFlyerStores();
// function
const handleLogout = async () => {
    await signOut();
    userStore?.reset();
    sidebarStore?.reset();
    userStore?.reset();
    commonStores?.reset();
    orderDetailStores.reset();
    orderStores.reset();
    flyerRegistrationStores?.reset();
    flyerStores?.reset();
    localStorage.removeItem('searchParams');
    router?.push('/login');
};

// fetch data
const { isError, data, fetchData } = useFetch(UserService.getUser);

watch(isError, (newValue) => {
    userStore?.setIsLogin(false);
    if (newValue) {
        userStore?.setUser(false);
        userStore?.setUserInfo({
            userName: 'UnAuthorized',
            affiliationKbn: 'Invalid',
            schoolCd: 'Invalid',
            schoolName: 'Invalid',
        });
    }
});

watch(data, (newValue) => {
    if (newValue) userStore?.setUserInfo(newValue?.result?.data);
    userStore?.setUser(true);
    userStore?.setIsLogin(true);
    // admin
    if (newValue?.result?.data?.affiliationKbn === 1) {
        commonStores?.setSidebarArray([
            // flyers
            {
                accept: true,
                sub: [
                    // flyer-registration
                    { accept: true },
                    // flyers
                    { accept: true },
                ],
            },
            // orders
            {
                accept: true,
                sub: [
                    // orders
                    { accept: true },
                ],
            },
            // school
            {
                accept: true,
                sub: [
                    // school staff
                    { accept: true },
                ],
            },
            // shops
            {
                accept: true,
                sub: [
                    // shop-registration
                    { accept: false },
                    // shop-list
                    { accept: true },
                ],
            },
            // staff
            {
                accept: true,
                sub: [
                    // shop staff list
                    { accept: true },
                ],
            },
            // notifications
            // {
            //     accept: true,
            //     sub: [
            //         // notification-registration
            //         { accept: true },
            //         // notifications
            //         { accept: true },
            //     ],
            // },
            // students
            {
                accept: true,
                sub: [
                    // students
                    { accept: true },
                ],
            },
        ]);
    }
    // teacher
    if (newValue?.result?.data?.affiliationKbn === 2) {
        commonStores?.setSidebarArray([
            // flyers
            {
                accept: true,
                sub: [
                    // flyer-registration
                    { accept: false },
                    // flyers
                    { accept: true },
                ],
            },
            // orders
            {
                accept: true,
                sub: [
                    // orders
                    { accept: true },
                ],
            },
            // school staff
            {
                accept: false,
                sub: [
                    // school staff
                    { accept: false },
                ],
            },
            // shops
            {
                accept: false,
                sub: [
                    // shop-registration
                    { accept: false },
                    // shop-list
                    { accept: false },
                ],
            },
            // shop-staff
            {
                accept: false,
                sub: [
                    // shop staff list
                    { accept: false },
                ],
            },
            // notifications
            // {
            //     accept: false,
            //     sub: [
            //         // notification-registration
            //         { accept: false },
            //         // notifications
            //         { accept: false },
            //     ],
            // },
            // students
            {
                accept: true,
                sub: [
                    // students
                    { accept: true },
                ],
            },
        ]);
    }
    // shop
    if (newValue?.result?.data?.affiliationKbn === 3) {
        commonStores?.setSidebarArray([
            // flyers
            {
                accept: true,
                sub: [
                    // flyer-registration
                    { accept: true },
                    // flyers
                    { accept: true },
                ],
            },
            // orders
            {
                accept: true,
                sub: [
                    // orders
                    { accept: true },
                ],
            },
            // school staff
            {
                accept: false,
                sub: [
                    // school staff
                    { accept: false },
                ],
            },
            // shops
            {
                accept: false,
                sub: [
                    // shop-registration
                    { accept: false },
                    // shop-list
                    { accept: false },
                ],
            },
            // shop-staff
            {
                accept: false,
                sub: [
                    // shop staff list
                    { accept: false },
                ],
            },
            // notifications
            // {
            //     accept: false,
            //     sub: [
            //         // notification-registration
            //         { accept: false },
            //         // notifications
            //         { accept: false },
            //     ],
            // },
            // students
            {
                accept: false,
                sub: [
                    // students
                    { accept: false },
                ],
            },
        ]);
    }
});

userStore?.setUserId(props?.userId);
fetchData(props?.userId);
</script>

<style lang="css" src="./BaseHeader.css" scoped></style>
