<template>
    <li class="wrapper__error-message__item">
        {{ props?.errorMessage }}
    </li>
</template>

<script setup>
const props = defineProps({
    errorMessage: {
        type: String,
        required: true,
    },
});
</script>

<style src="./ErrorMessageItem.css" scoped></style>
