<template>
    <div class="text-center">
        <v-pagination
            v-if="props?.total"
            v-model="page"
            class="my-4"
            :length="props?.total"
            :total-visible="5"
            size="32"
            show-first-last-page
            active-color="#1778CA"
            :first-icon="FirstIcon"
            :last-icon="LastIcon"
            v-bind="$attrs"
        ></v-pagination>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import FirstIcon from './FirstIcon.vue';
import LastIcon from './LastIcon.vue';

const props = defineProps({
    total: {
        type: Number,
        required: true,
    },
    currPage: {
        type: Number,
        required: false,
    },
});
const emits = defineEmits(['change']);
const page = ref(props?.currPage || 1);

watch(page, (newPage) => {
    emits('change', newPage);
});

watch(
    () => props?.currPage,
    () => {
        page.value = props?.currPage;
    },
);
</script>

<style lang="css" src="./ThePagination.css"></style>
