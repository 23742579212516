import { reactive, toRefs } from 'vue';
import { signOut } from '@aws-amplify/auth';

export default function useFetch(fn) {
    const state = reactive({
        isLoading: false,
        isError: false,
        errorMessage: '',
        errorCode: '',
        data: null,
    });

    const fetchData = async (params) => {
        try {
            state.isLoading = true;
            state.isError = false;
            state.errorMessage = '';
            state.errorCode = '';
            state.data = null;
            const response = await fn(params);
            if (response?.status === 9) {
                if (response?.result?.errorCode === 'E0003') {
                    signOut();
                } else {
                    state.isError = true;
                    state.errorMessage = response?.result?.message;
                    state.errorCode = response?.result?.errorCode;
                    state.data = null;
                }
            } else {
                state.data = response;
            }
        } catch (e) {
            state.isError = true;
            state.errorMessage = e?.message;
            state.errorCode = e?.errorCode;
            state.data = null;
        } finally {
            state.isLoading = false;
        }
    };

    return {
        ...toRefs(state),
        fetchData,
    };
}

export function useFetching(fn) {
    const state = reactive({
        isLoading: false,
        isError: false,
        errorMessage: '',
        errorCode: '',
        isSuccess: false,
        data: null,
    });

    const fetchData = async (...params) => {
        try {
            state.isLoading = true;
            state.isError = false;
            state.errorMessage = '';
            state.errorCode = '';
            state.data = null;
            state.isSuccess = false;
            const response = await fn(...params);
            if (response?.status === 9) {
                if (response?.result?.errorCode === 'E0003') {
                    signOut();
                } else {
                    state.isError = true;
                    state.errorMessage = response?.result?.message;
                    state.errorCode = response?.result?.errorCode;
                    state.data = null;
                }
            } else {
                state.data = response;
                state.isSuccess = true;
            }
        } catch (e) {
            state.isError = true;
            state.errorMessage = e?.message;
            state.errorCode = e?.errorCode;
            state.data = null;
            state.isSuccess = false;
        } finally {
            state.isLoading = false;
        }
    };

    return {
        ...toRefs(state),
        fetchData,
    };
}
