<template>
    <BaseDialog
        class="wrapper__dialog--delete"
        :title="t('schoolStaff.dialog.titleDelete')"
        :content="t('schoolStaff.dialog.contentDelete', { staffName: props?.staffName })"
    >
        <template #default>
            <Teleport to="body">
                <BaseLoading :loading="isLoading"></BaseLoading>
            </Teleport>
            <div class="action--list">
                <TheButton class="action--item" @click="handleCancel">{{ t('confirm.content2') }}</TheButton>
                <TheButton class="action--item" @click="handleDelete">{{ t('confirm.content3') }}</TheButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import BaseLoading from '../../../../components/loading/BaseLoading.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useSchoolStaffStores } from '../../../../stores/SchoolStaffStores.js';
import SchoolStaffService from '../../../../services/SchoolStaffService.js';
import useFetch from '../../../../utils/useFetch.js';
import { useUserStore } from '../../../../stores/UserStore';

// i18n
const { t } = useI18n();

// stores
const schoolStaffStores = useSchoolStaffStores();
const userStore = useUserStore();

// service
const { isError, errorMessage, data, fetchData, isLoading } = useFetch(SchoolStaffService?.deleteSchoolStaff);
// props
const props = defineProps({
    staffName: {
        type: String,
        required: false,
    },
    userId: {
        type: String,
        required: false,
    },
});

// function
const handleCancel = () => {
    schoolStaffStores?.setIsModalDelete(false);
};
const handleDelete = async () => {
    fetchData({
        userId: userStore?.userId,
        delUserId: props?.userId,
    });
};

// watch
watch(isError, (newValue) => {
    if (newValue) {
        schoolStaffStores?.setTitleNotice(t('schoolStaff.dialog.titleDelete'));
        schoolStaffStores?.setIsNotice(true);
        schoolStaffStores?.setNoticeError(errorMessage.value);
    }
});

watch(data, (newValue) => {
    if (newValue?.status === 0) {
        schoolStaffStores?.setTitleNotice(t('schoolStaff.dialog.titleDelete'));
        schoolStaffStores?.setIsNotice(true);
        schoolStaffStores?.setNoticeError(t('schoolStaff.dialog.contentSuccess2'));
        schoolStaffStores?.setIsModalDelete(false);
        schoolStaffStores?.setIsSearch();
    }
});
</script>

<style lang="css" src="./BaseDialogDelete.css" scoped></style>
