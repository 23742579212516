<template>
    <div v-if="props?.errorMessages.length > 0" class="wrapper__error-message__list">
        <ul class="error-message__list">
            <ErrorMessageItem
                v-for="(errorMessage, index) in props?.errorMessages"
                :key="index"
                :error-message="errorMessage"
            ></ErrorMessageItem>
        </ul>
    </div>
</template>

<script setup>
import ErrorMessageItem from './ErrorMessageItem.vue';

const props = defineProps({
    errorMessages: {
        type: Array,
        required: true,
    },
});
</script>

<style src="./ErrorMessageList.css" scoped></style>
