import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useCommonStores = defineStore('commonStores', () => {
    const showSidebar = ref(false);
    const showHeader = ref(false);
    const expired = ref(false);
    const isExpand = ref(true);
    const sidebarArray = ref([
        // flyers
        {
            accept: false,
            sub: [{ accept: false }, { accept: false }],
        },
        // orders
        {
            accept: false,
            sub: [{ accept: false }],
        },
        // school
        {
            accept: false,
            sub: [{ accept: false }],
        },
        // shops
        {
            accept: false,
            sub: [{ accept: false }, { accept: false }],
        },
        // staff
        {
            accept: false,
            sub: [{ accept: false }],
        },
        // // notification
        // {
        //     accept: false,
        //     sub: [{ accept: false }, { accept: false }],
        // },
        // students
        {
            accept: false,
            sub: [{ accept: false }],
        },
    ]);

    function setShowSidebar(value) {
        showSidebar.value = value;
    }
    function setShowHeader(value) {
        showHeader.value = value;
    }
    function setExpired(value) {
        expired.value = value;
    }
    function setIsExpand(value) {
        isExpand.value = value;
    }
    function setSidebarArray(value) {
        sidebarArray.value = value;
    }
    function reset() {
        setShowSidebar(true);
        setShowHeader(true);
        setExpired(false);
        setIsExpand(true);
        setSidebarArray([
            // flyers
            {
                accept: false,
                sub: [{ accept: false }, { accept: false }],
            },
            // orders
            {
                accept: false,
                sub: [{ accept: false }],
            },
            // school
            {
                accept: false,
                sub: [{ accept: false }],
            },
            // shops
            {
                accept: false,
                sub: [{ accept: false }, { accept: false }],
            },
            // staff
            {
                accept: false,
                sub: [{ accept: false }],
            },
            // // notification
            // {
            //     accept: false,
            //     sub: [{ accept: false }, { accept: false }],
            // },
            // students
            {
                accept: false,
                sub: [{ accept: false }],
            },
        ]);
    }
    return {
        showSidebar,
        setShowSidebar,
        showHeader,
        setShowHeader,
        expired,
        setExpired,
        isExpand,
        setIsExpand,
        sidebarArray,
        setSidebarArray,
        reset,
    };
});
