<template>
    <transition mode="out-in">
        <div
            class="wrapper__sidebar"
            :class="{
                expand: sidebarStore?.expand,
                collapse: !sidebarStore?.expand,
            }"
        >
            <div class="icon" :class="{ expand: sidebarStore?.expand }">
                <img
                    v-if="sidebarStore?.expand"
                    src="@/assets/icons/chevrons-left.svg"
                    class="icon__hidden"
                    alt="chevrons-left-icon"
                    @click="handleExpandCollapse(false)"
                />
                <img
                    v-else
                    src="@/assets/icons/chevrons-right.svg"
                    alt="chevrons-right-icon"
                    @click="handleExpandCollapse(true)"
                />
            </div>

            <div class="content__sidebar" :class="{ collapse: !sidebarStore?.expand }">
                <SidebarsList>
                    <SidebarItem
                        v-for="(item, index) in iconsSidebar"
                        :key="index"
                        :accept="commonStores?.sidebarArray[index]?.accept"
                        :sub-accept="commonStores?.sidebarArray[index]?.sub"
                        :title="item?.title"
                        :icon-url="item?.iconUrl"
                        :key-active="index"
                        :sub-items-sidebar="item?.subItemsSidebar"
                    >
                    </SidebarItem>
                </SidebarsList>
            </div>

            <div v-if="sidebarStore?.expand" class="copy__right">
                <p>{{ t('common.license') }}</p>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import SidebarsList from './SidebarsList.vue';
import SidebarItem from './SidebarItem.vue';
import { iconsSidebar } from '../../assets/icons/IconsSidebar.js';
import { useSidebarStore } from '../../stores/SidebarStore.js';
import { useCommonStores } from '../../stores/CommonStores.js';

// i18n
const { t } = useI18n();

// store
const sidebarStore = useSidebarStore();
const commonStores = useCommonStores();

// function
const handleExpandCollapse = (value) => {
    sidebarStore?.setExpand(value);
    sidebarStore?.setKeyActive(null);
};
</script>

<style lang="css" src="./BaseSidebar.css" scoped></style>
