import 'normalize.css';
import '@mdi/font/css/materialdesignicons.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { fetchAuthSession, signOut } from '@aws-amplify/auth';

import { vuetifyConfig } from './plugins/Vuetify.js';
import router from './Router.js';
import { i18n } from './plugins/I18n.js';
import { useCommonStores } from './stores/CommonStores.js';
import App from './App.vue';

const app = createApp(App);

// language
app.use(i18n);

// store
app.use(createPinia());
const commonStores = useCommonStores();

// component libaries and CSS
app.use(vuetifyConfig);

// router
app.use(router);

router.beforeEach(async (to, from, next) => {
    const auth = await fetchAuthSession();

    if (to.fullPath === '/login') {
        commonStores.setShowHeader(false);
        commonStores.setShowSidebar(false);
        if (auth?.tokens?.idToken) {
            next({ path: from.fullPath });
            return;
        }
        next();
        return;
    }

    if (to.fullPath !== '/login') {
        commonStores.setShowHeader(true);
        commonStores.setShowSidebar(true);
        if (!auth?.tokens?.idToken) {
            await signOut();
            next({ path: '/login' });
            return;
        }
        next();
    }
});

app.mount('#app');
