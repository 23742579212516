import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useShopStaffStores = defineStore('shopStaffStores', () => {
    const shopParams = ref({});
    const staffParams = ref({});
    const isSearchShop = ref(true);
    const isSearchStaff = ref(true);
    const isModalDelete = ref(false);
    const isModalCreate = ref(false);
    const shopName = ref('');
    const shopAddress = ref('');
    const shopCode = ref('');
    const titleNotice = ref('');
    const isNotice = ref(false);
    const noticeError = ref('');

    function setShopParams(value) {
        shopParams.value = value;
    }
    function setStaffParams(value) {
        staffParams.value = value;
    }
    function setIsSearchShop() {
        isSearchShop.value = !isSearchShop.value;
    }
    function setIsSearchStaff() {
        isSearchStaff.value = !isSearchStaff.value;
    }
    function setIsModalDelete(value) {
        isModalDelete.value = value;
    }
    function setIsModalCreate(value) {
        isModalCreate.value = value;
    }
    function setShopName(value) {
        shopName.value = value;
    }
    function setShopAddress(value) {
        shopAddress.value = value;
    }
    function setShopCode(value) {
        shopCode.value = value;
    }
    function setTitleNotice(value) {
        titleNotice.value = value;
    }
    function setIsNotice(value) {
        isNotice.value = value;
    }
    function setNoticeError(value) {
        noticeError.value = value;
    }
    function reset() {
        setShopParams({});
        setStaffParams({});
        setIsSearchShop();
        setIsSearchStaff();
        setIsModalDelete(false);
        setIsModalCreate(false);
        setShopName('');
        setShopAddress('');
        setShopCode('');
        setTitleNotice('');
        setIsNotice(false);
        setNoticeError('');
    }

    return {
        shopParams,
        setShopParams,
        staffParams,
        setStaffParams,
        isSearchShop,
        setIsSearchShop,
        isSearchStaff,
        setIsSearchStaff,
        isModalCreate,
        setIsModalCreate,
        isModalDelete,
        setIsModalDelete,
        shopName,
        setShopName,
        shopAddress,
        setShopAddress,
        shopCode,
        setShopCode,
        titleNotice,
        setTitleNotice,
        isNotice,
        setIsNotice,
        noticeError,
        setNoticeError,
        reset,
    };
});
