<template>
    <div class="wrapper__search__flyer">
        <div class="search__flyer--title">
            <TheTitle class="title">
                <h1>{{ t('flyers.titleSearch') }}</h1>
            </TheTitle>
            <TheButton class="search__flyer--button" :disabled="false" @click="handleSearch">
                <template #left-icon>
                    <img :src="iconSearch" alt="search-icon" />
                </template>
                <p>{{ t('flyers.labelSearchName') }}</p>
            </TheButton>
        </div>
        <div class="search__flyers--list">
            <div class="search__flyer--item">
                <label class="item__label">{{ t('flyers.labelFlyerName') }}</label>
                <div class="item__selection">
                    <input id="focus--item" v-model="flyerName" :placeholder="t('flyers.placeholder1')" class="item__input" />
                </div>
            </div>
            <div class="search__flyer--item">
                <div class="item__label">{{ t('flyers.labelSchoolName') }}</div>
                <div class="item__selection">
                    <TheSelect
                        :selection="schoolCode"
                        :disabled="userStore?.userInfo?.affiliationKbn === 2"
                        :options="flyerStores?.schoolNames?.map((item) => item)"
                        data-type="string"
                        :array-value="flyerStores?.schoolCodes?.map((item) => item)"
                        @input="
                            (newValue) => {
                                schoolName = newValue;
                                schoolCode = newValue;
                            }
                        "
                    ></TheSelect>
                </div>
            </div>
            <div class="search__flyer--item">
                <div class="item__label">{{ t('flyers.labelClassName') }}</div>
                <div class="item__selection">
                    <TheSelect
                        :selection="schoolYear"
                        :options="[
                            all,
                            ...flyerStores?.schoolYears?.map((item) => {
                                if (item == '0') {
                                    return t('common.newStudent');
                                }
                                return item;
                            }),
                        ]"
                        data-type="string"
                        :array-value="[all, ...flyerStores?.schoolYears?.map((item) => item)]"
                        @input="
                            (newValue) => {
                                schoolYear = newValue;
                            }
                        "
                    ></TheSelect>
                </div>
            </div>
            <div class="search__flyer--item">
                <div class="item__label">{{ t('flyers.labelStartDate') }}</div>
                <div class="item__selection">
                    <DatePicker
                        :date="startDate"
                        @update-date="
                            (newValue) => {
                                startDate = newValue;
                            }
                        "
                    >
                    </DatePicker>
                </div>
            </div>
            <div class="search__flyer--item">
                <div class="item__label">{{ t('flyers.labelEndDate') }}</div>
                <div class="item__selection">
                    <DatePicker
                        :date="endDate"
                        @update-date="
                            (newValue) => {
                                endDate = newValue;
                            }
                        "
                    >
                    </DatePicker>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import TheSelect from '../../../../components/select/TheSelect.vue';
import DatePicker from '../../../../components/datePicker/DatePicker.vue';
import FlyersService from '../../../../services/FlyersService';
import { useFlyerStores } from '../../../../stores/FlyerStores';
import { useUserStore } from '../../../../stores/UserStore';
import useFetch from '../../../../utils/useFetch';
import iconSearch from '../../../../assets/icons/search.svg';

// i18n
const { t } = useI18n();

// store
const flyerStores = useFlyerStores();
const userStore = useUserStore();

// ref
const all = t('common.all');
const handleData = ref([]);
const flyerName = ref('');
const schoolName = ref('');
const schoolCode = ref('');
const schoolYear = ref('');
const startDate = ref('');
const endDate = ref('');
const localStoredParams = ref('');

const { isError, data, fetchData } = useFetch(FlyersService.getFlyerSearchList);

onMounted(() => {
    document.querySelector('#focus--item')?.focus();
    getLocalStoredValue();
});

watch(isError, (newValue) => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        flyerStores?.schoolCodes?.push(all);
        flyerStores?.schoolNames?.push(all);
        schoolName.value = all;
        schoolCode.value = all;
    } else {
        flyerStores?.schoolCodes?.push(userStore?.userInfo?.schoolCd);
        flyerStores?.schoolNames?.push(userStore?.userInfo?.schoolName);
        schoolName.value = userStore?.userInfo?.schoolName;
        schoolCode.value = userStore?.userInfo?.schoolCd;
    }

    flyerStores?.schoolYears?.push(all);
    schoolYear.value = all;
});

watch(data, (newValue) => {
    handleData.value = [];

    // reset before get data
    flyerStores?.setSchoolCodes([]);
    flyerStores?.setSchoolNames([]);
    flyerStores?.setSchoolYears([]);

    // push value default
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        flyerStores?.schoolCodes?.push(all);
        flyerStores?.schoolNames?.push(all);
    }
    // flyerStores?.schoolYears?.push(all);

    // push data which gotted
    newValue?.result?.data?.schoolList?.map((item) => {
        if (item?.schoolYear) {
            flyerStores?.schoolYears?.push(item?.schoolYear);
        }
        // handle data
        handleData?.value.push(item?.schoolCode + '+' + item?.schoolName);
    });

    // handle same item
    flyerStores?.setSchoolYears([...new Set(flyerStores?.schoolYears)]);
    handleData.value = [...new Set(handleData.value)];

    // assign value
    handleData.value?.map((item) => {
        flyerStores?.schoolCodes?.push(item.slice(0, item.indexOf('+')));
        flyerStores?.schoolNames?.push(item.slice(item.indexOf('+') + 1));
    });

    flyerStores?.setSchoolYears(
        flyerStores?.schoolYears?.sort((a, b) => {
            const numA = isNaN(a) ? Infinity : Number(a);
            const numB = isNaN(b) ? Infinity : Number(b);
            return numA - numB;
        }),
    );
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        schoolName.value = all;
        schoolCode.value = all;
    } else {
        schoolName.value = userStore?.userInfo?.schoolName;
        schoolCode.value = userStore?.userInfo?.schoolCd;
    }
    schoolYear.value = all;

    if (localStoredParams.value) {
        if (userStore?.userInfo?.affiliationKbn !== 2) {
            schoolCode.value = localStoredParams.value?.schoolCode ? localStoredParams.value?.schoolCode : all;
        }
        flyerName.value = localStoredParams.value?.flyerName;
        schoolYear.value = localStoredParams.value?.schoolYear ? localStoredParams.value?.schoolYear : all;
        startDate.value = localStoredParams.value?.flyerStartDate?.replace(/\//g, '-');
        endDate.value = localStoredParams.value?.flyerEndDate?.replace(/\//g, '-');
    }

    handleSearch();
});

// function
const handleSearch = () => {
    const searchParams = {
        flyerName: flyerName?.value,
        schoolCode: schoolCode?.value === all ? '' : schoolCode?.value,
        schoolYear: schoolYear?.value === all ? '' : schoolYear?.value,
        flyerStartDate: startDate?.value ? startDate?.value?.replace(/-/g, '/') : '',
        flyerEndDate: endDate?.value ? endDate?.value?.replace(/-/g, '/') : '',
    };
    flyerStores?.setParams(searchParams);
    localStorage.setItem('searchParams', JSON.stringify(searchParams));
    flyerStores?.setIsSearch();
};

// function get value from localstore
const getLocalStoredValue = () => {
    const value = localStorage.getItem('searchParams');
    if (value) {
        localStoredParams.value = JSON.parse(value);
    }
};

// NOTE
fetchData({ userId: userStore?.userId });
</script>

<style lang="css" src="./BaseSearchFlyer.css" scoped></style>
