<template>
    <BaseDialog title="エラー" :content="orderDetailStores?.errorContent">
        <template #default>
            <div class="button__action">
                <TheButton class="button__confirm" @click="handleCloseModal">{{ t('confirm.content1') }}</TheButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import BaseDialog from './BaseDialog.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useOrderDetailStores } from '../../../../stores/OrderDetailStores.js';
import { useOrderStores } from '../../../../stores/OrderStores.js';

// i18n
const { t } = useI18n();

// stores
const orderDetailStores = useOrderDetailStores();
const orderStores = useOrderStores();
// function
const handleCloseModal = () => {
    orderDetailStores.setModalResultDelete(false);
    orderStores?.setOrderDetail(false);
    orderStores?.setOrderCode('');
};
</script>

<style lang="css" src="./DialogResultDelete.css" scoped></style>
