<template>
    <div>
        <div class="input__wapper">
            <label class="label" :class="{ error__lable: props.error }" :style="props.styleLabel">
                {{ props?.label }}
                <span v-if="props.isRequired" class="input__required">(*)</span>
            </label>
            <input
                ref="input"
                type="text"
                class="input"
                :class="{ error__input: props.error }"
                :value="props?.modelValue"
                :maxlength="maxLength"
                :placeholder="props.placeholder"
                @input="emits('update:modelValue', $event?.target?.value)"
                @blur="emits('blur')"
            />
            <div class="unit">{{ props?.unit }}</div>
        </div>
        <p v-if="props.error" class="error__message">{{ props.error }}</p>
    </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    label: {
        type: String,
        require: false,
    },
    unit: {
        type: String,
        require: false,
    },
    modelValue: {
        type: String,
        require: false,
    },
    maxLength: {
        type: Number,
        require: false,
    },
    styleLabel: {
        type: Object,
        require: false,
    },
    isRequired: {
        type: Boolean,
        require: false,
    },
    error: {
        type: String,
        require: false,
    },
    placeholder: {
        type: String,
        require: false,
    },
});
const emits = defineEmits(['update:modelValue', 'blur']);
const input = ref(null);

defineExpose({ input });
</script>

<style lang="css" src="./TextInput.css" scoped></style>
