<template>
    <div class="wrapper__students">
        <div class="container">
            <div class="sidebar__behind" :class="{ expand: sidebarStore?.expand, collapse: !sidebarStore?.expand }"></div>
            <div class="content">
                <div class="top__content">
                    <NotificationList />
                </div>
                <div class="table__content">
                    <StudentList class="table__students" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import NotificationList from './components/NotificationList.vue';
import StudentList from './components/StudentList.vue';
import { useSidebarStore } from '../../stores/SidebarStore.js';
import { useUserStore } from '../../stores/UserStore.js';

// stores
const sidebarStore = useSidebarStore();
const userStore = useUserStore();

// router
const router = useRouter();

// mounted
onMounted(() => {
    if (userStore?.userInfo?.affiliationKbn === 3) {
        router.push('/orders');
    }
});

// watch
watch(
    () => userStore?.userInfo?.affiliationKbn,
    (newValue, oldValue) => {
        if (newValue === 3 || oldValue === 3) {
            router.push('/orders');
        }
    },
);
</script>

<style lang="css" src="./StudentsPage.css" scoped></style>
