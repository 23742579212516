import { http } from '../utils/Axios';

class ShopStaffService {
    static async getShopStaffList(params) {
        try {
            const response = await http.get('/getShopStaffList', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async registerShopStaff(body) {
        try {
            const response = await http.post('/registerShopStaff', body);
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async deleteShopStaff(body) {
        try {
            const response = await http.delete('/deleteShopStaff', { data: body });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }
}

export default ShopStaffService;
