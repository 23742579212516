import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useOrderDetailStores = defineStore('orderDetail', () => {
    const orderDetailInfo = ref({});
    const orderDetailItemList = ref([]);
    const modalDeleteOrder = ref(false);
    const modalResultDelete = ref(false);
    const refreshData = ref(true);
    const errorContent = ref('');

    function setOrderDetailInfo(value) {
        orderDetailInfo.value = value;
    }
    function setOrderDetailItemList(value) {
        orderDetailItemList.value = value;
    }
    function setModalDeleteOrder(value) {
        modalDeleteOrder.value = value;
    }
    function setModalResultDelete(value) {
        modalResultDelete.value = value;
    }
    function setRefreshData() {
        refreshData.value = !refreshData.value;
    }
    function setErrorContent(value) {
        errorContent.value = value;
    }
    function reset() {
        setOrderDetailInfo({});
        setOrderDetailItemList([]);
        setModalDeleteOrder(false);
        setModalResultDelete(false);
        setErrorContent('');
        setRefreshData();
    }

    return {
        orderDetailInfo,
        orderDetailItemList,
        setOrderDetailInfo,
        setOrderDetailItemList,
        modalDeleteOrder,
        setModalDeleteOrder,
        modalResultDelete,
        setModalResultDelete,
        refreshData,
        setRefreshData,
        errorContent,
        setErrorContent,
        reset,
    };
});
