<template>
    <input type="checkbox" :checked="props?.checked" @change="changeValue" />
    <label v-if="props?.textLabel">{{ props?.textLabel }}</label>
</template>
<script setup>
const emits = defineEmits(['checked']);
const props = defineProps({
    checked: {
        type: Boolean,
        required: false,
        default: false,
    },
    textLabel: {
        type: String,
        required: false,
    },
});

const changeValue = (event) => {
    emits('checked', event?.target?.checked);
};
</script>
<style src="./TheCheckbox.css" scoped></style>
