<template>
    <div>
        <p class="id__string">
            {{ props.content }}<v-tooltip activator="parent" location="bottom">{{ props.content }}</v-tooltip>
        </p>
    </div>
</template>
<script setup>
const props = defineProps({
    content: String,
});
</script>
<style lang="css" src="./TheToolTip.css" scoped></style>
