<template>
    <BaseDialog
        :title="t('productRegistration.dialog.titleDialog3')"
        :content="`${t('productRegistration.dialog.contentDialog3_1')}\n${t('productRegistration.dialog.contentDialog3_2')}`"
    >
        <template #default>
            <div class="button__action">
                <TheButton class="button__action--cancel" @click="handleCloseModal">{{ t('confirm.content2') }}</TheButton>
                <TheButton class="button__action--confirm" @click="handleBackToList">{{ t('confirm.content3') }}</TheButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useProductRegistrationStores } from '../../../../stores/ProductRegistrationStores.js';

// i18n
const { t } = useI18n();

// stores
const productRegistrationStores = useProductRegistrationStores();

// router
const router = useRouter();
const route = useRoute();

// function
const handleCloseModal = () => {
    productRegistrationStores?.setIsNotice(0);
};
const handleBackToList = () => {
    productRegistrationStores?.setIsNotice(0);
    productRegistrationStores?.detailImageUrls?.forEach((item) => {
        URL.revokeObjectURL(item);
    });
    URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
    router.push(
        // eslint-disable-next-line max-len
        `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
            route.params?.shopCode,
        )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(route.params?.schoolCode)}/${encodeURIComponent(
            route.params?.schoolYear,
        )}/update/products/`,
    );
};
</script>

<style lang="css" src="./BaseDialogNavigate.css" scoped></style>
