<template>
    <div class="wrapper__shop--item">
        <p class="infor__name">
            {{ props?.shopName + ' ' + (props?.address || '') }}
        </p>
    </div>
</template>
<script setup>
// props
const props = defineProps({
    shopName: {
        type: String,
        required: false,
    },
    address: {
        type: String,
        required: false,
    },
});
</script>

<style lang="css" src="./ShopItem.css" scoped></style>
