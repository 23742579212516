<template>
    <div class="wrapper__upload-file">
        <div class="container">
            <div class="content__upload-file">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!--TODO - β1,β2は、何も表示しない、もしくは固定メッセージ-->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <!-- <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" /> -->
                </div>
                <div class="section2">
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseContent></BaseContent
                    ></ThePaper>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import BaseContent from './component/content/BaseContent.vue';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
// import BaseBanner from '../../components/banner/BaseBanner.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import { useCommonStores } from '../../stores/CommonStores';

// stores
const commonStores = useCommonStores();

// unmounted
// onUnmounted(() => {
//     uploadStores?.reset();
// });
</script>

<style lang="css" src="./UploadPage.css" scoped></style>
