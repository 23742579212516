<template>
    <div class="flyer__info--container">
        <TagInfo v-for="flyerInfo in flyerInfoArr" :key="flyerInfo?.key" class="tag__info--productlist" :label="flyerInfo?.label">
            {{ transferedFlyerInfo[flyerInfo?.key] }}
            <v-tooltip activator="parent" location="bottom">{{ transferedFlyerInfo[flyerInfo?.key] }}</v-tooltip>
        </TagInfo>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import TagInfo from '../../../../components/tagInfo/TagInfo.vue';

const props = defineProps({
    flyer: Object,
});
const transferedFlyerInfo = computed(() => {
    return {
        ...props?.flyer,
        flyerStartDate: props?.flyer?.flyerStartDate
            ? `${props?.flyer?.flyerStartDate.replace('-', '年').replace('-', '月')}日`
            : null,
        flyerEndDate: props?.flyer?.flyerEndDate ? `${props?.flyer?.flyerEndDate.replace('-', '年').replace('-', '月')}日` : null,
        schoolClass: props?.flyer?.schoolClass || t('common.all'),
    };
});
// i18n
const { t } = useI18n();

const flyerInfoArr = [
    {
        label: t('products.flyerInfo.eventName'),
        key: 'eventName',
    },
    {
        label: t('products.flyerInfo.flyerName'),
        key: 'flyerName',
    },
    {
        label: t('products.flyerInfo.orderStartDate'),
        key: 'flyerStartDate',
    },
    {
        label: t('products.flyerInfo.orderEndDate'),
        key: 'flyerEndDate',
    },
    {
        label: t('products.flyerInfo.schoolName'),
        key: 'schoolName',
    },
    {
        label: t('products.flyerInfo.schoolYear'),
        key: 'schoolYear',
    },
    {
        label: t('products.flyerInfo.schoolClass'),
        key: 'schoolClass',
    },
];
</script>

<style lang="css" src="./FlyerInfo.css" scoped></style>
