<template>
    <div class="table__wrapper">
        <table class="table">
            <thead>
                <TableHeader :columns="props?.columns" />
            </thead>
            <tbody v-if="props?.data && props?.data?.length !== 0">
                <TableRow
                    :data="props?.data"
                    :columns="props?.columns"
                    :unique-key="props?.uniqueKey"
                    @change="handleChangeRow"
                />
            </tbody>
        </table>
    </div>
</template>

<script setup>
import TableHeader from './TableHeader.vue';
import TableRow from './TableRow.vue';

const props = defineProps({
    columns: Array,
    data: Array,
    uniqueKey: String,
});

const emits = defineEmits(['change']);

const handleChangeRow = (key) => {
    emits('change', key);
};
</script>

<style lang="css" src="./TheTable.css" scoped></style>
