import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useFlyerRegistrationStores = defineStore('flyerRegistrationStores', () => {
    const isModalNavigate = ref(false);
    const isModalPreview = ref(false);
    const isModalPreviewImage = ref(false);
    const isModalDelete = ref(false);
    const urlMainImagePreview = ref('');
    const urlSubImagePreview = ref('');
    const activeSelect = ref({
        eventName: false,
        flyerName: false,
        shopCode: false,
        schoolName: false,
        schoolYear: false,
        className: false,
        startDate: false,
        endDate: false,
        displayStartDate: false,
        displayEndDate: false,
        mainImage: false,
        subImage: false,
        messageNotification: false,
        notificationRemindDate1: false,
        notificationRemindDate2: false,
        notificationRemindDate3: false,
        flyerSupplement: false,
    });
    const message = ref('');
    const titleDialog = ref('');
    const contentDialog = ref('');
    /**
     * 1 : register
     * 2 : update
     * 3 : register from a flyer
     */
    const registrationStates = ref(1);
    const isModalShopList = ref(false);

    function setIsModalNavigate(value) {
        isModalNavigate.value = value;
    }
    function setIsModalPreview(value) {
        isModalPreview.value = value;
    }
    function setIsModalPreviewImage(value) {
        isModalPreviewImage.value = value;
    }
    function setIsModalDelete(value) {
        isModalDelete.value = value;
    }
    function setUrlMainImagePreview(value) {
        urlMainImagePreview.value = value;
    }
    function setUrlSubImagePreview(value) {
        urlSubImagePreview.value = value;
    }
    function setActiveSelect(value) {
        activeSelect.value.eventName = value?.eventName;
        activeSelect.value.flyerName = value?.flyerName;
        activeSelect.value.shopCode = value?.shopCode;
        activeSelect.value.schoolName = value?.schoolName;
        activeSelect.value.schoolYear = value?.schoolYear;
        activeSelect.value.className = value?.className;
        activeSelect.value.startDate = value?.startDate;
        activeSelect.value.endDate = value?.endDate;
        activeSelect.value.displayStartDate = value?.displayStartDate;
        activeSelect.value.displayEndDate = value?.displayEndDate;
        activeSelect.value.messageNotification = value?.messageNotification;
        activeSelect.value.mainImage = value?.mainImage;
        activeSelect.value.subImage = value?.subImage;
        activeSelect.value.notificationRemindDate1 = value?.notificationRemindDate1;
        activeSelect.value.notificationRemindDate2 = value?.notificationRemindDate2;
        activeSelect.value.notificationRemindDate3 = value?.notificationRemindDate3;
        activeSelect.value.flyerSupplement = value?.flyerSupplement;
    }
    function setMessage(value) {
        message.value = value;
    }
    function setRegistrationStates(value) {
        registrationStates.value = value;
    }
    function setTitleDialog(value) {
        titleDialog.value = value;
    }
    function setContentDialog(value) {
        contentDialog.value = value;
    }
    function setIsModalShopList(value) {
        isModalShopList.value = value;
    }
    function reset() {
        setIsModalNavigate(false);
        setIsModalPreview(false);
        setIsModalPreviewImage(false);
        setIsModalDelete(false);
        setUrlMainImagePreview('');
        setUrlSubImagePreview('');
        setActiveSelect({
            eventName: false,
            flyerName: false,
            shopCode: false,
            schoolName: false,
            schoolYear: false,
            className: false,
            startDate: false,
            endDate: false,
            displayStartDate: false,
            displayEndDate: false,
            mainImage: false,
            subImage: false,
            messageNotification: false,
            notificationRemindDate1: false,
            notificationRemindDate2: false,
            notificationRemindDate3: false,
            flyerSupplement: false,
        });
        setMessage('');
        setRegistrationStates(1);
        setTitleDialog('');
        setContentDialog('');
        setIsModalShopList(false);
    }
    return {
        isModalNavigate,
        setIsModalNavigate,
        isModalPreview,
        setIsModalPreview,
        isModalPreviewImage,
        setIsModalPreviewImage,
        isModalDelete,
        setIsModalDelete,
        urlMainImagePreview,
        setUrlMainImagePreview,
        urlSubImagePreview,
        setUrlSubImagePreview,
        activeSelect,
        setActiveSelect,
        message,
        setMessage,
        registrationStates,
        setRegistrationStates,
        titleDialog,
        setTitleDialog,
        contentDialog,
        setContentDialog,
        isModalShopList,
        setIsModalShopList,
        reset,
    };
});
