<template>
    <div class="wrapper__form--item">
        <label class="form__item--label">
            {{ props?.label }}
            <strong v-if="props?.required">{{ t('common.required') }}</strong>
        </label>
        <div class="form__item--group">
            <slot name="default"></slot>
            <p v-if="props?.error" class="error__message">
                {{ props?.errorMessage }}
            </p>
        </div>
    </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
// i18n
const { t } = useI18n();

// props
const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
    },
    error: {
        type: Boolean,
        required: false,
    },
    errorMessage: {
        type: String,
        required: false,
    },
});
</script>

<style lang="css" src="./FormInput.css" scoped></style>
