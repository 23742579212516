import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useFlyerStores = defineStore('flyerStores', () => {
    const schoolCodes = ref([]);
    const schoolNames = ref([]);
    const schoolYears = ref([]);
    const params = ref({});
    const isSearch = ref(true);
    function setSchoolCodes(data) {
        schoolCodes.value = [...data];
    }
    function setSchoolNames(data) {
        schoolNames.value = [...data];
    }
    function setSchoolYears(data) {
        schoolYears.value = [...data];
    }
    function setParams(value) {
        params.value = value;
    }
    function setIsSearch() {
        isSearch.value = !isSearch.value;
    }
    function reset() {
        setSchoolCodes([]);
        setSchoolNames([]);
        setSchoolYears([]);
        setParams({});
        setIsSearch();
    }

    return {
        schoolCodes,
        setSchoolCodes,
        schoolNames,
        setSchoolNames,
        schoolYears,
        setSchoolYears,
        params,
        setParams,
        isSearch,
        setIsSearch,
        reset,
    };
});
