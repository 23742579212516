import { http } from '../utils/Axios';

class ProductRegistrationService {
    static async registerProductInfor(body) {
        try {
            const response = await http.post('/registerProductInfor', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async deleteFlyerProductInfor(body) {
        try {
            const response = await http.delete('/deleteFlyerProductInfor', { data: body });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async updateProductDisplay(body) {
        try {
            const response = await http.put('/updateProductDisplay', body);
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async updateFlyerProduct(body) {
        try {
            const response = await http.put('/updateFlyerProduct', body);
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getProductDetail(params) {
        try {
            const response = await http.get('/getProductDetail', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getProductCategoryList() {
        try {
            const response = await http.get('/getProductCategoryList');
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }
}

export default ProductRegistrationService;
