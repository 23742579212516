import { http } from '../utils/Axios';
import { networkErrorMsg } from '../utils/Consts';

class CommonService {
    static async getBanner() {
        try {
            const response = await http.get('/banners');
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }
}

export default CommonService;
