<template>
    <div class="wrapper__order__detail">
        <component :is="DialogCustom" :show="orderDetailStores?.modalDeleteOrder">
            <DialogDelete v-if="orderDetailStores?.modalDeleteOrder"></DialogDelete>
        </component>
        <component :is="DialogCustom" :show="orderDetailStores?.modalResultDelete">
            <DialogResultDelete></DialogResultDelete>
        </component>
        <header class="order__detail--header">
            <h1>{{ t('orderDetail.titleHeader') }}</h1>
            <img src="../../assets/icons/icon-close.svg" alt="icon-close" @click="handleCloseDialog" />
        </header>
        <div v-if="!isLoading" class="order__detail--content">
            <!--注文サマリー-->
            <BaseInformationOrder></BaseInformationOrder>
            <!--注文者情報-->
            <BaseInformationUser></BaseInformationUser>
            <!--注文商品情報-->
            <BaseDetailTable></BaseDetailTable>
        </div>
        <div v-else class="order_loading">
            <div class="custom-loader"></div>
        </div>
    </div>
</template>

<script setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import DialogCustom from '../../components/dialog/DialogCustom.vue';
import DialogDelete from './components/dialog/DialogDelete.vue';
import DialogResultDelete from './components/dialog/DialogResultDelete.vue';
import BaseInformationOrder from './components/informationOrder/BaseInformationOrder.vue';
import BaseInformationUser from './components/informationUser/BaseInformationUser.vue';
import BaseDetailTable from './components/table/BaseDetailTable.vue';
import { useOrderStores } from '../../stores/OrderStores.js';
import { useOrderDetailStores } from '../../stores/OrderDetailStores.js';
import OrderDetailService from '../../services/OrderDetailService.js';
import useFetch from '../../utils/useFetch.js';

// i18n
const { t } = useI18n();

// store
const orderStores = useOrderStores();
const orderDetailStores = useOrderDetailStores();

// function
const handleCloseDialog = () => {
    orderStores?.setOrderCode('');
    orderStores?.setOrderDetail(false);
};

const { data, isLoading, fetchData } = useFetch(OrderDetailService?.getOrderDetailes);

// fetch and format data
watch(data, (newValue) => {
    orderDetailStores?.setOrderDetailInfo({
        ...newValue?.result?.data?.orderDetailInfo,
        orderDate: newValue?.result?.data?.orderDetailInfo?.orderDate,
        delvDate: newValue?.result?.data?.orderDetailInfo?.delvDate,
        puchOdrDate: newValue?.result?.data?.orderDetailInfo?.puchOdrDate,
        shipDate: newValue?.result?.data?.orderDetailInfo?.shipDate,
        cancelDate: newValue?.result?.data?.orderDetailInfo?.cancelDate,
        userCancelDate: newValue?.result?.data?.orderDetailInfo?.userCancelDate,
        delvFee: newValue?.result?.data?.orderDetailInfo?.delvFee
            ? `${String(newValue?.result?.data?.orderDetailInfo?.delvFee).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円`
            : '0円',
        payAmount: newValue?.result?.data?.orderDetailInfo?.payAmount
            ? `${String(newValue?.result?.data?.orderDetailInfo?.payAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円`
            : '0円',
    });
    orderDetailStores?.setOrderDetailItemList(
        newValue?.result?.data?.orderDetailItemList?.map((item) => {
            return {
                ...item,
                unitPrice: item?.unitPrice ? `${String(item?.unitPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円` : '0円',
                subTotal: item?.subTotal ? `${String(item?.subTotal).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円` : '0円',
                orderQuantity: item?.orderQuantity ? `${String(item?.orderQuantity).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '',
            };
        }),
    );
});

watch(
    () => orderStores?.orderCode,
    async (newValue) => {
        orderDetailStores?.reset();
        if (newValue !== '') {
            await fetchData({ orderCode: newValue });
        }
    },
);
</script>

<style lang="css" src="./OrderDetailPage.css" scoped></style>
