<template>
    <div class="wrapper__product-registration__content">
        <HeaderProductRegistration> </HeaderProductRegistration>
        <div class="content__list">
            <FormList class="form__list"> </FormList>
            <div class="item__device">
                <!-- <img src="../../../../assets/img/phone-template-1.png" alt="thumb-nail" /> -->
                <PhoneDevice class="phone__device--product"> </PhoneDevice>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import HeaderProductRegistration from '../header/HeaderProductRegistration.vue';
import FormList from '../formList/FormList.vue';
import PhoneDevice from '../phone/PhoneDevice.vue';

// i18n
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { t } = useI18n();
</script>

<style lang="css" src="./RegisterContent.css" scoped></style>
