<template>
    <BaseDialog title="エラー" :content="shopStores?.errorContent">
        <template #default>
            <div class="button__action">
                <TheButton class="button__confirm" @click="handleCloseModal">OK</TheButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import BaseDialog from './BaseDialog.vue';
import TheButton from '../../../../../../components/button/TheButton.vue';
import { useShopStores } from '../../../../../../stores/ShopStores.js';

// store
const shopStores = useShopStores();

// function
const handleCloseModal = () => {
    shopStores?.setShopDeleteResult(false);
    shopStores?.setShopDeleteConfirm(false);
    shopStores?.setShopDetail(false);
    shopStores?.setShopListRefreshData();
    shopStores?.setShopCode('');
};
</script>

<style lang="css" src="./DeleteResultDialog.css" scoped></style>
