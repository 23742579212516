export const isDoubleByte = (str) => {
    if (!str) return false;
    for (let i = 0, n = str.length; i < n; i++) {
        if (str.charCodeAt(i) > 255) {
            return true;
        }
    }
    return false;
};

export const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
};

export const formatDateOnly = (dateStr, separator = '/') => {
    if (!dateStr) return '';
    if (new Date(dateStr) == 'Invalid Date') return '';

    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}${separator}${month}${separator}${day}`;

    return formattedDate;
};
export const formatTimeOnly = (dateStr, separator = ':') => {
    if (!dateStr) return '';

    const time = new Date(dateStr);
    const HH = String(time.getHours()).padStart(2, '0');
    const MM = String(time.getMinutes()).padStart(2, '0');
    const SS = String(time.getSeconds()).padStart(2, '0');
    return `${HH}${separator}${MM}${separator}${SS}`;
};
export const cutStringByByte = (inputString, maxByteLength) => {
    const result = [];
    let currentByteLength = 0;
    let currentPart = '';

    for (let i = 0; i < inputString.length; i++) {
        const char = inputString.charAt(i);
        const charBytes = encodeURIComponent(char).replace(/%[A-F\d]{2}/g, 'U').length;

        if (currentByteLength + charBytes <= maxByteLength) {
            currentPart += char;
            currentByteLength += charBytes;
        } else {
            result.push(currentPart);
            currentPart = char;
            currentByteLength = charBytes;
        }
    }

    if (currentPart) {
        result.push(currentPart);
    }

    return result;
};

export const countBytes = (inputString) => {
    let byteCount = 0;

    for (let i = 0; i < inputString.length; i++) {
        const char = inputString.charAt(i);
        const charCode = char.charCodeAt(0);

        // Count bytes based on UTF-8 encoding rules
        if (charCode <= 0x7f) {
            byteCount += 1;
        } else if (charCode <= 0x7ff) {
            byteCount += 2;
        } else if (charCode <= 0xffff) {
            byteCount += 3;
        } else {
            byteCount += 4;
        }
    }

    return byteCount;
};
export const checkValidate = (obj) => {
    for (const key in obj) {
        if (obj[key]) {
            return !obj[key];
        }
    }
    return true;
};

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const getYearArray = (startYear, endYear) => {
    const yearArray = [];
    for (let step = endYear; step >= startYear; step--) {
        yearArray.push(step);
    }
    return yearArray;
};

export const yearArray = getYearArray(2023, new Date().getFullYear());

export const removeDuplicateInArrayByKey = (values = [], key = '') => {
    if (!key) return false;
    const valueArr = values.map((item) => item[key]);
    return new Set(valueArr);
};
