<template>
    <div class="update__pay--buttons">
        <!-- Ver1.2 #11 -->
        <!-- orderStatus: 2 => 5 -->
        <!-- :disabled="!orderStores.searchParams?.flyerCode || isDisableUpdateStatusOrderedButton" -->
        <TheButton
            :disabled="isDisableUpdateStatusOrderedButton"
            class="update__status--button"
            @click="isActiveOrderedModal = true"
        >
            {{ t('order.table.updatePayTypeDelivered') }}
        </TheButton>
        <v-dialog v-model="isActiveOrderedModal" width="500">
            <v-card>
                <div class="card__container">
                    <TheTitle class="update__paytype--title">
                        <h3>{{ t('notification.title3') }}</h3>
                    </TheTitle>
                    <div class="content__dialog">
                        <p>{{ t('notification.content3_1') }}</p>
                        <p class="explain__description">{{ t('notification.content3_2') }}</p>
                        <div class="search__status">
                            <!-- Loading -->
                            <div v-if="isLoading" class="table__item--center">
                                <div class="custom-loader"></div>
                            </div>
                        </div>
                        <div v-if="errorMessages.length !== 0" class="errorMessages">
                            <p v-for="message in errorMessages" :key="message">* {{ message }}</p>
                        </div>
                        <div class="button__action">
                            <TheButton class="button__action--cancel" @click="isActiveOrderedModal = false">
                                {{ t('confirm.content2') }}
                            </TheButton>
                            <TheButton class="button__action--confirm" @click="() => handleUpdatePayType('ORDER')">
                                {{ t('confirm.content3') }}
                            </TheButton>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <!-- Ver1.2 #11 -->
        <!-- orderStatus: 2 or 5 =>  6-->
        <!-- :disabled="!orderStores.searchParams?.flyerCode || isDisableUpdateStatusDeliveredButton" -->
        <TheButton
            :disabled="isDisableUpdateStatusDeliveredButton"
            class="update__status--button"
            @click="isActiveDeliveredModal = true"
        >
            {{ t('order.table.updatePayTypeSent') }}
        </TheButton>
        <v-dialog v-model="isActiveDeliveredModal" width="500">
            <v-card>
                <div class="card__container">
                    <TheTitle class="update__paytype--title">
                        <h3>{{ t('notification.title4') }}</h3>
                    </TheTitle>
                    <div class="content__dialog">
                        <p>{{ t('notification.content4_1') }}</p>
                        <p class="explain__description">{{ t('notification.content4_2') }}</p>
                        <div class="search__status">
                            <!-- Loading -->
                            <div v-if="isLoading" class="table__item--center">
                                <div class="custom-loader"></div>
                            </div>
                        </div>
                        <div v-if="errorMessages.length !== 0" class="errorMessages">
                            <p v-for="message in errorMessages" :key="message">* {{ message }}</p>
                        </div>
                        <div class="button__action">
                            <TheButton class="button__action--cancel" @click="isActiveDeliveredModal = false">
                                {{ t('confirm.content2') }}
                            </TheButton>
                            <TheButton class="button__action--confirm" @click="() => handleUpdatePayType('DELIVERY')">
                                {{ t('confirm.content3') }}
                            </TheButton>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import TheButton from '../../../../components/button/TheButton.vue';
import TheTitle from '../../../../components/title/TheTitle.vue';
import { useOrderStores } from '../../../../stores/OrderStores';
import { useUserStore } from '../../../../stores/UserStore';
import OrdersService from '../../../../services/OrdersService';
import useFetch from '../../../../utils/useFetch';

// i18n
const { t } = useI18n();

const emits = defineEmits(['refreshOrders']);

const isDisableUpdateStatusOrderedButton = ref(true);
const isDisableUpdateStatusDeliveredButton = ref(true);
const isActiveOrderedModal = ref(false);
const isActiveDeliveredModal = ref(false);
const errorMessages = ref([]);

const orderStores = useOrderStores();
const userStore = useUserStore();

// Ver1.2 #11
watch(
    () => orderStores?.dataOrderStatusChange,
    (newOrders) => {
        isDisableUpdateStatusDeliveredButton.value = !newOrders?.some((order) => {
            return order?.checked && (order?.orderStatus == '2' || order?.orderStatus == '5');
        });
        isDisableUpdateStatusOrderedButton.value = !newOrders?.some((order) => {
            return order?.checked && order?.orderStatus == '2';
        });
    },
);

const { isLoading, fetchData } = useFetch(OrdersService.updateOrderStatus);

const handleUpdatePayType = async (type) => {
    let orderList = [];

    if (type === 'ORDER') {
        orderList =
            orderStores?.dataOrderStatusChange?.filter((order) => order?.checked === true && order?.orderStatus == 2) || [];
    }

    if (type === 'DELIVERY') {
        orderList =
            orderStores?.dataOrderStatusChange?.filter(
                (order) => order?.checked === true && (order?.orderStatus == 2 || order?.orderStatus == 5),
            ) || [];
    }
    const orderCodes = orderList?.map((order) => order?.orderCode) || [];

    await fetchData({
        flyerCode: orderStores?.searchParams?.flyerCode,
        userId: userStore?.userId,
        mode: type,
        orderCodes: orderCodes,
    });
    isActiveOrderedModal.value = false;
    isActiveDeliveredModal.value = false;
    emits('refreshOrders');
};
</script>

<style lang="css" src="./UpdatePayTypeButton.css" scoped></style>
