<template>
    <div class="wrapper__dialog__order__detail">
        <TheTitle class="order__detail--title">
            <h2>{{ props?.title }}</h2>
        </TheTitle>
        <div class="content__dialog">
            <div class="dialog--text">{{ props?.content }}</div>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import TheTitle from '../../../../components/title/TheTitle.vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    content: {
        type: String,
        required: true,
    },
});
</script>

<style lang="css" src="./BaseDialog.css" scoped></style>
