<template>
    <DialogCustom :show="props?.loading" :z-index="999">
        <div class="custom-loader"></div>
    </DialogCustom>
</template>

<script setup>
import DialogCustom from '../dialog/DialogCustom.vue';

const props = defineProps({
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
});
</script>

<style lang="css" src="./BaseLoading.css" scoped></style>
