<template>
    <div class="wrapper__teachers">
        <div class="container">
            <div class="content__teachers">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!--TODO - β1,β2は、何も表示しない、もしくは固定メッセージ-->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <div class="section2">
                    <!-- チラシ検索 -->
                    <ThePaper class="section2__left">
                        <BaseSearchSchoolStaff></BaseSearchSchoolStaff>
                    </ThePaper>
                    <!-- チラシ一覧 -->
                    <ThePaper class="section2__right">
                        <BaseTeacherList></BaseTeacherList>
                    </ThePaper>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import BaseSearchSchoolStaff from './components/search/BaseSearchSchoolStaff.vue';
import BaseTeacherList from './components/teacherList/BaseTeacherList.vue';
import { useCommonStores } from '../../stores/CommonStores';
import { useSchoolStaffStores } from '../../stores/SchoolStaffStores';
import { useUserStore } from '../../stores/UserStore.js';

// stores
const commonStores = useCommonStores();
const schoolStaffStores = useSchoolStaffStores();
const userStore = useUserStore();

// router
const router = useRouter();

// watch
watch(
    () => userStore?.userInfo?.affiliationKbn,
    (newValue, oldValue) => {
        if (newValue !== 1) {
            router.push('/orders');
        }
    },
);

// Unmouted
onUnmounted(() => {
    schoolStaffStores?.reset();
});
</script>

<style lang="css" src="./SchoolStaffPage.css" scoped></style>
