<template>
    <div class="wrapper__banner" :class="{ expand: !props?.isExpand }">
        <div class="banner__title" :class="{ expand: props?.isExpand }">
            <div>
                <img src="../../assets/icons/banner-icon.svg" alt="banner-icon" />
            </div>
            <h2>{{ t('banner.title1') }}</h2>
        </div>
        <div v-if="isLoading" class="banner__image">
            <img src="../../assets/img/default-thumbnail.jpg" alt="image-default" />
        </div>
        <div v-else class="banner__image" :class="{ collapse: !props?.isExpand }">
            <!-- hide-delimiter-background -->
            <v-carousel class="group__slide" :continuous="true" :cycle="true" interval="5000">
                <v-carousel-item v-for="(item, index) in urlBanners" :key="index" class="item__slide">
                    <v-sheet>
                        <div class="slide--item d-flex fill-height justify-center align-center">
                            <img
                                class="image__item"
                                :src="item"
                                :alt="`banner-image-item-${index}`"
                                @click="handleRedirect(item?.link)"
                            />
                        </div>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
        </div>
    </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import CommonService from '../../services/CommonService.js';
import useFetch from '../../utils/useFetch.js';

// i18n
const { t } = useI18n();

// service
const { data, isError, fetchData, isLoading } = useFetch(CommonService.getBanner);

// props
const props = defineProps({
    isExpand: {
        type: Boolean,
        required: false,
        default: true,
    },
});
// ref
const urlBanners = ref([]);

// function
const handleRedirect = (link) => {
    console.log(link);
    // window.open('https://www.yahoo.co.jp/', '_blank');
};

fetchData();
watch(isError, (newError) => {
    urlBanners.value = [];
});

watch(data, (newBanner) => {
    if (newBanner) {
        urlBanners.value = newBanner?.map((item) => item?.url);
    }
});
</script>

<style lang="css" src="./BaseBanner.css" scoped></style>
