<template>
    <BaseDialog
        class="wrapper__dialog--delete"
        :title="t('shopStaff.dialog.titleDelete')"
        :content="t('shopStaff.dialog.contentDelete', { staffName: props?.staffName })"
    >
        <template #default>
            <Teleport to="body">
                <BaseLoading :loading="isLoading"> </BaseLoading>
            </Teleport>
            <div class="action--list">
                <TheButton class="action--item" @click="handleCancel">{{ t('confirm.content2') }}</TheButton>
                <TheButton class="action--item" @click="handleDelete">{{ t('confirm.content3') }}</TheButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseLoading from '../../../../components/loading/BaseLoading.vue';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useShopStaffStores } from '../../../../stores/ShopStaffStores.js';
import ShopStaffService from '../../../../services/ShopStaffService.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import useFetch from '../../../../utils/useFetch.js';

// i18n
const { t } = useI18n();

// stores
const shopStaffStores = useShopStaffStores();
const userStore = useUserStore();

// service
const { isError, errorMessage, fetchData, data, isLoading } = useFetch(ShopStaffService?.deleteShopStaff);

// props
const props = defineProps({
    staffName: {
        type: String,
        required: false,
    },
    userId: {
        type: String,
        required: false,
    },
});

// function
const handleCancel = () => {
    shopStaffStores?.setIsModalDelete(false);
};
const handleDelete = async () => {
    fetchData({
        userId: userStore?.userId,
        delUserId: props?.userId,
    });
};

// watch
watch(isError, (newValue) => {
    shopStaffStores?.setTitleNotice(t('shopStaff.dialog.titleDelete'));
    if (newValue) {
        shopStaffStores?.setIsNotice(true);
        shopStaffStores?.setNoticeError(errorMessage.value);
    }
});

watch(data, (newValue) => {
    if (newValue?.status === 0) {
        shopStaffStores?.setTitleNotice(t('shopStaff.dialog.titleDelete'));
        shopStaffStores?.setIsNotice(true);
        shopStaffStores?.setNoticeError(t('shopStaff.dialog.contentSuccess2'));
        shopStaffStores?.setIsSearchStaff();
        shopStaffStores?.setIsModalDelete(false);
    }
});
</script>

<style lang="css" src="./BaseDialogDelete.css" scoped></style>
