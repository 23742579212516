<template>
    <div class="wrapper__flyers">
        <div class="container">
            <div class="content__flyers">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!--TODO - β1,β2は、何も表示しない、もしくは固定メッセージ-->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <div class="section2">
                    <!-- チラシ検索 -->
                    <ThePaper class="section2__left">
                        <BaseSearchFlyer></BaseSearchFlyer>
                    </ThePaper>
                    <!-- チラシ一覧 -->
                    <ThePaper class="section2__right">
                        <BaseFlyerList></BaseFlyerList>
                    </ThePaper>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onUnmounted } from 'vue';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import BaseSearchFlyer from './components/search/BaseSearchFlyer.vue';
import BaseFlyerList from './components/flyerList/BaseFlyerList.vue';
import { useCommonStores } from '../../stores/CommonStores';
import { useFlyerStores } from '../../stores/FlyerStores';

// stores
const commonStores = useCommonStores();
const FlyerStore = useFlyerStores();

// unmounted
onUnmounted(() => {
    FlyerStore?.reset();
});
</script>

<style lang="css" src="./FlyersPage.css" scoped></style>
