import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebar', () => {
    const keyActive = ref(null);
    const expand = ref(false);

    function setKeyActive(key) {
        keyActive.value = key;
    }
    function setExpand(value) {
        expand.value = value;
    }
    function reset() {
        setKeyActive(null);
        setExpand(false);
    }
    return {
        keyActive,
        expand,
        setKeyActive,
        setExpand,
        reset,
    };
});
