<template>
    <div class="wrapper__form-list--registration">
        <Teleport to="body">
            <BaseLoading
                :loading="
                    fetchRegisterProduct?.isLoading.value ||
                    fetchGetProductDetail?.isLoading.value ||
                    fetchUpdateProductInfo?.isLoading.value ||
                    fetchGetProductCategoryList?.isLoading.value
                "
            >
            </BaseLoading>
        </Teleport>
        <!-- 型/セット名 -->
        <InputItem
            :important="false"
            class="item__registration--input"
            :label="t('productRegistration.inputLabel1')"
            :select="false"
        >
            <template #default>
                <input id="focus--item" v-model.trim="productSetName" maxlength="128" class="item__input--text" />
            </template>
        </InputItem>
        <!-- カテゴリ -->
        <InputItem
            :required="validate.categoryCode"
            class="item__registration--input"
            :label="t('productRegistration.inputLabel10')"
            :select="true"
        >
            <template #default>
                <TheSelect
                    class="item__input--select"
                    :selection="categoryCode"
                    :array-value="productCategoryList?.map((item) => item?.categoryCode)"
                    :options="productCategoryList?.map((item) => item?.categoryName)"
                    :required="validate.categoryCode"
                    @input="
                        (newSelect) => {
                            categoryCode = newSelect;
                            // const name = productCategoryList
                            //     ?.map((item) => {
                            //         if (item?.categoryCode == newSelect) {
                            //             return item?.categoryName;
                            //         }
                            //         return '';
                            //     })
                            //     ?.filter((item) => item !== '');
                            // if (name && name.length > 0) {
                            //     productRegistrationStores.phoneReview.categoryName = name[0];
                            // } else productRegistrationStores.phoneReview.categoryName = false;
                        }
                    "
                >
                </TheSelect>
            </template>
        </InputItem>
        <!-- 商品名 -->
        <InputItem
            :required="validate.productName"
            class="item__registration--input"
            :label="t('productRegistration.inputLabel2')"
            :placeholder="t('common.placeholder2')"
            :select="false"
        >
            <template #default>
                <input
                    v-model.trim="productName"
                    :placeholder="t('common.placeholder2')"
                    :required="validate.productName"
                    maxlength="128"
                    class="item__input--text"
                />
            </template>
        </InputItem>
        <!-- 商品コード/品番 -->
        <InputItem
            :required="validate.productCode"
            class="item__registration--input"
            :label="t('productRegistration.inputLabel3')"
            :select="false"
            :default="specCharacter"
        >
            <template #default>
                <input
                    v-model.trim="productCode"
                    :placeholder="t('common.placeholder2')"
                    :required="validate.productCode"
                    maxlength="16"
                    class="item__input--text"
                />
            </template>
        </InputItem>
        <!-- 税区分 -->
        <InputItem
            :required="validate.taxCategory"
            class="item__registration--input"
            :label="t('productRegistration.inputLabel5')"
            :select="true"
        >
            <template #default>
                <TheSelect
                    class="item__input--select"
                    :selection="taxCategory"
                    :array-value="taxCategoryConst.values"
                    :options="taxCategoryConst.options"
                    :required="validate.taxCategory"
                    @input="
                        (newSelect) => {
                            taxCategory = newSelect;
                        }
                    "
                >
                </TheSelect>
            </template>
        </InputItem>
        <!-- feature/Ver1.2#1#3 -->
        <!-- 希望小売価格（税込） -->
        <InputItem
            class="item__registration--input"
            :label="t('productRegistration.inputLabel16')"
            :select="false"
            :important="false"
        >
            <template #default>
                <input v-model.trim="taxNormalPrice" maxlength="8" class="item__input--text text-right w-custom" />
                <p class="unit__price">{{ t('common.currencyUnit') }}</p>
            </template>
        </InputItem>
        <div class="checkbox__group">
            <div class="checkbox__item">
                <input v-model="requiredPurchase" class="input__item--checkbox" type="checkbox" />
                <label class="checkbox__label">{{ t('productRegistration.inputLabel11') }}</label>
            </div>
            <!-- <div class="checkbox__item">
                <label class="checkbox__label">{{ t('productRegistration.inputLabel12') }}</label>
                <input v-model="productStatus" class="input__item--checkbox" type="checkbox" />
            </div> -->
        </div>

        <!-- feature/Ver1.2#1#3 -->
        <div class="input__group">
            <!-- 表示価格（税抜） -->
            <InputItem
                :required="validate.nonTaxedPrice"
                class="item__registration--input"
                :label="t('productRegistration.inputLabel4')"
                :select="false"
                :custom-class-label="false"
                :custom-message="false"
            >
                <template #default>
                    <input
                        v-model.trim="nonTaxedPrice"
                        :required="validate.nonTaxedPrice"
                        maxlength="8"
                        class="item__input--text text-right w-custom-x2"
                    />
                    <p class="unit__price">{{ t('common.currencyUnit') }}</p>
                </template>
            </InputItem>
            <!-- 表示価格（税込） -->
            <InputItem
                :required="validate.taxIncludedPrice"
                class="item__registration--input p-right"
                maxlength="8"
                :label="t('productRegistration.inputLabel6')"
                :select="false"
                :custom-class-label="false"
                :custom-message="false"
            >
                <template #default>
                    <input
                        v-model="taxIncludedPrice"
                        :required="validate.taxIncludedPrice"
                        maxLength="8"
                        class="item__input--text text-right w-custom-x2"
                    />
                    <p class="unit__price">{{ t('common.currencyUnit') }}</p>
                </template>
            </InputItem>
        </div>
        <!-- 在庫 -->
        <InputItem
            :label="t('productRegistration.inputLabelStock')"
            :required="validate.stock"
            class="item__registration--input1"
            :important="false"
            :select="false"
        >
            <template #default>
                <label class="form__item text-nowrap">
                    <input v-model="stockFlg" name="stockFlg" value="0" type="radio" checked />
                    {{ t('productRegistration.inputLabelStockFlg', { status: 'なし' }) }}
                </label>
                <label class="form__item text-nowrap">
                    <input v-model="stockFlg" name="stockFlg" value="1" type="radio" />
                    {{ t('productRegistration.inputLabelStockFlg', { status: 'あり' }) }}
                </label>
                <div class="form__item">
                    <label class="text-nowrap">
                        {{ t('productRegistration.inputLabelStockQuantity') }}
                        <strong v-if="stockFlg === '1'" class="required">{{ t('common.required') }}</strong>
                    </label>
                    <input
                        v-model.trim="stock"
                        :required="validate.stock"
                        name="stock"
                        class="item__input--text text-right"
                        :disabled="stockFlg == '0'"
                    />
                </div>
            </template>
        </InputItem>
        <!-- feature/Ver1.2#1#3 -->
        <!-- 表示価格と同じ -->
        <div class="checkbox__item">
            <input id="sameAsListedPrice" v-model="sameAsListedPrice" class="input__item--checkbox--left" type="checkbox" />
            <label for="sameAsListedPrice" class="checkbox__label--left">{{ t('productRegistration.inputLabel15') }}</label>
        </div>
        <ImageItem class="item__registration--image" :label="t('productRegistration.inputLabel7')">
            <template #default>
                <TheButton class="item__registration--btn__image" @click="handleChoiceImage('item__input--image')">
                    {{ t('productRegistration.buttonLabel1') }}
                </TheButton>
                <input type="file" class="item__input--image" @change="(e) => handleChangeImage(e)" />
                <div class="error__message--group">
                    <div v-if="!validate.productMainImage" class="image_infor image_infor--main">
                        {{ productMainImage?.name }}
                    </div>
                    <div
                        v-else-if="validate.productMainImage && !productMainImage?.name"
                        class="image_infor image_infor--main validate__message--image"
                    >
                        {{ t('error.validateMessage4') }}
                    </div>
                    <p v-else-if="validate.productMainImage === 1" class="image_infor image_infor--main validate__message--image">
                        {{ t('error.validateMessage5_1') }}
                    </p>
                    <p
                        v-else-if="validate?.productMainImage === 2"
                        class="image_infor image_infor--main validate__message--image"
                    >
                        {{ t('error.validateMessage6') }}
                    </p>
                    <p
                        v-else-if="validate?.productMainImage === 3"
                        class="image_infor image_infor--main validate__message--image"
                    >
                        {{ t('error.validateMessage7') }}
                    </p>
                </div>
            </template>
        </ImageItem>
        <!-- feature/Ver1.2#1#3 -->
        <div class="input__group">
            <!-- 販売価格（税抜） -->
            <InputItem
                :required="validate.nonTaxedSalePrice"
                class="item__registration--input"
                :label="t('productRegistration.inputLabel13')"
                :select="false"
                :custom-class-label="false"
                :custom-message="false"
                :number-custom-message="2"
                :important="!sameAsListedPrice"
            >
                <template #default>
                    <input
                        v-if="!sameAsListedPrice"
                        v-model.trim="nonTaxedSalePrice"
                        :required="validate.nonTaxedSalePrice"
                        maxlength="8"
                        :class="`item__input--text text-right ${sameAsListedPrice ? 'background-disable' : ''}`"
                        :disabled="sameAsListedPrice"
                    />
                    <input
                        v-else
                        v-model.trim="nonTaxedPrice"
                        :required="validate.nonTaxedSalePrice"
                        maxlength="8"
                        :class="`item__input--text text-right ${sameAsListedPrice ? 'background-disable' : ''}`"
                        :disabled="sameAsListedPrice"
                    />
                    <p class="unit__price">{{ t('common.currencyUnit') }}</p>
                </template>
            </InputItem>
            <!-- 販売価格（税込） -->
            <InputItem
                :required="validate.taxIncludedSalePrice"
                class="item__registration--input p-right"
                :label="t('productRegistration.inputLabel14')"
                maxlength="8"
                :select="false"
                :custom-class-label="false"
                :custom-message="false"
                :number-custom-message="2"
                :important="!sameAsListedPrice"
            >
                <template #default>
                    <input
                        v-if="!sameAsListedPrice"
                        v-model.trim="taxIncludedSalePrice"
                        :required="validate.taxIncludedSalePrice"
                        maxLength="8"
                        :class="`item__input--text text-right ${sameAsListedPrice ? 'background-disable' : ''}`"
                        :disabled="sameAsListedPrice"
                    />
                    <input
                        v-else
                        v-model.trim="taxIncludedPrice"
                        :required="validate.taxIncludedSalePrice"
                        maxLength="8"
                        :class="`item__input--text text-right ${sameAsListedPrice ? 'background-disable' : ''}`"
                        :disabled="sameAsListedPrice"
                    />
                    <p class="unit__price">{{ t('common.currencyUnit') }}</p>
                </template>
            </InputItem>
        </div>
        <InputItem
            :required="validate.productDetail"
            class="item__registration--input"
            :label="t('productRegistration.inputLabel8', { index: 1 })"
            :error-filed-name="t('productRegistration.inputLabel8', { index: 1 })"
            :select="false"
        >
            <template #default>
                <textarea
                    v-model.trim="productDetail.detail1"
                    :required="validate.productDetail"
                    maxlength="256"
                    class="item__input--textarea"
                    placeholder="サイズ情報 ：
色 ：
重さ ：
"
                >
                </textarea>
            </template>
        </InputItem>
        <InputItem
            :class="`item__registration--input ${
                !validate.nonTaxedSalePrice && !validate.taxIncludedSalePrice ? '' : 'extra__input--textarea'
            }`"
            :important="false"
            :label="t('productRegistration.inputLabel8', { index: 2 })"
            :error-filed-name="t('productRegistration.inputLabel8', { index: 2 })"
        >
            <template #default>
                <textarea v-model.trim="productDetail.detail2" class="item__input--textarea" maxlength="512"> </textarea>
            </template>
        </InputItem>
        <InputItem
            class="item__registration--input"
            :important="false"
            :label="t('productRegistration.inputLabel8', { index: 3 })"
            :error-filed-name="t('productRegistration.inputLabel8', { index: 3 })"
        >
            <template #default>
                <textarea v-model.trim="productDetail.detail3" class="item__input--textarea" maxlength="512"> </textarea>
            </template>
        </InputItem>
        <!-- fix layoyut, don't delete empty div -->
        <div></div>
        <!-- fix layoyut, don't delete empty div -->
        <ImageItem
            v-for="(item, index) in productDetailImage"
            :key="index"
            class="item__registration--image"
            :label="t('productRegistration.inputLabel9', { index: index + 1 })"
            :optional="false"
        >
            <template #default>
                <TheButton
                    class="item__registration--btn__image"
                    @click="handleChoiceImage(`item__input--image__detail${index + 1}`)"
                >
                    {{ t('productRegistration.buttonLabel1') }}
                </TheButton>
                <input
                    type="file"
                    :class="`item__input--image__detail item__input--image__detail${index + 1}`"
                    @change="(e) => handleChangeImage(e, index + 1)"
                />
                <div v-if="!validate.productDetailImage[index]" class="image_infor">{{ item?.name }}</div>
                <!-- <div
                    v-if="validate.productDetailImage[index] && !item?.name && productDetailImage.length > 1"
                    class="image_infor validate__message--image"
                >
                    {{ t('error.validateMessage4') }}
                </div> -->
                <p v-if="validate.productDetailImage[index] === 1" class="image_infor validate__message--image">
                    {{ t('error.validateMessage5') }}
                </p>
                <p v-if="validate.productDetailImage[index] === 2" class="image_infor validate__message--image">
                    {{ t('error.validateMessage6') }}
                </p>
                <p v-if="validate.productDetailImage[index] === 3" class="image_infor validate__message--image">
                    {{ t('error.validateMessage7') }}
                </p>
                <button
                    v-if="
                        (productDetailImage[index]?.name && productDetailImage?.length === 1) ||
                        productDetailImage?.length > 1 ||
                        validate.productDetailImage[index]
                    "
                    class="item--delete__image--btn"
                    @click="handleDeleteImage(index + 1, `item__input--image__detail${index + 1}`)"
                >
                    {{ t('productRegistration.buttonLabel3') }}
                </button>
            </template>
        </ImageItem>
        <div class="add__image--btn">
            <TheButton class="item__registration--add__btn" :disabled="productDetailImage.length >= 10" @click="handleAddImage">
                {{ t('productRegistration.buttonLabel2') }}
            </TheButton>
        </div>
        <div class="more__actions">
            <TheButton class="action__btn" @click="handleBacktoProductList">
                {{ t('productRegistration.buttonLabel4') }}
            </TheButton>
            <TheButton
                v-if="productRegistrationStores?.registerStatus === 0"
                class="action__btn"
                @click="handleProductRegistration"
            >
                {{ t('productRegistration.buttonLabel5') }}
            </TheButton>
            <TheButton v-else class="action__btn" @click="handleProductUpdation">
                {{ t('productRegistration.buttonLabel6') }}
            </TheButton>
            <TheButton
                v-if="decodeURIComponent(route.params?.mode) === 'register'"
                class="action__btn"
                @click="handleRefreshData"
                >{{ t('productRegistration.buttonLabel7') }}</TheButton
            >
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import BaseLoading from '../../../../components/loading/BaseLoading.vue';
import InputItem from '../formItem/InputItem.vue';
import ImageItem from '../formItem/ImageItem.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import TheSelect from '../../../../components/select/TheSelect.vue';
import { useProductRegistrationStores } from '../../../../stores/ProductRegistrationStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import { taxCategoryConst } from '../../../../utils/Consts.js';
import useFetch from '../../../../utils/useFetch.js';
import ProductRegistrationService from '../../../../services/ProductRegistrationService.js';

// i18n
const { t } = useI18n();

// router
const route = useRoute();

// store
const productRegistrationStores = useProductRegistrationStores();
const userStore = useUserStore();

// service
const fetchRegisterProduct = useFetch(ProductRegistrationService?.registerProductInfor);
const fetchGetProductDetail = useFetch(ProductRegistrationService?.getProductDetail);
const fetchUpdateProductInfo = useFetch(ProductRegistrationService?.updateFlyerProduct);
const fetchGetProductCategoryList = useFetch(ProductRegistrationService?.getProductCategoryList);

// const
const arrayKeyImageDetail = [
    'productDetailImage1',
    'productDetailImage2',
    'productDetailImage3',
    'productDetailImage4',
    'productDetailImage5',
    'productDetailImage6',
    'productDetailImage7',
    'productDetailImage8',
    'productDetailImage9',
    'productDetailImage10',
];

// ref
const productSetName = ref('');
const productName = ref('');
const productCode = ref('');
const specCharacter = ref(true);
const oldProductCode = ref('');
const taxNormalPrice = ref(''); // feature/Ver1.2#1#3
const nonTaxedPrice = ref();
const taxIncludedPrice = ref();
const sameAsListedPrice = ref(true);
const nonTaxedSalePrice = ref(); // feature/Ver1.2#1#3
const taxIncludedSalePrice = ref(); // feature/Ver1.2#1#3
const stockFlg = ref('0');
const stock = ref();
const taxCategory = ref('');
const productCategoryList = ref([]);
const categoryCode = ref('');
const requiredPurchase = ref(false);
const productStatus = ref(true);
const productMainImage = ref({});
const productDetail = reactive({
    detail1: '',
    detail2: '',
    detail3: '',
});
const productDetailImage = ref([
    {
        name: '',
        file: undefined,
    },
]);
const validate = reactive({
    productSetName: false,
    productName: false,
    productCode: false,
    nonTaxedPrice: false,
    taxIncludedPrice: false,
    // feature/Ver1.2#1#3
    nonTaxedSalePrice: false,
    taxIncludedSalePrice: false,
    stock: false,
    categoryCode: false,
    taxCategory: false,
    productMainImage: 0,
    productDetail: false,
    productDetailImage: [false],
});

onMounted(async () => {
    if (route.name === 'Product Update Page') {
        productRegistrationStores?.setRegisterStatus(1);
        oldProductCode.value = decodeURIComponent(route.params?.oldProductCode);
        await fetchGetProductDetail?.fetchData({
            flyerCode: decodeURIComponent(route.params?.flyerCode),
            shopCode: decodeURIComponent(route.params?.shopCode),
            productCode: decodeURIComponent(route.params?.oldProductCode),
        });
    }
    document?.querySelector('#focus--item')?.focus();
});

// function choice image
const handleChoiceImage = (className) => {
    document.querySelector('.' + className).click();
};
// function change image
const handleChangeImage = (e, index) => {
    if (
        e?.target?.files[0] &&
        (e?.target?.files[0]?.name?.slice(-4) === '.png' || e?.target?.files[0]?.name?.slice(-4) === '.jpg') &&
        e?.target?.files[0]?.name?.length <= 128 &&
        e?.target?.files[0]?.size <= 1024 * 1024
    ) {
        if (!index) {
            if (e?.target?.files[0]?.name?.slice(-4) === '.png') {
                URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
                validate.productMainImage = 1;
                productMainImage.value.name = '1'; // hard code to pass validate
                productMainImage.value.file = undefined;
                productRegistrationStores?.setMainImageUrl('');
            } else {
                URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
                validate.productMainImage = 0;
                productMainImage.value.name = e?.target?.files[0]?.name;
                productMainImage.value.file = e?.target?.files[0];
                productRegistrationStores?.setMainImageUrl(URL.createObjectURL(e?.target?.files[0]));
            }
        } else {
            URL.revokeObjectURL(productRegistrationStores?.detailImageUrls[index - 1]);
            validate.productDetailImage[index - 1] = 0;
            productDetailImage.value[index - 1].name = e?.target?.files[0]?.name;
            productDetailImage.value[index - 1].file = e?.target?.files[0];
            productRegistrationStores?.setDetailImageUrls(URL.createObjectURL(e?.target?.files[0]), index);
        }
        return;
    }
    if (
        e?.target?.files[0] &&
        e?.target?.files[0]?.name?.slice(-4) !== '.png' &&
        e?.target?.files[0]?.name?.slice(-4) !== '.jpg'
    ) {
        if (!index) {
            URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
            validate.productMainImage = 1;
            productMainImage.value.name = '1'; // hard code to pass validate
            productMainImage.value.file = undefined;
            productRegistrationStores?.setMainImageUrl('');
        } else {
            URL.revokeObjectURL(productRegistrationStores?.detailImageUrls[index - 1]);
            validate.productDetailImage[index - 1] = 1;
            productDetailImage.value[index - 1].name = '1'; // hard code to pass validate
            productDetailImage.value[index - 1].file = undefined;
            productRegistrationStores?.setDetailImageUrls('', index);
        }
        return;
    }
    if (e?.target?.files[0] && e?.target?.files[0]?.name?.length > 128) {
        if (!index) {
            URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
            validate.productMainImage = 2;
            productMainImage.value.name = '2'; // hard code to pass validate
            productMainImage.value.file = undefined;
            productRegistrationStores?.setMainImageUrl('');
        } else {
            URL.revokeObjectURL(productRegistrationStores?.detailImageUrls[index - 1]);
            validate.productDetailImage[index - 1] = 2;
            productDetailImage.value[index - 1].name = '2'; // hard code to pass validate
            productDetailImage.value[index - 1].file = undefined;
            productRegistrationStores?.setDetailImageUrls('', index);
        }
        return;
    }
    if (e?.target?.files[0] && e?.target?.files[0]?.size > 1024 * 1024) {
        if (!index) {
            URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
            validate.productMainImage = 3;
            productMainImage.value.name = '3'; // hard code to pass validate
            productMainImage.value.file = undefined;
            productRegistrationStores?.setMainImageUrl('');
        } else {
            URL.revokeObjectURL(productRegistrationStores?.detailImageUrls[index - 1]);
            validate.productDetailImage[index - 1] = 3;
            productDetailImage.value[index - 1].name = '3'; // hard code to pass validate
            productDetailImage.value[index - 1].file = undefined;
            productRegistrationStores?.setDetailImageUrls('', index);
        }
        return;
    }
    if (!e?.target?.files[0]) {
        if (!index) {
            URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
            productMainImage.value.name = ''; // hard code to pass validate
            productMainImage.value.file = undefined;
            productRegistrationStores?.setMainImageUrl('');
        } else {
            URL.revokeObjectURL(productRegistrationStores?.detailImageUrls[index - 1]);
            productDetailImage.value[index - 1].name = ''; // hard code to pass validate
            productDetailImage.value[index - 1].file = undefined;
            productRegistrationStores?.setDetailImageUrls('', index);
        }
    }
};
// function add image row
const handleAddImage = () => {
    if (productDetailImage.value.length > 9) {
        alert("Can't Add image item");
    } else {
        productDetailImage.value.push({
            name: '',
            file: undefined,
        });
        productRegistrationStores?.detailImageUrls?.push('');
        validate.productDetailImage.push(0);
    }
};
// function delete image row
const handleDeleteImage = (index, className) => {
    URL.revokeObjectURL(productRegistrationStores?.detailImageUrls[index - 1]);
    if (productDetailImage.value?.length > 1) {
        productDetailImage.value?.splice(index - 1, 1);
        validate.productDetailImage?.splice(index - 1, 1);
        productRegistrationStores?.detailImageUrls?.splice(index - 1, 1);
    } else {
        const el = document.querySelector('.' + className);
        el.value = '';
        productDetailImage.value[0] = {
            name: '',
            file: undefined,
        };
        validate.productDetailImage[0] = false;
        productRegistrationStores?.setDetailImageUrls('', index);
    }
};
// function back to products page
const handleBacktoProductList = () => {
    productRegistrationStores?.setIsNotice(1);
};
// function validate
const validateFunction = async () => {
    if (!productName.value) {
        validate.productName = true;
    }
    if (!productCode.value) {
        validate.productCode = true;
    }
    if (/[/\\:*?"<>|]/.test(productCode.value)) {
        validate.productCode = true;
        specCharacter.value = false;
    }
    if (!nonTaxedPrice.value || isNaN(Number(nonTaxedPrice.value))) {
        validate.nonTaxedPrice = true;
    }
    if (!taxIncludedPrice.value || isNaN(Number(taxIncludedPrice.value))) {
        validate.taxIncludedPrice = true;
    }
    // feature/Ver1.2#1#3
    if ((!nonTaxedSalePrice.value && sameAsListedPrice.value == false) || isNaN(Number(nonTaxedSalePrice.value))) {
        sameAsListedPrice.value == false ? (validate.nonTaxedSalePrice = true) : (validate.nonTaxedSalePrice = false);
    }
    // feature/Ver1.2#1#3
    if (!taxIncludedSalePrice.value || isNaN(Number(taxIncludedSalePrice.value))) {
        sameAsListedPrice.value == false ? (validate.taxIncludedSalePrice = true) : (validate.taxIncludedSalePrice = false);
    }
    if (stockFlg.value == '1' && (!stock.value || isNaN(Number(stock.value)))) {
        validate.stock = true;
    }
    if (taxCategory.value === '') {
        validate.taxCategory = true;
    }
    if (categoryCode.value === '') {
        validate.categoryCode = true;
    }
    if (!productMainImage.value?.file) {
        validate.productMainImage = true;
        productMainImage.value.name = '';
    }
    if (!productDetail.detail1) {
        validate.productDetail = true;
    }
    productDetailImage.value?.forEach((item, index) => {
        if (!item?.file) {
            validate.productDetailImage[index] = false;
            productDetailImage.value[index].name = '';
        }
    });
};
const assignFormData = async () => {
    const form = new FormData();
    form.append('userId', userStore?.userId);
    form.append('flyerCode', route?.params?.flyerCode);
    form.append('eventCode', route?.params?.eventCode);
    form.append('shopCode', route?.params?.shopCode);
    form.append('schoolCode', route?.params?.schoolCode);
    form.append('schoolYear', route?.params?.schoolYear);
    form.append('productSetName', productSetName.value);
    form.append('productName', productName.value);
    form.append('productCode', productCode.value);
    form.append('oldProductCode', oldProductCode.value);
    form.append('taxNormalPrice', taxNormalPrice.value); // feature/Ver1.2#1#3
    form.append('nonTaxedPrice', nonTaxedPrice.value);
    form.append('taxIncludedPrice', taxIncludedPrice.value);
    form.append('samePriceFlg', sameAsListedPrice.value); // feature/Ver1.2#1#3
    // eslint-disable-next-line max-len
    form.append('nonTaxedSalePrice', sameAsListedPrice.value ? nonTaxedPrice.value : nonTaxedSalePrice.value); // feature/Ver1.2#1#3
    // eslint-disable-next-line max-len
    form.append('taxIncludedSalePrice', sameAsListedPrice.value ? taxIncludedPrice.value : taxIncludedSalePrice.value); // feature/Ver1.2#1#3
    form.append('stock', stock.value); // feature/Ver1.2#16
    form.append('stockFlg', stockFlg.value); // feature/Ver1.2#16
    form.append('taxCategory', taxCategory.value);
    form.append('categoryCode', categoryCode.value);
    form.append('requiredPurchase', requiredPurchase.value ? 1 : 0);
    form.append('productStatus', productStatus.value ? 1 : 0);
    form.append('productMainImage', productMainImage.value?.file);
    form.append('productDetail', productDetail.detail1);
    form.append('productDetail2', productDetail.detail2);
    form.append('productDetail3', productDetail.detail3);
    const temp = await productDetailImage.value
        ?.map((item) => {
            return item?.file;
        })
        ?.filter((item) => item !== undefined);

    temp?.forEach((item, index) => {
        form.append('productDetailImage' + (index + 1), item);
    });
    return form;
};
// function register product
const handleProductRegistration = async () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        productRegistrationStores?.setNavigate(true);
        await validateFunction();
        if (
            !validate.productName &&
            !validate.productCode &&
            !validate.nonTaxedPrice &&
            !validate.taxIncludedPrice &&
            !validate.nonTaxedSalePrice &&
            !validate.taxIncludedSalePrice &&
            !validate.stock &&
            !validate.taxCategory &&
            !validate.productMainImage &&
            !validate.productDetail
        ) {
            const formData = await assignFormData();
            fetchRegisterProduct?.fetchData(formData);
        }
    }
};
// function update product
const handleProductUpdation = async () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        productRegistrationStores?.setNavigate(true);
        await validateFunction();
        if (
            !validate.productName &&
            !validate.productCode &&
            !validate.nonTaxedPrice &&
            !validate.taxIncludedPrice &&
            !validate.nonTaxedSalePrice &&
            !validate.taxIncludedSalePrice &&
            !validate.stock &&
            !validate.taxCategory &&
            !validate.productMainImage &&
            !validate.productDetail
        ) {
            const formData = await assignFormData();
            fetchUpdateProductInfo?.fetchData(formData);
        }
    }
};

// function refresh data
const handleRefreshData = async () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        await handleProductRegistration();
        productRegistrationStores?.setNavigate(false);
    }
};
// watch
watch(productName, (newValue) => {
    productRegistrationStores.phoneReview.productName = newValue;
    if (newValue) {
        validate.productName = false;
    }
});

watch(categoryCode, (newValue) => {
    const name = productCategoryList.value
        ?.map((item) => {
            if (item?.categoryCode == newValue) {
                return item?.categoryName;
            }
            return '';
        })
        ?.filter((item) => item !== '');
    if (name && name.length > 0) {
        productRegistrationStores.phoneReview.categoryName = name[0];
        if (productRegistrationStores.phoneReview.categoryName === '選択ください') {
            productRegistrationStores.phoneReview.categoryName = false;
        }
    } else productRegistrationStores.phoneReview.categoryName = false;
});

watch(productCode, (newValue) => {
    if (newValue) {
        validate.productCode = false;
        specCharacter.value = true;
    }
});
// feature/Ver1.2#1#3
watch(taxNormalPrice, (newValue) => {
    productRegistrationStores.phoneReview.taxNormalPrice = newValue;
});

watch(nonTaxedPrice, (newValue) => {
    if (newValue && !isNaN(Number(newValue))) {
        validate.nonTaxedPrice = false;
    }
});
watch(taxIncludedPrice, (newValue) => {
    productRegistrationStores.phoneReview.taxIncludedPrice = newValue;
    if (newValue && !isNaN(Number(newValue))) {
        validate.taxIncludedPrice = false;
    }
});
// feature/Ver1.2#1#3
watch(sameAsListedPrice, (newValue) => {
    if (newValue === true) {
        validate.nonTaxedSalePrice = false;
        validate.taxIncludedSalePrice = false;
        nonTaxedSalePrice.value = '';
        taxIncludedSalePrice.value = '';
    }
});
// feature/Ver1.2#1#3
watch(nonTaxedSalePrice, (newValue) => {
    if (newValue && !isNaN(Number(newValue))) {
        validate.nonTaxedSalePrice = false;
    }
});
// feature/Ver1.2#1#3
watch(taxIncludedSalePrice, (newValue) => {
    productRegistrationStores.phoneReview.taxIncludedSalePrice = newValue;
    if (newValue && !isNaN(Number(newValue))) {
        validate.taxIncludedSalePrice = false;
    }
});
watch(stockFlg, (newValue) => {
    if (newValue == '0') {
        validate.stock = false;
        // reset value
        stock.value = '';
    }
});
watch(stock, (newValue) => {
    // validate.stock = stockFlg.value == 0 ? false : true;
    if (stockFlg.value == 1) {
        validate.stock = Boolean(!stock.value.toString());
    }
});
watch(requiredPurchase, (newValue) => {
    productRegistrationStores.phoneReview.requiredPurchase = newValue;
});
watch(taxCategory, (newValue) => {
    if (newValue) {
        validate.taxCategory = false;
    }
});
watch(categoryCode, (newValue) => {
    if (newValue) {
        validate.categoryCode = false;
    }
});
watch(
    () => productDetail.detail1,
    (newValue) => {
        productRegistrationStores.phoneReview.productDetail.detail1 = newValue
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\n/g, '<br>');
        if (newValue) {
            validate.productDetail = false;
        }
    },
);
watch(
    () => productDetail.detail2,
    (newValue) => {
        productRegistrationStores.phoneReview.productDetail.detail2 = newValue
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\n/g, '<br>');
    },
);
watch(
    () => productDetail.detail3,
    (newValue) => {
        productRegistrationStores.phoneReview.productDetail.detail3 = newValue
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\n/g, '<br>');
    },
);
// listen Error when call fetchGetProductCategoryList API
watch(fetchGetProductCategoryList?.isError, (newError) => {
    productCategoryList.value?.unshift({
        categoryCode: '',
        categoryName: t('common.placeholder1'),
    });
    if (newError) {
        console.log(newError);
    }
});
// listen response when call fetchGetProductCategoryList API
watch(fetchGetProductCategoryList?.data, (newData) => {
    if (newData) {
        productCategoryList.value = newData?.result?.data?.productCategoryList;
        productCategoryList.value?.unshift({
            categoryCode: '',
            categoryName: t('common.placeholder1'),
        });
    } else {
        productCategoryList.value?.unshift({
            categoryCode: '',
            categoryName: t('common.placeholder1'),
        });
    }
});
// listen Error when call fetchRegisterProduct API
watch(fetchRegisterProduct?.isError, (newError) => {
    if (newError) {
        productRegistrationStores?.setIsNotice(3);
        productRegistrationStores?.setNavigate(false);
        productRegistrationStores?.setTitleNotice(t('productRegistration.dialog.titleDialog1'));
        productRegistrationStores?.setContentNotice(fetchRegisterProduct?.errorMessage.value);
    }
});
// listen response when call fetchRegisterProduct API
watch(fetchRegisterProduct?.data, async (newData) => {
    if (newData) {
        productRegistrationStores?.setTitleNotice(t('productRegistration.dialog.titleDialog1'));
        productRegistrationStores?.setContentNotice(t('productRegistration.dialog.contentDialog1'));
        productRegistrationStores?.setIsNotice(2);
        if (!productRegistrationStores?.navigate) {
            productSetName.value = '';
            productName.value = '';
            productCode.value = '';
            oldProductCode.value = '';
            taxNormalPrice.value = ''; // feature/Ver1.2#1#3
            nonTaxedPrice.value = '';
            taxIncludedPrice.value = '';
            nonTaxedSalePrice.value = ''; // feature/Ver1.2#1#3
            taxIncludedSalePrice.value = ''; // feature/Ver1.2#1#3
            taxCategory.value = '';
            categoryCode.value = '';
            requiredPurchase.value = false;
            productStatus.value = true;
            productMainImage.value = {};
            productDetail.detail1 = '';
            productDetail.detail2 = '';
            productDetail.detail3 = '';
            productDetailImage.value = await productDetailImage.value?.map((item) => ({ name: '', file: undefined }));
            productRegistrationStores?.setDetailImageUrls(
                productRegistrationStores?.detailImageUrls?.map((item, index) => {
                    URL.revokeObjectURL(item);
                    const el = document.querySelector(`.item__input--image__detail${index + 1}`);
                    el.value = '';
                    return '';
                }),
            );
            URL.revokeObjectURL(productRegistrationStores?.mainImageUrl);
            productRegistrationStores?.setMainImageUrl('');
            const el = document.querySelector('.item__input--image');
            el.value = '';
        }
    }
});
// listen Error when call fetchUpdateProductInfo API
watch(fetchUpdateProductInfo?.isError, (newError) => {
    if (newError) {
        productRegistrationStores?.setIsNotice(3);
        productRegistrationStores?.setNavigate(false);
        productRegistrationStores?.setTitleNotice(t('productRegistration.dialog.titleDialog2'));
        productRegistrationStores?.setContentNotice(fetchUpdateProductInfo?.errorMessage.value);
    }
});
// listen response when call fetchUpdateProductInfo API
watch(fetchUpdateProductInfo?.data, (newData) => {
    if (newData) {
        productRegistrationStores?.setIsNotice(2);
        productRegistrationStores?.setTitleNotice(t('productRegistration.dialog.titleDialog2'));
        productRegistrationStores?.setContentNotice(t('productRegistration.dialog.contentDialog2'));
    }
});
// listen Error when call fetchGetProductDetail API
watch(fetchGetProductDetail?.isError, (newError) => {
    if (newError) {
        alert(fetchGetProductDetail?.errorMessage.value);
    }
});

// listen response when call fetchGetProductDetail API
watch(fetchGetProductDetail?.data, async (newData) => {
    if (newData) {
        // feature/Ver1.2#1#3
        if (
            newData?.result?.data?.nonTaxedPrice?.toString() === newData?.result?.data?.nonTaxedSalePrice?.toString() &&
            newData?.result?.data?.taxIncludedPrice?.toString() === newData?.result?.data?.taxIncludedSalePrice?.toString()
        ) {
            sameAsListedPrice.value = true;
        } else {
            sameAsListedPrice.value = false;
        }

        productSetName.value = newData?.result?.data?.productSetName || '';
        productName.value = newData?.result?.data?.productName || '';
        productCode.value = newData?.result?.data?.productCode || '';
        taxNormalPrice.value = newData?.result?.data?.taxNormalPrice?.toString() || ''; // feature/Ver1.2#1#3
        nonTaxedPrice.value = newData?.result?.data?.nonTaxedPrice?.toString() || '';
        taxIncludedPrice.value = newData?.result?.data?.taxIncludedPrice?.toString() || '';
        // eslint-disable-next-line max-len
        nonTaxedSalePrice.value = sameAsListedPrice.value ? '' : newData?.result?.data?.nonTaxedSalePrice?.toString(); // feature/Ver1.2#1#3
        // eslint-disable-next-line max-len
        taxIncludedSalePrice.value = sameAsListedPrice.value ? '' : newData?.result?.data?.taxIncludedSalePrice?.toString(); // feature/Ver1.2#1#3
        taxCategory.value = newData?.result?.data?.taxCategory || '';
        categoryCode.value = newData?.result?.data?.categoryCode || '';
        requiredPurchase.value = newData?.result?.data?.requiredPurchase ? true : false;
        productStatus.value = newData?.result?.data?.productStatus ? true : false;
        stock.value = newData?.result?.data?.stock || 0;
        stockFlg.value = newData?.result?.data?.stockFlg;

        // productMainImage;
        await fetch(newData?.result?.data?.productImageName?.imageURL)
            .then((res) => {
                if (res.ok) {
                    return res.blob();
                }

                throw Error("Can't load main image of product");
            })
            .then((blob) => {
                productMainImage.value = {
                    name: newData?.result?.data?.productImageName?.imageName,
                    file: new File([blob], newData?.result?.data?.productImageName?.imageName, { type: blob.type }),
                };

                validate.productMainImage = false;
                productRegistrationStores?.setMainImageUrl(newData?.result?.data?.productImageName?.imageURL);
            })
            .catch((err) => {
                console.log(err);
            });
        productDetail.detail1 = newData?.result?.data?.productDetail;
        productDetail.detail2 = newData?.result?.data?.productDetail2 || '';
        productDetail.detail3 = newData?.result?.data?.productDetail3 || '';

        // productImageDetail
        productDetailImage.value = [];
        validate.productDetailImage = [];
        const promises = arrayKeyImageDetail.map(async (item, index) => {
            if (newData?.result?.data[item]?.imageURL) {
                await fetch(newData?.result?.data[item]?.imageURL)
                    .then((res) => {
                        if (res.ok) {
                            return res.blob();
                        }

                        throw Error("Can't load image");
                    })
                    .then((blob) => {
                        productDetailImage.value?.push({
                            name: newData?.result?.data[item]?.imageName,
                            file: new File([blob], newData?.result?.data[item]?.imageName, { type: blob.type }),
                        });
                        validate.productDetailImage.push(false);
                        productRegistrationStores?.setDetailImageUrls(newData?.result?.data[item]?.imageURL, index + 1);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
        await Promise.allSettled(promises);
        if (productDetailImage.value?.length === 0) {
            productDetailImage.value.push({
                name: '',
                file: undefined,
            });
        }
    }
});

// fetch data productCategoryList
fetchGetProductCategoryList.fetchData();
</script>

<style lang="css" src="./FormList.css" scoped></style>

<style>
.input__group .item__registration--input.p-right .input--item__label__group {
    min-width: fit-content;
    width: fit-content;
    justify-content: end;
}
.input__group .item__registration--input.p-right .input--item__slot {
    flex: 0;
    min-width: 40%;
    justify-content: start;
}
.input__group .item__registration--input.p-right {
    justify-content: flex-end;
}
</style>
