<template>
    <div class="wrapper__teacher--list">
        <div class="teacher__list--title">
            <TheTitle class="title">
                <h1>{{ t('schoolStaff.titleList') }}</h1>
            </TheTitle>
            <div class="acction__button--header">
                <TheButton
                    :disabled="userStore?.userInfo?.affiliationKbn !== 1 || !schoolStaffStores?.activeRegistration"
                    @click="handleCreateTeacher"
                >
                    {{ t('schoolStaff.labelRegisterUser') }}
                </TheButton>
                <img
                    :src="commonStores?.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </div>
        <TeacherList></TeacherList>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import TeacherList from './TeacherList.vue';
import { useCommonStores } from '../../../../stores/CommonStores';
import { useSchoolStaffStores } from '../../../../stores/SchoolStaffStores.js';
import { useUserStore } from '../../../../stores/UserStore';

import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';

// i18n
const { t } = useI18n();

// stores
const commonStores = useCommonStores();
const userStore = useUserStore();
const schoolStaffStores = useSchoolStaffStores();

// function
const handleChangeSize = () => {
    commonStores?.setIsExpand(!commonStores?.isExpand);
};
const handleCreateTeacher = () => {
    schoolStaffStores?.setIsModalCreate(true);
};
</script>

<style lang="css" src="./BaseTeacherList.css" scoped></style>
