import shopIcon from '@/assets/icons/shop.svg';
import salesRepresentativeIcon from '@/assets/icons/sales-representative.svg';
// import notificationIcon from '@/assets/icons/notification.svg';
// import schoolIcon from '@/assets/icons/school.svg';
// import familyIcon from '@/assets/icons/family.svg';
import multiStrokesIcon from '@/assets/icons/multi-strokes.svg';
import cartIcon from '@/assets/icons/cart.svg';
// import diagramIcon from '@/assets/icons/diagram.svg';
import investorIcon from '@/assets/icons/investor.svg';
import groupIcon from '@/assets/icons/group.svg';

export const iconsSidebar = [
    // {
    //     iconUrl: schoolIcon,
    //     title: '学校管理',
    //     subItemsSidebar: [
    //         {
    //             title: '学校登録',
    //             link: '#',
    //         },
    //         {
    //             title: '学校一覧',
    //             link: '#',
    //         },
    //     ],
    // },

    // {
    //     iconUrl: familyIcon,
    //     title: '保護者管理',
    //     subItemsSidebar: [
    //         {
    //             title: '保護者一覧',
    //             link: '/parents',
    //         },
    //         // {
    //         //     title: '保護者詳細',
    //         //     link: '#',
    //         // },
    //     ],
    // },
    {
        iconUrl: multiStrokesIcon,
        title: '商品管理',
        subItemsSidebar: [
            {
                title: 'チラシ情報登録',
                link: '/flyer-registration',
            },
            // {
            //     title: 'チラシ商品登録',
            //     link: '#',
            // },
            {
                title: 'チラシ情報一覧',
                link: '/flyers',
            },
        ],
    },
    {
        iconUrl: cartIcon,
        title: '注文管理',
        subItemsSidebar: [
            {
                title: '注文一覧',
                link: '/orders',
            },
            // {
            //     title: '商品マスタ取込',
            //     link: '#',
            // },
            // {
            //     title: '生徒用品購入一覧情報(β2)',
            //     link: '#',
            // },
        ],
    },
    // {
    //     iconUrl: diagramIcon,
    //     title: '実績管理',
    //     subItemsSidebar: [
    //         {
    //             title: '実績',
    //             link: '#',
    //         },
    //         {
    //             title: 'ユーザ行動実績',
    //             link: '#',
    //         },
    //         {
    //             title: 'Push配信実績',
    //             link: '#',
    //         },
    //         {
    //             title: '広告実績',
    //             link: '#',
    //         },
    //     ],
    // },
    {
        iconUrl: investorIcon,
        title: '学校担当者管理',
        subItemsSidebar: [
            // {
            //     title: '学校担当者登録',
            //     link: '#',
            // },
            {
                title: '学校担当者一覧',
                link: '/school-staff',
            },
        ],
    },
    {
        iconUrl: shopIcon,
        title: '販売店管理',
        subItemsSidebar: [
            {
                title: '販売店登録',
                link: '#',
            },
            {
                title: '販売店一覧',
                link: '/shops',
            },
        ],
    },
    {
        iconUrl: salesRepresentativeIcon,
        title: '販売店担当者管理',
        subItemsSidebar: [
            // {
            //     title: '販売店担当者登録',
            //     link: '#',
            // },
            {
                title: '販売店担当者一覧',
                link: '/staff-list',
            },
        ],
    },
    // {
    //     iconUrl: notificationIcon,
    //     title: 'お知らせ管理',
    //     subItemsSidebar: [
    //         {
    //             title: 'お知らせ登録',
    //             link: '/notification-registration',
    //         },
    //         {
    //             title: 'お知らせ一覧',
    //             link: '/notifications',
    //         },
    //     ],
    // },
    {
        iconUrl: groupIcon,
        title: '会員管理',
        subItemsSidebar: [
            {
                title: '生徒一覧',
                link: '/students',
            },
        ],
    },
];
