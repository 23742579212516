<template>
    <BaseDialog class="wrapper__dialog--create" :title="t('shopStaff.dialog.titleCreate')">
        <template #default>
            <Teleport to="body">
                <BaseLoading :loading="isLoading"></BaseLoading>
            </Teleport>

            <div class="shop__information--list">
                <p class="shop__information--item">{{ shopStaffStores?.shopAddress }}</p>
                <p class="shop__information--item">{{ shopStaffStores?.shopName }}</p>
            </div>
            <div class="input--list">
                <div class="input--item">
                    <label for="lastName">{{ t('shopStaff.dialog.lastName') }}</label>
                    <div class="input__wrapper">
                        <input id="lastName" v-model.trim="lastName" :required="validateForm.lastName" />
                        <p v-if="validateForm.lastName" class="validate--item">
                            {{ t('error.validateMessage8', { name: t('shopStaff.dialog.lastName') }) }}
                        </p>
                    </div>
                </div>
                <div class="input--item">
                    <label for="firstName">{{ t('shopStaff.dialog.firstName') }}</label>
                    <div class="input__wrapper">
                        <input id="firstName" v-model.trim="firstName" :required="validateForm.firstName" />
                        <p v-if="validateForm.firstName" class="validate--item">
                            {{ t('error.validateMessage8', { name: t('shopStaff.dialog.firstName') }) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="input__email">
                <div class="input__email--item">
                    <label for="emailAddress">{{ t('shopStaff.dialog.emailAddress') }}</label>
                    <div class="input__wrapper--email">
                        <input id="emailAddress" v-model.trim="emailAddress" :required="validateForm.emailAddress" type="email" />
                        <p v-if="validateForm.emailAddress === 1" class="validate--item">
                            {{ t('error.validateMessage8', { name: t('shopStaff.dialog.emailAddress') }) }}
                        </p>
                        <p v-if="validateForm.emailAddress === 2" class="validate--item">
                            {{ t('error.validateMessage1') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="action--list">
                <TheButton class="action--item" @click="handleCancel">{{ t('shopStaff.dialog.labelCancelButton') }}</TheButton>
                <TheButton class="action--item" @click="handleCreate">{{ t('shopStaff.dialog.labelRegisterButton') }}</TheButton>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import BaseLoading from '../../../../components/loading/BaseLoading.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useShopStaffStores } from '../../../../stores/ShopStaffStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import ShopStaffService from '../../../../services/ShopStaffService.js';
import useFetch from '../../../../utils/useFetch.js';
import { checkValidate, isValidEmail } from '../../../../utils/Helpers.js';
// i18n
const { t } = useI18n();

// stores
const shopStaffStores = useShopStaffStores();
const userStore = useUserStore();
// service
const { isError, errorMessage, fetchData, data, isLoading } = useFetch(ShopStaffService?.registerShopStaff);

// ref
const lastName = ref('');
const firstName = ref('');
const emailAddress = ref('');
const validateForm = reactive({
    lastName: false,
    firstName: false,
    emailAddress: 0,
});

// function
const handleCancel = () => {
    lastName.value = '';
    firstName.value = '';
    emailAddress.value = '';
    validateForm.lastName = false;
    validateForm.firstName = false;
    validateForm.emailAddress = false;
    shopStaffStores?.setIsModalCreate(false);
};
const handleCreate = async () => {
    if (userStore?.userInfo?.affiliationKbn === 1) {
        if (!lastName.value) {
            validateForm.lastName = true;
        }
        if (!firstName.value) {
            validateForm.firstName = true;
        }
        if (!emailAddress.value) {
            validateForm.emailAddress = 1;
        } else {
            if (!isValidEmail(emailAddress.value)) {
                validateForm.emailAddress = 2;
            }
        }
        if (checkValidate(validateForm)) {
            await fetchData({
                shopCode: shopStaffStores?.shopCode,
                userFamName: lastName.value,
                userName: firstName.value,
                mailAddress: emailAddress.value,
                userId: userStore?.userId,
            });
        }
    }
};
// watch
watch(isError, (newValue) => {
    if (newValue) {
        shopStaffStores?.setIsNotice(true);
        shopStaffStores?.setNoticeError(errorMessage.value);
        shopStaffStores?.setTitleNotice(t('shopStaff.dialog.titleCreate'));
    }
});

watch(data, (newValue) => {
    shopStaffStores?.setTitleNotice(t('shopStaff.dialog.titleCreate'));
    if (newValue?.status === 0) {
        shopStaffStores?.setIsNotice(true);
        shopStaffStores?.setNoticeError(t('shopStaff.dialog.contentSuccess1'));
        shopStaffStores?.setIsSearchStaff();
        handleCancel();
    }
});

// listen validate
watch(lastName, () => {
    validateForm.lastName = false;
});
watch(firstName, () => {
    validateForm.firstName = false;
});
watch(emailAddress, () => {
    validateForm.emailAddress = 0;
});
</script>

<style lang="css" src="./BaseDialogCreate.css" scoped></style>
