import TheTitle from '../../../../components/title/TheTitle.vue';
<template>
    <div class="wrapper__flyer__list">
        <div class="flyer__list--title">
            <TheTitle class="title">
                <h1>{{ t('flyers.titleList') }}</h1>
            </TheTitle>
            <div class="acction__button--header">
                <TheButton
                    v-if="userStore?.userInfo?.affiliationKbn !== 2"
                    :disabled="userStore?.userInfo?.affiliationKbn === 2"
                    variant="outlined"
                    @click="handleUploadFile"
                >
                    {{ t('flyers.labelImportCSV') }}
                </TheButton>
                <TheButton :disabled="userStore?.userInfo?.affiliationKbn === 2" @click="handleRegistrationNavigation">
                    {{ t('flyers.labelRegister') }}
                </TheButton>
                <img
                    :src="commonStores?.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </div>
        <FlyerList></FlyerList>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import FlyerList from './FlyerList.vue';
import { useCommonStores } from '../../../../stores/CommonStores';
import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';
import { useUserStore } from '../../../../stores/UserStore';

// i18n
const { t } = useI18n();

// router
const router = useRouter();

// stores
const commonStores = useCommonStores();
const userStore = useUserStore();

// function
const handleUploadFile = () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        router.push('/flyer-registration/upload-file');
    }
};

const handleRegistrationNavigation = () => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        router.push('/flyer-registration');
    }
};

const handleChangeSize = () => {
    commonStores?.setIsExpand(!commonStores?.isExpand);
};
</script>

<style lang="css" src="./BaseFlyerList.css" scoped></style>
