import { http } from '../utils/Axios';
import { networkErrorMsg } from '../utils/Consts';

class OrdersService {
    static async getSearchOrderBoxList(params) {
        try {
            const response = await http.get('/getSearchOrderBoxList', { params });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }

    static async getOrderList(searchParams) {
        try {
            const response = await http.get('/getOrderList', {
                params: {
                    ...searchParams,
                    offset: (searchParams.page - 1) * searchParams.limit,
                    page: undefined,
                },
            });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }

    static async exportOrderListByProduct(searchParams) {
        try {
            const response = await http.get('/exportOrderListByProduct', { params: searchParams });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }

    static async exportOrderListByStudent(searchParams) {
        try {
            const response = await http.get('/exportOrderListByStudent', { params: searchParams });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }

    static async updateOrderStatus(searchParams) {
        try {
            const response = await http.get('/updateOrderStatus', { params: searchParams });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }
}

export default OrdersService;
