<template>
    <div class="wrapper__base-error-message">
        <ErrorMessageList :error-messages="props?.errorMessages"></ErrorMessageList>
    </div>
</template>

<script setup>
import ErrorMessageList from './ErrorMessageList.vue';

const props = defineProps({
    errorMessages: {
        type: Array,
        required: true,
    },
});
</script>

<style src="./BaseErrorMessage.css" scoped></style>
