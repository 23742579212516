<template>
    <header class="wrapper__product-registration--header">
        <TheTitle class="header__title">
            <h1>
                {{
                    productRegistrationStores?.registerStatus === 0
                        ? t('productRegistration.headerTitle1')
                        : t('productRegistration.headerTitle2')
                }}
            </h1>
        </TheTitle>
        <div class="expand__item">
            <img :src="commonStores?.isExpand ? expandIcon : collapseIcon" alt="maximize-icon" @click="handleChangeSize" />
        </div>
    </header>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import { useCommonStores } from '../../../../stores/CommonStores.js';
import { useProductRegistrationStores } from '../../../../stores/ProductRegistrationStores.js';

import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';

// i18n
const { t } = useI18n();

// stores
const commonStores = useCommonStores();
const productRegistrationStores = useProductRegistrationStores();

// function
const handleChangeSize = () => {
    commonStores?.setIsExpand(!commonStores?.isExpand);
};
</script>

<style lang="css" src="./HeaderProductRegistration.css" scoped></style>
