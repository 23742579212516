<template>
    <div class="teacher__list--content">
        <header class="header">
            <div class="header-item">
                <p class="item--label">{{ t('schoolStaff.table.label1') }}</p>
                <span>{{ provinceName[prefectureNum] || '' }}</span>
            </div>
            <div class="header-item">
                <p class="item--label">{{ t('schoolStaff.table.label2') }}</p>
                <span>{{ schoolAddress }}</span>
            </div>
            <div class="header-item">
                <p class="item--label">{{ t('schoolStaff.table.label3') }}</p>
                <span>{{ schoolName }}</span>
            </div>
        </header>
        <div class="teacher__list--body">
            <Teleport to="body">
                <component :is="DialogCustom" :show="schoolStaffStores?.isNotice" :z-index="991">
                    <BaseDialog :title="schoolStaffStores?.titleNotice" :content="schoolStaffStores?.noticeError">
                        <template #default>
                            <div class="button__hidden--notice">
                                <TheButton @click="() => schoolStaffStores?.setIsNotice(false)">OK</TheButton>
                            </div>
                        </template>
                    </BaseDialog>
                </component>
            </Teleport>
            <Teleport to="body">
                <component :is="DialogCustom" :show="schoolStaffStores?.isModalDelete" :z-index="990">
                    <BaseDialogDelete :staff-name="infoUser?.staffName" :user-id="infoUser?.userId"></BaseDialogDelete>
                </component>
            </Teleport>
            <Teleport to="body">
                <component :is="DialogCustom" :show="schoolStaffStores?.isModalCreate" :z-index="990">
                    <BaseDialogCreate
                        :location="prefectureNum"
                        :school-address="schoolAddress"
                        :school-name="schoolName"
                    ></BaseDialogCreate>
                </component>
            </Teleport>
            <TheTable :columns="columns" :data="teacherList" class="teacher__list--table" />
            <div class="search__status">
                <!-- Empty -->
                <div v-if="isLoading" class="table__item--center">
                    <div class="custom-loader"></div>
                </div>
                <!-- Loading -->
                <div v-else-if="teacherList.length === 0 && schoolName && errorCode !== 'E0004'" class="table__item--center">
                    <img src="../../../../assets/icons/empty_folder.svg" alt="empty-folder-icon" />
                    <p class="empty_list">{{ t('error.emptyMessage') }}</p>
                </div>
                <!-- Error -->
                <div v-else-if="isError && errorCode === 'E0004'" class="table__item--center">
                    <img src="../../../../assets/icons/empty_folder.svg" alt="empty-folder-icon" />
                    <p class="empty_list">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
        <div v-if="teacherList?.length > 0" class="teacher__list--footer">
            <div class="number__of--page">
                <span>{{ t('common.searchResult') }}</span>
                <SelectField :model-value="limit" @update:model-value="handleChangeLimit" />
                <span>
                    {{
                        t('common.showSearchResult', {
                            total: totalRecords,
                            start: (currPage - 1) * limit + 1,
                            end: currPage * limit > totalRecords ? totalRecords : currPage * limit,
                        })
                    }}
                </span>
            </div>
            <div>
                <ThePagination :total="totalPage" :curr-page="currPage" @change="handleChangePage" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, h, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTable from '../../../../components/table/TheTable.vue';
import HeaderItem from '../../../../components/table/HeaderItem.vue';
import ThePagination from '../../../../components/pagination/ThePagination.vue';
import SelectField from '../../../../components/select/SelectField.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import BaseDialogDelete from '../dialog/BaseDialogDelete.vue';
import BaseDialogCreate from '../dialog/BaseDialogCreate.vue';
import SchoolStaffService from '../../../../services/SchoolStaffService.js';
import { SORT_TYPE, provinceName } from '../../../../utils/Consts';
import { useSchoolStaffStores } from '../../../../stores/SchoolStaffStores.js';
import useFetch from '../../../../utils/useFetch.js';
import './GlobalCSS.css';

// i18n
const { t } = useI18n();

// store
const schoolStaffStores = useSchoolStaffStores();

// data header
const columnsData = [
    {
        key: 'staffName',
        title: t('schoolStaff.table.staffName'),
        styles: {
            minWidth: '30%',
            maxWidth: '30%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'mailAddress',
        title: t('schoolStaff.table.mailAddress'),
        styles: {
            minWidth: '50%',
            maxWidth: '50%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'action',
        title: '',
        styles: {
            minWidth: '20%',
            maxWidth: '20%',
            display: 'flex',
            justifyContent: 'right',
            paddingRight: '2%',
        },
        render: (column, item) =>
            h(
                TheButton,
                {
                    class: 'button__delete',
                    style: {
                        backgroundColor: '#ed5e68',
                    },
                    onClick: () => handleShowModalDelete(item),
                },
                t('schoolStaff.labelDeleteUser'),
            ),
    },
];

// ref
const currPage = ref(1);
const limit = ref(30);
const params = ref({});
const columns = ref(columnsData);
const teacherList = ref([]);
const totalPage = ref(0);
const totalRecords = ref(0);
const prefectureNum = ref('');
const schoolAddress = ref('');
const schoolName = ref('');
const infoUser = ref();

// fetch data
const { isLoading, isError, errorMessage, errorCode, data, fetchData } = useFetch(SchoolStaffService?.getSchoolStaffList);

const headerElement = (column, sortType, isActive) => {
    return h(HeaderItem, {
        title: column?.title,
        keyColumn: column?.key,
        sortType,
        isActive,
        onHandleClick: (key, sortType, isActive) => {
            handleSortColumn(key, sortType, isActive);
        },
    });
};

const handleSortColumn = (key, sortType, isActive) => {
    columns.value = columnsData.map((e) => {
        if (e?.key === key) {
            if (teacherList.value?.length === 0) return e;
            let type = sortType || SORT_TYPE.SORT_UP;
            let active = isActive || false;
            if (sortType === SORT_TYPE.SORT_UP) {
                type = active ? SORT_TYPE.SORT_DOWN : SORT_TYPE.SORT_UP;
                active = true;
            } else if (sortType === SORT_TYPE.SORT_DOWN) {
                type = SORT_TYPE.SORT_UP;
                active = false;
            }
            params.value = { ...params.value, sortField: active ? key : '', sortType: active ? type : '', page: 1 };
            currPage.value = 1;
            return {
                ...e,
                renderHeader: (column) => headerElement(column, type, active),
            };
        }
        return e;
    });
};

// function
const handleChangePage = (newPage) => {
    currPage.value = newPage;
    params.value = {
        ...params.value,
        limit: limit.value,
        sortField: '',
        sortType: '',
        offset: (currPage.value - 1) * limit.value,
    };
};
const handleChangeLimit = (newLimit) => {
    limit.value = newLimit;
    params.value = { ...params.value, limit: limit.value };
};
const handleShowModalDelete = (item) => {
    infoUser.value = item;
    schoolStaffStores?.setIsModalDelete(true);
};

// watch
watch(
    () => schoolStaffStores?.isSearch,
    (newValue) => {
        params.value = {
            sortField: '',
            sortType: '',
            ...params.value,
            ...schoolStaffStores?.params,
            offset: 0,
            limit: limit.value,
        };
    },
);

watch(params, (newValue) => {
    teacherList.value = [];
    schoolStaffStores?.setActiveRegistration(false);
    schoolStaffStores?.setErrorSearchMessage('');
    fetchData(newValue);
});

watch(isError, (newValue) => {
    if (newValue) {
        teacherList.value = [];
        prefectureNum.value = '';
        schoolAddress.value = '';
        schoolName.value = '';
        schoolStaffStores?.setActiveRegistration(false);
        if (!schoolStaffStores?.params?.schoolCode) {
            schoolStaffStores?.setErrorSearchMessage(errorMessage.value);
        } else {
            schoolStaffStores?.setErrorSearchMessage('');
        }
    }
});

watch(data, (newValue) => {
    if (newValue) {
        teacherList.value = newValue?.result?.data?.userLists;
        totalPage.value = newValue?.result?.data?.totalPages;
        totalRecords.value = newValue?.result?.data?.totalRecords;
        prefectureNum.value = Number(newValue?.result?.data?.prefectureNum);
        schoolAddress.value = newValue?.result?.data?.schoolAddress;
        schoolName.value = newValue?.result?.data?.schoolName;
        schoolStaffStores?.setActiveRegistration(true);
    }
});
</script>

<style lang="css" src="./TeacherList.css" scoped></style>
