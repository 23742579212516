<template>
    <input
        class="date__picker--input"
        :style="{ cursor: hide ? 'no-drop' : 'pointer' }"
        :value="props?.date"
        :disabled="hide"
        type="date"
        @change="(e) => changeDateValue(e)"
    />
</template>

<script setup>
const emits = defineEmits(['updateDate']);
const props = defineProps({
    date: {
        type: String,
        required: true,
    },
    hide: {
        type: Boolean,
        required: false,
    },
});
const changeDateValue = (e) => {
    emits('updateDate', e.target.value);
};
</script>

<style lang="css" src="./DatePicker.css" scoped></style>
