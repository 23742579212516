<!-- eslint-disable max-len -->
<template>
    <div class="staff__list--content">
        <header class="header">
            <div class="header-item">
                <p class="item--label">{{ t('shopStaff.table.label1') }}</p>
                <p class="item--name">{{ shopStaffStores?.shopName }}</p>
            </div>
            <div class="header-item">
                <p class="item--label">{{ t('shopStaff.table.label2') }}</p>
                <p class="item--name">{{ shopStaffStores?.shopAddress }}</p>
            </div>
        </header>
        <div class="staff__list--body">
            <Teleport to="body">
                <component :is="DialogCustom" :show="shopStaffStores?.isNotice" :z-index="991">
                    <BaseDialog :title="shopStaffStores?.titleNotice" :content="shopStaffStores?.noticeError">
                        <template #default>
                            <div class="button__hidden--notice">
                                <TheButton @click="handleHiddenNotice">OK</TheButton>
                            </div>
                        </template>
                    </BaseDialog>
                </component>
            </Teleport>
            <Teleport to="body">
                <component :is="DialogCustom" :show="shopStaffStores?.isModalDelete" :z-index="990">
                    <BaseDialogDelete :staff-name="userInfo?.staffName" :user-id="userInfo?.userId"></BaseDialogDelete>
                </component>
            </Teleport>
            <Teleport to="body">
                <component :is="DialogCustom" :show="shopStaffStores?.isModalCreate" :z-index="990">
                    <BaseDialogCreate></BaseDialogCreate>
                </component>
            </Teleport>
            <TheTable :columns="columns" :data="staffList" class="staff__list--table" />
            <div class="search__status">
                <!-- Empty -->
                <div v-if="isError && errorCode === 'E0004'" class="table__item--center">
                    <img src="../../../../assets/icons/empty_folder.svg" alt="empty-folder-icon" />
                    <p class="empty_list">{{ errorMessage }}</p>
                </div>
                <!-- Error -->
                <div v-if="isError && errorCode !== 'E0004'" class="table__item--center">
                    <img src="../../../../assets/icons/error-icon.svg" alt="error-icon" />
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <!-- Loading -->
                <div v-if="isLoading" class="table__item--center">
                    <div class="custom-loader"></div>
                </div>
            </div>
        </div>
        <div v-if="staffList?.length > 0" class="staff__list--footer">
            <div class="number__of--page">
                <span>{{ t('common.searchResult') }}</span>
                <SelectField :model-value="limit" @update:model-value="handleChangeLimit" />
                <span>
                    {{
                        t('common.showSearchResult', {
                            total: totalRecords,
                            start: (currPage - 1) * limit + 1,
                            end: currPage * limit > totalRecords ? totalRecords : currPage * limit,
                        })
                    }}
                </span>
            </div>
            <div>
                <ThePagination :total="totalPage" :curr-page="currPage" @change="handleChangePage" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, h, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTable from '../../../../components/table/TheTable.vue';
import HeaderItem from '../../../../components/table/HeaderItem.vue';
import ThePagination from '../../../../components/pagination/ThePagination.vue';
import SelectField from '../../../../components/select/SelectField.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import BaseDialogDelete from '../dialog/BaseDialogDelete.vue';
import BaseDialogCreate from '../dialog/BaseDialogCreate.vue';
import ShopStaffService from '../../../../services/ShopStaffService.js';
import { SORT_TYPE } from '../../../../utils/Consts';
import { useShopStaffStores } from '../../../../stores/ShopStaffStores';
import useFetch from '../../../../utils/useFetch.js';
import './GlobalCSS.css';
// i18n
const { t } = useI18n();

// store
const shopStaffStores = useShopStaffStores();

// data header
const columnsData = [
    {
        key: 'staffName',
        title: t('shopStaff.table.shopName'),
        styles: {
            minWidth: '35%',
            maxWidth: '35%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'mailAddress',
        title: t('shopStaff.table.emailAddress'),
        styles: {
            minWidth: '55%',
            maxWidth: '55%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'action',
        title: '',
        styles: {
            minWidth: '10%',
            maxWidth: '10%',
            display: 'flex',
            justifyContent: 'right',
            paddingRight: '2%',
        },
        render: (column, item) =>
            h(
                TheButton,
                {
                    class: 'button__delete',
                    style: {
                        backgroundColor: '#ed5e68',
                        minWidth: 'max-content',
                    },
                    onClick: () => handleShowModalDelete(item),
                },
                t('shopStaff.labelDeleteUser'),
            ),
    },
];

// ref
const currPage = ref(1);
const limit = ref(30);
const params = ref({});
const columns = ref(columnsData);
const staffList = ref([]);
const totalPage = ref(0);
const totalRecords = ref(0);
const userInfo = ref();

// fetch data
const { isLoading, isError, errorMessage, errorCode, data, fetchData } = useFetch(ShopStaffService?.getShopStaffList);

const headerElement = (column, sortType, isActive) => {
    return h(HeaderItem, {
        title: column?.title,
        keyColumn: column?.key,
        sortType,
        isActive,
        onHandleClick: (key, sortType, isActive) => {
            handleSortColumn(key, sortType, isActive);
        },
    });
};

const handleSortColumn = (key, sortType, isActive) => {
    columns.value = columnsData.map((e) => {
        if (e?.key === key) {
            if (staffList.value?.length === 0) return e;
            let type = sortType || SORT_TYPE.SORT_UP;
            let active = isActive || false;
            if (sortType === SORT_TYPE.SORT_UP) {
                type = active ? SORT_TYPE.SORT_DOWN : SORT_TYPE.SORT_UP;
                active = true;
            } else if (sortType === SORT_TYPE.SORT_DOWN) {
                type = SORT_TYPE.SORT_UP;
                active = false;
            }
            params.value = { ...params.value, sortField: active ? key : '', sortType: active ? type : '', page: 1 };
            currPage.value = 1;
            return {
                ...e,
                renderHeader: (column) => headerElement(column, type, active),
            };
        }
        return e;
    });
};

// function
const handleChangePage = (newPage) => {
    currPage.value = newPage;
    params.value = { ...params.value, offset: (currPage.value - 1) * limit.value };
};
const handleChangeLimit = (newLimit) => {
    limit.value = newLimit;
    params.value = { ...params.value, limit: limit.value };
};
const handleShowModalDelete = (item) => {
    userInfo.value = item;
    shopStaffStores?.setIsModalDelete(true);
};
const handleHiddenNotice = () => {
    shopStaffStores?.setIsNotice(false);
};

// watch
watch(
    () => shopStaffStores?.isSearchStaff,
    (newValue) => {
        params.value = {
            procType: 1,
            sortField: '',
            sortType: '',
            ...params.value,
            ...shopStaffStores?.staffParams,
            limit: limit.value,
            offset: 0,
        };
    },
);

watch(params, (newValue) => {
    fetchData(newValue);
});

watch(isError, (newValue) => {
    staffList.value = [];
});

watch(data, (newValue) => {
    staffList.value = newValue?.result?.data?.shopStaffList;
    totalPage.value = newValue?.result?.data?.totalPages;
    totalRecords.value = newValue?.result?.data?.totalRecords;
});
watch(
    () => shopStaffStores?.isSearchShop,
    (newValue) => {
        staffList.value = [];
        shopStaffStores?.setShopName('');
        shopStaffStores?.setShopAddress('');
        shopStaffStores?.setStaffParams({});
        isError.value = false;
    },
);
</script>

<style lang="css" src="./StaffList.css" scoped></style>
