<template>
    <div class="product__action" :class="{ disabled: props?.disabled }" @click="handleRedirectToUpdate">
        {{ t('products.updateButtonContent') }}
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

// i18n
const { t } = useI18n();

// router
const router = useRouter();
const route = useRoute();

const props = defineProps({
    productCode: {
        type: String,
    },
    flyerCode: {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
});

const handleRedirectToUpdate = () => {
    if (!props?.disabled) {
        router.push(
            // eslint-disable-next-line max-len
            `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
                route.params?.shopCode,
            )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(
                route.params?.schoolCode,
            )}/${encodeURIComponent(route.params?.schoolYear)}/products/${encodeURIComponent(props?.productCode)}/update`,
        );
    }
};
</script>

<style lang="css" src="./UpdateRow.css" scoped></style>
