/* eslint no-useless-escape: "off"*/
import Encoding from 'encoding-japanese';
import { formatDateOnly } from './Helpers';

const csvErrorMsg = {
    DATA_REQUIRED: 'Data for csv is required',
    INVALID_HEADER: 'Headers for csv should be a string array',
    INVALID_DATA: 'Data for csv should be a array',
    INVALID_ELEMENT: 'The elemet of data of for csv should be a array',
};

export const convertToCSV = (data, headers) => {
    if (!data) throw new Error(csvErrorMsg.DATA_REQUIRED);

    if (!Array.isArray(headers)) throw new Error(csvErrorMsg.INVALID_HEADER);
    data.unshift(headers);

    if (!Array.isArray(data)) throw new Error(csvErrorMsg.INVALID_DATA);
    const covertedCSV = data
        .map((row) => {
            if (!Array.isArray(row)) throw new Error(csvErrorMsg.INVALID_ELEMENT);

            const handledEscapeLetterArr = row.map((e) => {
                if (!e) return '';
                if (typeof e !== 'string' && typeof e !== 'number') return '[unknow]';
                if (typeof e === 'number') return e;
                if (typeof e === 'string') {
                    // Validate double-quotes
                    e = e.replace(/\"/g, '""');

                    // Validate comma
                    e = `"${e.replace(/\\"/g, '""')}"`;

                    return e;
                }
                return '';
            });

            return handledEscapeLetterArr.join(',');
        })
        .join('\n');

    return covertedCSV;
};

export const convertToShiftJIS = function (target) {
    const uniArray = Encoding.stringToCode(target);
    const sjisArray = Encoding.convert(uniArray, {
        to: 'SJIS',
        from: 'UNICODE',
    });

    const unit8Array = new Uint8Array(sjisArray);
    return unit8Array;
};

const EXPORT_FLYER_HEADER = [
    'チラシコード',
    '学校コード',
    '学校名',
    '学年',
    'クラス',
    'チラシ名',
    '開始日',
    '終了日',
    'リマインド配信日1',
    'リマインド配信日2',
    'リマインド配信日3',
    '通知メッセージ',
    '納品予定',
];

const EXPORT_TEMPLATE = `【説明】
上記の2行目のデータは、ダウンロード元のチラシ情報です。アップロードの際には無視されます。
以下の情報がアップロード対象の情報になります。12行目以降のデータが登録されます。
ダウンロード元のチラシに紐付く商品情報も登録されます。ダウンロード元のチラシに紐付く商品情報については商品一覧画面から確認して下さい。
【注意事項】
チラシコードは変更できません。
学校名はアップロード時に無視されます。学校コードを正しく入力してください。
販売店に紐付く学校が全て記載されています。不要な学校がある場合は、その行を削除して下さい。
チラシコード,学校コード,学校名,学年,クラス,チラシ名,開始日,終了日,リマインド配信日1,リマインド配信日2,リマインド配信日3,通知メッセージ,納品予定`;

export const convertDatatoText = (flyerInfo, schoolInfo) => {
    let text =
        EXPORT_FLYER_HEADER.toString() +
        '\n' +
        flyerInfo.flyerCode +
        ',' +
        flyerInfo.schoolCode +
        ',' +
        flyerInfo.schoolName +
        ',' +
        flyerInfo.schoolYear +
        ',' +
        (flyerInfo.schoolClass || '全て') +
        ',' +
        flyerInfo.flyerName +
        ',' +
        formatDateOnly(flyerInfo.flyerStartDate) +
        ',' +
        formatDateOnly(flyerInfo.flyerEndDate) +
        ',' +
        formatDateOnly(flyerInfo.notificationRemindDate1) +
        ',' +
        formatDateOnly(flyerInfo.notificationRemindDate2) +
        ',' +
        formatDateOnly(flyerInfo.notificationRemindDate3) +
        ',' +
        `"${flyerInfo.notificationMessage}"` +
        ',' +
        (flyerInfo.flyerSupplement || '') +
        '\n' +
        EXPORT_TEMPLATE +
        '\n';
    // row 12 -> ...
    schoolInfo.map((school) => {
        text =
            text +
            flyerInfo.flyerCode +
            ',' +
            school.schoolCode +
            ',' +
            school.schoolName +
            ',' +
            flyerInfo.schoolYear +
            ',' +
            (flyerInfo.schoolClass || '全て') +
            ',' +
            flyerInfo.flyerName +
            ',' +
            formatDateOnly(flyerInfo.flyerStartDate) +
            ',' +
            formatDateOnly(flyerInfo.flyerEndDate) +
            ',' +
            formatDateOnly(flyerInfo.notificationRemindDate1) +
            ',' +
            formatDateOnly(flyerInfo.notificationRemindDate2) +
            ',' +
            formatDateOnly(flyerInfo.notificationRemindDate3) +
            ',' +
            `"${flyerInfo.notificationMessage}"` +
            ',' +
            (flyerInfo.flyerSupplement || '') +
            '\n';
    });

    return text;
};
