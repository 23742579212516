<template>
    <div class="wrapper__input-image--item">
        <label class="input--item__label">
            {{ props?.label }}
            <strong v-if="props?.optional">{{ t('common.required') }}</strong>
        </label>
        <div class="input--image__slot">
            <slot></slot>
            <p v-if="props?.required" class="validate__message--image">{{ t('error.validateMessage5') }}</p>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

// i18n
const { t } = useI18n();

// stores

const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
    optional: {
        type: Boolean,
        default: true,
    },
});
</script>

<style lang="css" src="./ImageItem.css" scoped></style>
