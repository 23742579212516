<template>
    <li
        v-if="props?.accept"
        class="wrapper_item-sidebar"
        :class="{
            active: keyActive === sidebarStore?.keyActive,
            expand: sidebarStore?.expand,
            collapse: !sidebarStore?.expand,
        }"
    >
        <div class="item-sidebar" @click="handleKeyActive(keyActive)">
            <div class="icon__sidebar">
                <img :src="iconUrl" alt="icon-url" />
            </div>

            <div
                v-if="sidebarStore?.expand"
                class="content__sidebar"
                :class="{
                    active: keyActive === sidebarStore?.keyActive,
                    expand: sidebarStore?.expand,
                }"
            >
                {{ props?.title }}
            </div>

            <transition name="collapse">
                <div
                    v-if="!sidebarStore?.expand && keyActive === sidebarStore?.keyActive"
                    class="content__sidebar"
                    :class="{ collapse: !sidebarStore?.expand }"
                >
                    {{ props?.title }}
                </div>
            </transition>

            <div v-if="sidebarStore?.expand" class="icon__show-hiden">
                <svg
                    :class="keyActive === sidebarStore?.keyActive ? 'show' : 'hiden'"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M6 12L10 8L6 4" stroke="#E2EEFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>

        <transition mode="out-in" :name="sidebarStore?.expand ? 'expand' : 'collapse'">
            <div
                v-if="keyActive === sidebarStore?.keyActive"
                class="sub-sidebar"
                :class="{
                    expand: sidebarStore?.expand,
                    collapse: !sidebarStore?.expand,
                }"
            >
                <SidebarSubItem
                    v-for="(subItem, index) in props.subItemsSidebar"
                    :key="index"
                    :accept="props?.subAccept[index]?.accept"
                    :title="subItem?.title"
                    :link="subItem?.link"
                    :key-active="keyActive"
                />
            </div>
        </transition>
    </li>
</template>

<script setup>
import { useSidebarStore } from '../../stores/SidebarStore.js';
import SidebarSubItem from './SidebarSubItem.vue';

// store
const sidebarStore = useSidebarStore();

// props
const props = defineProps({
    iconUrl: {
        type: String,
        require: true,
    },
    title: {
        type: String,
        require: true,
    },
    subItemsSidebar: {
        type: Array,
        require: true,
    },
    keyActive: {
        type: Number,
        require: true,
    },
    accept: {
        type: Boolean,
        require: true,
    },
    subAccept: {
        type: Array,
        require: true,
    },
});

// functions
const handleKeyActive = (keyActive) => {
    if (keyActive === sidebarStore?.keyActive) {
        sidebarStore?.setKeyActive(null);
    } else {
        sidebarStore?.setKeyActive(keyActive);
    }
};
</script>

<style lang="css" src="./SidebarItem.css" scoped></style>
