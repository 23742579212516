<template>
    <dialog :show="props?.show" class="wrapper__dialog" :style="{ display: props?.show ? 'flex' : 'none', zIndex: zIndex }">
        <slot></slot>
    </dialog>
</template>

<script setup>
const props = defineProps({
    show: {
        type: Boolean,
        required: true,
        default: false,
    },
    zIndex: {
        type: Number,
        required: false,
        default: 20,
    },
});
</script>

<style lang="css" src="./DialogCustom.css" scoped></style>
