export const SORT_TYPE = {
    SORT_UP: 'ASC',
    SORT_DOWN: 'DESC',
};
export const orderStatusConst = {
    values: [1, 2, 3, 4, 5, 6],
    options: ['支払い待ち', '注文完了', 'キャンセル', '注文取消', '発注済', '納品済'],
};
export const orderStatus = {
    1: {
        badgeContent: '支払い待ち',
        color: '#FFB001',
        bgColor: '#FFFDEA',
        borderColor: '#FFF085',
    },
    2: {
        badgeContent: '注文完了',
        color: '#1AB722',
        bgColor: '#F0FDF0',
        borderColor: '#B9F9BB',
    },
    3: {
        badgeContent: 'キャンセル',
        color: '#334155',
        bgColor: '#e2e8f0',
        borderColor: 'rgba(51,65,85,0.2)',
    },
    4: {
        badgeContent: '注文取消',
        color: '#FF2F61',
        bgColor: '#FFEAF4',
        borderColor: '#FFCDE5',
    },
    5: {
        badgeContent: '発注済',
        color: '#19E2B0',
        bgColor: '#E7FFF9',
        borderColor: '#ADFFEB',
    },
    6: {
        badgeContent: '納品済',
        color: '#C755F7',
        bgColor: '#FBF4FF',
        borderColor: '#EECEFF',
    },
};
/**
 * 1: countWaitingPayment   -> 支払い待ち
 * 2: countOrderComplete    -> 注文完了
 * 3: countCancel           -> キャンセル
 * 4: countShopOrderCancel  -> 注文取消
 * 5: countShopOrdered      -> 発注済
 * 6: countShopDelivered    -> 納品済
 */
export const statusTag = [
    // 1
    {
        key: 'countWaitingPayment',
        description: '支払い待ち',
        color: '#FFB001',
        bgColor: '#FFFDEA',
        borderColor: '#FFF085',
        value: 1,
    },
    // 2
    {
        key: 'countOrderComplete',
        description: '注文完了',
        color: '#1AB722',
        bgColor: '#F0FDF0',
        borderColor: '#B9F9BB',
        value: 2,
    },
    // 5
    {
        key: 'countShopOrdered',
        description: '発注済',
        color: '#19E2B0',
        bgColor: '#E7FFF9',
        borderColor: '#ADFFEB',
        value: 5,
    },
    // 6
    {
        key: 'countShopDelivered',
        description: '納品済',
        color: '#C755F7',
        bgColor: '#FBF4FF',
        borderColor: '#EECEFF',
        value: 6,
    },
    // 4
    {
        key: 'countShopOrderCancel',
        description: '注文取消',
        color: '#FF2F61',
        bgColor: '#FFEAF4',
        borderColor: '#FFCDE5',
        value: 4,
    },
    // 3
    {
        key: 'countCancel',
        description: 'キャンセル',
        color: '#334155',
        bgColor: '#e2e8f0',
        borderColor: 'rgba(51,65,85,0.2)',
        value: 3,
    },
];
export const payType = {
    0: '決済なし',
    1: 'クレジット',
    2: 'PayPay',
    3: 'コンビニ決済',
};

export const networkErrorMsg = 'ネットワークエラー';
export const noticeCategoryConst = {
    options: ['選択ください', '重要', '周知', 'メンテナンス'],
    values: ['', 1, 2, 3],
};
export const notificationTargetConst = {
    options: ['選択ください', '全て', '販売店のみ', '学校のみ', '個別'],
    values: ['', 0, 1, 2, 9],
};
export const flyerStatus = {
    1: '未',
    2: '済',
    3: '削除',
};
export const taxCategoryConst = {
    options: ['選択ください', '消費税', '軽減税率', '非課税'],
    values: ['', 0, 1, 2],
};

export const taxCategoryMapping = {
    0: '消費税',
    1: '軽減税率',
    2: '非課税',
};

export const provinceName = {
    1: '北海道',
    2: '青森県',
    3: '岩手県',
    4: '宮城県',
    5: '秋田県',
    6: '山形県',
    7: '福島県',
    8: '茨城県',
    9: '栃木県',
    10: '群馬県',
    11: '埼玉県',
    12: '千葉県',
    13: '東京都',
    14: '神奈川県',
    15: '新潟県',
    16: '富山県',
    17: '石川県',
    18: '福井県',
    19: '山梨県',
    20: '長野県',
    21: '岐阜県',
    22: '静岡県',
    23: '愛知県',
    24: '三重県',
    25: '滋賀県',
    26: '京都府',
    27: '大阪府',
    28: '兵庫県',
    29: '奈良県',
    30: '和歌山県',
    31: '鳥取県',
    32: '島根県',
    33: '岡山県',
    34: '広島県',
    35: '山口県',
    36: '徳島県',
    37: '香川県',
    38: '愛媛県',
    39: '高知県',
    40: '福岡県',
    41: '佐賀県',
    42: '長崎県',
    43: '熊本県',
    44: '大分県',
    45: '宮崎県',
    46: '鹿児島県',
    47: '沖縄県',
};
