import { http } from '../utils/Axios';

class FlyerRegistrationService {
    static async registerFlyerInfor(body) {
        try {
            const response = await http.post('/registerFlyerInfor', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async updateFlyer(body) {
        try {
            const response = await http.put('/updateFlyerInfor', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerdDetail(params) {
        try {
            const response = await http.get('/getFlyerDetails', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerRegisterList(params) {
        try {
            const response = await http.get('/getFlyerRegisterList', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async deleteFlyerInfor(body) {
        try {
            const response = await http.delete('/deleteFlyerInfor', { data: body });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerRegisterShopSearch(params) {
        try {
            const response = await http.get('/getFlyerRegisterShopSearch', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerRegisterShopName(params) {
        try {
            const response = await http.get('/getFlyerRegisterShopName', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }
}

export default FlyerRegistrationService;
