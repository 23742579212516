<template>
    <BaseDialog class="wrapper__dialog--preview" title="プレビュー">
        <template #icon-close>
            <img class="icon__close" src="../../../../assets/icons/icon-close.svg" alt="icon-close" @click="handleCloseDialog" />
        </template>

        <template #default>
            <div class="smart__device">
                <PhoneDevice class="phone__preview" :message="flyerRegistrationStores?.message" :show="props?.show">
                    <template #image>
                        <img
                            v-if="flyerRegistrationStores?.urlMainImagePreview"
                            class="phone_image"
                            :src="flyerRegistrationStores?.urlMainImagePreview"
                            alt="main-image"
                        />
                        <img v-else :src="defaultThumbnail" alt="main-image" />
                    </template>
                    <template #imagesub>
                        <img
                            v-if="flyerRegistrationStores?.urlSubImagePreview"
                            :src="flyerRegistrationStores?.urlSubImagePreview"
                            alt="sub-image"
                        />
                        <img v-else :src="defaultThumbnail" alt="main-image" />
                    </template>
                </PhoneDevice>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import PhoneDevice from '../phoneDevice/PhoneDevice.vue';
import defaultThumbnail from '../../../../assets/img/default-thumbnail.jpg';

import { useFlyerRegistrationStores } from '../../../../stores/FlyerRegistrationStores';

// store
const flyerRegistrationStores = useFlyerRegistrationStores();

// props
const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
});

// function
const handleCloseDialog = () => {
    flyerRegistrationStores?.setIsModalPreview(false);
    flyerRegistrationStores?.setIsModalPreviewImage(false);
};
</script>

<style lang="css" src="./BaseDialogPhone.css" scoped></style>
