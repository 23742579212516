<template>
    <div class="smartphone">
        <div class="smartphone__header"></div>
        <div class="smartphone__app">
            <div class="smartphone__image" :class="{ image: !props?.show }">
                <div class="image__item">
                    <slot name="image"></slot>
                </div>
                <div v-if="!props?.show" class="smartphone__image--sub">
                    <div class="image__item">
                        <slot name="imagesub"></slot>
                    </div>
                </div>
            </div>
            <div v-if="props?.show" class="smartphone__content">
                <div class="user">
                    <img src="../../../../assets/img/conet_logo.png" alt="conet-logo" />
                </div>
                <p class="message__phone"></p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { watch } from 'vue';

const props = defineProps({
    message: {
        type: String,
        default: '',
        required: true,
    },
    show: {
        type: Boolean,
        default: false,
    },
});

watch(
    () => props?.message,
    (newValue) => {
        if (document?.querySelectorAll('.message__phone')) {
            document.querySelectorAll('.message__phone')[0].innerHTML = props?.message;
            document.querySelectorAll('.message__phone')[1].innerHTML = props?.message;
        }
    },
);
</script>

<style lang="css" src="./PhoneDevice.css" scoped></style>
