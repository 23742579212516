<template>
    <div class="shop__list--content">
        <div class="shop__list--body">
            <TheTable class="shop__list--table" :columns="columns" :data="shopList" />
            <div class="search__status">
                <!-- Empty -->
                <div v-if="data?.result?.data?.shopList?.length === 0" class="table__item--center">
                    <img src="../../../../assets/icons/empty_folder.svg" alt="empty-folder-icon" />
                    <p class="empty_list">{{ t('common.emptyList') }}</p>
                </div>
                <!-- Error -->
                <div v-if="isError" class="table__item--center">
                    <img src="../../../../assets/icons/error-icon.svg" alt="error-icon" />
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <!-- Loading -->
                <div v-if="isLoading" class="table__item--center">
                    <div class="custom-loader"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, h, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTable from '../../../../components/table/TheTable.vue';
import { useFlyerStores } from '../../../../stores/FlyerStores.js';
import useFetch from '../../../../utils/useFetch';
import FlyerRegistrationService from '../../../../services/FlyerRegistrationService';
import './GlobalCSS.css';
// i18n
const { t } = useI18n();

// service
const { isError, errorMessage, data, fetchData, isLoading } = useFetch(FlyerRegistrationService?.getFlyerRegisterShopSearch);

// store
const flyerStores = useFlyerStores();

// data header
const columnsData = [
    {
        key: 'action',
        title: '',
        styles: {
            display: 'flex',
            minWidth: '5.5%',
            maxWidth: '5.5%',
            textAlign: 'left',
            fontSize: '1rem',
        },
        render: (column, item) =>
            h(
                'p',
                {
                    style: {
                        color: '#1778ca',
                        textDecoration: ' underline',
                        cursor: 'pointer',
                    },
                    onClick: () => emits('handleChoiceShop', item),
                },
                t('flyerRegistration.dialog.table.labelButton1'),
            ),
    },
    {
        key: 'shopCode',
        title: t('flyerRegistration.dialog.table.labelHeader1'),
        styles: {
            display: 'flex',
            minWidth: '34%',
            maxWidth: '34%',
            justifyContent: 'flex-start',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            workWrap: 'break-word',
        },
    },
    {
        key: 'shopName',
        title: t('flyerRegistration.dialog.table.labelHeader2'),
        styles: {
            display: 'flex',
            minWidth: '22.5%',
            maxWidth: '22.5%',
            justifyContent: 'flex-start',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            workWrap: 'break-word',
        },
    },
    {
        key: 'address1',
        title: t('flyerRegistration.dialog.table.labelHeader3'),
        styles: {
            display: 'flex',
            minWidth: '22%',
            maxWidth: '22%',
            justifyContent: 'flex-start',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            workWrap: 'break-word',
        },
    },
    {
        key: 'representativeName',
        title: t('flyerRegistration.dialog.table.labelHeader4'),
        styles: {
            display: 'flex',
            minWidth: '14%',
            maxWidth: '14%',
            justifyContent: 'flex-start',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            workWrap: 'break-word',
        },
    },
];

// emits
const emits = defineEmits(['handleChoiceShop']);

const props = defineProps({
    paramsSearch: {
        type: Object,
        required: false,
    },
    close: {
        type: Boolean,
        required: false,
    },
});

// ref
const currPage = ref(1);
const limit = ref(30);
const params = ref({});
const columns = ref(columnsData);
const shopList = ref([]);

// watch
watch(
    () => props?.close,
    (newValue) => {
        shopList.value = [];
    },
);
watch(
    () => flyerStores?.isSearch,
    (newValue) => {
        params.value = {
            ...flyerStores?.params,
            limit: limit.value,
            sortField: '',
            sortType: '',
            offset: (currPage.value - 1) * limit.value,
        };
    },
);

watch(
    () => props?.paramsSearch,
    (newParams) => {
        fetchData(newParams);
    },
);

watch(isError, (newError) => {
    if (newError) {
        shopList.value = [];
    }
});

watch(data, (newData) => {
    shopList.value = newData?.result?.data?.shopList;
});
</script>

<style lang="css" src="./ShopList.css" scoped></style>
