import { http } from '../utils/Axios';

class ShopsService {
    static async getShopList(searchParams) {
        // ダミーデータ
        // const dummyData = require('./ShopsDummy.json');

        // userId が dummyUserId の場合はダミーデータを返す
        // if (searchParams.userId === 'dummyUserId') {
        //     return new Promise((resolve) => {
        //         setTimeout(() => resolve(dummyData), 500); // タイムアウトをシミュレートするために500ms待つ
        //     });
        // }

        try {
            const response = await http.get('/getShopList', {
                params: {
                    ...searchParams,
                    offset: (searchParams.page - 1) * searchParams.limit,
                    page: undefined,
                },
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getShop(shopCode) {
        // // ダミーデータ
        // const dummyData = require('./ShopsDummy.json');

        // // userId が dummyUserId の場合はダミーデータから検索
        // if (userId === 'dummyUserId') {
        //     const shop = dummyData.result.data.shopsLists.find((item) => item.fdcShopCode === 'FDC1');
        //     console.log(shop);
        //     return new Promise((resolve) => {
        //         setTimeout(() => resolve(shop), 500); // タイムアウトをシミュレートするために500ms待つ
        //     });
        // }

        try {
            const response = await http.get('/getShopDetail', {
                params: {
                    shopCode: shopCode,
                },
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async updateShop(userId, shopData) {
        // // userId が dummyUserId の場合はダミーデータを返す
        // if (userId === 'dummyUserId') {
        //     return new Promise((resolve) => {
        //         setTimeout(() => resolve({ result: { message: '更新しました' } }), 500); // タイムアウトをシミュレートするために500ms待つ
        //     });
        // }

        try {
            const response = await http.post('/updateShop', {
                userId: userId,
                shopData: shopData,
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async registerShop(userId, shopData) {
        // // userId が dummyUserId の場合はダミーデータを返す
        // if (userId === 'dummyUserId') {
        //     return new Promise((resolve) => {
        //         setTimeout(() => resolve({ result: { message: '更新しました' } }), 500); // タイムアウトをシミュレートするために500ms待つ
        //     });
        // }
        try {
            const response = await http.post('/registerShop', {
                userId: userId,
                shopData: shopData,
            });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async deleteShop(params) {
        console.log(params);
        try {
            const response = await http.post('/deleteShop', params);
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }
}

export default ShopsService;
