<template>
    <div class="wrapper__search__flyer">
        <div class="search__flyer--title">
            <TheTitle class="title">
                <h1>{{ t('shop.search.title') }}</h1>
            </TheTitle>
            <TheButton class="search__flyer--button" :disabled="false" @click="handleSearch">
                <template #left-icon>
                    <img :src="iconSearch" alt="search-icon" />
                </template>
                <h1>{{ t('shop.search.title') }}</h1>
            </TheButton>
        </div>
        <div class="search__flyers--list">
            <div class="search__flyer--item">
                <label class="item__label">{{ t('shop.search.labelShopName') }}</label>
                <div class="item__selection">
                    <input v-model="shopName" :placeholder="t('shop.search.shopNamePlaceholder')" class="item__input" />
                </div>
            </div>
            <div class="search__flyer--item">
                <div class="item__label">{{ t('shop.search.labelShopRepresentativeName') }}</div>
                <div class="item__selection">
                    <input
                        v-model="shopRepresentativeName"
                        :placeholder="t('shop.search.shopNamePlaceholder')"
                        class="item__input"
                    />
                </div>
            </div>
            <div class="flyer__selection--item">
                <div class="item__label">{{ t('shop.search.labelShopStatus') }}</div>
                <div class="item__selection">
                    <TheSelect
                        :selection="shopStatus"
                        :hide="false"
                        :options="shopStores?.shopStatusOptions.label"
                        :array-value="shopStores?.shopStatusOptions.data"
                        @input="
                            (newValue) => {
                                shopStatus = newValue;
                            }
                        "
                    ></TheSelect>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// import { ref, watch } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheSelect from '../../../../components/select/TheSelect.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useShopStores } from '../../../../stores/ShopStores.js';
// import { useUserStore } from '../../../../stores/UserStore.js';
// import OrdersService from '../../../../services/OrdersService.js';
// import useFetch from '../../../../utils/useFetch';
import iconSearch from '../../../../assets/icons/search.svg';
// i18n
const { t } = useI18n();

// store
const shopStores = useShopStores();
// const userStore = useUserStore();

// ref
// const all = t('common.all');
const shopName = ref('');
const shopRepresentativeName = ref('');
const shopStatus = ref('');
// const shopNameRef = ref(null);
// const status = ref('');
// const hide = ref(true);
// const handleData = ref([]);
// const { isError, data, fetchData } = useFetch(OrdersService.getSearchOrderBoxList);
// const { fetchData } = useFetch(OrdersService.getSearchOrderBoxList);

const handleSearch = () => {
    shopStores?.setSearchParams({
        shopName: shopName?.value,
        shopRepresentativeName: shopRepresentativeName?.value,
        shopStatus: shopStatus?.value,
    });
    shopStores?.setIsListSearch();
};
</script>

<style lang="css" src="./BaseSearchShop.css" scoped></style>
