<template>
    <ThePaper v-if="dummyData.length !== 0" id="notification__paper--id" class="notification__paper">
        <NotificationTitle />
        <div class="notification__items">
            <NotificationItem
                v-for="(notification, index) in dummyData"
                :key="`${notification.date}-${index}`"
                :date="notification?.noticeDate"
                :content="notification?.noticeText"
                :color="notification?.noticeType"
                :visited="new Date(currentDate) > new Date(notification?.noticeDate)"
            />
        </div>
    </ThePaper>
</template>

<script setup>
import { ref, watch } from 'vue';
import NotificationItem from '../../../components/notifications/NotificationItem.vue';
import NotificationTitle from '../../../components/notifications/NotificationTitle.vue';
import ThePaper from '../../../components/paper/ThePaper.vue';
import NotificationService from '../../../services/NotificationsService.js';
import useFetch from '../../../utils/useFetch.js';
import { useUserStore } from '../../../stores/UserStore.js';
// stores
const userStore = useUserStore();

// service
const { data, isError, errorMesage, fetchData } = useFetch(NotificationService?.getNoticeDisplay);
const currentDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
const dummyData = ref([]);

// watch
// listen error when call getNoticeDisplay API
watch(isError, (newError) => {
    if (newError) {
        console.log(errorMesage);
    }
});
// listen response when call getNoticeDisplay API
watch(data, (newData) => {
    if (newData) {
        dummyData.value = newData?.result?.data?.noticeList;
    }
});

fetchData({ userId: userStore?.userId });
</script>

<style lang="css" src="./NotificationList.css" scoped></style>
