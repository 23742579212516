<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path
            d="M8.6665 11.8334L11.9998 8.50008L8.6665 5.16675"
            stroke="#6D6D6D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M4 11.8334L7.33333 8.50008L4 5.16675"
            stroke="#6D6D6D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
