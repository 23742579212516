<template>
    <div class="wrapper__status__tag" :style="{ backgroundColor: props?.bgColor, border: `1px solid ${props?.borderColor}` }">
        <div class="status__tag__content">
            <div class="content__value">
                <h1 :style="{ color }">
                    {{ orderStores?.infoStatus[props?.keyName] ? orderStores?.infoStatus[props?.keyName] : 0 }}
                </h1>
                <p>{{ t('order.orderStatus.unit') }}</p>
            </div>
            <div class="content__description">{{ description }}</div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useOrderStores } from '../../../../stores/OrderStores';

// i18n
const { t } = useI18n();

// store
const orderStores = useOrderStores();

const props = defineProps({
    keyName: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    color: {
        type: String,
        required: true,
    },

    bgColor: {
        type: String,
        required: true,
    },

    borderColor: {
        type: String,
        required: true,
    },
});
</script>

<style lang="css" src="./StatusTag.css" scoped></style>
