<template>
    <div class="wrapper__shop__crud">
        <Teleport to="body">
            <component :is="DialogCustom" :show="shopStores?.isModalNavigate" :z-index="990">
                <BaseDialogNavigate></BaseDialogNavigate>
            </component>
        </Teleport>
        <DialogCustom :show="shopStores?.shopDeleteConfirm">
            <DeleteConfirmDialog></DeleteConfirmDialog>
        </DialogCustom>
        <DialogCustom :show="shopStores?.shopDeleteResult">
            <DeleteResultDialog></DeleteResultDialog>
        </DialogCustom>
        <header class="notification__registration--header">
            <TheTitle class="notification__registration--title">
                <h2 v-if="shopStores?.shopCRUDStatus === 1">販売店登録</h2>
                <h2 v-else-if="shopStores?.shopCRUDStatus === 2">販売店詳細</h2>
                <h2 v-if="shopStores?.shopCRUDStatus === 3">販売店更新</h2>
            </TheTitle>
            <div class="acction__button--header">
                <img
                    :src="commonStores?.isExpand ? expandIcon : collapseIcon"
                    class="expand__actions"
                    alt="maximize-icon"
                    @click="handleChangeSize"
                />
            </div>
        </header>
        <div v-if="!isLoading && (shopData || shopStores?.shopCRUDStatus === 1)" class="shop__updata--content">
            <div class="flyer__registration--header">
                <div class="flyer__registration--title">
                    <h2 class="subtitle">販売店情報</h2>
                </div>
            </div>
            <div class="wrapper__base__infomation__updata__content">
                <div class="infomation__content">
                    <div class="flyer__registration--item">
                        <label class="item__label">販売店名</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shopName"
                                class="selection--item item__input"
                                maxlength="256"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shopName" class="error-message">
                                {{ errors.shopName }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">販売店名カナ</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shopNameKana"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shopNameKana" class="error-message">
                                {{ errors.shopNameKana }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">販売店名(略称)</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shopNameAbbreviation"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shopNameAbbreviation" class="error-message">
                                {{ errors.shopNameAbbreviation }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item"></div>
                    <div class="flyer__registration--item">
                        <label class="item__label">郵便番号</label>
                        <div class="item__selection">
                            <input
                                v-model="form.postalCode"
                                class="selection--item item__input"
                                maxlength="7"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.postalCode" class="error-message">
                                {{ errors.postalCode }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">住所１</label>
                        <div class="item__selection">
                            <input
                                v-model="form.address1"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.address1" class="error-message">
                                {{ errors.address1 }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">住所２</label>
                        <div class="item__selection">
                            <input
                                v-model="form.address2"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.address2" class="error-message">
                                {{ errors.address2 }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">住所３</label>
                        <div class="item__selection">
                            <input
                                v-model="form.address3"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.address3" class="error-message">
                                {{ errors.address3 }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">電話番号</label>
                        <div class="item__selection">
                            <input
                                v-model="form.phoneNumber"
                                class="selection--item item__input"
                                maxlength="20"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.phoneNumber" class="error-message">
                                {{ errors.phoneNumber }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">FAX番号</label>
                        <div class="item__selection">
                            <input
                                v-model="form.faxNumber"
                                class="selection--item item__input"
                                maxlength="20"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.faxNumber" class="error-message">
                                {{ errors.faxNumber }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">代表者名</label>
                        <div class="item__selection">
                            <input
                                v-model="form.representativeName"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.representativeName" class="error-message">
                                {{ errors.representativeName }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">代表者名カナ</label>
                        <div class="item__selection">
                            <input
                                v-model="form.representativeNameKana"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.representativeNameKana" class="error-message">
                                {{ errors.representativeNameKana }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">窓口担当者</label>
                        <div class="item__selection">
                            <input
                                v-model="form.windower"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.windower" class="error-message">
                                {{ errors.windower }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">窓口担当者カナ</label>
                        <div class="item__selection">
                            <input
                                v-model="form.windowerkana"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.windowerkana" class="error-message">
                                {{ errors.windowerkana }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">URL</label>
                        <div class="item__selection">
                            <input
                                v-model="form.url"
                                class="selection--item item__input"
                                maxlength="256"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.url" class="error-message">
                                {{ errors.url }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">代表者ﾒｰﾙｱﾄﾞﾚｽ</label>
                        <div class="item__selection">
                            <input
                                v-model="form.representativeEmail"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.representativeEmail" class="error-message">
                                {{ errors.representativeEmail }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">利用開始日</label>
                        <div v-if="shopStores?.shopCRUDStatus !== 2" class="item__selection">
                            <DatePicker
                                class="selection--item date__picker"
                                :date="form.startingDate"
                                style="height: 30px"
                                @update-date="
                                    (newValue) => {
                                        form.startingDate = newValue;
                                    }
                                "
                            >
                            </DatePicker>
                            <p v-if="errors.startingDate" class="error-message">
                                {{ errors.startingDate }}
                            </p>
                        </div>
                        <div v-else class="item__selection">
                            <input
                                :value="form.startingDate"
                                class="selection--item item__input"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">FDC販売店ｺｰﾄﾞ</label>
                        <div class="item__selection">
                            <input
                                v-model="form.fdcShopCode"
                                class="selection--item item__input"
                                maxlength="32"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.fdcShopCode" class="error-message">
                                {{ errors.fdcShopCode }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">登録番号</label>
                        <div class="item__selection">
                            <input
                                v-model="form.registrationNumber"
                                class="selection--item item__input"
                                maxlength="16"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.registrationNumber" class="error-message">
                                {{ errors.registrationNumber }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">発送先郵便番号</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shippingPostalCode"
                                class="selection--item item__input"
                                maxlength="7"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shippingPostalCode" class="error-message">
                                {{ errors.shippingPostalCode }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">発行先住所１</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shippingAddress1"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shippingAddress1" class="error-message">
                                {{ errors.shippingAddress1 }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">発行先住所２</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shippingAddress2"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shippingAddress2" class="error-message">
                                {{ errors.shippingAddress2 }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">発行先住所３</label>
                        <div class="item__selection">
                            <input
                                v-model="form.shippingAddress3"
                                class="selection--item item__input"
                                maxlength="64"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.shippingAddress3" class="error-message">
                                {{ errors.shippingAddress3 }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">販売店ステータス</label>
                        <div v-if="shopStores?.shopCRUDStatus !== 2" class="item__selection">
                            <input
                                id="storeStatus_1"
                                v-model="form.shopStatus"
                                class="selection--item item_select"
                                type="radio"
                                value="1"
                                maxlength="1"
                                :disabled="shopDisabled"
                            />
                            <label for="storeStatus_0" class="item_select--label">有効</label>
                            <input
                                id="storeStatus_0"
                                v-model="form.shopStatus"
                                class="selection--item item_select"
                                type="radio"
                                value="0"
                                maxlength="1"
                                :disabled="shopDisabled"
                            />
                            <label for="storeStatus_0" class="item_select--label">無効</label>
                        </div>
                        <div v-else class="item__selection">
                            <input
                                :value="Number(form.shopStatus) === 1 ? '有効' : '無効'"
                                class="selection--item item__input"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flyer__registration--header">
                <div class="flyer__registration--title">
                    <h2 class="subtitle">金融機関情報</h2>
                </div>
            </div>
            <div class="wrapper__base__infomation__updata__content">
                <div class="infomation__content">
                    <div class="flyer__registration--item">
                        <label class="item__label">金融機関コード</label>
                        <div class="item__selection">
                            <input
                                v-model="form.financialInstitutionCode"
                                class="selection--item item__input"
                                maxlength="4"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.financialInstitutionCode" class="error-message">
                                {{ errors.financialInstitutionCode }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">金融機関名</label>
                        <div class="item__selection">
                            <input
                                v-model="form.financialInstitutionName"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.financialInstitutionName" class="error-message">
                                {{ errors.financialInstitutionName }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">支店コード</label>
                        <div class="item__selection">
                            <input
                                v-model="form.branchCode"
                                class="selection--item item__input"
                                maxlength="4"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.branchCode" class="error-message">
                                {{ errors.branchCode }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">支店名</label>
                        <div class="item__selection">
                            <input
                                v-model="form.branchName"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.branchName" class="error-message">
                                {{ errors.branchName }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">預金種別</label>
                        <div v-if="shopStores?.shopCRUDStatus !== 2" class="item__selection">
                            <input
                                id="accountType"
                                v-model="form.accountType"
                                class="selection--item item_select"
                                type="radio"
                                value="1"
                                maxlength="1"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <label for="accountType" class="item_select--label">普通預金</label>
                            <input
                                id="accountType"
                                v-model="form.accountType"
                                class="selection--item item_select"
                                type="radio"
                                value="2"
                                maxlength="1"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <label for="accountType" class="item_select--label">当座預金</label>
                            <p v-if="errors.accountType" class="error-message">
                                {{ errors.accountType }}
                            </p>
                        </div>
                        <div v-else class="item__selection">
                            <input
                                :value="form.accountType === '1' ? '普通預金' : form.accountType === '2' ? '当座預金' : ''"
                                class="selection--item item__input"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">口座番号</label>
                        <div class="item__selection">
                            <input
                                v-model="form.accountNumber"
                                class="selection--item item__input"
                                maxlength="8"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.accountNumber" class="error-message">
                                {{ errors.accountNumber }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">口座名義</label>
                        <div class="item__selection">
                            <input
                                v-model="form.accountHolderName"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.accountHolderName" class="error-message">
                                {{ errors.accountHolderName }}
                            </p>
                        </div>
                    </div>
                    <div class="flyer__registration--item">
                        <label class="item__label">口座名義カナ</label>
                        <div class="item__selection">
                            <input
                                v-model="form.accountHolderNameKana"
                                class="selection--item item__input"
                                maxlength="128"
                                style="color: #000"
                                :disabled="shopDisabled"
                            />
                            <p v-if="errors.accountHolderNameKana" class="error-message">
                                {{ errors.accountHolderNameKana }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="shopStores?.shopCRUDStatus === 1" class="actions--button">
                <button class="transition--button" @click="returnShopList_Reference">販売店一覧に戻る</button>
                <button class="transition--button" @click="registerShopData">販売店を登録する</button>
                <button class="delete__order-btn" @click="returnShopList_Reference">キャンセル</button>
                <p></p>
            </div>
            <div v-if="shopStores?.shopCRUDStatus === 2" class="actions--button">
                <button class="transition--button" @click="returnShopList">販売店一覧に戻る</button>
                <button class="transition--button" @click="goShopUpdate">販売店を更新する</button>
                <button class="delete__order-btn" @click="deleteShopData">販売店を削除する</button>
                <p></p>
            </div>
            <div v-if="shopStores?.shopCRUDStatus === 3" class="actions--button">
                <button class="transition--button" @click="returnShopList_Reference">販売店一覧に戻る</button>
                <button class="transition--button" @click="updateShopData">更新する</button>
                <button class="delete__order-btn" @click="returnShopDetail">キャンセル</button>
                <p></p>
            </div>
        </div>
        <div v-else class="shop_loading">
            <div class="custom-loader"></div>
        </div>
    </div>
</template>

<script setup>
import ShopsService from '../../../../services/ShopsService.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import { ref, reactive, watchEffect, watch } from 'vue';
import { validateshop } from './ShopInfoValidation.js';
import useFetch from '../../../../utils/useFetch';
import { useShopStores } from '../../../../stores/ShopStores.js';
import { useCommonStores } from '../../../../stores/CommonStores';
import TheTitle from '../../../../components/title/TheTitle.vue';
import DatePicker from './components/DatePicker.vue';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';
import DeleteConfirmDialog from './components/dialog/DeleteConfirmDialog.vue';
import BaseDialogNavigate from './components/dialog/BaseDialogNavigate.vue';
import DeleteResultDialog from './components/dialog/DeleteResultDialog.vue';
import expandIcon from '../../../../assets/icons/maximize.svg';
import collapseIcon from '../../../../assets/icons/minimize.svg';

// store
const commonStores = useCommonStores();
const userStore = useUserStore();
const shopStores = useShopStores();

const shopData = ref(null);
let shopDisabled = false;

const form = reactive({
    shopCode: '',
    shopName: '',
    shopNameKana: '',
    shopNameAbbreviation: '',
    postalCode: '',
    address1: '',
    phoneNumber: '',
    address2: '',
    faxNumber: '',
    address3: '',
    representativeName: '',
    representativeNameKana: '',
    windower: '',
    windowerkana: '',
    url: '',
    representativeEmail: '',
    startingDate: '',
    fdcShopCode: '',
    registrationNumber: '',
    shippingPostalCode: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingAddress3: '',
    financialInstitutionCode: '',
    financialInstitutionName: '',
    branchCode: '',
    branchName: '',
    accountType: '',
    accountNumber: '',
    accountHolderName: '',
    accountHolderNameKana: '',
    shopStatus: '1',
});

const errors = reactive({
    shopName: '',
    shopNameKana: '',
    shopNameAbbreviation: '',
    postalCode: '',
    address1: '',
    phoneNumber: '',
    address2: '',
    faxNumber: '',
    address3: '',
    representativeName: '',
    representativeNameKana: '',
    windower: '',
    windowerkana: '',
    url: '',
    representativeEmail: '',
    startingDate: '',
    fdcShopCode: '',
    registrationNumber: '',
    shippingPostalCode: '',
    shippingAddress1: '',
    shippingAddress2: '',
    shippingAddress3: '',
    financialInstitutionCode: '',
    financialInstitutionName: '',
    branchCode: '',
    branchName: '',
    accountType: '',
    accountNumber: '',
    accountHolderName: '',
    accountHolderNameKana: '',
    shopStatus: '',
});

// const filteredErrors = computed(() => {
//     return Object.entries(errors).reduce((acc, [key, error]) => {
//         if (error) {
//             acc[key] = error;
//         }
//         return acc;
//     }, {});
// });

const { isLoading, data, fetchData } = useFetch(ShopsService.getShop);

watch(data, (newData) => {
    shopData.value = newData?.result?.data;
});

watch(
    () => shopStores?.shopCode,
    async (newValue) => {
        if (newValue !== '') {
            await fetchData(newValue);
        }
    },
);

watch(
    () => shopStores?.shopUpdateRefreshData,
    async (newValue) => {
        await fetchData(shopStores?.shopCode);
        Object.keys(errors).forEach((key) => {
            errors[key] = '';
        });
    },
);

watch(
    () => shopStores?.shopDetailRefreshData,
    async (newValue) => {
        await fetchData(shopStores?.shopCode);
        Object.keys(errors).forEach((key) => {
            errors[key] = '';
        });
    },
);

watch(
    () => shopStores?.shopCRUDStatus,
    async (newValue) => {
        if (shopStores?.shopCRUDStatus === 2) {
            shopDisabled = true;
        } else {
            shopDisabled = false;
        }
        if (shopStores?.shopCRUDStatus === 1) {
            shopData.value = null;
            Object.keys(form).forEach((key) => {
                if (key === 'shopStatus') {
                    form[key] = '1';
                } else {
                    form[key] = '';
                }
            });
            Object.keys(errors).forEach((key) => {
                errors[key] = '';
            });
        }
    },
);

watchEffect(() => {
    if (shopData.value) {
        form.shopCode = shopData.value.shopCode;
        form.shopName = shopData.value.shopName;
        form.shopNameKana = shopData.value.shopNameKana;
        form.shopNameAbbreviation = shopData.value.shopNameAbbreviation;
        form.postalCode = shopData.value.postalCode;
        form.address1 = shopData.value.address1;
        form.phoneNumber = shopData.value.phoneNumber;
        form.address2 = shopData.value.address2;
        form.faxNumber = shopData.value.faxNumber;
        form.address3 = shopData.value.address3;
        form.representativeName = shopData.value.representativeName;
        form.representativeNameKana = shopData.value.representativeNameKana;
        form.windower = shopData.value.windower;
        form.windowerkana = shopData.value.windowerkana;
        form.url = shopData.value.url;
        form.representativeEmail = shopData.value.representativeEmail;
        form.startingDate = shopData.value.startingDate != '0000-00-00' ? shopData.value.startingDate : '';
        form.fdcShopCode = shopData.value.fdcShopCode;
        form.registrationNumber = shopData.value.registrationNumber;
        form.shippingPostalCode = shopData.value.shippingPostalCode;
        form.shippingAddress1 = shopData.value.shippingAddress1;
        form.shippingAddress2 = shopData.value.shippingAddress2;
        form.shippingAddress3 = shopData.value.shippingAddress3;
        form.financialInstitutionCode = shopData.value.financialInstitutionCode;
        form.financialInstitutionName = shopData.value.financialInstitutionName;
        form.branchCode = shopData.value.branchCode;
        form.branchName = shopData.value.branchName;
        form.accountType = shopData.value.accountType;
        form.accountNumber = shopData.value.accountNumber;
        form.accountHolderName = shopData.value.accountHolderName;
        form.accountHolderNameKana = shopData.value.accountHolderNameKana;
        form.shopStatus = shopData.value.shopStatus;
    }
});

const returnShopDetail = () => {
    shopStores?.setShopCRUDStatus(2);
    shopStores?.setShopDetailRefreshData();
};

const goShopUpdate = () => {
    shopStores?.setShopCRUDStatus(3);
    shopStores?.setShopUpdateRefreshData();
};

const returnShopList = () => {
    shopStores?.setShopList(true);
    shopStores?.setShopCRUDStatus(4);
};

const returnShopList_Reference = () => {
    shopStores?.setIsModalNavigate(true);
};

async function deleteShopData() {
    shopStores?.setShopDeleteConfirm(true);
}

const handleChangeSize = () => {
    commonStores.setIsExpand(!commonStores?.isExpand);
};
async function updateShopData() {
    Object.keys(errors).forEach((key) => {
        errors[key] = '';
    });
    validateshop(form, errors);
    if (Object.values(errors).every((error) => !error)) {
        try {
            isLoading.value = true;
            await ShopsService.updateShop(userStore?.userId, form);
            isLoading.value = false;
            // // API呼び出し後にポップアップを表示
            // alert('販売店情報を更新しました。');
            // ShopDetail.vueに遷移
            shopStores?.setShopCRUDStatus(2);
            shopStores?.setShopDetailRefreshData();
            shopStores?.setShopListRefreshData();
        } catch (error) {
            console.error(error);
        }
    } else {
        console.log('Form has validation errors', errors);
    }
}

async function registerShopData() {
    Object.keys(errors).forEach((key) => {
        errors[key] = '';
    });
    validateshop(form, errors);
    if (Object.values(errors).every((error) => !error)) {
        try {
            isLoading.value = true;
            const res = await ShopsService.registerShop(userStore?.userId, form);
            isLoading.value = false;
            // API呼び出し後にポップアップを表示
            // alert('販売店を登録しました。');
            // ShopDetail.vueに遷移
            shopStores?.setShopCode(res.shopCode);
            shopStores?.setShopCRUDStatus(2);
            shopStores?.setShopDetailRefreshData();
            shopStores?.setShopListRefreshData();
        } catch (error) {
            console.error(error);
        }
    } else {
        console.log('Form has validation errors', errors);
    }
}
</script>
<style src="./ShopCRUD.css" scoped>
input:disabled {
    background-color: #cccccc;
    color: #888888;
}
</style>
