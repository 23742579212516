import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useOrderStores = defineStore('orderStores', () => {
    const orderDetail = ref(false);
    const flyerOptions = ref([]);
    const schoolOptions = ref([]);
    const schoolCode = ref([]);
    const yearOptions = ref([]);
    const classOptions = ref([]);
    const orderStatusOptions = ref(['販売開始前', '販売中', '販売終了']);
    const searchParams = ref({});
    const orderCode = ref('');
    const infoStatus = ref({});
    const dataOrderStatusChange = ref([]);

    function setOrderDetail(value) {
        orderDetail.value = value;
    }
    function setFlyerOptions(value) {
        flyerOptions.value = value;
    }
    function setSchoolOptions(value) {
        schoolOptions.value = value;
    }
    function setSchoolCode(value) {
        schoolCode.value = value;
    }
    function setYearOptions(value) {
        yearOptions.value = value;
    }
    function setClassOptions(value) {
        classOptions.value = value;
    }
    function setOrderStatusOptions(value) {
        orderStatusOptions.value = value;
    }
    function setSearchParams(value) {
        searchParams.value = value;
    }
    function setOrderCode(value) {
        orderCode.value = value;
    }
    function setInfoStatus(value) {
        infoStatus.value = value;
    }
    function setDataOrderStatusChange(value) {
        dataOrderStatusChange.value = value;
    }
    function reset() {
        orderDetail.value = false;
        flyerOptions.value = [];
        schoolOptions.value = [];
        schoolCode.value = [];
        yearOptions.value = [];
        classOptions.value = [];
        orderStatusOptions.value = ['販売開始前', '販売中', '販売終了'];
        searchParams.value = {};
        orderCode.value = '';
        infoStatus.value = {};
        dataOrderStatusChange.value = [];
    }

    return {
        orderDetail,
        setOrderDetail,
        flyerOptions,
        setFlyerOptions,
        schoolOptions,
        setSchoolOptions,
        schoolCode,
        setSchoolCode,
        yearOptions,
        setYearOptions,
        classOptions,
        setClassOptions,
        orderStatusOptions,
        setOrderStatusOptions,
        searchParams,
        setSearchParams,
        orderCode,
        setOrderCode,
        infoStatus,
        setInfoStatus,
        dataOrderStatusChange,
        setDataOrderStatusChange,
        reset,
    };
});
