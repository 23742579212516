<template>
    <div
        v-if="props?.accept"
        class="wrapper__subitem-sidebar"
        :class="{ expand: sidebarStore?.expand, collapse: !sidebarStore?.expand }"
        @click="changeRoute(link)"
    >
        {{ props?.title }}
    </div>
</template>

<script setup>
import { useSidebarStore } from '../../stores/SidebarStore.js';
import { useRouter, useRoute } from 'vue-router';

// store
const sidebarStore = useSidebarStore();

// route
const router = useRouter();
const route = useRoute();

// props
const props = defineProps({
    title: {
        type: String,
        required: false,
    },
    link: {
        type: String,
        required: false,
    },
    keyActive: {
        type: Number,
        required: false,
    },
    accept: {
        type: Boolean,
        required: false,
    },
});

const changeRoute = (url) => {
    if (url !== route.fullPath) {
        router?.push(url);
    }
};

document.querySelector('.wrapper_item-sidebar.collapse.active')?.addEventListener('mouseleave', () => {
    if (!sidebarStore?.expand) {
        sidebarStore?.setKeyActive(null);
    }
});
</script>

<style lang="css" src="./SidebarSubItem.css" scoped></style>
