<template>
    <div class="wrapper__orders">
        <!--注文詳細画面-->
        <component :is="DialogCustom" :show="orderStores?.orderDetail" :z-index="200">
            <OrderDetailPage></OrderDetailPage>
        </component>
        <div class="container">
            <div class="content__orders">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!--TODO - β1,β2は、何も表示しない、もしくは固定メッセージ-->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <div class="section2">
                    <div class="section2__left">
                        <!--注文検索-->
                        <ThePaper class="section2__left--sub1">
                            <BaseSearchFlyer></BaseSearchFlyer>
                        </ThePaper>
                        <!--注文状況-->
                        <ThePaper class="section2__left--sub2">
                            <BaseOrderStatus></BaseOrderStatus>
                        </ThePaper>
                    </div>
                    <ThePaper class="section2__right">
                        <!--注文情報一覧-->
                        <OrdersList />
                    </ThePaper>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onUnmounted } from 'vue';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import BaseOrderStatus from './components/orderStatus/BaseOrderStatus.vue';
import BaseSearchFlyer from './components/flyers/BaseSearchFlyer.vue';
import OrdersList from './components/orders/OrderList.vue';
import OrderDetailPage from '../orderDetail/OrderDetailPage.vue';
import DialogCustom from '../../components/dialog/DialogCustom.vue';
import { useOrderStores } from '../../stores/OrderStores';
import { useCommonStores } from '../../stores/CommonStores';

const orderStores = useOrderStores();
const commonStores = useCommonStores();

// Unmounted
onUnmounted(() => {
    orderStores?.reset();
});
</script>

<style lang="css" src="./OrdersPage.css" scoped></style>
