<template>
    <div class="csv__button--container">
        <!-- <TheButton :disabled="!orderStores.searchParams?.startYear" @click="handleClick"> -->
        <TheButton
            :disabled="
                (!orderStores.searchParams?.shopCode && userStore?.userInfo?.affiliationKbn !== 2) ||
                (!orderStores.searchParams?.school && userStore?.userInfo?.affiliationKbn === 2)
            "
            @click="handleClick"
        >
            <template #left-icon>
                <!-- :src="orderStores?.searchParams?.startYear ? uploadIcon : uploadGrayIcon" -->
                <img
                    class="margin__icon--right"
                    :src="
                        !(
                            (!orderStores.searchParams?.shopCode && userStore?.userInfo?.affiliationKbn !== 2) ||
                            (!orderStores.searchParams?.school && userStore?.userInfo?.affiliationKbn === 2)
                        )
                            ? uploadIcon
                            : uploadGrayIcon
                    "
                    alt="upload-icon"
                />
            </template>
            {{ t('order.table.exportButton') }}
            <template #right-icon>
                <!-- :src="orderStores?.searchParams?.startYear ? chevronsDownWhiteIcon : chevronsDownGrayIcon" -->
                <img
                    class="margin__icon--left"
                    :class="{ is__show__options: isShowOptions }"
                    :src="
                        !(
                            (!orderStores.searchParams?.shopCode && userStore?.userInfo?.affiliationKbn !== 2) ||
                            (!orderStores.searchParams?.school && userStore?.userInfo?.affiliationKbn === 2)
                        )
                            ? chevronsDownWhiteIcon
                            : chevronsDownGrayIcon
                    "
                    alt="chevron-down-icon"
                />
            </template>
        </TheButton>
        <div class="menu__items__exports" :class="{ isShow: isShowOptions }">
            <div class="menu__item" @click="() => fetchOrderListByStudent(orderStores.searchParams)">
                {{ t('order.table.subExportButton1') }}
            </div>
            <div class="menu__item" @click="() => fetchOrderListByProduct(orderStores.searchParams)">
                {{ t('order.table.subExportButton2') }}
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TheButton from '../../../../components/button/TheButton.vue';
import useFetch from '../../../../utils/useFetch';
import OrdersService from '../../../../services/OrdersService';
import { convertToCSV } from '../../../../utils/CSV';
import { useOrderStores } from '../../../../stores/OrderStores';
import { formatDateOnly, cutStringByByte, countBytes, removeDuplicateInArrayByKey } from '../../../../utils/Helpers';
import uploadIcon from '../../../../assets/icons/upload.svg';
import uploadGrayIcon from '../../../../assets/icons/upload-gray.svg';
import chevronsDownGrayIcon from '../../../../assets/icons/chevron-down-gray.svg';
import chevronsDownWhiteIcon from '../../../../assets/icons/chevron-down-white.svg';
import { payType, orderStatus, orderStatusConst } from '../../../../utils/Consts';
import { useUserStore } from '../../../../stores/UserStore';

// i18n
const { t } = useI18n();

// BOOM is to help the CSV reader detect the correct charset (UTF-8), I included a BOM at the beginning
const BOOM = 'data:text/csv;charset=utf-8,%EF%BB%BF';

// Ver1.2 #20
const ORDERS_BY_PRODUCT_HEADER = [
    // t('order.csv.csvHeaderByProduct.schoolName'),
    // t('order.csv.csvHeaderByProduct.schoolYear'),
    t('order.csv.csvHeaderByProduct.productCode'),
    t('order.csv.csvHeaderByProduct.productName'),
    t('order.csv.csvHeaderByProduct.orderStatus'),
    t('order.csv.csvHeaderByProduct.quantity'),
    // t('order.csv.csvHeaderByProduct.unitPrice'),
    // t('order.csv.csvHeaderByProduct.totalAmount'),
];

const ORDERS_BY_STUDENT_HEADER = [
    t('order.csv.csvHeaderByStudent.orderNumber'),
    t('order.csv.csvHeaderByStudent.schoolName'),
    t('order.csv.csvHeaderByStudent.schoolYear'),
    t('order.csv.csvHeaderByStudent.schoolClass'),
    t('order.csv.csvHeaderByStudent.flyerName'),
    t('order.csv.csvHeaderByStudent.orderDate'),
    t('order.csv.csvHeaderByStudent.productCode'),
    t('order.csv.csvHeaderByStudent.productName'),
    t('order.csv.csvHeaderByStudent.quantity'),
    t('order.csv.csvHeaderByStudent.unitPrice'),
    t('order.csv.csvHeaderByStudent.subtotalAmount'),
    t('order.csv.csvHeaderByStudent.paymentType'),
    t('order.csv.csvHeaderByStudent.orderer'),
    t('order.csv.csvHeaderByStudent.studentName'),
    t('order.csv.csvHeaderByStudent.orderStatus'),
];

const userStore = useUserStore();
const orderStores = useOrderStores();
const isShowOptions = ref(false);

const {
    data: orderListByProduct,
    errorCode: orderListByProductErrorCode,
    fetchData: fetchOrderListByProduct,
} = useFetch(OrdersService.exportOrderListByProduct);
const {
    data: orderListByStudent,
    errorCode: orderListByStudentErrorCode,
    fetchData: fetchOrderListByStudent,
} = useFetch(OrdersService.exportOrderListByStudent);

watch(orderListByProduct, (data) => {
    const orders = data?.result?.data?.orderByProductList;
    const arrInArr = orders?.map((e) =>
        Object.values({
            ...e,
            // Ver1.2 #20
            orderStatus: orderStatusConst?.options[e?.orderStatus - 1] || '',
            // unitPrice: e.unitPrice
            //     ? `${String(e.unitPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`
            //     : '',
            // payAmount: e.payAmount
            //     ? `${String(e.payAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`
            //     : '',
            // schoolYear: e.schoolYear ? `${e.schoolYear}${t('common.schoolYearUnit')}` : '',
        }),
    );
    const csvName = genarateCSVName(
        orderStores.searchParams?.schoolCode,
        orderStores.searchParams?.flyerCode,
        t('order.csv.csvHeaderByProduct.prefixName'),
    );
    handleExportCsv(arrInArr, ORDERS_BY_PRODUCT_HEADER, csvName);
});

watch(orderListByStudent, (data) => {
    const orders = data?.result?.data?.orderByStudentList;
    const schoolList = removeDuplicateInArrayByKey(orders, 'schoolName');

    schoolList?.forEach((school) => {
        const orderItems = orders?.filter((order) => order?.schoolName === school);
        const arrInArr = orderItems?.map((e) =>
            Object.values({
                ...e,
                // Ver1.2 #21 #CDI
                schoolYear: e.schoolYear ? (e.schoolYear !== '0' ? `${e.schoolYear}年` : t('common.newStudent')) : '',
                orderDate: e.orderDate ? e.orderDate : '',
                // Ver1.2 API#21
                // ? `${String(e.unitPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`
                unitPrice: e.unitPrice ? `${String(e.unitPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '',
                // Ver1.2 API#21
                // ? `${String(e.payAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`
                payAmount: e.payAmount ? `${String(e.payAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '',
                payType: e.payType ? payType[e.payType] : '',
                orderStatus: orderStatus[e.orderStatus].badgeContent,
            }),
        );

        if (school) {
            const csvName = genarateCSVName(
                orderStores.searchParams?.schoolCode,
                orderStores.searchParams?.flyerCode,
                t('order.csv.csvHeaderByStudent.prefixName'),
                school,
            );
            handleExportCsv(arrInArr, ORDERS_BY_STUDENT_HEADER, csvName);
        }
    });
});

watch(orderListByStudentErrorCode, () => {
    if (orderListByStudentErrorCode.value === 'E0013') {
        const csvName = genarateCSVName(
            orderStores.searchParams?.schoolCode,
            orderStores.searchParams?.flyerCode,
            t('order.csv.csvHeaderByStudent.prefixName'),
        );
        handleExportCsv([], ORDERS_BY_STUDENT_HEADER, csvName);
    }
});

watch(orderListByProductErrorCode, () => {
    if (orderListByProductErrorCode.value === 'E0014') {
        const csvName = genarateCSVName(
            orderStores.searchParams?.schoolCode,
            orderStores.searchParams?.flyerCode,
            t('order.csv.csvHeaderByProduct.prefixName'),
        );
        handleExportCsv([], ORDERS_BY_PRODUCT_HEADER, csvName);
    }
});

const genarateCSVName = (schoolCode, flyerCode, prefix, schoolNameConst) => {
    const currDate = formatDateOnly(new Date(Date.now()), '');

    if (schoolNameConst) {
        const schoolName = cutStringByByte(schoolNameConst, 44)[0];
        return `${schoolName}_${prefix}__${currDate}`;
    }
    const flyer = orderStores.flyerOptions?.find((flyer) => flyer.flyerCode === flyerCode);
    const schoolIndex = orderStores.schoolCode?.findIndex((code) => code === schoolCode);
    const school = { schoolName: orderStores.schoolOptions[schoolIndex] };

    if (school?.schoolName && flyer?.flyerName) {
        let schoolNameNum = countBytes(school?.schoolName);
        let flyerNameNum = countBytes(flyer?.flyerName);
        if (schoolNameNum >= 22 && flyerNameNum >= 22) {
            schoolNameNum = 22;
            flyerNameNum = 22;
        }
        if (schoolNameNum >= 22 && flyerNameNum <= 22) {
            schoolNameNum = 44 - flyerNameNum;
        }
        if (schoolNameNum <= 22 && flyerNameNum >= 22) {
            flyerNameNum = 44 - schoolNameNum;
        }
        const schoolName = cutStringByByte(school?.schoolName, schoolNameNum)[0];
        const flyerName = cutStringByByte(flyer?.flyerName, flyerNameNum)[0];
        return `${prefix}_${schoolName}_${flyerName}_${currDate}`;
    }
    if (school?.schoolName && !flyer?.flyerName) {
        const schoolName = cutStringByByte(school?.schoolName, 45)[0];
        return `${prefix}_${schoolName}_${currDate}`;
    }
    if (!school?.schoolName && flyer?.flyerName) {
        const flyerName = cutStringByByte(flyer?.flyerName, 45)[0];
        return `${prefix}_${flyerName}_${currDate}`;
    }

    return `${prefix}_${currDate}`;
};

const handleExportCsv = (dataArr, header, name) => {
    try {
        const csvStr = convertToCSV(dataArr, header);
        const data = encodeURI(csvStr);
        const japnaneseCsvStr = BOOM + data;
        const link = document.createElement('a');
        link.setAttribute('href', japnaneseCsvStr);
        link.setAttribute('download', name);
        link.click();
    } catch (error) {
        console.log(error);
    }
    return '';
};

const handleClick = () => {
    isShowOptions.value = !isShowOptions.value;
};

const handleClose = () => {
    if (isShowOptions.value) {
        isShowOptions.value = false;
    }
};

onMounted(() => {
    document.querySelector('.csv__button--container')?.addEventListener('mouseleave', handleClose);
});

onUnmounted(() => {
    document.querySelector('.csv__button--container')?.removeEventListener('mouseleave', handleClose);
});
</script>
<style lang="css" src="./ExportCsvButton.css" scoped></style>
