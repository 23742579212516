<!-- eslint-disable vue/no-v-html -->
<template>
    <div class="smartphone">
        <div class="smartphone__header"></div>
        <div class="smartphone__app">
            <header class="app__header">
                <h3 class="header__text">{{ 'プレビュー' || t('productRegistration.phone.header') }}</h3>
                <div class="header__breadcrumb">
                    <p class="breadcrumb1">{{ t('productRegistration.phone.breadcrumb1') }}</p>
                    ＞
                    <p class="breadcrumb2">{{ t('productRegistration.phone.breadcrumb2') }}</p>
                </div>
            </header>
            <div class="app__content">
                <div class="header__content">
                    <!-- <h3>{{ productRegistrationStores?.phoneReview.productSetName || '型/セット名' }}</h3> -->
                    <!--  -->
                    <h3>{{ productRegistrationStores?.phoneReview.productName || '商品名' }}</h3>
                    <h3>{{ schoolName || '学校名' }}</h3>

                    <div class="infor__flyer">
                        <p>{{ t('productRegistration.phone.studentName', { name: '田中' }) }}</p>
                        <p>{{ t('productRegistration.phone.shopName', { name: shopName || '例文具店' }) }}</p>
                    </div>
                    <h3>{{ productRegistrationStores?.phoneReview.categoryName || 'カテゴリ' }}</h3>
                    <div class="infor_product">
                        <!-- <h3>{{ productRegistrationStores?.phoneReview.productName || '商品名' }}</h3> -->
                        <p v-if="productRegistrationStores?.phoneReview.requiredPurchase" class="required__purchase">
                            {{ t('productRegistration.inputLabel11') }}
                        </p>
                    </div>
                </div>
                <div class="image__product--list">
                    <v-carousel v-model="currentItem" height="max-content" hide-delimiter-background :continuous="false">
                        <v-carousel-item v-if="productRegistrationStores?.mainImageUrl" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.mainImageUrl" alt="" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-else height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img src="../../../../assets/img/default-thumbnail.jpg" alt="" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item
                            v-if="productRegistrationStores?.detailImageUrls?.every((item) => item === '')"
                            height="100%"
                        >
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img src="../../../../assets/img/default-thumbnail.jpg" alt="" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[0]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[0]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[1]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[1]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[2]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[2]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[3]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[3]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[4]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[4]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[5]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[5]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[6]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[6]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[7]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[7]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[8]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[8]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                        <v-carousel-item v-if="productRegistrationStores?.detailImageUrls[9]" height="100%">
                            <v-sheet height="100%">
                                <div class="slide--item d-flex fill-height justify-center align-center">
                                    <img :src="productRegistrationStores?.detailImageUrls[9]" alt="image-detail" />
                                </div>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <div class="detail__product">
                    <div class="detail__group--price">
                        <div class="price">
                            <strong>
                                {{
                                    `${t('common.iconPrice')}${productRegistrationStores?.phoneReview.taxIncludedPrice}(${t(
                                        'productRegistration.phone.taxIncluded',
                                    )})`
                                }}
                            </strong>
                        </div>
                        <!-- feature/Ver1.2#1#3 -->
                        <div v-show="productRegistrationStores?.phoneReview.taxNormalPrice !== ''" class="taxNormalPrice">
                            <p>
                                {{ `参考：` }}
                                <s>
                                    {{ t('common.iconPrice') }}{{ `${productRegistrationStores?.phoneReview.taxNormalPrice} ` }}
                                </s>
                            </p>
                            <span>{{ t('productRegistration.phone.taxIncluded') }}</span>
                        </div>
                    </div>

                    <div class="detail--list">
                        <p class="message__phone"></p>
                        <p class="message__phone"></p>
                        <p class="message__phone"></p>
                    </div>
                </div>
            </div>
            <div class="app__action">
                <TheButton class="button__phone">{{ t('productRegistration.phone.buttonlabel') }}</TheButton>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import TheButton from '../../../../components/button/TheButton.vue';
import { useProductRegistrationStores } from '../../../../stores/ProductRegistrationStores';
import { onMounted, ref, watch } from 'vue';
import SchoolStaffService from '../../../../services/SchoolStaffService';
import FlyerRegistrationService from '../../../../services/FlyerRegistrationService';
import useFetch from '../../../../utils/useFetch';
import { useUserStore } from '../../../../stores/UserStore';

// vue-i18n
const { t } = useI18n();

// router
const route = useRoute();

const fetchGetSchoolName = useFetch(SchoolStaffService.getStudentListSchoolName);
const fetchGetShopName = useFetch(FlyerRegistrationService?.getFlyerRegisterShopName);

const productRegistrationStores = useProductRegistrationStores();
const userStore = useUserStore();

const schoolName = ref('');
const shopName = ref('');
const currentItem = ref(0);

watch(productRegistrationStores?.phoneReview.productDetail, () => {
    document.querySelectorAll('.message__phone')[0].innerHTML =
        productRegistrationStores?.phoneReview.productDetail.detail1 || '商品詳細1';
    document.querySelectorAll('.message__phone')[1].innerHTML =
        productRegistrationStores?.phoneReview.productDetail.detail2 || '';
    document.querySelectorAll('.message__phone')[2].innerHTML =
        productRegistrationStores?.phoneReview.productDetail.detail3 || '';
});
// listen response when call fetchGetSchoolName API
watch(fetchGetSchoolName?.data, (newValue) => {
    if (newValue) {
        schoolName.value = newValue.result.data.schoolName;
    }
});

// listen handle delete last carousel item
watch(productRegistrationStores?.detailImageUrls, (newValue) => {
    if (newValue) {
        if (newValue.length <= currentItem.value) {
            currentItem.value = newValue.length;
        }
    }
});

// listen response when call fetchGetShopName API
watch(fetchGetShopName?.data, (newValue) => {
    if (newValue) {
        shopName.value = newValue.result.data.shopName;
    }
});
onMounted(async () => {
    await fetchGetSchoolName?.fetchData({
        schoolCode: decodeURIComponent(route.params?.schoolCode),
        userId: userStore?.userId,
    });
    await fetchGetShopName?.fetchData({
        shopCode: decodeURIComponent(route.params?.shopCode),
    });
});
</script>

<style lang="css" src="./PhoneDevice.css" scoped></style>
