import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useSchoolStaffStores = defineStore('schoolStaffStores', () => {
    const params = ref({});
    const isSearch = ref(true);
    const errorSearchMessage = ref();
    const isModalDelete = ref(false);
    const isModalCreate = ref(false);
    const schoolCode = ref('');
    const activeRegistration = ref(false);
    const titleNotice = ref('');
    const isNotice = ref(false);
    const noticeError = ref('');

    function setParams(value) {
        params.value = value;
    }
    function setIsSearch() {
        isSearch.value = !isSearch.value;
    }
    function setErrorSearchMessage(value) {
        errorSearchMessage.value = value;
    }
    function setIsModalDelete(value) {
        isModalDelete.value = value;
    }
    function setIsModalCreate(value) {
        isModalCreate.value = value;
    }
    function setSchoolCode(value) {
        schoolCode.value = value;
    }
    function setActiveRegistration(value) {
        activeRegistration.value = value;
    }
    function setTitleNotice(value) {
        titleNotice.value = value;
    }
    function setIsNotice(value) {
        isNotice.value = value;
    }
    function setNoticeError(value) {
        noticeError.value = value;
    }
    function reset() {
        setParams({});
        setIsModalDelete(false);
        setIsModalCreate(false);
        setSchoolCode('');
        setActiveRegistration(false);
        setTitleNotice('');
        setIsNotice(false);
        setNoticeError('');
        setErrorSearchMessage('');
    }

    return {
        params,
        setParams,
        isSearch,
        setIsSearch,
        errorSearchMessage,
        setErrorSearchMessage,
        isModalCreate,
        setIsModalCreate,
        isModalDelete,
        setIsModalDelete,
        schoolCode,
        setSchoolCode,
        activeRegistration,
        setActiveRegistration,
        titleNotice,
        setTitleNotice,
        isNotice,
        setIsNotice,
        noticeError,
        setNoticeError,
        reset,
    };
});
