<template>
    <div class="products__container">
        <div class="product__header">
            <h2 class="products__title">{{ t('products.titleProductList') }}</h2>
            <div class="product__actions">
                <TheButton @click="handleRedirectToFlyerList">{{ t('products.backToFlyerList') }}</TheButton>
                <TheButton
                    :disabled="userStore?.userInfo?.affiliationKbn === 2 || useRoute().params.mode !== 'update'"
                    @click="handleRedirectToRegister"
                >
                    {{ t('products.productRegistering') }}
                </TheButton>
            </div>
        </div>
        <div class="products__list">
            <TheTableDraggable
                :columns="columnsData"
                :data="products"
                :unique-attr="uniqueAttr"
                class="products__table"
                @handle-update-postion="(products) => emits('handleUpdatePostion', products)"
            />
        </div>
    </div>
</template>
<script setup>
import { ref, h, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import TheButton from '../../../../components/button/TheButton.vue';
import TheTableDraggable from '../../../../components/tableDraggable/TheTableDraggable.vue';
import TheRowActions from './TheRowActions.vue';
import TheMoveIcon from './TheMoveIcon.vue';
import { taxCategoryMapping } from '../../../../utils/Consts';
import { useUserStore } from '../../../../stores/UserStore';

const uniqueAttr = 'productCode';
// i18n
const { t } = useI18n();

// stores
const userStore = useUserStore();

const props = defineProps({
    flyer: Object,
});

const emits = defineEmits(['refreshProduct', 'handleUpdatePostion']);

const products = ref([]);
const router = useRouter();
const route = useRoute();

// onMounted
onMounted(() => {
    if (route.params?.mode === 'update' && userStore.userInfo?.affiliationKbn === 2) {
        router.push(
            // eslint-disable-next-line max-len
            `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
                route.params?.shopCode,
            )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(
                route.params?.schoolCode,
            )}/${encodeURIComponent(route.params?.schoolYear)}/reference/products`,
        );
    }
});
watch(
    () => userStore?.userInfo?.affiliationKbn,
    (newValue) => {
        if (route.params?.mode === 'update' && newValue === 2) {
            router.push(
                // eslint-disable-next-line max-len
                `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
                    route.params?.shopCode,
                )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(
                    route.params?.schoolCode,
                )}/${encodeURIComponent(route.params?.schoolYear)}/reference/products`,
            );
        }
    },
);

watch(
    () => props?.flyer?.flyerDetailInfo,
    () => {
        products.value = props?.flyer?.flyerDetailInfo?.map((product) => ({
            ...product,
            // nonTaxedPrice: product?.nonTaxedPrice
            //     ? `${String(product?.nonTaxedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`
            //     : null,
            // taxIncludedPrice: product?.taxIncludedPrice
            //     ? `${String(product?.taxIncludedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`,
            //     : null,
            nonTaxedPrice: `${String(product?.nonTaxedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t('common.currencyUnit')}`,
            taxIncludedPrice: `${String(product?.taxIncludedPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${t(
                'common.currencyUnit',
            )}`,

            productImageURL: product?.productImageName?.imageURL,
            transferedTaxCategory: taxCategoryMapping[product?.taxCategory],
        }));
    },
);

const handleRedirectToRegister = () => {
    if (userStore?.userInfo?.affiliationKbn) {
        router.push(
            // eslint-disable-next-line max-len
            `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
                route.params?.shopCode,
            )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(
                route.params?.schoolCode,
            )}/${encodeURIComponent(route.params?.schoolYear)}/products/register`,
        );
    }
};

const handleRedirectToFlyerList = () => {
    router.push('/flyers');
};

const TheRowActionsElement = (column, item) =>
    h(TheRowActions, {
        productCode: item[uniqueAttr],
        flyerCode: item['flyerCode'],
        onRefreshProduct: () => emits('refreshProduct'),
    });

const TheDraggableElement = (column, item) => h(TheMoveIcon);
const TheImageElement = (column, item) => h('img', { src: item[column.key], alt: '', style: { height: '50px' } });

const renderWidth = (width) => {
    return { 'min-width': `${width}px`, 'max-width': `${width}px` };
};

const columnsData = [
    {
        key: 'tableKey0',
        title: '',
        styles: renderWidth(100),
        render: (column, item) => TheRowActionsElement(column, item),
    },
    {
        key: 'productSetName',
        styles: renderWidth(200),
        title: t('products.table.productType'),
    },
    {
        key: 'productName',
        styles: renderWidth(200),
        title: t('products.table.productName'),
    },
    {
        key: 'productCode',
        styles: renderWidth(200),
        title: t('products.table.productCode'),
    },
    {
        key: 'nonTaxedPrice',
        styles: renderWidth(150),
        title: t('products.table.productPriceExcludedTax'),
    },
    {
        key: 'taxIncludedPrice',
        styles: renderWidth(150),
        title: t('products.table.productPriceIncludedTax'),
    },
    {
        key: 'transferedTaxCategory',
        styles: renderWidth(100),
        title: t('products.table.taxType'),
    },
    {
        key: 'productImageURL',
        title: t('products.table.productImage'),
        styles: renderWidth(100),
        render: (column, item) => TheImageElement(column, item),
    },
    {
        key: 'tableKey8',
        title: t('products.table.rowMoving'),
        styles: renderWidth(100),
        render: (column, item) => TheDraggableElement(column, item),
    },
];
</script>
<style lang="css" src="./ProductList.css" scoped></style>
