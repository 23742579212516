<template>
    <ul class="wrapper__shop--list">
        <!-- Loading -->
        <div v-if="isLoading" class="table__item--center">
            <div class="custom-loader"></div>
        </div>
        <!-- Error -->
        <div v-if="isError && errorCode !== 'E0004'" class="table__item--center">
            <img src="../../../../assets/icons/error-icon.svg" alt="error-icon" />
            <p class="error--message">
                {{ errorMessage }}
            </p>
        </div>
        <div v-if="isError && errorCode === 'E0004'" class="table__item--center">
            <img src="../../../../assets/icons/empty_folder.svg" alt="empty" />
            <p class="empty--message">
                {{ errorMessage }}
            </p>
        </div>
        <li
            v-for="(item, index) in shopList"
            :key="index"
            class="shop--item"
            :class="{ active: item?.shopCode === shopStaffStores?.staffParams?.shopCode }"
            @click="handleChoiceShop(item)"
        >
            <ShopItem :shop-name="item?.shopName" :address="item?.address1"></ShopItem>
        </li>
    </ul>
</template>

<script setup>
import { ref, watch } from 'vue';
import ShopItem from './ShopItem.vue';
import { useShopStaffStores } from '../../../../stores/ShopStaffStores.js';
import ShopStaffServive from '../../../../services/ShopStaffService.js';
import useFetch from '../../../../utils/useFetch.js';
// store
const shopStaffStores = useShopStaffStores();

// ref
const shopList = ref([]);

// service
const { isLoading, isError, errorCode, errorMessage, fetchData, data } = useFetch(ShopStaffServive?.getShopStaffList);

// function
const handleChoiceShop = (item) => {
    shopStaffStores?.setShopName(item?.shopName);
    shopStaffStores?.setShopAddress(item?.address1);
    shopStaffStores?.setShopCode(item?.shopCode);
    shopStaffStores?.setStaffParams({
        shopName: item?.shopName,
        shopCode: item?.shopCode,
        userId: shopStaffStores?.shopParams?.userId,
    });
    shopStaffStores?.setIsSearchStaff();
};

// watch
watch(
    () => shopStaffStores?.isSearchShop,
    () => {
        shopList.value = [];
        fetchData({
            ...shopStaffStores?.shopParams,
            procType: 0,
        });
    },
);

/**
 * listen error when call getShopStaffList API
 */
watch(isError, (newValue) => {
    if (newValue) {
        console.log('error');
    }
});

/**
 * listen response when call getShopStaffList API
 */
watch(data, (newValue) => {
    if (newValue) {
        shopList.value = newValue?.result?.data?.shopList;
    }
});
</script>

<style lang="css" src="./ShopList.css" scoped></style>
