<template>
    <BaseDialog
        :title="t('flyerRegistration.dialogTitle2')"
        :content="t('flyerRegistration.dialogContent2', { flyerName: props?.flyerName })"
    >
        <template #default>
            <Teleport to="body">
                <component :is="DialogCustom" :show="isSuccess" :z-index="997">
                    <BaseDialog :title="flyerRegistrationStores?.titleDialog" :content="flyerRegistrationStores?.contentDialog">
                        <template #default>
                            <div class="button__confirm">
                                <TheButton
                                    @click="
                                        () => {
                                            isSuccess = false;
                                            flyerRegistrationStores?.setIsModalDelete(false);
                                            router.push('/flyers');
                                        }
                                    "
                                >
                                    OK
                                </TheButton>
                            </div>
                        </template>
                    </BaseDialog>
                </component>
            </Teleport>
            <div class="more__action">
                <div class="button__action">
                    <TheButton class="button__action--cancel" @click="handleCloseModal">{{ t('confirm.content2') }}</TheButton>
                    <TheButton class="button__action--confirm" @click="handleDeleteOrder">{{ t('confirm.content3') }}</TheButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useFlyerRegistrationStores } from '../../../../stores/FlyerRegistrationStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import FlyerRegistrationService from '../../../../services/FlyerRegistrationService.js';
import useFetch from '../../../../utils/useFetch.js';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';

// i18n
const { t } = useI18n();

// router
const router = useRouter();
const route = useRoute();

// service
const { isError, data, fetchData, errorMessage } = useFetch(FlyerRegistrationService?.deleteFlyerInfor);

// store
const flyerRegistrationStores = useFlyerRegistrationStores();
const userStore = useUserStore();

// ref
const isSuccess = ref(false);

const props = defineProps({
    flyerName: {
        type: String,
        required: false,
    },
});

// function
const handleCloseModal = () => {
    flyerRegistrationStores?.setIsModalDelete(false);
};

/**
 * call deleteFlyerInfo API.
 */
const handleDeleteOrder = () => {
    fetchData({
        userId: userStore?.userId,
        flyerCode: decodeURIComponent(route.params?.flyerCode),
        eventCode: decodeURIComponent(route.params?.eventCode),
        shopCode: decodeURIComponent(route.params?.shopCode),
        schoolCode: decodeURIComponent(route.params?.schoolCode),
        schoolYear: decodeURIComponent(route.params?.schoolYear),
    });
};

// watch
// listen error when call call deleteFlyerInfo API.
watch(isError, (newError) => {
    if (newError) {
        isSuccess.value = true;
        flyerRegistrationStores?.setTitleDialog(t('flyerRegistration.dialogTitle2'));
        flyerRegistrationStores?.setContentDialog(errorMessage);
    }
});

// listen response when call deleteFlyerInfo API.
watch(data, (newData) => {
    if (newData) {
        flyerRegistrationStores?.setIsModalDelete(false);
        router.push('/flyers');
    }
});
</script>

<style lang="css" src="./BaseDialogDelete.css" scoped></style>
