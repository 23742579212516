<template>
    <div class="flyer__list--content">
        <div class="flyer__list--body">
            <TheTable :columns="columns" :data="flyerList" class="flyer__list--table" />
            <div class="search__status">
                <!-- Empty -->
                <div v-if="isError && errorCode === 'E0019'" class="table__item--center">
                    <img src="../../../../assets/icons/empty_folder.svg" alt="empty-folder-icon" />
                    <p class="empty_list">{{ t('common.emptyList') }}</p>
                </div>
                <!-- Error -->
                <div v-if="isError && errorCode !== 'E0019'" class="table__item--center">
                    <img src="../../../../assets/icons/error-icon.svg" alt="error-icon" />
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <!-- Loading -->
                <div v-if="isLoading" class="table__item--center">
                    <div class="custom-loader"></div>
                </div>
            </div>
        </div>
        <div v-if="flyerList?.length > 0" class="flyer__list--footer">
            <div class="number__of--page">
                <span>{{ t('common.searchResult') }}</span>
                <SelectField :model-value="limit" @update:model-value="handleChangeLimit" />
                <span>
                    {{
                        t('common.showSearchResult', {
                            total: totalRecords,
                            start: (currPage - 1) * limit + 1,
                            end: currPage * limit > totalRecords ? totalRecords : currPage * limit,
                        })
                    }}
                </span>
            </div>
            <div>
                <ThePagination :total="totalPage" :curr-page="currPage" @change="handleChangePage" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, h, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import TheTable from '../../../../components/table/TheTable.vue';
import HeaderItem from '../../../../components/table/HeaderItem.vue';
import ThePagination from '../../../../components/pagination/ThePagination.vue';
import SelectField from '../../../../components/select/SelectField.vue';
import GroupButton from './GroupButton.vue';
import FlyersService from '../../../../services/FlyersService.js';
import { SORT_TYPE, flyerStatus } from '../../../../utils/Consts';
import { useFlyerStores } from '../../../../stores/FlyerStores.js';
import { useUserStore } from '../../../../stores/UserStore';
import useFetch from '../../../../utils/useFetch.js';

import './FlyerListGlobal.css';
import imageDefault from '../../../../assets/img/default-thumbnail.jpg';
import { convertDatatoText } from '../../../../utils/CSV.js';
import { formatDateOnly, formatTimeOnly } from '../../../../utils/Helpers.js';

// i18n
const { t } = useI18n();

// router
const router = useRouter();

// store
const flyerStores = useFlyerStores();
const userStore = useUserStore();

// data header
const columnsData = [
    {
        key: 'action',
        title: '',
        styles: {
            minWidth: '112px',
            textAlign: 'center',
            fontSize: '16px',
        },
        render: (column, item) =>
            h(GroupButton, {
                onHandleReference: () => handleReferenceNavigate(item),
                onHandleUpdate: () => handleUpdateNavigate(item),
                onHandleCreate: () => handleCreateNavigate(item),
                onHandleReferenceProduct: () => handleReferenceProductNavigate(item),
                onHandleExportCSV: () => handleExportCSV(item),
            }),
    },
    {
        key: 'flyerImageNameMain',
        title: t('flyers.table.image'),
        styles: {
            minWidth: '200px',
            display: 'flex',
            justifyContent: 'center',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'left',
        },
        render: (column, item) =>
            h('img', {
                src: item[column?.key] || '../../../../assets/img/default-thumbnail.jpg',
                alt: 'image',
                class: `img-${item[column?.key]}`,
                style: {
                    width: '100%',
                },
                onError: () => defautlImage(item[column?.key]),
            }),
    },
    {
        key: 'flyerImageNameSub',
        title: t('flyers.table.subImage'),
        styles: {
            minWidth: '200px',
            display: 'flex',
            justifyContent: 'center',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'left',
        },
        render: (column, item) =>
            h('img', {
                src: item[column?.key],
                class: `img-${item[column?.key]}`,
                style: {
                    width: '100%',
                },
                onError: () => removeImage(item[column?.key]),
            }),
    },
    {
        key: 'flyerName',
        title: t('flyers.table.flyerName'),
        styles: {
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'left',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'schoolName',
        title: t('flyers.table.schoolName'),
        styles: {
            minWidth: '200px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'left',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'schoolYear',
        title: t('flyers.table.schoolYear'),
        styles: {
            minWidth: '80px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'right',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    },
    {
        key: 'schoolClass',
        title: t('flyers.table.className'),
        styles: {
            minWidth: '120px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'right',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
        render: (column, item) => h('p', {}, item[column.key] || t('common.all')),
    },
    {
        key: 'flyerStatus',
        title: t('flyers.table.status'),
        styles: {
            minWidth: '200px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
        },
        stylesBody: {
            display: 'block',
            textAlign: 'center',
        },
        renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
        render: (column, item) =>
            h(
                'p',
                {
                    style: {
                        width: '100%',
                    },
                },
                flyerStatus[item?.flyerStatus],
            ),
    },
    // {
    //     key: 'flyerStartDate',
    //     title: t('flyers.table.startDate'),
    //     styles: {
    //         minWidth: '200px',
    //         textAlign: 'left',
    //         display: 'flex',
    //         justifyContent: 'center',
    //         fontSize: '16px',
    //     },
    //     stylesBody: {
    //         display: 'block',
    //         textAlign: 'center',
    //     },
    //     renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    // },
    // {
    //     key: 'flyerEndDate',
    //     title: t('flyers.table.endDate'),
    //     styles: {
    //         minWidth: '200px',
    //         textAlign: 'left',
    //         display: 'flex',
    //         justifyContent: 'center',
    //         fontSize: '16px',
    //     },
    //     stylesBody: {
    //         display: 'block',
    //         textAlign: 'center',
    //     },
    //     renderHeader: (column) => headerElement(column, SORT_TYPE.SORT_UP, false),
    // },
];

// ref
const currPage = ref(1);
const limit = ref(30);
const params = ref({});
const columns = ref(columnsData);
const flyerList = ref([]);
const totalPage = ref(0);
const totalRecords = ref(0);

// fetch data
const { isLoading, errorMessage, isError, errorCode, data, fetchData } = useFetch(FlyersService?.getFlyerList);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getExportFlyerList = useFetch(FlyersService?.getExportFlyerList);

const headerElement = (column, sortType, isActive) => {
    return h(HeaderItem, {
        title: column?.title,
        keyColumn: column?.key,
        sortType,
        isActive,
        onHandleClick: (key, sortType, isActive) => {
            handleSortColumn(key, sortType, isActive);
        },
    });
};

const handleSortColumn = (key, sortType, isActive) => {
    columns.value = columnsData.map((e) => {
        if (e?.key === key) {
            if (!params.value.flyerName && flyerList.value?.length === 0) return e;
            let type = sortType || SORT_TYPE.SORT_UP;
            let active = isActive || false;
            if (sortType === SORT_TYPE.SORT_UP) {
                type = active ? SORT_TYPE.SORT_DOWN : SORT_TYPE.SORT_UP;
                active = true;
            } else if (sortType === SORT_TYPE.SORT_DOWN) {
                type = SORT_TYPE.SORT_UP;
                active = false;
            }
            params.value = { ...params.value, sortField: active ? key : '', sortType: active ? type : '', page: 1 };
            currPage.value = 1;
            return {
                ...e,
                renderHeader: (column) => headerElement(column, type, active),
            };
        }
        return e;
    });
};

// function
const removeImage = (src) => {
    document.querySelector(`img[src="${src}"]`)?.remove();
};
const defautlImage = (src) => {
    const img = document.querySelector(`img[src="${src}"]`);
    if (img) {
        img.src = imageDefault;
    }
};
const handleChangePage = (newPage) => {
    currPage.value = newPage;
    params.value = { ...params.value, offset: (currPage.value - 1) * limit.value };
};
const handleChangeLimit = (newLimit) => {
    limit.value = newLimit;
    params.value = { ...params.value, limit: limit.value };
};
// show flyer => can't edit
const handleReferenceNavigate = (item) => {
    router.push(
        `flyers/${encodeURIComponent(item?.flyerCode)}/${encodeURIComponent(item?.eventCode)}/${encodeURIComponent(
            item?.shopCode,
        )}/${encodeURIComponent(item?.schoolCode)}/${encodeURIComponent(
            item?.schoolYear === t('common.newStudent') ? 0 : item?.schoolYear,
        )}/0/reference`,
    );
};

// show flyer => can edit
const handleUpdateNavigate = (item) => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        router.push(
            // eslint-disable-next-line max-len
            `/flyer-update/${encodeURIComponent(item?.flyerCode)}/${encodeURIComponent(item?.eventCode)}/${encodeURIComponent(
                item?.shopCode,
            )}/${encodeURIComponent(item?.schoolCode)}/${encodeURIComponent(
                item?.schoolYear === t('common.newStudent') ? 0 : item?.schoolYear,
            )}/0/update`,
        );
    }
};
// create flyer from the flyer was choiced
const handleCreateNavigate = (item) => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        router.push(
            // eslint-disable-next-line max-len
            `flyer-registration/${encodeURIComponent(item?.flyerCode)}/${encodeURIComponent(
                item?.eventCode,
            )}/${encodeURIComponent(item?.shopCode)}/${encodeURIComponent(item?.schoolCode)}/${encodeURIComponent(
                item?.schoolYear,
            )}/0/registration`,
        );
    }
};

const handleReferenceProductNavigate = (item) => {
    router.push(
        // eslint-disable-next-line max-len
        `/flyers/${encodeURIComponent(item?.flyerCode)}/${encodeURIComponent(item?.shopCode)}/${encodeURIComponent(
            item?.eventCode,
        )}/${encodeURIComponent(item?.schoolCode)}/${encodeURIComponent(
            item?.schoolYear === t('common.newStudent') ? 0 : item?.schoolYear,
        )}/update/products`,
    );
};

const handleExportCSV = (item) => {
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        getExportFlyerList.fetchData({ flyerCode: item.flyerCode, shopCode: item.shopCode });
    }
};

// watch
watch(
    () => flyerStores?.isSearch,
    (newValue) => {
        // Ver1.2 fix bug search flyer list
        currPage.value = 1;
        params.value = {
            ...flyerStores?.params,
            userId: userStore?.userId,
            limit: limit.value,
            sortField: '',
            sortType: '',
            offset: (currPage.value - 1) * limit.value,
        };
    },
);

watch(params, (newValue) => {
    fetchData(newValue);
});

watch(isError, (newValue) => {
    if (newValue) {
        flyerList.value = [];
    }
});

watch(data, (newValue) => {
    // Ver1.2 schoolYear = 0
    flyerList.value = newValue?.result?.data?.flyerList?.map((flyer) => {
        return {
            ...flyer,
            schoolYear: flyer?.schoolYear == 0 ? t('common.newStudent') : flyer?.schoolYear,
        };
    });
    totalPage.value = newValue?.result?.data?.totalPages;
    totalRecords.value = newValue?.result?.data?.totalRecords;
});

watch(getExportFlyerList?.data, (newData) => {
    if (newData) {
        const flyerInfo = newData.result.data.flyerList;
        const schoolInfo = newData.result.data.flyerSchoolList;

        const dataCSV = convertDatatoText(flyerInfo, schoolInfo);

        const link = document.createElement('a');
        // NOTE
        // %EF%BB%BF' -> const href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(dataCSV);
        const href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(dataCSV);
        link.setAttribute('href', href);

        // set filename csv
        const time = new Date();
        const filename = 'flyer_' + formatDateOnly(time, '') + formatTimeOnly(time, '') + '.csv';

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
});
</script>

<style lang="css" src="./FlyerList.css" scoped></style>
