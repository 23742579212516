<template>
    <div
        class="product__action"
        :class="{ disabled: props?.disabled }"
        @click="
            () => {
                if (!props?.disabled) {
                    isShowDeleteModal = true;
                }
            }
        "
    >
        {{ t('products.deleteButtonContent') }}
    </div>
    <v-dialog v-model="isShowDeleteModal" width="400">
        <v-card>
            <div class="card__container">
                <TheTitle class="modal__title">
                    <h3>{{ t('products.deleteModal.title') }}</h3>
                </TheTitle>
                <div class="content__dialog">
                    <p>{{ t('products.deleteModal.modalContent') }}</p>
                    <div class="search__status">
                        <!-- Loading -->
                        <div v-if="isLoading" class="table__item--center">
                            <div class="custom-loader"></div>
                        </div>
                    </div>
                    <div v-if="errorMessage" class="errorMessages">
                        <p>* {{ errorMessage }}</p>
                    </div>
                    <div class="button__action">
                        <TheButton class="button__action--cancel" @click="isShowDeleteModal = false">
                            {{ t('products.deleteModal.cancelButtonContent') }}
                        </TheButton>
                        <TheButton
                            class="button__action--confirm"
                            @click="
                                () => {
                                    if (!props?.disabled) {
                                        handleDeleteRow({
                                            userId: useUserStore().userId,
                                            productCode: props?.productCode,
                                            flyerCode: route.params?.flyerCode,
                                            eventCode: route.params?.eventCode,
                                            shopCode: route.params?.shopCode,
                                            schoolCode: route.params?.schoolCode,
                                            schoolYear: route.params?.schoolYear,
                                        });
                                    }
                                }
                            "
                        >
                            {{ t('products.deleteModal.acceptButtonContent') }}
                        </TheButton>
                    </div>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import TheButton from '../../../../components/button/TheButton.vue';
import TheTitle from '../../../../components/title/TheTitle.vue';
import useFetch from '../../../../utils/useFetch';
import ProductRegistrationService from '../../../../services/ProductRegistrationService';
import { useUserStore } from '../../../../stores/UserStore';

// i18n
const { t } = useI18n();
const route = useRoute();

const props = defineProps({
    productCode: {
        type: String,
    },
    flyerCode: {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
});
const emits = defineEmits(['refreshProduct']);

const isShowDeleteModal = ref(false);
const { errorMessage, data, fetchData } = useFetch(ProductRegistrationService.deleteFlyerProductInfor);

watch(data, (newData) => {
    if (newData?.status === 0) {
        emits('refreshProduct');
        isShowDeleteModal.value = false;
    }
});

const handleDeleteRow = (ids, cb) => {
    if (!props?.disabled) {
        fetchData(ids);
        if (cb) cb();
    }
};
</script>

<style lang="css" src="./DeleteRow.css" scoped></style>
