<template>
    <div
        class="wrapper__badge"
        :style="{
            backgroundColor: props?.bgColor,
            color: props?.color,
            border: props?.borderColor ? `1px solid ${props?.borderColor}` : '',
        }"
    >
        <p>{{ props?.badgeContent }}</p>
    </div>
</template>

<script setup>
const props = defineProps({
    badgeContent: {
        type: String,
        required: true,
    },
    bgColor: {
        type: String,
        required: true,
        default: '#dbfddc',
    },
    color: {
        type: String,
        required: true,
        default: '#128318',
    },
    borderColor: {
        type: String,
        required: false,
        default: '',
    },
});
</script>

<style lang="css" src="./TheBadge.css" scoped></style>
