import { http } from '../utils/Axios';

class SchoolStaffService {
    static async getSchoolStaffList(params) {
        try {
            const response = await http.get('/getSchoolStaffList', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async registerSchoolStaff(body) {
        try {
            const response = await http.post('/registerSchoolStaff', body);
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async deleteSchoolStaff(body) {
        try {
            const response = await http.delete('/deleteSchoolStaff', { data: body });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getStudentListSchoolSearch(params) {
        try {
            const response = await http.get('/getStudentListSchoolSearch', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getStudentListSchoolName(params) {
        try {
            const response = await http.get('/getStudentListSchoolName', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }
}

export default SchoolStaffService;
