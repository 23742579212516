<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
        <path
            d="M7.33333 11.8334L4 8.50008L7.33333 5.16675"
            stroke="#6D6D6D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12.0001 11.8334L8.66675 8.50008L12.0001 5.16675"
            stroke="#6D6D6D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
