<template>
    <div class="notification__title">
        <div>
            <img src="../../assets/icons/bell.svg" alt="bell icon" />
        </div>
        <h2>{{ t('notification.title1') }}</h2>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

// i18n
const { t } = useI18n();
</script>

<style lang="css" src="./NotificationTitle.css" scoped></style>
