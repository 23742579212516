import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './pages/login/LoginPage.vue';
import OrdersPage from './pages/orders/OrdersPage.vue';
import SchoolStaffPage from './pages/schoolStaff/SchoolStaffPage.vue';
import StudentsPage from './pages/students/StudentsPage.vue';
import FlyersPage from './pages/flyers/FlyersPage.vue';
import FlyerRegistrationPage from './pages/flyerRegistration/FlyerRegistrationPage.vue';
import ProductRegistrationPage from './pages/productRegistration/ProductRegistrationPage.vue';
import ProductListPage from './pages/products/ProductListPage.vue';
// import NotificationsPage from './pages/notifications/NotificationsPage.vue';
// import NotificationRegistrationPage from './pages/notificationRegistration/NotificationRegistrationPage.vue';
import ShopStaffPage from './pages/shopStaff/ShopStaffPage.vue';
import ShopsPage from './pages/shops/ShopsPage.vue';
import NotFound from './pages/notFound/NotFound.vue';
import UploadPage from './pages/uploadFile/UploadPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/orders',
        },
        {
            path: '/login',
            name: 'Login Page',
            component: LoginPage,
        },
        {
            path: '/staff-list',
            name: 'ShopStaff Page',
            component: ShopStaffPage,
        },
        {
            path: '/flyers',
            name: 'Flyers Page',
            component: FlyersPage,
        },
        {
            path: '/flyers/:flyerCode/:eventCode/:shopCode/:schoolCode/:schoolYear/:procType/reference',
            name: 'Flyer Reference Page',
            component: FlyerRegistrationPage,
        },
        {
            path: '/flyer-registration',
            name: 'Flyer New Registration Page',
            component: FlyerRegistrationPage,
        },
        {
            path: '/flyer-registration/:flyerCode/:eventCode/:shopCode/:schoolCode/:schoolYear/:procType/registration',
            name: 'Flyer Registration Page',
            component: FlyerRegistrationPage,
        },
        {
            path: '/flyer-update/:flyerCode/:eventCode/:shopCode/:schoolCode/:schoolYear/:procType/update',
            name: 'Flyer Update Page',
            component: FlyerRegistrationPage,
        },
        {
            path: '/flyer-registration/upload-file',
            name: 'Upload Page',
            component: UploadPage,
        },
        {
            path: '/flyers/:flyerCode/:shopCode/:eventCode/:schoolCode/:schoolYear/:mode/products',
            name: 'Products Page',
            component: ProductListPage,
        },
        {
            path: '/flyers/:flyerCode/:shopCode/:eventCode/:schoolCode/:schoolYear/products/:mode',
            name: 'Product Registration Page',
            component: ProductRegistrationPage,
        },
        {
            path: '/flyers/:flyerCode/:shopCode/:eventCode/:schoolCode/:schoolYear/products/:oldProductCode/:mode',
            name: 'Product Update Page',
            component: ProductRegistrationPage,
        },
        // {
        //     path: '/notifications',
        //     name: 'Notifications Page',
        //     component: NotificationsPage,
        // },
        // {
        //     path: '/notifications/:noticeId',
        //     name: 'Notifications Update Page',
        //     component: NotificationRegistrationPage,
        // },
        // {
        //     path: '/notification-registration',
        //     name: 'Nofitication Registration Page',
        //     component: NotificationRegistrationPage,
        // },
        {
            path: '/orders',
            name: 'Orders Page',
            component: OrdersPage,
        },
        {
            path: '/school-staff',
            name: 'School Staff Page',
            component: SchoolStaffPage,
        },
        {
            path: '/students',
            name: 'Students Page',
            component: StudentsPage,
        },
        {
            path: '/shops',
            name: 'Shops Page',
            component: ShopsPage,
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/not-found',
        },

        {
            path: '/not-found',
            name: 'Not found',
            component: NotFound,
        },
    ],
});

export default router;
