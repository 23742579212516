<template>
    <div class="row__actions">
        <UpdateRow
            :product-code="props?.productCode"
            :flyer-code="props?.flyerCode"
            :disabled="useUserStore()?.userInfo?.affiliationKbn === 2 || useRoute().params.mode !== 'update'"
        />
        <DeleteRow
            :product-code="props?.productCode"
            :flyer-code="props?.flyerCode"
            :disabled="useUserStore()?.userInfo?.affiliationKbn === 2 || useRoute().params.mode !== 'update'"
            @refresh-product="() => emits('refreshProduct')"
        />
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useUserStore } from '../../../../stores/UserStore';
import DeleteRow from './DeleteRow.vue';
import UpdateRow from './UpdateRow.vue';
const props = defineProps({
    productCode: {
        type: String,
    },
    flyerCode: {
        type: String,
    },
});
const emits = defineEmits(['refreshProduct']);
</script>

<style lang="css" src="./TheRowActions.css" scoped></style>
