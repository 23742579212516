<template>
    <tr>
        <th v-for="column in props?.columns" :id="column?.title" :key="column?.title" :style="{ ...column?.styles }">
            <component :is="column?.renderHeader(column)" v-if="column?.renderHeader" />
            {{ !column?.renderHeader ? column?.title : null }}
        </th>
    </tr>
</template>

<script setup>
const props = defineProps({
    columns: {
        type: Array,
        required: true,
    },
});
</script>

<style lang="css" src="./TableHeader.css" scoped></style>
