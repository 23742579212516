import { http } from '../utils/Axios';
import { networkErrorMsg } from '../utils/Consts';

class OrderDetailService {
    static async getOrderDetailes(params) {
        try {
            const response = await http.get('/getOrderDetailes', { params });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }

    static async deleteOrder(params) {
        try {
            const response = await http.get('/cancelOrder', { params });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }
}

export default OrderDetailService;
