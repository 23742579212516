<template>
    <div class="wrapper__products__registration">
        <div class="container">
            <div class="content__products__registration">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification />
                    </ThePaper>
                    <!--TODO - β1,β2は、何も表示しない、もしくは固定メッセージ-->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <ThePaper class="section2">
                    <div class="products__conatiner">
                        <TheTitle class="products__header--title">
                            <h2>{{ t('products.productListTitle') }}</h2>
                        </TheTitle>
                        <div class="products__list--container">
                            <FlyerInfo :flyer="flyerInfo" />
                            <ProductList
                                :flyer="flyerInfo"
                                @refresh-product="
                                    () =>
                                        fetchData({
                                            flyerCode: decodeURIComponent(route.params?.flyerCode),
                                            eventCode: decodeURIComponent(route.params?.eventCode),
                                            schoolCode: decodeURIComponent(route.params?.schoolCode),
                                            schoolYear: decodeURIComponent(route.params?.schoolYear),
                                            shopCode: decodeURIComponent(route.params?.shopCode),
                                            procType: 1,
                                        })
                                "
                                @handle-update-postion="handleUpdatePostion"
                            />
                        </div>
                    </div>
                </ThePaper>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import TheTitle from '../../components/title/TheTitle.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import FlyerInfo from './components/flyerInfor/FlyerInfo.vue';
import ProductList from './components/productList/ProductList.vue';
import FlyersService from '../../services/FlyersService';
import ProductRegistrationService from '../../services/ProductRegistrationService';
import useFetch from '../../utils/useFetch';
import { useUserStore } from '../../stores/UserStore';

// i18n
const { t } = useI18n();

// ref
const flyerInfo = ref(null);
const route = useRoute();

// services
const { data, fetchData } = useFetch(FlyersService.getFlyerDetails);
const { fetchData: updateProductDisplay } = useFetch(ProductRegistrationService.updateProductDisplay);

// mounted
onMounted(() => {
    fetchData({
        flyerCode: decodeURIComponent(route.params?.flyerCode),
        eventCode: decodeURIComponent(route.params?.eventCode),
        schoolCode: decodeURIComponent(route.params?.schoolCode),
        schoolYear: decodeURIComponent(route.params?.schoolYear),
        shopCode: decodeURIComponent(route.params?.shopCode),
        procType: 1,
    });
});

watch(data, () => {
    flyerInfo.value = data.value?.result?.data?.flyerInfo;

    flyerInfo.value = {
        ...flyerInfo.value,
        schoolYear: flyerInfo.value?.schoolYear == 0 ? t('common.newStudent') : flyerInfo.value?.schoolYear,
    };
});

const handleUpdatePostion = (products) => {
    if (flyerInfo.value) {
        const userId = useUserStore().userId;
        const flyerCode = flyerInfo.value?.flyerCode;
        const displayOrders = products.map((product, idx) => ({
            productCode: product?.productCode,
            index: idx + 1,
        }));
        updateProductDisplay({ userId, flyerCode, displayOrders });
    }
};
</script>

<style lang="css" src="./ProductListPage.css" scoped></style>
