<template>
    <div class="wrapper__content--upload">
        <Teleport to="body">
            <component :is="DialogCustom" :show="isNoticeModal" :z-index="990">
                <BaseDialogNotice @close-modal="handleCloseModal('notice')"></BaseDialogNotice>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="isSelectionModal" :z-index="990">
                <BaseDialogShopSelection
                    @close-modal="handleCloseModal('selection')"
                    @handle-choice="
                        (code, name) => {
                            shopCode = code;
                            getGetFlyerRegisterShopName?.fetchData({ shopCode: shopCode });
                        }
                    "
                ></BaseDialogShopSelection>
            </component>
        </Teleport>
        <Teleport to="body">
            <BaseLoading :loading="postUploadFlyerListFile?.isLoading.value"></BaseLoading>
        </Teleport>
        <div class="content--title">
            <TheTitle class="title">
                <h1>{{ t('upload.title') }}</h1>
            </TheTitle>
        </div>
        <div class="content">
            <div class="action">
                <div v-if="userStore?.userInfo?.affiliationKbn === 1" class="select__action">
                    <label class="input__item--label" for="shopCode">
                        {{ t('flyerRegistration.shopName') }}
                        <strong>{{ t('common.required') }}</strong>
                    </label>
                    <div class="input__item">
                        <input
                            id="shopCode"
                            v-model="shopCode"
                            class="input"
                            autocomplete="off"
                            :placeholder="t('common.placeholder2')"
                            :required="validateFormData?.shopCode"
                            @blur="handleFetchShopName"
                        />
                        <TheButton
                            class="button"
                            @click="
                                () => {
                                    isSelectionModal = true;
                                }
                            "
                        >
                            {{ t('flyerRegistration.labelButton14') }}
                        </TheButton>
                        <div
                            v-if="!getGetFlyerRegisterShopName?.isLoading.value"
                            class="text__item--name"
                            :class="{
                                error: getGetFlyerRegisterShopName?.isError.value,
                            }"
                        >
                            <p v-if="errorShopName !== ''">{{ errorShopName }}</p>
                            <p v-if="shopName !== ''">{{ shopName }}</p>
                        </div>
                        <div v-else class="custom-loader"></div>

                        <p v-if="validateFormData?.shopCode && errorShopName === ''" class="validate__message">
                            {{ t('error.validateMessage8', { name: t('flyerRegistration.shopName') }) }}
                        </p>
                    </div>
                </div>
                <div class="upload__action">
                    <TheButton class="upload-button" variant="outlined" @click="handleChoiceFile">
                        {{ t('upload.buttonLabel1') }}
                    </TheButton>
                    <input ref="inputRef" class="input-file" type="file" hidden accept=".csv" @change="handleChangeFile" />
                    <!-- <p class="filename" @click="handleChoiceFile">{{ t('upload.filename') }}</p> -->
                    <p v-if="errorFile" class="filename-error">{{ t('error.fileError') }}</p>
                    <p v-else class="filename" @click="handleChoiceFile">{{ filename }}</p>
                </div>
            </div>
            <div class="upload-message">
                <BaseErrorMessage :error-messages="errorMessages"></BaseErrorMessage>
            </div>
            <div class="action--footer">
                <TheButton variant="outlined" @click="router.push('/flyers')"> {{ t('upload.buttonLabel2') }}</TheButton>
                <TheButton @click="handleUploadFile"> {{ t('upload.buttonLabel3') }}</TheButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import BaseLoading from '../../../../components/loading/BaseLoading.vue';
import BaseDialogNotice from '../dialog/BaseDialogNotice.vue';
import BaseDialogShopSelection from '../dialog/BaseDialogShopSelection.vue';
import BaseErrorMessage from '../message/BaseErrorMessage.vue';
import { useUserStore } from '../../../../stores/UserStore';
import { useRouter } from 'vue-router';
import useFetch, { useFetching } from '../../../../utils/useFetch';
import FlyersService from '../../../../services/FlyersService';
import FlyerRegistrationService from '../../../../services/FlyerRegistrationService';
import { checkValidate } from '../../../../utils/Helpers';

// vue-i18n
const { t } = useI18n();

// stores
const userStore = useUserStore();

// router
const router = useRouter();

// ref variable
const inputRef = ref();
const filename = ref('');
const errorFile = ref(false);
const fileValue = ref(undefined);
const shopCode = ref('');
const shopName = ref('');
const errorShopName = ref('');

// ref modal
const isNoticeModal = ref(false);
const isSelectionModal = ref(false);

// validates
const validateFormData = reactive({
    shopCode: false,
});

// services
const postUploadFlyerListFile = useFetching(FlyersService?.postUploadFlyerListFile);
const getGetFlyerRegisterShopName = useFetch(FlyerRegistrationService?.getFlyerRegisterShopName);
const errorMessages = ref([]);

// functions
const validation = () => {
    if (shopCode.value === '') {
        validateFormData.shopCode = true;
    }
};

const handleFetchShopName = () => {
    if (shopCode.value) {
        validateFormData.shopCode = false;
        getGetFlyerRegisterShopName?.fetchData({ shopCode: shopCode.value });
    } else {
        shopName.value = '';
    }
};

const handleChoiceFile = () => {
    filename.value = '';
    fileValue.value = undefined;
    errorFile.value = false;
    errorMessages.value = [];
    inputRef.value.click();
};

const handleChangeFile = (event) => {
    if (event?.target?.files[0]?.type !== 'text/csv') {
        filename.value = '';
        fileValue.value = undefined;
        errorFile.value = true;
        return;
    }
    const file = event.target.files[0];
    filename.value = file.name;
    errorFile.value = false;
    fileValue.value = file;
    inputRef.value.value = '';
};

const handleUploadFile = async () => {
    validation();
    if (!checkValidate(validateFormData)) {
        return;
    }
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        if (userStore?.userInfo?.affiliationKbn === 3) {
            shopCode.value = userStore?.userInfo?.shopCd;
        }
        const form = new FormData();
        form.append('fileCSV', fileValue.value);
        errorMessages.value = [];
        await postUploadFlyerListFile.fetchData({ userId: userStore?.userId, shopCode: shopCode.value }, form);
    }
};

const handleCloseModal = (name) => {
    switch (name) {
        case 'notice':
            isNoticeModal.value = false;
            fileValue.value = undefined;
            filename.value = '';
            break;
        case 'selection':
            isSelectionModal.value = false;
            break;
        default:
        // code block
    }
};

watch(getGetFlyerRegisterShopName?.isError, (newIsError) => {
    if (newIsError) {
        validateFormData.shopCode = true;
        errorShopName.value = t('error.validateMessage14', { name: shopCode.value });
        shopName.value = '';
    }
});

watch(getGetFlyerRegisterShopName?.data, (newData) => {
    if (newData) {
        errorShopName.value = '';
        shopName.value = newData?.result?.data?.shopName;
    }
});

watch(postUploadFlyerListFile?.isError, (newIsError) => {
    if (newIsError) {
        errorMessages.value = [...postUploadFlyerListFile.errorMessage.value];
    }
});

watch(postUploadFlyerListFile?.isSuccess, (newIsSuccess) => {
    if (newIsSuccess) {
        errorMessages.value = [];
        isNoticeModal.value = true;
    }
});

watch(
    () => userStore?.userInfo,
    (newUser) => {
        // teacher
        if (newUser?.affiliationKbn === 2) {
            router.push('/flyers');
        }

        // shop
        if (newUser?.affiliationKbn === 3) {
            shopCode.value = newUser?.shopCd;
            shopName.value = newUser?.shopName;
        }

        if (newUser?.affiliationKbn === 3) {
            shopCode.value = newUser?.shopCd;
            shopName.value = newUser?.shopName;
        }
    },
);

watch(shopCode, (newShopCode) => {
    if (newShopCode !== '') {
        validateFormData.shopCode = false;
    }
});

onMounted(() => {
    if (userStore?.userInfo?.affiliationKbn === 2) {
        router.push('/flyers');
    }

    if (userStore?.userInfo?.affiliationKbn === 3) {
        shopCode.value = userStore?.userInfo?.shopCd;
        shopName.value = userStore?.userInfo?.shopName;
    }
});
</script>

<style src="./BaseContent.css" scoped></style>
