import { reactive, ref } from 'vue';
import { defineStore } from 'pinia';

export const useProductRegistrationStores = defineStore('productRegistrationStores', () => {
    const productId = ref('');
    const flyerCode = ref('');
    /**
     * 0: default
     * 1: navigate
     * 2: register success
     */
    const isNotice = ref(0);
    /**
     * 0: register
     * 1: update
     */
    const registerStatus = ref(0);
    const titleNotice = ref('');
    const contentNotice = ref('');
    const navigate = ref(false);
    const phoneReview = reactive({
        categoryName: '',
        productName: '',
        requiredPurchase: false,
        taxIncludedPrice: '',
        taxNormalPrice: '',
        productDetail: {
            detail1: '',
            detail2: '',
            detail3: '',
        },
    });
    const mainImageUrl = ref('');
    const detailImageUrls = ref(['']);

    function setProductId(value) {
        productId.value = value;
    }
    function setFlyerCode(value) {
        flyerCode.value = value;
    }
    function setIsNotice(value) {
        isNotice.value = value;
    }
    function setRegisterStatus(value) {
        registerStatus.value = value;
    }
    function setTitleNotice(value) {
        titleNotice.value = value;
    }
    function setContentNotice(value) {
        contentNotice.value = value;
    }
    function setNavigate(value) {
        navigate.value = value;
    }
    function setPhoneReview(value) {
        phoneReview.categoryName = value.categoryName;
        phoneReview.productName = value.productName;
        phoneReview.requiredPurchase = value.requiredPurchase;
        phoneReview.taxIncludedPrice = value.taxIncludedPrice;
        phoneReview.taxNormalPrice = value.taxNormalPrice;
        phoneReview.productDetail = value.productDetail;
    }
    function setMainImageUrl(value) {
        mainImageUrl.value = value;
    }
    function setDetailImageUrls(value, index) {
        if (index) {
            detailImageUrls.value[index - 1] = value;
        } else {
            detailImageUrls.value = value;
        }
    }
    function reset() {
        setProductId('');
        setFlyerCode('');
        setIsNotice(0);
        setRegisterStatus(0);
        setTitleNotice('');
        setContentNotice('');
        setNavigate(false);
        setPhoneReview({
            categoryName: '',
            productName: '',
            requiredPurchase: false,
            taxIncludedPrice: '',
            taxNormalPrice: '',
            productDetail: {
                detail: '',
                detail1: '',
                detail2: '',
            },
        });
        setDetailImageUrls(['']);
        setMainImageUrl('');
    }
    return {
        productId,
        setProductId,
        flyerCode,
        setFlyerCode,
        isNotice,
        setIsNotice,
        registerStatus,
        setRegisterStatus,
        titleNotice,
        setTitleNotice,
        contentNotice,
        setContentNotice,
        navigate,
        setNavigate,
        phoneReview,
        setPhoneReview,
        mainImageUrl,
        setMainImageUrl,
        detailImageUrls,
        setDetailImageUrls,
        reset,
    };
});
