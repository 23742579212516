<template>
    <draggable
        v-model="myArray"
        :item-key="uniqueAttr"
        ghost-class="ghost"
        :animation="200"
        handle=".handle"
        @start="drag = true"
        @end="drag = false"
    >
        <template #item="{ element }">
            <tr>
                <TableRowCell
                    v-for="(column, columnIndex) in props?.columns"
                    :key="`table-row-cell-${columnIndex}`"
                    :item="element"
                    :column="column"
                />
            </tr>
        </template>
    </draggable>
</template>

<script setup>
import { ref, watch } from 'vue';
import draggable from 'vuedraggable';

import TableRowCell from '../table/TableRowCell.vue';

const props = defineProps({
    data: {
        type: Array,
        required: false,
    },
    columns: {
        type: Array,
        required: true,
    },
    uniqueAttr: {
        type: String,
        required: true,
    },
});
const emits = defineEmits(['handleUpdatePostion']);

const myArray = ref(props?.data);
watch(
    () => props.data,
    () => {
        myArray.value = props?.data;
    },
);
watch(myArray, () => {
    emits('handleUpdatePostion', myArray.value);
});
const drag = ref(false);
</script>

<style lang="css" src="./TableRow.css" scoped></style>
