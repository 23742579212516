<template>
    <section class="page_404">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="col-sm-10 col-sm-offset-1 text-center">
                        <div class="four_zero_four_bg">
                            <h1 class="text-center">{{ t('404') }}</h1>
                        </div>
                        <div class="contant_box_404">
                            <h3 class="h2">{{ t('404Heading') }}</h3>
                            <p>{{ t('404Paragraph') }}</p>
                            <router-link to="/" class="link_404">{{ t('404Link') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

import { useCommonStores } from '../../stores/CommonStores.js';

// i18n
const { t } = useI18n();

const commonStores = useCommonStores();
commonStores.setShowHeader(false);
commonStores.setShowSidebar(false);
</script>

<style lang="css" src="./NotFound.css" scoped></style>
