import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export const vuetifyConfig = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
});
