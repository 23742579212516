<template>
    <div class="wrapper__order__status">
        <div class="order__status--title">
            <TheTitle class="title">
                <h1>{{ t('order.orderStatus.title') }}</h1>
            </TheTitle>
        </div>
        <div class="order__status--content">
            <!--GI#4_1_9, GI#4_1_10, GI#4_1_11, GI#4_1_12-->
            <!-- 1: countWaitingPayment : 支払い待ち    =====   2: countOrderComplete   :   注文完了    -->
            <!-- 5: countShopOrdered    : 発注済       =======  6: countShopDelivered   :   納品済      -->
            <!-- 4: countShopOrderCancel: 注文取消     =======  3: countCancel          :   キャンセル  -->
            <StatusTag
                v-for="(tag, index) in statusTag"
                :key="index"
                :key-name="tag?.key"
                :description="tag?.description"
                :color="tag?.color"
                :bg-color="tag?.bgColor"
                :border-color="tag?.borderColor"
            ></StatusTag>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import StatusTag from './StatusTag.vue';
import TheTitle from '../../../../components/title/TheTitle.vue';
import { statusTag } from '../../../../utils/Consts.js';

// i18n
const { t } = useI18n();
</script>

<style lang="css" src="./BaseOrderStatus.css" scoped></style>
