<template>
    <select v-model="value" class="select__custom">
        <option value="30">{{ t('common.unit', { unit: 30 }) }}</option>
        <option value="50">{{ t('common.unit', { unit: 50 }) }}</option>
        <option value="100">{{ t('common.unit', { unit: 100 }) }}</option>
    </select>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

// i18n
const { t } = useI18n();
const props = defineProps({
    modelValue: {
        type: Number,
        require: false,
    },
});
const emits = defineEmits(['update:modelValue']);
const value = ref(props.modelValue);

watch(value, (newValue) => {
    emits('update:modelValue', Number(newValue));
});
</script>

<style lang="css" src="./SelectField.css" scoped></style>
