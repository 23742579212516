export const CONFIG = {
    /** honban*/
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'https://shbo.conet-hiroshima.jp/Stage/',
    BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'https://qn36nabm9e.execute-api.ap-northeast-1.amazonaws.com/prd/',
    /** test */
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'https://shbo-dev.conet-hiroshima.jp/Stage/',
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'https://d5e5svkjog.execute-api.ap-northeast-1.amazonaws.com/Stage/',
    /** uat */
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'https://shbo-uat.conet-hiroshima.jp/Stage/',
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'https://5b36s41ce2.execute-api.ap-northeast-1.amazonaws.com/Stage/',
    /** cloud9 */
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'http://52.68.1.253:3002/',
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'http://52.68.1.253:3000/',
    /** localhost */
    // BASE_URL_BE: process?.env?.VUE_APP_BASE_URL_BE || 'http://localhost:3002/',
    /** example */
    // BASE_URL_BE: process.env.VUE_APP_BASE_URL_BE || 'https://pokeapi.co/api',
};
