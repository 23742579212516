import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { CONFIG } from '../Config.js';
import awsmobile from '../aws-exports.js';
import { useCommonStores } from '../stores/CommonStores.js';

export const http = axios?.create({
    baseURL: CONFIG.BASE_URL_BE,
    header: {
        Accept: '*/*',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Credentials': true,
        'X-Requested-With': 'XMLHttpRequest',
    },
});

http.interceptors.request.use(
    async (config) => {
        Amplify.configure(awsmobile);
        const auth = await fetchAuthSession();
        if (auth?.tokens?.idToken) {
            config.headers.Authorization = `Bearer ${auth?.tokens?.idToken?.toString()}`;
        } else {
            useCommonStores().setExpired(true);
        }
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    },
);
