<template>
    <BaseDialog :title="title" :content="content">
        <template #default>
            <div class="more__action">
                <div class="button__action">
                    <TheButton class="button__action--cancel" @click="handleCloseModal">いいえ</TheButton>
                    <TheButton class="button__action--confirm" @click="handleDeleteShop">はい</TheButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { watch } from 'vue';
import BaseDialog from './BaseDialog.vue';
import TheButton from '../../../../../../components/button/TheButton.vue';
import { useUserStore } from '../../../../../../stores/UserStore.js';
import ShopsService from '../../../../../../services/ShopsService.js';
import { useShopStores } from '../../../../../../stores/ShopStores.js';
import useFetch from '../../../../../../utils/useFetch';

const title = '販売店削除';
const content = '販売店を削除します。よろしいですか？';

// store
const userStore = useUserStore();
const shopStores = useShopStores();

// fetch
const { isError, errorMessage, data, fetchData } = useFetch(ShopsService.deleteShop);

// function
const handleCloseModal = () => {
    shopStores?.setShopDeleteConfirm(false);
};

const handleDeleteShop = async () => {
    await fetchData({
        userId: userStore?.userId,
        shopCode: shopStores?.shopCode,
    });
    shopStores?.setShopDeleteConfirm(false);
};

// watch
watch(isError, (newValue) => {
    if (newValue) {
        shopStores?.setErrorContent(errorMessage.value);
        shopStores?.setShopDeleteResult(true);
    }
});

watch(data, (newValue) => {
    shopStores?.setShopDeleteConfirm(false);
    shopStores?.setShopCRUDStatus(4);
    shopStores?.setShopListRefreshData();
    shopStores?.setShopList(true);
    shopStores?.setShopCode('');
});
</script>

<style lang="css" src="./DeleteConfirmDialog.css" scoped></style>
