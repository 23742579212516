<template>
    <NotificationTitle />
    <div v-if="dummyData?.length > 0" class="notification__items" :class="{ collapse: !props?.isExpand }">
        <NotificationItem
            v-for="(item, index) in dummyData"
            :key="index"
            :date="item?.noticeDate"
            :content="item?.noticeText"
            :color="item?.noticeType"
            :visited="new Date(currentDate) > new Date(item?.noticeDate)"
        />
    </div>
    <div v-if="length === 0 || isError" class="message_notification" :class="{ collapse: !props?.isExpand }">
        <p>{{ t('error.validateMessage15') }}</p>
    </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import NotificationItem from './NotificationItem.vue';
import NotificationTitle from './NotificationTitle.vue';
import NotificationService from '../../services/NotificationsService.js';
import useFetch from '../../utils/useFetch';
import { useUserStore } from '../../stores/UserStore.js';
import { useI18n } from 'vue-i18n';

// i18n
const { t } = useI18n();

// stores
const userStore = useUserStore();

// service
const { data, isError, errorMesage, fetchData } = useFetch(NotificationService?.getNoticeDisplay);

// props
const props = defineProps({
    isExpand: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const currentDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
const dummyData = ref([]);

// computed
const length = computed(() => {
    return data?.result?.data?.noticeList;
});

// watch
// listen error when call getNoticeDisplay API
watch(isError, (newError) => {
    if (newError) {
        console.log(errorMesage);
    }
});
// listen response when call getNoticeDisplay API
watch(data, (newData) => {
    if (newData) {
        dummyData.value = newData?.result?.data?.noticeList;
    }
});

fetchData({ userId: userStore?.userId });
</script>

<style src="./BaseNotification.css" scoped></style>
