<template>
    <div class="row__actions" @click="() => emits('click', props.schoolCode)">選択</div>
</template>
<script setup>
const props = defineProps({
    schoolCode: {
        type: String,
        require: true,
    },
});
const emits = defineEmits(['click']);
</script>
<style lang="css" src="./TheRowActions.css" scoped></style>
