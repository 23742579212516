<template>
    <div class="wrapper__base__dialog--notice">
        <TheTitle class="dialog--title">
            <h2>
                {{ t('upload.dialog.dialogTitle1') }}
                <slot name="icon-close"></slot>
            </h2>
        </TheTitle>
        <div class="dialog--content">
            <div class="dialog--text">{{ t('upload.dialog.dialogContent1') }}</div>
            <div class="dialog--text">{{ t('upload.dialog.dialogContent2_1') }}</div>
            <div class="dialog--text">{{ t('upload.dialog.dialogContent2_2') }}</div>
            <div class="action">
                <TheButton class="button__action--cancel" @click="handleCloseModal">{{ t('confirm.content3') }}</TheButton>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheButton from '../../../../components/button/TheButton.vue';

// i18n
const { t } = useI18n();

// emits
const emits = defineEmits(['closeModal']);

// function
const handleCloseModal = () => {
    emits('closeModal');
};
</script>

<style lang="css" src="./BaseDialogNotice.css" scoped></style>
