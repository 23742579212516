<template>
    <span
        class="header__item"
        :style="props?.styles"
        @click="emits('handleClick', props?.keyColumn, props?.sortType, props?.isActive)"
    >
        {{ props?.title }}
        <img
            v-if="props?.sortType === SORT_TYPE.SORT_UP"
            src="@/assets/icons/arrow-up.svg"
            alt="icon-sort-up"
            class="sort__icon"
            :class="{ active: props?.isActive }"
        />
        <img
            v-if="props?.sortType === SORT_TYPE.SORT_DOWN"
            src="@/assets/icons/arrow-down.svg"
            alt="icon-sort-down"
            class="sort__icon"
            :class="{ active: props?.isActive }"
        />
    </span>
</template>
<script setup>
import { SORT_TYPE } from '../../utils/Consts';
const props = defineProps({
    title: {
        type: String,
        require: true,
    },
    keyColumn: {
        type: String,
        require: true,
    },
    sortType: {
        type: String,
        require: false,
    },
    isActive: {
        type: Boolean,
        default: false,
    },
    styles: {
        type: Object,
        require: false,
    },
});
const emits = defineEmits(['handleClick']);
</script>
<style lang="css" src="./HeaderItem.css" scoped></style>
