<template>
    <Authenticator :hide-sign-up="true">
        <template #sign-in-header>
            <div id="img__login__page" style="{ padding: var(--amplify-space-large), }">
                <img class="amplify-image" src="./assets/img/conet_logo_login.png" alt="conet_logo_login" />
            </div>
        </template>
        <template #default="{ user }">
            <BaseHeader v-if="commonStores?.showHeader" :user-id="user.userId"></BaseHeader>
            <BaseSidebar v-if="commonStores?.showSidebar"></BaseSidebar>
            <RouterView></RouterView>
        </template>
    </Authenticator>
</template>

<script setup>
import '@aws-amplify/ui-vue/styles.css';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
import { Authenticator, translations } from '@aws-amplify/ui-vue';
import { I18n, Hub } from '@aws-amplify/core';
import BaseSidebar from './components/sidebar/BaseSidebar.vue';
import BaseHeader from './components/header/BaseHeader.vue';
import { useSidebarStore } from './stores/SidebarStore.js';
import { useCommonStores } from './stores/CommonStores';
import { useRouter } from 'vue-router';
// store
const sidebarStore = useSidebarStore();
const commonStores = useCommonStores();

// router
const router = useRouter();

I18n.putVocabularies(translations);
I18n.setLanguage('ja');
I18n.putVocabularies({
    ja: require('./locales/ja.json'),
});
Amplify.configure(awsconfig);

// START TODO
Hub.listen('auth', ({ payload }) => {
    if (payload?.event === 'signedIn') {
        router.push('/orders');
    }
    if (payload?.event === 'signedOut') {
        router.push('/login');
    }
});
// END TODO

window.addEventListener('click', (e) => {
    if (document?.querySelector('.container')?.contains(e?.target)) {
        sidebarStore?.setKeyActive(null);
        sidebarStore?.setExpand(false);
    }
});
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');
html {
    overflow: hidden;
}
#app {
    font-family: 'Nato Sans JP', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100vh;
}
#app div[data-amplify-authenticator] {
    margin: auto;
    height: 100vh;
}
#img__login__page {
    margin-top: 44px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-size: 14px;
    position: relative;
}
.button__delete:hover {
    opacity: 0.85;
}
.v-input__details {
    display: none;
}
.v-field__input {
    display: none;
}
.v-field {
    padding: 8px;
}
.custom-loader {
    width: 30px;
    height: 30px;
    display: grid;
    border-radius: 50%;
    mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
    -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
    background:
        linear-gradient(0deg, #1778ca80 50%, #1778caff 0) center/4px 100%,
        linear-gradient(90deg, #1778ca40 50%, #1778cabf 0) center/100% 4px;
    background-repeat: no-repeat;
    animation: s3 1s infinite steps(12);
}
.custom-loader::before,
.custom-loader::after {
    content: '';
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
}
.custom-loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
}
@keyframes s3 {
    100% {
        transform: rotate(1turn);
    }
}
.v-window__controls {
    padding: 0;
}
.v-btn--icon.v-btn--size-default {
    width: 1.6rem;
    height: 1.6rem;
}
.v-carousel__controls__item .v-icon {
    opacity: 1;
}
.v-btn--icon.v-btn--density-default {
    width: auto;
    height: auto;
}
.image__product--list .v-btn--icon .v-icon {
    --v-icon-size-multiplier: 0.8;
}
.v-btn--size-x-small {
    --v-btn-size: 0.475rem;
}
.v-carousel__controls__item {
    margin: 0 2px;
}
.tag__info--productlist .tag__information--value {
    cursor: default;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.group__slide .v-window__container .v-window-item .v-responsive .v-responsive__content {
    height: 100% !important;
}
.banner__image .v-btn--icon .v-icon {
    --v-icon-size-multiplier: 1;
}
</style>
