<template>
    <div class="wrapper__dialog--shop">
        <header class="header__dialog--shop">
            <TheTitle class="title__dialog">
                <h2>{{ t('flyerRegistration.dialog.titleDialogShop') }}</h2>
            </TheTitle>
            <div class="action__dialog--close">
                <img :src="closeIcon" alt="close-icon" @click="handleCloseModal" />
            </div>
        </header>
        <div class="form__search--shop">
            <div class="form__search--list">
                <FormInput
                    class="form__search--item col-8"
                    :label="t('flyerRegistration.dialog.labelInput2')"
                    :error="validate.check"
                    :error-message="t('error.validateMessage8', { name: t('flyerRegistration.dialog.commonMessage') })"
                >
                    <template #default
                        ><!-- Ver 1.2 #9 -->
                        <input v-model.trim="shopName" class="form__item--input" @keyup.enter="handleSearch" />
                    </template>
                </FormInput>
                <FormInput
                    class="form__search--item col-8"
                    :label="t('flyerRegistration.dialog.labelInput3')"
                    :error="validate.check"
                    :error-message="t('error.validateMessage8', { name: t('flyerRegistration.dialog.commonMessage') })"
                >
                    <template #default>
                        <!-- Ver 1.2 #9 -->
                        <input v-model.trim="address1" class="form__item--input" @keyup.enter="handleSearch" />
                    </template>
                </FormInput>
                <FormInput
                    class="form__search--item col-8"
                    :label="t('flyerRegistration.dialog.labelInput4')"
                    :error="validate.check"
                    :error-message="t('error.validateMessage8', { name: t('flyerRegistration.dialog.commonMessage') })"
                >
                    <template #default>
                        <!-- Ver 1.2 #9 -->
                        <input v-model.trim="representativeName" class="form__item--input" @keyup.enter="handleSearch" />
                    </template>
                </FormInput>
            </div>
            <div class="actions--list">
                <TheButton class="action--item" @click="handleSearch">
                    {{ t('flyerRegistration.labelButton12') }}
                </TheButton>
                <TheButton class="action--item" @click="handleRefreshParams">
                    {{ t('flyerRegistration.labelButton13') }}
                </TheButton>
            </div>
        </div>
        <div class="table--shop">
            <ShopList
                :params-search="params"
                :close="closeModal"
                @handle-choice-shop="(item) => handleChoiceShop(item)"
            ></ShopList>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TheButton from '../../../../components/button/TheButton.vue';
import TheTitle from '../../../../components/title/TheTitle.vue';
import closeIcon from '../../../../assets/icons/close-icon.svg';
import FormInput from '../formItem/FormInput.vue';
import ShopList from '../shopList/ShopList.vue';
import { useFlyerRegistrationStores } from '../../../../stores/FlyerRegistrationStores.js';

// i18n
const { t } = useI18n();

// store
const flyerRegistrationStores = useFlyerRegistrationStores();

// emits
const emits = defineEmits(['handleChoice']);

// ref
const shopName = ref('');
const address1 = ref('');
const representativeName = ref('');
const validate = reactive({
    check: false,
});
const closeModal = ref(true);
const params = ref({});
// function
const handleCloseModal = () => {
    handleRefreshParams();
    validate.check = false;
    flyerRegistrationStores?.setIsModalShopList(false);
    closeModal.value = !closeModal.value;
};
const handleSearch = () => {
    if (!shopName.value && !address1.value && !representativeName.value) {
        validate.check = true;
    } else {
        params.value = {
            shopName: shopName.value,
            address1: address1.value,
            representativeName: representativeName.value,
        };
    }
};
const handleRefreshParams = () => {
    shopName.value = '';
    address1.value = '';
    representativeName.value = '';
};
const handleChoiceShop = (item) => {
    emits('handleChoice', item?.shopCode, item?.shopName);
    handleCloseModal();
};

// watch
watch(shopName, (newShopName) => {
    validate.check = false;
});
watch(address1, (newAddress1) => {
    validate.check = false;
});
watch(representativeName, (newRepresentativeName) => {
    validate.check = false;
});
</script>

<style lang="css" src="./BaseDialogShop.css" scoped></style>
