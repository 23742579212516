<template>
    <tr
        v-for="(row, itemIndex) in props?.data"
        :key="`table-body-${itemIndex}`"
        :class="{ isActive: props.uniqueKey && active === row[props.uniqueKey], isGray: row.isGray }"
        @click="() => handleChangeRow(row[props.uniqueKey])"
    >
        <TableRowCell
            v-for="(column, columnIndex) in props?.columns"
            :key="`table-row-cell-${itemIndex}-${columnIndex}`"
            :item="row"
            :column="column"
        />
    </tr>
</template>

<script setup>
import { ref } from 'vue';

import TableRowCell from './TableRowCell.vue';

const props = defineProps({
    data: {
        type: Array,
        required: false,
    },
    columns: {
        type: Array,
        required: true,
    },
    uniqueKey: String,
});
const emits = defineEmits(['change']);

const active = ref(false);

const handleChangeRow = (value) => {
    active.value = value;
    emits('change', value);
};
</script>

<style lang="css" src="./TableRow.css" scoped></style>
