import { http } from '../utils/Axios';

class FlyersService {
    static async postUploadFlyerListFile(params, body) {
        try {
            const response = await http.post(
                '/uploadFlyerListFile',
                body,
                { params },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getExportFlyerList(params) {
        try {
            const response = await http.get('/exportFlyerList', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerSearchList(params) {
        try {
            const response = await http.get('/getFlyerSearchList', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerList(params) {
        try {
            const response = await http.get('/getFlyerList', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }

    static async getFlyerDetails(params) {
        try {
            const response = await http.get('/getFlyerDetails', { params });
            return response?.data;
        } catch (error) {
            let message = '';
            if (error?.response) {
                message = error?.response?.data ? error?.response?.data?.result?.message : error?.response?.statusText;
            } else {
                message = error?.message;
            }
            throw new Error(message);
        }
    }
}

export default FlyersService;
