<template>
    <div class="wrapper__shop">
        <div class="container">
            <div class="content__shop">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!--TODO - β1,β2は、何も表示しない、もしくは固定メッセージ-->
                    <!-- <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <!--販売店情報表示更新-->
                <div v-show="shopStores?.shopCRUDStatus !== 4" class="section2">
                    <ThePaper class="section2_all">
                        <ShopCRUD></ShopCRUD>
                    </ThePaper>
                </div>
                <!-- 販売店一覧 -->
                <div v-show="shopStores?.shopList" class="section2">
                    <div class="section2__left">
                        <!--注文検索-->
                        <ThePaper class="section2__left--sub1">
                            <BaseSearchShop />
                        </ThePaper>
                    </div>
                    <ThePaper class="section2__right">
                        <!--注文情報一覧-->
                        <ShopsList />
                    </ThePaper>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onUnmounted } from 'vue';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import BaseSearchShop from './components/search/BaseSearchShop.vue';
import ShopsList from './components/ShopsList.vue';
import ShopCRUD from './components/shop/ShopCRUD.vue';
import { useShopStores } from '../../stores/ShopStores';
import { useCommonStores } from '../../stores/CommonStores';

const shopStores = useShopStores();
const commonStores = useCommonStores();

// Unmounted
onUnmounted(() => {
    shopStores?.reset();
});
</script>

<style src="./ShopsPage.css" scoped></style>
