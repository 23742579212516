<template>
    <div class="order__detail">
        <TheTitle class="order__detail--title">
            <h2>{{ t('orderDetail.table.title') }}</h2>
        </TheTitle>
        <div class="order__detail--table">
            <TheTable :columns="columns" :data="orderDetailStores?.orderDetailItemList" class="table__detail" />
        </div>
        <div class="order__price">
            <div class="order__detail--postage">
                <div class="postage__key">{{ t('orderDetail.table.postage') }}</div>
                <div class="postage__content">
                    {{ orderDetailStores?.orderDetailInfo?.delvFee ? orderDetailStores?.orderDetailInfo?.delvFee : '' }}
                </div>
            </div>
            <div class="order__detail--total__amount">
                <div class="total__amount__key">{{ t('orderDetail.table.payAmount') }}</div>
                <div class="total__amount__content">
                    {{ orderDetailStores?.orderDetailInfo?.payAmount ? orderDetailStores?.orderDetailInfo?.payAmount : '' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, h } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheTable from '../../../../components/table/TheTable.vue';
import { useOrderDetailStores } from '../../../../stores/OrderDetailStores.js';
import TheToolTip from '../../../../components/tooltip/TheToolTip.vue';

// i18n
const { t } = useI18n();

// store
const orderDetailStores = useOrderDetailStores();

const delvFee = ref(0);
const payAmount = ref(0);
orderDetailStores?.orderDetailItemList?.forEach((item) => {
    delvFee.value += item?.delvFee;
    payAmount.value += item?.orderQuantity * item?.unitPrice;
});

const columns = [
    {
        key: 'productCode',
        title: t('orderDetail.table.productCode'),
        styles: {
            maxWidth: '160px',
        },
    },
    {
        key: 'productName',
        title: t('orderDetail.table.productName'),
        styles: {
            whiteSpace: 'wrap',
            maxWidth: '440px',
            minWidth: '440px',
        },
        render: (column, item) => ToolTipElement(column, item),
    },
    {
        key: 'orderQuantity',
        title: t('orderDetail.table.orderQuantity'),
        styles: {
            maxWidth: '160px',
            textAlign: 'right',
        },
    },
    {
        key: 'unitPrice',
        title: t('orderDetail.table.unitPrice'),
        styles: {
            maxWidth: '200px',
            textAlign: 'right',
        },
    },
    {
        key: 'subTotal',
        title: t('orderDetail.table.subTotal'),
        styles: {
            maxWidth: '200px',
            textAlign: 'right',
            padding: '8px 25px 8px 8px',
        },
        stylesBody: {
            maxWidth: '183px',
            textAlign: 'right',
            padding: '8px 8px 8px 8px',
        },
    },
];

const ToolTipElement = (column, item) => h(TheToolTip, { content: item[column?.key] });
</script>

<style lang="css" src="./BaseDetailTable.css" scoped></style>
