<template>
    <div class="wrapper__flyer">
        <Teleport to="body">
            <component :is="DialogCustom" :show="flyerRegistrationStores?.isModalShopList" :z-index="990">
                <BaseDialogShop
                    @handle-choice="
                        (code, name) => {
                            shopCode = code;
                            fetchGetFlyerRegisterShopName?.fetchData({ shopCode });
                        }
                    "
                >
                </BaseDialogShop>
            </component>
        </Teleport>
        <div class="flyer--title">
            <TheTitle class="title">
                <h1>{{ t('order.flyer.title') }}</h1>
            </TheTitle>
            <div class="flyer--action">
                <!--GI#4_1_1-->
                <!-- Ver1.2 #11 -->
                <TheButton
                    class="flyer--button"
                    :disabled="
                        (!shopCode && userStore?.userInfo?.affiliationKbn !== 2) ||
                        (!school && userStore?.userInfo?.affiliationKbn === 2)
                    "
                    @click="handleSearch"
                >
                    <template #left-icon>
                        <img
                            :src="
                                !(
                                    (!shopCode && userStore?.userInfo?.affiliationKbn !== 2) ||
                                    (!school && userStore?.userInfo?.affiliationKbn === 2)
                                )
                                    ? iconSearch
                                    : iconSearchGray
                            "
                            alt="search-icon"
                        />
                    </template>
                    <p>{{ t('order.flyer.searchName') }}</p>
                </TheButton>
                <img
                    class="icon__show"
                    :class="{ expand: expandOptions, collapse: !expandOptions }"
                    src="../../../../assets/icons/chevrons-down.svg"
                    alt="chevrons-down-icon"
                    @click="handleShowStatus"
                />
            </div>
        </div>
        <transition mode="out-in">
            <div v-if="expandOptions" class="flyer__selection--list">
                <!-- 販売店 -->
                <div v-if="userStore?.userInfo?.affiliationKbn === 1" class="flyer__selection--item">
                    <div class="item__label">
                        {{ t('order.flyer.shop') }} <strong>{{ t('common.required') }}</strong>
                    </div>
                    <div class="item__selection">
                        <TheButton class="select__button" @click="() => flyerRegistrationStores?.setIsModalShopList(true)">
                            {{ t('order.flyer.searchButton') }}
                        </TheButton>
                        <p class="item__description">
                            {{ shopName }}
                            <v-tooltip activator="parent" location="bottom">{{ shopName }}</v-tooltip>
                        </p>
                    </div>
                </div>
                <!-- 学校 -->
                <div class="flyer__selection--item">
                    <div class="item__label">{{ t('order.flyer.schoolName') }}</div>
                    <div class="item__selection">
                        <TheSelect
                            :selection="school"
                            :disabled="hide || userStore?.userInfo?.affiliationKbn === 2"
                            :options="schoolOptions?.map((item) => item?.schoolName)"
                            data-type="string"
                            :array-value="schoolOptions?.map((item) => item?.schoolCode)"
                            @input="
                                (newValue) => {
                                    school = newValue;
                                }
                            "
                        ></TheSelect>
                    </div>
                </div>
                <!-- 開始年 -->
                <div class="flyer__selection--item">
                    <div class="item__label">{{ t('order.flyer.year') }}</div>
                    <div class="item__selection">
                        <!-- Ver1.2 #17 -->
                        <TheSelect
                            :selection="year.toString()"
                            :disabled="hide"
                            :options="[all, ...yearArray]"
                            :array-value="[all, ...yearArray]"
                            data-type="string"
                            @input="
                                (newValue) => {
                                    year = newValue;
                                }
                            "
                        ></TheSelect>
                    </div>
                </div>
                <!-- チラシ名 -->
                <div class="flyer__selection--item">
                    <div class="item__label">{{ t('order.table.flyerName') }}</div>
                    <div class="item__selection">
                        <TheSelect
                            :selection="flyer"
                            :disabled="hide"
                            :options="flyerOptionsComputed?.map((item) => item?.flyerName)"
                            data-type="string"
                            :array-value="flyerOptionsComputed?.map((item) => item?.flyerCode)"
                            @input="
                                (newValue) => {
                                    flyer = newValue;
                                }
                            "
                        ></TheSelect>
                    </div>
                </div>
                <!-- 注文状態 -->
                <div class="flyer__selection--item">
                    <div class="item__label">{{ t('order.table.orderStatus') }}</div>
                    <div class="item__selection">
                        <!-- Ver1.2 #17 -->
                        <!-- <TheSelect
                            v-if="userStore?.userInfo?.affiliationKbn !== 3"
                            :selection="status"
                            :options="[all, ...orderStatusConst.options]"
                            :array-value="[all, ...orderStatusConst.values]"
                            @input="
                                (newValue) => {
                                    status = newValue;
                                }
                            "
                        ></TheSelect> -->
                        <div class="checbox__list">
                            <v-checkbox
                                v-for="orderStatus in statusTag"
                                :key="orderStatus?.value"
                                v-model="status"
                                class="checkbox__item"
                                :value="orderStatus?.value"
                                :label="orderStatus?.description"
                            ></v-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheSelect from '../../../../components/select/TheSelect.vue';
import BaseDialogShop from '../dialog/BaseDialogShop.vue';
import DialogCustom from '../../../../components/dialog/DialogCustom.vue';
// import DatePicker from '../../../../components/datePicker/DatePicker.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useOrderStores } from '../../../../stores/OrderStores.js';
import { useUserStore } from '../../../../stores/UserStore.js';
import { useFlyerRegistrationStores } from '../../../../stores/FlyerRegistrationStores.js';
import { yearArray } from '../../../../utils/Helpers.js';
// Ver1.2 #17
// import { orderStatusConst, statusTag } from '../../../../utils/Consts.js';
import { statusTag } from '../../../../utils/Consts.js';
import OrdersService from '../../../../services/OrdersService.js';
import FlyerRegistrationService from '../../../../services/FlyerRegistrationService.js';
import useFetch from '../../../../utils/useFetch';
import iconSearch from '../../../../assets/icons/search.svg';
import iconSearchGray from '../../../../assets/icons/search-gray.svg';

// i18n
const { t } = useI18n();

// store
const orderStores = useOrderStores();
const userStore = useUserStore();
const flyerRegistrationStores = useFlyerRegistrationStores();

// ref
const expandOptions = ref(true);
const all = t('common.all');
const defautlFlyer = t('common.default');
const shopCode = ref('');
const shopName = ref('');
const school = ref(all);
//  Ver1.2 #17
const year = ref(all);
const flyer = ref(all);
const status = ref([1, 2]);
const hide = ref(true);
const replace = ref(true);

// options
const flyerOptions = ref([{ flyerCode: all, flyerName: all }]);
const schoolOptions = ref([{ schoolCode: all, schoolName: all }]);
const options = ref();

// service
const { isError, data, fetchData } = useFetch(OrdersService.getSearchOrderBoxList);
const fetchGetFlyerRegisterShopName = useFetch(FlyerRegistrationService?.getFlyerRegisterShopName);

// function
const handleShowStatus = () => {
    expandOptions.value = !expandOptions.value;
};
const handleSearch = () => {
    // Ver1.2 #17
    orderStores?.setSearchParams({
        userId: userStore?.userId,
        flyerCode: flyer.value === all ? '' : flyer.value,
        schoolCode: school.value === all ? '' : school.value,
        shopCode: shopCode.value ? shopCode.value : '',
        startYear: year.value === all ? '' : year.value,
        orderStatus: status.value?.length > 0 ? status.value : [],
    });
};
const handleCommon = () => {
    replace.value = true;
    hide.value = false;
    fetchData({
        userId: userStore?.userId,
        // Ver1.2 #17
        startYear: year.value === all ? '' : year.value,
    });
};

// mouted
onMounted(async () => {
    if (userStore?.userInfo?.affiliationKbn === 3) {
        shopCode.value = userStore?.userInfo?.shopCd;
        school.value = all;
        handleCommon();
        // Ver1.2 #17
        handleSearch();
    }
    if (userStore?.userInfo?.affiliationKbn === 2) {
        school.value = userStore?.userInfo?.schoolCd;
        shopCode.value = '';
        handleCommon();
        // Ver1.2 #17
        handleSearch();
    }
});

// watch
watch(
    () => userStore?.userInfo?.affiliationKbn,
    () => {
        if (userStore?.userInfo?.affiliationKbn === 3) {
            shopCode.value = userStore?.userInfo?.shopCd;
            // Ver1.2 #17
            handleCommon();
            handleSearch();
        }
        if (userStore?.userInfo?.affiliationKbn === 2) {
            shopCode.value = '';
            school.value = userStore?.userInfo?.schoolCd;
            handleCommon();
            // Ver1.2 #17
            handleSearch();
        }
    },
);

watch(isError, (newValue) => {
    flyerOptions.value = [
        // Ver1.2 #17
        // { schoolCode: defautlFlyer, schoolName: defautlFlyer },
        { flyerCode: all, flyerName: all },
    ];
    if (userStore?.userInfo?.affiliationKbn !== 2) {
        schoolOptions.value = [
            // Ver1.2 #17
            // { schoolCode: defautlFlyer, schoolName: defautlFlyer },
            { schoolCode: all, schoolName: all },
        ];
    } else {
        schoolOptions.value = [
            // Ver1.2 #17
            // { schoolCode: defautlFlyer, schoolName: defautlFlyer },
            { schoolCode: all, schoolName: all },
            { schoolCode: userStore?.userInfo?.schoolCd, schoolName: userStore?.userInfo?.schoolName },
        ];
    }
});

watch(data, (newValue) => {
    if (newValue?.status === 0) {
        flyerOptions.value = [
            // Ver1.2 #17
            // { flyerCode: defautlFlyer, flyerName: defautlFlyer },
            { flyerCode: all, flyerName: all },
        ];
        if (replace.value) {
            if (userStore?.userInfo?.affiliationKbn !== 2) {
                schoolOptions.value = [
                    // Ver1.2 #17
                    // { schoolCode: defautlFlyer, schoolName: defautlFlyer },
                    { schoolCode: all, schoolName: all },
                ];
            } else {
                schoolOptions.value = [
                    // Ver1.2 #17
                    // { schoolCode: defautlFlyer, schoolName: defautlFlyer },
                    { schoolCode: userStore?.userInfo?.schoolCd, schoolName: userStore?.userInfo?.schoolName },
                ];
            }
            newValue?.result?.data?.schoolList?.forEach((item) => {
                schoolOptions.value?.push({ schoolCode: item?.schoolCode, schoolName: item?.schoolName });
                flyerOptions.value = [...flyerOptions.value, ...item.flyerList];
            });
        } else {
            newValue?.result?.data?.schoolList?.forEach((item) => {
                flyerOptions.value = [...flyerOptions.value, ...item.flyerList];
            });
        }

        options.value = newValue?.result?.data?.schoolList;
        replace.value = false;
    }
});
/**
 * listen Error when call fetchGetFlyerRegisterShopName API.
 */
watch(fetchGetFlyerRegisterShopName?.isError, (newError) => {
    if (newError) {
        shopName.value = t('error.validateMessage14', { name: shopCode.value });
        hide.value = true;
    }
});

/**
 * listen response when call fetchGetFlyerRegisterShopName API.
 */
watch(fetchGetFlyerRegisterShopName?.data, (newData) => {
    if (newData) {
        shopName.value = newData?.result?.data?.shopName;
        hide.value = false;
    }
});

watch(shopName, (newData) => {
    replace.value = true;
    schoolOptions.value = [
        // Ver1.2 #17
        // { schoolCode: defautlFlyer, schoolName: defautlFlyer },
        { schoolCode: all, schoolName: all },
    ];
    if (userStore?.userInfo.affiliationKbn !== 1) {
        fetchData({
            userId: userStore?.userId,
            // Ver1.2 #17
            startYear: year.value === all ? '' : year.value,
        });
    } else {
        fetchData({
            userId: userStore?.userId,
            shopCode: shopCode.value,
            // Ver1.2 #17
            startYear: year.value === all ? '' : year.value,
        });
    }
    // status.value = all;
    // flyer.value = defautlFlyer;
    // school.value = defautlFlyer;

    // Ver1.2 #17
    status.value = [...status.value];
    flyer.value = all;
    school.value = all;
});

watch(year, (newValue) => {
    // Ver1.2 #17
    flyer.value = all;
    if (userStore?.userInfo.affiliationKbn !== 1) {
        fetchData({
            userId: userStore?.userId,
            // Ver1.2 #17
            startYear: newValue === all ? '' : newValue,
        });
    } else {
        fetchData({
            userId: userStore?.userId,
            shopCode: shopCode.value,
            // Ver1.2 #17
            startYear: newValue === all ? '' : newValue,
        });
    }
});
const flyerOptionsComputed = computed(() => {
    let tempArray = [
        // Ver1.2 #17
        // { flyerCode: defautlFlyer, flyerName: defautlFlyer },
        { flyerCode: all, flyerName: all },
    ];
    // Ver1.2 #17
    if (school.value === defautlFlyer || school.value === all) {
        options.value?.forEach((item) => {
            tempArray = [...tempArray, ...item.flyerList];
        });
    } else {
        options.value?.forEach((item) => {
            if (item?.schoolCode === school.value) {
                tempArray = [...tempArray, ...item.flyerList];
            }
        });
    }
    return tempArray;
});

watch(school, (newSchool) => {
    // Ver1.2 #17
    flyer.value = all;
});
</script>

<style lang="css" src="./BaseSearchFlyer.css" scoped></style>

<style lang="css">
.v-input__control {
    width: fit-content;
}
.v-input.v-input--horizontal.v-input--center-affix.v-input--density-default.v-locale--is-ltr.v-checkbox.checkbox__item {
    width: 50%;
}
.v-selection-control.v-selection-control--density-default.v-checkbox-btn,
.v-label.v-label--clickable {
    min-height: 20px !important;
    gap: 4px;
}
.v-selection-control__input,
.v-selection-control__wrapper {
    height: 20px !important;
    width: 20px !important;
}
</style>
