<template>
    <div class="wrapper__product__registration">
        <Teleport to="body">
            <component :is="DialogCustom" :show="productRegistrationStores?.isNotice === 1" :z-index="990">
                <BaseDialogNavigate></BaseDialogNavigate>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="productRegistrationStores?.isNotice === 2" :z-index="990">
                <BaseDialog :title="productRegistrationStores?.titleNotice" :content="productRegistrationStores?.contentNotice">
                    <template #default>
                        <div class="button__action">
                            <TheButton class="button__action--cancel" @click="handleConfirm(true)">
                                {{ t('confirm.content1') }}
                            </TheButton>
                        </div>
                    </template>
                </BaseDialog>
            </component>
        </Teleport>
        <Teleport to="body">
            <component :is="DialogCustom" :show="productRegistrationStores?.isNotice === 3" :z-index="990">
                <BaseDialog :title="productRegistrationStores?.titleNotice" :content="productRegistrationStores?.contentNotice">
                    <template #default>
                        <div class="button__action">
                            <TheButton class="button__action--cancel" @click="handleConfirm">
                                {{ t('confirm.content1') }}
                            </TheButton>
                        </div>
                    </template>
                </BaseDialog>
            </component>
        </Teleport>
        <div class="container">
            <div class="content__product__registration">
                <div class="section1">
                    <!--お知らせ - β1,β2は、テキスト情報を参照-->
                    <ThePaper class="section1__notify" :class="{ collapse: !commonStores?.isExpand }">
                        <BaseNotification :is-expand="commonStores?.isExpand" />
                    </ThePaper>
                    <!-- TODO - β1,β2は、何も表示しない、もしくは固定メッセージ
                    <ThePaper class="section1__todo">
                        <BaseTodos :is-expand="commonStores?.isExpand" />
                    </ThePaper> -->
                    <BaseBanner class="section1__banner" :is-expand="commonStores?.isExpand" />
                </div>
                <ThePaper class="section2">
                    <RegisterContent></RegisterContent>
                </ThePaper>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BaseNotification from '../../components/notifications/BaseNotification.vue';
import BaseBanner from '../../components/banner/BaseBanner.vue';
// import BaseTodos from '../../components/todos/BaseTodos.vue';
import TheButton from '../../components/button/TheButton.vue';
import ThePaper from '../../components/paper/ThePaper.vue';
import DialogCustom from '../../components/dialog/DialogCustom.vue';
import BaseDialog from '../../components/dialog/BaseDialog.vue';
import BaseDialogNavigate from './components/dialog/BaseDialogNavigate.vue';
import RegisterContent from './components/content/RegisterContent.vue';
import { useProductRegistrationStores } from '../../stores/ProductRegistrationStores.js';
import { useCommonStores } from '../../stores/CommonStores.js';
import { useUserStore } from '../../stores/UserStore.js';

// i18n
const { t } = useI18n();

// stores
const productRegistrationStores = useProductRegistrationStores();
const commonStores = useCommonStores();
const userStore = useUserStore();

// router
const router = useRouter();
const route = useRoute();

// function
const handleConfirm = () => {
    productRegistrationStores?.setIsNotice(0);
    if (productRegistrationStores?.navigate) {
        router.push(
            `/flyers/${encodeURIComponent(route.params?.flyerCode)}/${encodeURIComponent(
                route.params?.shopCode,
            )}/${encodeURIComponent(route.params?.eventCode)}/${encodeURIComponent(
                route.params?.schoolCode,
            )}/${encodeURIComponent(route.params?.schoolYear)}/update/products`,
        );
    }
};

// mounted
onMounted(() => {
    if (userStore?.userInfo?.affiliationKbn === 2) {
        router.push('/orders');
    }
});

// watch
watch(
    () => userStore?.userInfo?.affiliationKbn,
    (newValue, oldValue) => {
        if (newValue === 2 || oldValue === 2) {
            router.push('/orders');
        }
    },
);

// unMouted
onUnmounted(() => {
    productRegistrationStores?.reset();
});
</script>

<style lang="css" src="./ProductRegistrationPage.css" scoped></style>
