import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useShopStores = defineStore('shopStores', () => {
    const shopDetail = ref(false);
    const shopUpdate = ref(false);
    const shopCode = ref('');
    const shopListRefreshData = ref(true);
    const shopDeleteConfirm = ref(false);
    const shopDeleteResult = ref(false);
    const shopDetailRefreshData = ref(true);
    const shopUpdateRefreshData = ref(true);
    const errorContent = ref('');
    const searchParams = ref({});
    const isListSearch = ref(true);
    const shopStatusOptions = ref({
        label: ['全て', '無効', '有効'],
        data: ['', 0, 1],
    });
    const shopList = ref(true);
    /*
        1 : register
        2 : detail
        3 : update
        4 : non
    */
    const shopCRUDStatus = ref(4);
    const isModalNavigate = ref(false);
    function reset() {
        shopDetail.value = false;
        shopUpdate.value = false;
        shopCode.value = '';
        shopListRefreshData.value = true;
        shopDeleteConfirm.value = false;
        shopDeleteResult.value = false;
        shopDetailRefreshData.value = true;
        shopUpdateRefreshData.value = true;
        errorContent.value = '';
        searchParams.value = {};
        isListSearch.value = true;
        shopList.value = true;
        shopCRUDStatus.value = false;
        shopCRUDStatus.value = 4;
        isModalNavigate.value = false;
    }

    function setShopList(value) {
        shopList.value = value;
    }

    function setIsListSearch() {
        isListSearch.value = !isListSearch.value;
    }
    function setSearchParams(value) {
        searchParams.value = value;
    }

    function setShopDetailRefreshData() {
        shopDetailRefreshData.value = !shopDetailRefreshData.value;
    }
    function setShopUpdateRefreshData() {
        shopUpdateRefreshData.value = !shopUpdateRefreshData.value;
    }

    function setShopDeleteConfirm(value) {
        shopDeleteConfirm.value = value;
    }

    function setShopDeleteResult(value) {
        shopDeleteResult.value = value;
    }

    function setShopDetail(value) {
        shopDetail.value = value;
    }
    function setShopUpdate(value) {
        shopUpdate.value = value;
    }
    function setShopCode(value) {
        shopCode.value = value;
    }
    function setShopListRefreshData() {
        shopListRefreshData.value = !shopListRefreshData.value;
    }
    function setErrorContent(value) {
        errorContent.value = value;
    }
    function setShopCRUDStatus(value) {
        shopCRUDStatus.value = value;
    }
    function setIsModalNavigate(value) {
        isModalNavigate.value = value;
    }
    return {
        shopDetail: shopDetail,
        setShopDetail: setShopDetail,
        shopUpdate: shopUpdate,
        setShopUpdate: setShopUpdate,
        shopCode: shopCode,
        setShopCode: setShopCode,
        shopListRefreshData: shopListRefreshData,
        setShopListRefreshData: setShopListRefreshData,
        shopDeleteConfirm: shopDeleteConfirm,
        setShopDeleteConfirm: setShopDeleteConfirm,
        shopDeleteResult: shopDeleteResult,
        setShopDeleteResult: setShopDeleteResult,
        shopDetailRefreshData: shopDetailRefreshData,
        shopUpdateRefreshData: shopUpdateRefreshData,
        setShopDetailRefreshData: setShopDetailRefreshData,
        setShopUpdateRefreshData: setShopUpdateRefreshData,
        shopCRUDStatus: shopCRUDStatus,
        setShopCRUDStatus: setShopCRUDStatus,
        errorContent,
        setErrorContent,
        searchParams: searchParams,
        setSearchParams: setSearchParams,
        isListSearch: isListSearch,
        setIsListSearch: setIsListSearch,
        reset,
        shopStatusOptions: shopStatusOptions,
        shopList: shopList,
        setShopList: setShopList,
        isModalNavigate,
        setIsModalNavigate,
    };
});
