<template>
    <div class="notification__item">
        <div class="item__date">{{ props?.date }}</div>
        <div class="item__content" :class="{ visited: visited }">
            <div>
                <div class="notification__dot"></div>
            </div>
            <p class="notice__text" :class="`color-${props?.color}`">{{ props?.content }}</p>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    date: {
        type: String,
        required: true,
    },
    content: {
        type: String,
        required: true,
    },
    visited: {
        type: Boolean,
        required: false,
    },
    color: {
        type: String,
        required: false,
        default: '',
    },
});
</script>

<style lang="css" src="./NotificationItem.css" scoped></style>
