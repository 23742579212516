<template>
    <td
        :style="{
            ...column?.styles,
            'letter-spacing': isDoubleByte(props?.item[props?.column?.key]) ? '0px' : 'unset',
            ...column?.stylesBody,
        }"
    >
        <component :is="props?.column?.render(props?.column, props?.item)" v-if="props?.column?.render" />
        {{ !props?.column?.render ? props?.item[props?.column?.key] : null }}
    </td>
</template>

<script setup>
import { isDoubleByte } from '../../utils/Helpers';
const props = defineProps({
    item: {
        type: Object,
    },
    column: {
        type: Object,
    },
});
</script>

<style lang="css" src="./TableRowCell.css" scoped></style>
