import { http } from '../utils/Axios';
import { networkErrorMsg } from '../utils/Consts';

class StudentsService {
    static async getStudentList(searchParams) {
        try {
            const response = await http.get('/getStudentList', {
                params: {
                    ...searchParams,
                    offset: (searchParams.page - 1) * searchParams.limit,
                    page: undefined,
                },
            });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }

    static async getGradeClassListSearch(params) {
        try {
            const response = await http.get('/getGradeClassListSearch', {
                params: {
                    ...params,
                },
            });
            return response?.data;
        } catch (error) {
            if (error?.response?.data?.result?.message) throw new Error(error?.response?.data?.result?.message);
            else throw new Error(networkErrorMsg);
        }
    }
}

export default StudentsService;
