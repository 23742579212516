<template>
    <div class="order__information">
        <TheTitle class="order__information--title">
            <h2>{{ t('orderDetail.orderInfo.title') }}</h2>
            <button
                v-if="userStore?.userInfo?.affiliationKbn !== 2"
                class="delete__order-btn"
                :disabled="
                    userStore?.userInfo?.affiliationKbn === 2 ||
                    orderDetailStores?.orderDetailInfo?.orderStatus === '4' ||
                    orderDetailStores?.orderDetailInfo?.orderStatus === '3'
                "
                @click="handleShowModal"
            >
                <img src="../../../../assets/icons/trash.svg" alt="trash" />
                {{ t('orderDetail.orderInfo.buttonContent') }}
            </button>
        </TheTitle>
        <BaseInformationDetailContent>
            <div class="header__content">
                <TagInfo :label="t('orderDetail.orderInfo.orderCode')">
                    {{ orderDetailStores?.orderDetailInfo?.orderCode }}
                </TagInfo>
            </div>
            <div class="infomation__content">
                <TagInfo :label="t('orderDetail.orderInfo.orderStatus')">
                    <TheBadge
                        v-if="orderDetailStores?.orderDetailInfo?.orderStatus"
                        :badge-content="orderStatus[orderDetailStores?.orderDetailInfo?.orderStatus]?.badgeContent"
                        :bg-color="orderStatus[orderDetailStores?.orderDetailInfo?.orderStatus]?.bgColor"
                        :color="orderStatus[orderDetailStores?.orderDetailInfo?.orderStatus]?.color"
                        :border-color="orderStatus[orderDetailStores?.orderDetailInfo?.orderStatus]?.borderColor"
                    ></TheBadge>
                </TagInfo>
                <TagInfo v-for="(item, index) in orderInfoRow1" :key="index" :label="item?.label">
                    <p class="infomation__content--item">{{ orderDetailStores?.orderDetailInfo?.[item?.key] }}</p>
                </TagInfo>
                <TagInfo :label="payment?.label">
                    <img
                        v-if="
                            orderDetailStores?.orderDetailInfo?.orderStatus && orderDetailStores?.orderDetailInfo?.[payment?.key]
                        "
                        :src="imagesType[orderDetailStores?.orderDetailInfo?.[payment?.key]]"
                        class="infomation__content--icon"
                        alt="icon-payment"
                    />
                </TagInfo>
                <TagInfo v-for="(item, index) in orderInfoRow2" :key="index" :label="item?.label">
                    <p class="infomation__content--item">{{ orderDetailStores?.orderDetailInfo?.[item?.key] }}</p>
                </TagInfo>
            </div>
        </BaseInformationDetailContent>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import TheTitle from '../../../../components/title/TheTitle.vue';
import TheBadge from '../../../../components/badge/TheBadge.vue';
import TagInfo from '../../../../components/tagInfo/TagInfo.vue';
import BaseInformationDetailContent from '../informationDetailContent/BaseInformationDetailContent.vue';
import { useOrderDetailStores } from '../../../../stores/OrderDetailStores';
import { useUserStore } from '../../../../stores/UserStore';
import { orderStatus } from '../../../../utils/Consts.js';
import visaIcon from '../../../../assets/icons/visa.svg';
import paypayIcon from '../../../../assets/icons/paypay.svg';
import payShopIcon from '../../../../assets/icons/pay-shop.svg';

// i18n
const { t } = useI18n();
// stores
const orderDetailStores = useOrderDetailStores();
const userStore = useUserStore();

// function
const handleShowModal = () => {
    if (
        userStore?.userInfo?.affiliationKbn !== 2 ||
        orderDetailStores?.orderDetailInfo?.orderStatus !== '4' ||
        orderDetailStores?.orderDetailInfo?.orderStatus !== '3'
    ) {
        orderDetailStores?.setModalDeleteOrder(true);
    }
};
const orderInfoRow1 = [
    {
        label: t('orderDetail.orderInfo.puchOdrDate'),
        key: 'puchOdrDate',
    },
    {
        label: t('orderDetail.orderInfo.shipDate'),
        key: 'shipDate',
    },
    {
        label: t('orderDetail.orderInfo.userCancelDate'),
        key: 'userCancelDate',
    },
];

const payment = {
    label: t('orderDetail.orderInfo.payType'),
    key: 'payType',
};

const imagesType = {
    1: visaIcon,
    2: paypayIcon,
    3: payShopIcon,
};

const orderInfoRow2 = [
    {
        label: t('orderDetail.orderInfo.orderDate'),
        key: 'orderDate',
    },
    {
        label: t('orderDetail.orderInfo.delvDate'),
        key: 'delvDate',
    },
    {
        label: t('orderDetail.orderInfo.cancelDate'),
        key: 'cancelDate',
    },
];
</script>

<style lang="css" src="./BaseInformationOrder.css" scoped></style>
