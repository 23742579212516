<template>
    <BaseDialog
        :title="t('flyerRegistration.dialogTitle1')"
        :content="t('flyerRegistration.dialogContent1_1') + '\n' + t('flyerRegistration.dialogContent1_2')"
    >
        <template #default>
            <div class="more__action">
                <div class="button__action">
                    <TheButton class="button__action--cancel" @click="handleCloseModal">{{ t('confirm.content2') }}</TheButton>
                    <TheButton class="button__action--confirm" @click="handleDeleteOrder">{{ t('confirm.content3') }}</TheButton>
                </div>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import BaseDialog from '../../../../components/dialog/BaseDialog.vue';
import TheButton from '../../../../components/button/TheButton.vue';
import { useFlyerRegistrationStores } from '../../../../stores/FlyerRegistrationStores.js';

// i18n
const { t } = useI18n();

// router
const router = useRouter();

// store
const flyerRegistrationStores = useFlyerRegistrationStores();

// function
const handleCloseModal = () => {
    flyerRegistrationStores?.setIsModalNavigate(false);
};

const handleDeleteOrder = () => {
    flyerRegistrationStores?.setIsModalNavigate(false);
    router.push('/flyers');
};
</script>

<style lang="css" src="./BaseDialogNavigate.css" scoped></style>
