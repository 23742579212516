import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', () => {
    const isLogin = ref(false);
    const user = ref(true);
    const userId = ref('');
    const userInfo = ref({});

    function setIsLogin(value) {
        isLogin.value = value;
    }
    function setUserId(id) {
        userId.value = id;
    }
    function setUserInfo(user) {
        userInfo.value = user;
    }
    function setUser(value) {
        user.value = value;
    }
    function reset() {
        setIsLogin(false);
        setUser(true);
        setUserInfo({});
        setUserId('');
    }
    return {
        isLogin,
        setIsLogin,
        user,
        setUser,
        userId,
        setUserId,
        userInfo,
        setUserInfo,
        reset,
    };
});
