<template>
    <div class="table__wrapper">
        <table class="table">
            <thead>
                <TableHeader :columns="props?.columns" />
            </thead>
            <tbody v-if="props?.data && props?.data?.length !== 0">
                <TableRow
                    :data="props?.data"
                    :columns="props?.columns"
                    :unique-attr="props?.uniqueAttr"
                    @handle-update-postion="(products) => emits('handleUpdatePostion', products)"
                />
            </tbody>
        </table>
    </div>
</template>

<script setup>
import TableHeader from '../table/TableHeader.vue';
import TableRow from './TableRow.vue';

const props = defineProps({
    columns: Array,
    data: Array,
    uniqueAttr: String,
});
const emits = defineEmits(['handleUpdatePostion']);
</script>

<style lang="css" src="../table/TheTable.css" scoped></style>
