<template>
    <div class="wrapper__input--item">
        <!-- feature/Ver1.2#1#3 -->
        <label :class="`${props?.customClassLabel ? 'input--item__label' : 'input--item__label__group'}`">
            {{ props?.label }}
            <strong v-if="props?.important">{{ t('common.required') }}</strong>
        </label>
        <div class="input--item__slot">
            <slot></slot>
            <p
                v-if="props?.required && props?.select"
                :class="`${
                    props?.customMessage ? 'validate__message' : `${'validate__message--custom'}${props?.numberCustomMessage}`
                }`"
            >
                {{ t('error.validateMessage9', { name: props?.label }) }}
            </p>
            <p
                v-if="props?.required && !props?.select && props?.default"
                :class="`${
                    props?.customMessage
                        ? 'validate__message g'
                        : `${'general_custom validate__message--custom'}${props?.numberCustomMessage}`
                }`"
            >
                {{ t('error.validateMessage8', { name: props?.label }) }}
            </p>
            <p
                v-if="props?.required && !props?.select && !props?.default"
                :class="`${
                    props?.customMessage
                        ? 'validate__message'
                        : `${'general_custom validate__message--custom'}${props?.numberCustomMessage}`
                }`"
            >
                {{ t('error.validateMessage16', { n: '|' }) }}
            </p>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

// i18n
const { t } = useI18n();

// stores

const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
    select: {
        type: Boolean,
        required: false,
        default: true,
    },
    important: {
        type: Boolean,
        required: false,
        default: true,
    },
    default: {
        type: Boolean,
        required: false,
        default: true,
    },
    // feature/Ver1.2#1#3
    customClassLabel: {
        type: Boolean,
        required: false,
        default: true,
    },
    // feature/Ver1.2#1#3
    customMessage: {
        type: Boolean,
        required: false,
        default: true,
    },
    // feature/Ver1.2#1#3
    numberCustomMessage: {
        type: Number,
        required: false,
        default: 1,
    },
});
</script>

<style lang="css" src="./InputItem.css" scoped></style>
