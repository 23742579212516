<template>
    <div class="wrapper__base__dialog">
        <TheTitle class="dialog--title">
            <h2>
                {{ props?.title }}
                <slot name="icon-close"></slot>
            </h2>
        </TheTitle>
        <div class="dialog--content">
            <div v-if="props?.content" class="dialog--text">{{ props?.content }}</div>
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import TheTitle from '../title/TheTitle.vue';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    content: {
        type: String,
        required: false,
    },
    otherProp: {
        type: Object,
        required: false,
    },
});
</script>

<style lang="css" src="./BaseDialog.css" scoped></style>
